import { FormikValues, useFormikContext } from 'formik';

import { Flex } from 'components/flex';
import { getByPath } from 'utils/helpers';
import { InputField } from 'components/fields';

import { InputCountsProps } from './types';

export const InputCounts = ({
  name,
  product,
  hideUnit,
  onChange,
  hasTotalPrice,
  ...props
}: InputCountsProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const newValue = +e.target.value || 0;
    const fieldNamePart = fieldName.split('.').pop();

    const currentValues = getByPath(values, name);

    const cases =
      fieldNamePart === 'cases' ? newValue : +currentValues?.cases || 0;
    const units =
      fieldNamePart === 'units' ? newValue : +currentValues?.units || 0;

    const totalCount = units + cases * (product?.unitPerCase || 0);

    const costPrice = product?.costPrice ?? 0;
    const totalPrice = (currentValues?.price ?? costPrice) * totalCount;

    setFieldValue(`${name}.units`, units);
    setFieldValue(`${name}.cases`, cases);
    setFieldValue(`${name}.count`, totalCount);
    setFieldValue(`${name}.totalPrice`, totalPrice);

    onChange?.({
      cases,
      units,
      totalPrice,
      count: totalCount,
    });
  };

  return (
    <Flex gap={8}>
      <InputField
        number
        placeholder="Cases"
        name={`${name}.cases`}
        {...props}
        onChange={
          hasTotalPrice ? (e) => handleChange(e, `${name}.cases`) : undefined
        }
      />

      <InputField
        number
        disabled={hideUnit}
        placeholder="Units"
        name={`${name}.${hideUnit ? 'count' : 'units'}`}
        {...props}
        onChange={
          hasTotalPrice ? (e) => handleChange(e, `${name}.units`) : undefined
        }
      />
    </Flex>
  );
};
