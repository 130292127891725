import { useParams } from 'react-router-dom';

import { useNavigate, handleMutation } from 'hooks';
import { useGetQuery, useEditMutation } from 'store/api/company';

import { Form } from './form';

export const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useGetQuery(id!, { skip: !id });
  const [edit, mutation] = useEditMutation();

  handleMutation({
    ...mutation,
    onSuccess: () => navigate(-1),
  });

  return (
    <Form
      data={data}
      enableReinitialize
      onSubmit={(values) => {
        if (id && data) {
          edit({ ...values, id });
        }
      }}
    />
  );
};
