import { Formik, FormikConfig } from 'formik';

import { ButtonColors } from 'types';
import { useAppSelector } from 'store';
import * as CommonStyles from 'components/styles';
import { selectAuthUser } from 'store/slices/auth';
import { useAlert, isMobile, useNavigate } from 'hooks';
import { CustomerData } from 'store/api/customers/types';
import { handleFormSubmitWithFiles } from 'utils/helpers';
import { CustomerFormFieldsProps } from 'components/customer-form-fields';
import {
  Button,
  FormFooter,
  ButtonTypes,
  CustomerFormFields,
  CustomerFormValues,
  customerValidationSchema,
  getCustomerFormInitialValues,
} from 'components';

export interface FormProps
  extends Omit<FormikConfig<CustomerFormValues>, 'initialValues'>,
    Pick<CustomerFormFieldsProps, 'employeeOptions'>,
    Partial<Pick<FormikConfig<Partial<CustomerFormValues>>, 'initialValues'>> {
  data?: CustomerData;
}

export const Form = ({
  data,
  onSubmit,
  initialValues,
  employeeOptions,
  ...props
}: FormProps) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const { handleError } = useAlert();

  const authUser = useAppSelector(selectAuthUser);

  const isEdit = Boolean(data);

  return (
    <Formik
      {...props}
      validationSchema={customerValidationSchema}
      initialValues={{
        ...getCustomerFormInitialValues({
          data,
          authUser,
        }),
        ...initialValues,
      }}
      onSubmit={handleFormSubmitWithFiles({
        data,
        onSubmit,
        handleError,
        files: [
          {
            formFieldName: 'photo',
            requestFieldName: 'photoUrl',
            dataFieldName: 'person.photoUrl',
          },
        ],
      })}
    >
      {({ isSubmitting }) => (
        <CommonStyles.Form>
          <CustomerFormFields employeeOptions={employeeOptions} />

          <FormFooter
            right={
              <CommonStyles.FormButtons>
                <Button
                  text="Cancel"
                  fullWidth={mobile}
                  onClick={() => navigate(-1)}
                  colorType={ButtonColors.LightBlue}
                />
                <Button
                  fullWidth={mobile}
                  disabled={isSubmitting}
                  type={ButtonTypes.Submit}
                  text={isEdit ? 'Edit' : 'Create'}
                />
              </CommonStyles.FormButtons>
            }
          />
        </CommonStyles.Form>
      )}
    </Formik>
  );
};
