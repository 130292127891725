import styled, { css } from 'styled-components';

import { Input as TCInput } from '../../input';

export const Form = styled.form`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Label = styled.label`
  margin-right: 8px;
`;

export const Input = styled(TCInput)<{ forceMobileView: boolean }>`
  width: 64px;

  ${({ theme, forceMobileView }) => css`
    ${(theme.responsive.isMobile || forceMobileView) &&
    css`
      margin: 0 10px;

      input {
        text-align: center;
      }
    `}
  `}
`;
