import { useMemo, useState, useCallback } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { Nullable } from 'types';
import { range } from 'utils/helpers';

import { UsePaginationProps } from './types';

export const DOTS = '...';

const parsePage = (page?: Nullable<string>) => {
  if (!page) {
    return undefined;
  }
  return parseInt(page);
};

const usePagination = ({
  currentPage,
  totalPageCount,
  siblingCount = 1,
}: UsePaginationProps): (number | typeof DOTS)[] | undefined => {
  return useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not want to show dots if there is only one position left
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalPageCount, siblingCount, currentPage]);
};

const usePageQuery = () => {
  const [currentPage, setCurrentPage] = useState(
    parsePage(new URLSearchParams(location.search).get('page')) || 1
  );

  const scrollToTop = useCallback(() => {
    const scrollEl = document.getElementById('table-top');
    if (scrollEl) {
      scrollIntoView(scrollEl, {
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
        scrollMode: 'if-needed',
      });
    }
  }, []);

  const changePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      const params = new URLSearchParams(window.location?.search);
      if (page > 1) {
        params.set('page', String(page));
      } else {
        params.delete('page');
      }
      const search = params.toString();
      window.history.replaceState(
        null,
        '',
        search.length > 0
          ? `${window.location.pathname}?${params.toString()}`.replace(
              /%2C/g,
              ','
            )
          : window.location.pathname
      );
      scrollToTop();
    },
    [scrollToTop]
  );

  return { scrollToTop, currentPage, setCurrentPage: changePage };
};

export { usePageQuery, usePagination };
