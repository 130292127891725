import { Popup } from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styled, { css } from 'styled-components';

import { PopupProps } from './types';

export const StyledPopup = styled(Popup)<PopupProps>`
  &-overlay {
    padding: 16px 0;
    overflow-y: auto;
    z-index: 2000 !important;
    background: ${({ theme }) => theme.config.popup.modal};
  }

  &-content {
    padding: 0 !important;
    border: none !important;
    z-index: 2001 !important;

    ${({ width, theme, height, isBlack, maxWidth }) => css`
      width: ${width || 'auto'} !important;
      ${height && `height: ${height}`};
      max-width: ${maxWidth};
      border-radius: ${theme.borderRadius} !important;
      background: ${isBlack
        ? theme.config.popup.black.bg
        : theme.config.popup.bg};
      ${isBlack && `color: ${theme.config.popup.black.text}`};
      box-shadow: ${theme.config.popup.shadow} !important;
    `}
  }

  &-arrow {
    filter: drop-shadow(0 -5px 5px rgba(0, 0, 0, 0.16)) !important;
    stroke: none;

    & > path {
      fill: ${({ theme, isBlack }) =>
        isBlack ? theme.config.popup.black.bg : theme.config.popup.bg};
    }
  }
`;

export const HideInput = styled.input`
  display: none;
`;
