import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addParamsToUrl } from 'utils/query';
import {
  cacheByIdArg,
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import { Category, CreateFormValues, EditCategoryRequest } from './types';

const tagType = 'Category';

export const categoryApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'categoryApi',
  endpoints: (build) => ({
    details: build.query<Category, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.CATEGORY_DETAILS.replace(':id', id),
      }),
    }),

    create: build.mutation<void, CreateFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        data,
        method: 'post',
        url: URL.CATEGORIES,
      }),
    }),

    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.CATEGORY_DETAILS.replace(':id', id),
      }),
    }),

    list: build.query<Category[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (data) => ({
        data,
        method: 'get',
        url: addParamsToUrl(URL.CATEGORIES, { search: data?.search }),
      }),
    }),

    edit: build.mutation<void, EditCategoryRequest>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.CATEGORY_DETAILS.replace(':id', String(id)),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useEditMutation,
  useDetailsQuery,
  useRemoveMutation,
  useCreateMutation,
} = categoryApi;
