import * as Yup from 'yup';

import { Company } from 'types';
import { getUploadedFile } from 'utils/helpers';
import { CompanyFormValues } from 'store/api/company/types';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  processingFee: Yup.number()
    .min(1)
    .max(5)
    .required('Processing fee is required'),
});

export const getInitialValues = (
  data: Company | undefined
): CompanyFormValues => {
  return {
    name: data?.name || '',
    photo: getUploadedFile(data?.photoUrl),
    processingFee: data?.processingFee || 3.5,
  };
};
