import { FormikValues, useFormikContext } from 'formik';

import { Row, Col } from 'components/grid';
import { useListQuery } from 'store/api/users';
import { SelectField } from 'components/fields';
import { User, Roles, BackOfficeRoles } from 'types';

import { EventTeamAssignSelectsProps } from './types';

const getFilteredOptions = (
  users: User[],
  filterFn: (user: User) => boolean,
  excludeValues: number[] = []
) =>
  users
    ?.filter(filterFn)
    ?.filter((user) => !excludeValues.includes(user.id))
    ?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? [];

export const EventTeamAssignSelects = ({
  colProps,
  ...props
}: EventTeamAssignSelectsProps) => {
  const { values } = useFormikContext<FormikValues>();
  const { data: users = [] } = useListQuery();

  const filteredManagerOptions = getFilteredOptions(
    users,
    (user) => user.role?.name === BackOfficeRoles.MANAGER,
    values.teamMembers
  );

  const filteredUserOptions = getFilteredOptions(
    users,
    (user) => user.role?.name !== Roles.COMPANY_OWNER,
    [values.leaderId]
  );

  return (
    <Row>
      <Col {...colProps}>
        <SelectField
          onlyValue
          name="leaderId"
          label="Manager Assignment"
          placeholder="Manager Assignment"
          options={filteredManagerOptions}
          {...props}
        />
      </Col>

      <Col {...colProps}>
        <SelectField
          isMulti
          onlyValue
          name="teamMembers"
          label="Team Assignment"
          placeholder="Team Assignment"
          options={filteredUserOptions}
          {...props}
        />
      </Col>
    </Row>
  );
};
