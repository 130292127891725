import { Messages } from 'config';
import { PayoutType, ButtonColors } from 'types';
import { isMobile, handleMutation } from 'hooks';
import { Payout, useCreateMutation } from 'store/api/payout';
import {
  Button,
  Dialog,
  DialogType,
  ButtonTypes,
  NewEntityButtonWithRef,
  PaymentReimbursementForm,
} from 'components';

import { CreateReimbursementProps } from './types';

export const CreateReimbursement = ({ event }: CreateReimbursementProps) => {
  const mobile = isMobile();
  const [create, mutation] = useCreateMutation();

  handleMutation({
    ...mutation,
    successMessage: `Reimbursement ${Messages.ADDED_SUCCESSFULLY}`,
  });

  return (
    <Dialog
      title="Add reimbursement"
      trigger={<NewEntityButtonWithRef text="Add reimbursement" />}
    >
      {(close, _, ButtonWrap) => (
        <PaymentReimbursementForm
          isDialog
          hideBackButton
          isDisableEvent
          data={{ eventId: event.id } as Payout}
          onSubmit={async ({ isTeamLead, ...values }) => {
            const response = await create({
              ...values,
              amount: +values.amount,
              type: PayoutType.Reimbursements,
            }).unwrap();

            if (response.id) {
              close();
            }
          }}
          footerButtons={({ dirty, isSubmitting }) => (
            <ButtonWrap type={DialogType.Plain}>
              <Button
                text="Cancel"
                onClick={close}
                fullWidth={mobile}
                colorType={ButtonColors.LightBlue}
              />
              <Button
                text="Add"
                fullWidth={mobile}
                type={ButtonTypes.Submit}
                disabled={!dirty || isSubmitting}
              />
            </ButtonWrap>
          )}
        />
      )}
    </Dialog>
  );
};
