import { lazy } from 'react';

import { SalesList } from './sales';
import { BonusDetails } from './payments/bonuses';
import { AdvanceDetails } from './payments/advances';
import { EditBonuses, CreateBonuses } from './payments/bonuses/form';
import { EditAdvances, CreateAdvances } from './payments/advances/form';
import {
  EditReimbursements,
  CreateReimbursements,
} from './payments/reimbursements/form';

const Home = lazy(() => import('./details'));
const Payments = lazy(() => import('./payments'));
const Documents = lazy(() => import('./documents'));
const Customers = lazy(() => import('./customers'));
const ManageAppointments = lazy(() => import('./manage-appointments'));
const ReimbursementDetails = lazy(
  () => import('./payments/reimbursements/details')
);

export {
  Home,
  Payments,
  SalesList,
  Customers,
  Documents,
  EditBonuses,
  BonusDetails,
  EditAdvances,
  CreateBonuses,
  AdvanceDetails,
  CreateAdvances,
  ManageAppointments,
  EditReimbursements,
  CreateReimbursements,
  ReimbursementDetails,
};
