import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Messages } from 'config';
import { Details } from 'components';
import { mapQuery } from 'utils/query';
import { useAlert, useNavigate, handleMutation } from 'hooks';
import { useEditMutation, useDetailsQuery } from 'store/api/event';

import { Form } from './form';

export const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [edit, mutation] = useEditMutation();

  const { data, ...props } = useDetailsQuery(id!);

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Event ${Messages.UPDATED_SUCCESSFULLY}`);
    navigate(-1);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  return (
    <Details {...mapQuery(props)}>
      <Form data={data} onSubmit={(values) => edit({ ...values, id: id! })} />
    </Details>
  );
};
