import { Fragment } from 'react';

import { Flex } from 'components/flex';
import { ButtonColors, ButtonStyleTypes } from 'types';

import * as Styles from './styles';
import { StepperProps } from './types';

export const Stepper = ({
  steps,
  active,
  current,
  onChange,
  className,
}: StepperProps) => (
  <Styles.Container className={className}>
    {steps.map((step, index) => {
      const isDisabled = typeof active === 'number' && index > active;
      const isCurrent = current === index;
      const isLast = index === steps.length - 1;
      return (
        <Fragment key={index}>
          <Flex column gap={8}>
            <Styles.Button
              text={step}
              padding="8px"
              disabled={isDisabled}
              isCurrent={isCurrent}
              onClick={() => onChange(index)}
              styleType={ButtonStyleTypes.Ghost}
              colorType={
                isDisabled ? ButtonColors.LightBlue : ButtonColors.Blue
              }
              icon={
                <Styles.Number isCurrent={isCurrent} isDisabled={isDisabled}>
                  {index + 1}
                </Styles.Number>
              }
            />
          </Flex>

          {!isLast && <Styles.Divider isActive={!isDisabled} />}
        </Fragment>
      );
    })}
  </Styles.Container>
);
