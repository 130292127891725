import { useMemo } from 'react';
import { useField } from 'formik';

import { controlError } from 'utils/error';
import { DateRange, CommonFieldProps } from 'types';

import { DatePicker, DateInputProps } from '../date-picker';

export interface DatePickerFieldProps
  extends CommonFieldProps,
    Omit<DateInputProps, 'name' | 'onChange'>,
    Partial<Pick<DateInputProps, 'onChange'>> {}

export const DatePickerField = ({
  name,
  label,
  onChange,
  selectsRange,
  controlErrorLabel = true,
  ...props
}: DatePickerFieldProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);

  const error = controlError(meta, name, label, controlErrorLabel);

  const value = field.value;
  const inputProps = useMemo(() => {
    if (selectsRange) {
      const range = value as DateRange;
      return {
        endDate: range?.to,
        startDate: range?.from,
        onChange: async (dates: any): Promise<void> => {
          const [from, to] = dates;
          await helpers.setValue({ to, from });
          helpers.setTouched(true);
          onChange?.(dates);
        },
      };
    } else {
      return {
        selected: value,
        onChange: async (date: any): Promise<void> => {
          await helpers.setValue(date);
          helpers.setTouched(true);
          onChange?.(date);
        },
      };
    }
  }, [value, selectsRange, onChange]);

  return (
    <DatePicker
      label={label}
      selectsRange={selectsRange}
      input={{
        error,
      }}
      {...field}
      {...props}
      {...inputProps}
    />
  );
};
