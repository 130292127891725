import { formatPrice } from 'utils/helpers';
import { useListQuery } from 'store/api/payout';
import {
  Flex,
  Loader,
  Button,
  ListCard,
  InfoBlock,
  ListHeader,
} from 'components';
import {
  PayoutType,
  ButtonColors,
  CashCardType,
  PayoutStatus,
  ListCardStyle,
} from 'types';

import { ExpensesProps } from '../types';

import * as Styles from './styles';
import { titles, CashIcons, CashTitles } from './config';

interface CashCardProps {
  amount: number;
  type: CashCardType;
}

export const CashCard = ({ type, amount }: CashCardProps) => (
  <InfoBlock>
    <Flex gap={16} alignItems="center">
      <Styles.CashIcon type={type}>{CashIcons[type]}</Styles.CashIcon>
      <Flex gap={8} flexDirection="column">
        <Styles.CashTitle>{CashTitles[type]}</Styles.CashTitle>
        <h3>{formatPrice(amount)}</h3>
      </Flex>
    </Flex>
  </InfoBlock>
);

export const Expenses = ({ data, event }: ExpensesProps) => {
  const { isLoading, data: expenses } = useListQuery({
    eventId: String(event.id),
    // TODO: need change status to Paid when backend was ready
    status: PayoutStatus.InProgress,
    types: JSON.stringify([PayoutType.Expense]),
  });

  const totalCash = data.reduce((acc, value) => acc + value.total.total, 0);
  const totalOut =
    expenses?.reimbursements?.reduce?.((acc, value) => acc + value.amount, 0) ||
    0;

  return (
    <Loader isFullScreen={false} isLoading={isLoading}>
      <Flex gap={24} fullHeight>
        <Styles.Expenses>
          <CashCard amount={totalCash} type={CashCardType.Cash} />
          <CashCard amount={totalOut} type={CashCardType.Out} />
          <CashCard type={CashCardType.Balance} amount={totalCash - totalOut} />
        </Styles.Expenses>

        <Flex gap={24} fullWidth fullHeight flexDirection="column">
          <Styles.StyledList
            mainWrapperStyles={{ padding: 0 }}
            count={expenses?.reimbursements?.length}
            header={<ListHeader padding="8px" titles={titles} />}
            footer={
              <ListCard
                selected
                padding="16px"
                template="1fr 1fr 1fr"
                even={ListCardStyle.Green}
                values={[
                  <p className="semibold">Total</p>,
                  formatPrice(totalOut),
                ]}
              />
            }
          >
            {expenses?.reimbursements?.map((value) => (
              <ListCard
                key={value.id}
                padding="16px"
                values={[
                  value.notes,
                  formatPrice(value.amount),
                  <div>
                    <Button
                      transparent
                      padding="0"
                      text="Download"
                      to={value.receiptUrl}
                      colorType={ButtonColors.LightBlue}
                    />
                  </div>,
                ]}
              />
            ))}
          </Styles.StyledList>

          {/* TODO: add button for create expenses */}
          {/* <div>
            <Button text="Add expenses" colorType={ButtonColors.LightBlue} />
          </div> */}
        </Flex>
      </Flex>
    </Loader>
  );
};
