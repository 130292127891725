import styled, { css } from 'styled-components';

import { flexSpaceBetween } from 'styles/mixins/index';

import { GroupDateProps } from './types';

export const Container = styled.div`
  ${flexSpaceBetween};
  margin: 8px 0 24px;
`;

export const DateBadge = styled.div<Pick<GroupDateProps, 'isNew'>>`
  padding: 0 16px;
  border-radius: 24px;
  ${({ theme, isNew }) => css`
    color: ${isNew ? theme.config.text.white : theme.config.text.primary};
    background: ${isNew
      ? theme.colors.blue.primary.standard
      : theme.colors.grey.secondary.standard};
  `}
`;

export const Line = styled.div<Pick<GroupDateProps, 'isNew'>>`
  flex: 1;
  height: 1px;
  background: ${({ theme, isNew }) =>
    isNew
      ? theme.colors.blue.tertiary.action
      : theme.colors.grey.tertiary.action};
`;
