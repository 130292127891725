import styled, { css } from 'styled-components';

import { captionRegular } from 'styles/mixins/fonts';

export const Container = styled.div`
  padding: 8px 16px;

  ${({ theme }) => css`
    box-shadow: inset 0 -1px 0 0 ${theme.config.sidebar.border};

    svg path {
      fill: ${theme.config.text.primary};
    }
  `}
`;

export const Title = styled.h4`
  ${captionRegular};
  padding-bottom: 8px;
  color: ${({ theme }) => theme.config.input.placeholder};
`;
