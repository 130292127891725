import { cloneElement } from 'react';
import { PopupPosition } from 'reactjs-popup/dist/types';

import { EllipsisText } from 'components/ellipsis-text';
import { ErrorMessage } from 'components/error-message';

import { Icon } from '../icon';

import { InputContainerProps } from './types';
import {
  Label,
  InputWrap,
  IconWrapper,
  LeftSvgWrap,
  StyledPopup,
  RightSvgWrap,
  InputFieldWrap,
} from './styles';

const tooltipPosition: PopupPosition[] = [
  'bottom center',
  'top center',
  'left center',
  'right center',
];

export const InputContainer = ({
  file,
  large,
  label,
  value,
  error,
  style,
  footer,
  testId,
  search,
  tooltip,
  leftIcon,
  disabled,
  children,
  rightIcon,
  className,
  visibleType,
  requiredText,
  type = 'text',
  setVisibleType,
  onLeftIconClick,
  onRightIconClick,
}: InputContainerProps) => {
  return (
    <InputFieldWrap style={style} data-testid={testId} className={className}>
      {label && (
        <Label htmlFor={label} disabled={disabled}>
          <EllipsisText>{label}</EllipsisText>
          {requiredText && <ErrorMessage>{requiredText}</ErrorMessage>}
          {tooltip && (
            <StyledPopup
              aria-label={tooltip}
              on={['hover', 'focus']}
              position={tooltipPosition}
              trigger={
                <IconWrapper>
                  <Icon.Info />
                </IconWrapper>
              }
            >
              {tooltip}
            </StyledPopup>
          )}
        </Label>
      )}

      <InputWrap className="input-wrap">
        {leftIcon && !search && (
          <LeftSvgWrap
            large={large}
            disabled={disabled}
            withOnClick={onLeftIconClick !== undefined}
          >
            {cloneElement(leftIcon, { onClick: onLeftIconClick })}
          </LeftSvgWrap>
        )}
        {rightIcon && !search && (
          <RightSvgWrap
            large={large}
            disabled={disabled}
            withOnClick={onRightIconClick !== undefined}
          >
            {cloneElement(rightIcon, { onClick: onRightIconClick })}
          </RightSvgWrap>
        )}

        {search && (
          <>
            <LeftSvgWrap
              large={large}
              disabled={disabled}
              withOnClick={onLeftIconClick !== undefined}
            >
              <Icon.Search onClick={onLeftIconClick} />
            </LeftSvgWrap>

            {value && (
              <RightSvgWrap
                large={large}
                disabled={disabled}
                withOnClick={onRightIconClick !== undefined}
              >
                <Icon.CloseCircle onClick={onRightIconClick} />
              </RightSvgWrap>
            )}
          </>
        )}
        {children}
        {file}
        {footer}
        {type === 'password' && visibleType && setVisibleType && (
          <RightSvgWrap withOnClick large={large} disabled={disabled}>
            {visibleType === 'password' ? (
              <Icon.Eye
                onClick={() => {
                  setVisibleType('text');
                }}
              />
            ) : (
              <Icon.EyeOff
                onClick={() => {
                  setVisibleType('password');
                }}
              />
            )}
          </RightSvgWrap>
        )}
      </InputWrap>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputFieldWrap>
  );
};

export type { InputContainerProps };
