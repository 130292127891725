import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useCallback } from 'react';

import { useAlert } from 'hooks/alert';

import { showMessage } from './toastify';

export const useDownloadFile = () => {
  const { handleError } = useAlert();
  const [isLoading, setLoading] = useState(false);

  const downloadFile = useCallback(async (path: string, name?: string) => {
    const id = showMessage(`Downloading file ${name}...`, {
      toast: { autoClose: false },
    });
    try {
      setLoading(true);

      const response = await axios({
        url: path,
        method: 'GET',
        responseType: 'blob',
        headers: { Accept: '*/*' },
      });

      const url = URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = name || 'filename';
      a.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      handleError(error);
    } finally {
      toast.dismiss(id);
      setLoading(false);
    }
  }, []);

  return { isLoading, downloadFile };
};
