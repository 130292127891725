export const replaceAllWithMap = (
  string: string,
  mapObj: Record<string, any>
): string => {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return string.replace(re, (matched: string) => mapObj[matched]);
};

//Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

export const replaceAll = (
  string: string,
  match: string,
  replacement: string
) => string.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);

export const capitalize = (string: string): string =>
  string ? string[0].toUpperCase() + string.slice(1) : string;

export const getOwnTitleFromKey = (key: string = '') => {
  const parsed = key.split('-').join(' ').split('_').join('.');
  return capitalize(parsed);
};
