import { useScroll } from 'react-use';
import { useWindowSize } from 'usehooks-ts';
import {
  useRef,
  useMemo,
  ReactNode,
  cloneElement,
  PropsWithChildren,
} from 'react';

import { Loader } from 'components/loader';
import { useStateRef } from 'hooks/useStateRef';
import { Pagination } from 'components/pagination';
import { Placeholder } from 'components/placeholder';
import { RetryRequest } from 'components/retry-request';

import * as Styles from './styles';
import { ListProps } from './types';
import { HorizontalScrollWrapper } from './styles';

const List = ({
  id,
  gap,
  error,
  style,
  testId,
  header,
  footer,
  isError,
  refetch,
  children,
  count = 0,
  className,
  isLoading,
  topHeader,
  searchQuery,
  currentPage,
  hasMinHeight,
  pageSize = 20,
  setCurrentPage,
  setSearchQuery,
  listWrapperRef,
  dataPlaceholder,
  searchInputProps,
  horizontalScroll,
  mainWrapperStyles,
  searchBarLeftSlot,
  searchPlaceholder,
  searchBarRightSlot,
  searchContainerStyle,
  forceMobileView = false,
}: PropsWithChildren<ListProps>): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [list, setListRef] = useStateRef();
  const size = useWindowSize();

  const listRef = useMemo(() => {
    return { current: list };
  }, [list]);

  const scrollHeight = listRef?.current?.scrollHeight ?? 0;
  const clientHeight = listRef?.current?.clientHeight ?? 0;
  const hasScrollbar = useMemo(() => {
    return scrollHeight > clientHeight;
  }, [size, scrollHeight, scrollHeight]);

  const { y } = useScroll(listRef);

  const resultHeader = (
    <>
      {header &&
        cloneElement(header, {
          hasScrollbar,
          isScrolled: !isLoading && !isError && count > 0 && y > 0,
        })}
      <span id="table-top" />
    </>
  );

  const withHeader = (element: ReactNode) => (
    <>
      {resultHeader}
      {element}
    </>
  );

  const getMainElement = () => {
    if (isLoading) {
      return withHeader(<Loader isLoading isFullScreen={false} />);
    } else if (isError) {
      return withHeader(<RetryRequest error={error} refetch={refetch} />);
    } else if (count > 0) {
      const mainElement = (
        <Styles.Main
          gap={gap}
          ref={setListRef}
          style={mainWrapperStyles}
          horizontalScroll={horizontalScroll}
          horizontalScrollWidth={wrapperRef.current?.scrollWidth}
        >
          {children}
        </Styles.Main>
      );

      const pagination = currentPage && setCurrentPage && (
        <Styles.PaginationContainer
          forceMobileView={forceMobileView}
          horizontalScroll={horizontalScroll}
        >
          <Pagination
            totalCount={count}
            pageSize={pageSize}
            currentPage={currentPage}
            forceMobileView={forceMobileView}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Styles.PaginationContainer>
      );

      return horizontalScroll ? (
        <>
          <HorizontalScrollWrapper ref={wrapperRef}>
            {withHeader(mainElement)}
          </HorizontalScrollWrapper>
          {pagination}
          {footer}
        </>
      ) : (
        withHeader(
          <>
            {mainElement}
            {pagination}
            {footer}
          </>
        )
      );
    } else if (searchQuery) {
      return withHeader(
        searchPlaceholder || <Placeholder text="There no results" />
      );
    }

    return withHeader(
      dataPlaceholder === undefined ? (
        <Placeholder text="You have no data yet" />
      ) : (
        dataPlaceholder
      )
    );
  };

  return (
    <Styles.ListWrapper
      id={id}
      style={style}
      data-testid={testId}
      ref={listWrapperRef}
      className={className}
      $hasMinHeight={hasMinHeight}
    >
      {topHeader}
      {setSearchQuery && (
        <Styles.SearchContainer style={searchContainerStyle}>
          {searchBarLeftSlot}
          <Styles.SearchInput
            search
            type="text"
            name="search"
            value={searchQuery}
            placeholder="Search"
            onRightIconClick={() => setSearchQuery('')}
            onChange={(e) => setSearchQuery(e.target.value)}
            {...searchInputProps}
          />
          {searchBarRightSlot}
        </Styles.SearchContainer>
      )}
      {getMainElement()}
    </Styles.ListWrapper>
  );
};

export { List };
export type { ListProps };
