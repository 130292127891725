import { formatPrice } from 'utils/helpers';
import {
  Icon,
  InfoBlock,
  GeneralInfo,
  AvatarPlaceholderType,
} from 'components';

import { ProductDetailsProps } from './types';

const commonColProps = {
  col: 4,
  colTablet: 4,
  colMobile: 6,
};

export const ProductDetails = ({
  data,
  rows,
  ...props
}: ProductDetailsProps) => (
  <GeneralInfo
    photoSize={128}
    withPlaceholders
    name={data?.name}
    photo={data?.photoUrl}
    stickyWithHeader={false}
    sectionWrapper={InfoBlock}
    avatarIcon={<Icon.Template />}
    rightSlotStyles={{ margin: 0 }}
    placeholder={AvatarPlaceholderType.GreyIcon}
    {...props}
    sections={[
      {
        rows: [
          [
            {
              ...commonColProps,
              title: 'Supplier',
              value: data?.supplier?.name,
            },
            {
              ...commonColProps,
              title: 'Category',
              value: data?.category?.name,
            },
            {
              ...commonColProps,
              title: 'SKU',
              value: data.sku,
            },
          ],
          [
            {
              ...commonColProps,
              title: 'Unit weight',
              value: data.unitWeight,
            },
            {
              ...commonColProps,
              title: 'Unit per case',
              value: data.unitPerCase,
            },
            {
              ...commonColProps,
              title: 'Cost price',
              value: formatPrice(data.costPrice),
            },
          ],
          [
            {
              ...commonColProps,
              title: 'Min price',
              value: formatPrice(data.minPrice),
            },
            {
              ...commonColProps,
              title: 'Retail price',
              value: formatPrice(data.retailPrice),
            },
            {
              ...commonColProps,
              title: 'Daily countable',
              value: data.dailyCountable ? 'On' : 'Off',
            },
          ],
          ...(rows || []),
        ],
      },
    ]}
  />
);
