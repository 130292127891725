import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';
import { flexCenter } from 'styles/mixins';
import { bodySemibold } from 'styles/mixins/fonts';

import { RadioGroupProps } from './types';

const getRadioButtonColor = (color: string) => css`
  display: inline-block;
  path {
    fill: ${color};
  }
  circle {
    stroke: ${color};
  }
`;

export const RadioButtonBlock = styled.div`
  display: inline-block;
`;

export const RadioButtonLabel = styled.label<{
  label?: string;
  padding: string;
  transparent: boolean;
}>`
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${({ theme, label, padding, transparent }) =>
    label &&
    css`
      min-height: 40px;
      padding: ${padding};
      border-radius: ${theme.borderRadius};
      transition: ${theme.transition.default};

      ${!transparent &&
      css`
        background-color: ${theme.config.radioButton.default.bg};
        border: 1px solid ${theme.config.radioButton.default.border};

        &:hover {
          background-color: ${theme.config.radioButton.hover.bg};
          border-color: ${theme.config.radioButton.hover.border};
        }

        &:active {
          background-color: ${theme.config.radioButton.active.bg};
          border-color: ${theme.config.radioButton.active.border};
        }
      `};
    `}
`;

export const RadioMark = styled.span<{ label?: string }>`
  display: flex;
  margin-left: -4px;
  position: relative;

  ${({ theme, label }) => css`
    margin-right: ${label ? '4px' : 0};
    transition: ${theme.transition.default};

    #radio-button {
      &-empty {
        ${getRadioButtonColor(theme.config.radioButton.default.circle)}
      }
      &-select,
      &-hover {
        display: none;
      }
    }
  `}
`;

export const StyledIcon = styled.span`
  ${flexCenter};
  margin-right: 4px;
`;

export const RadioButtonText = styled.div<{ fontSize?: string }>`
  letter-spacing: 0.02em;
  font-size: ${({ fontSize }) => fontSize && fontSize};
  user-select: none;
`;

export const StyledInput = styled.input<{
  label?: string;
  transparent: boolean;
}>`
  position: absolute;
  -webkit-appearance: none;
  appearance: none;

  ${({ theme, label, transparent }) => css`
    &:hover ~ ${RadioButtonLabel} {
      ${RadioMark} {
        #radio-button {
          &-hover {
            ${getRadioButtonColor(theme.config.radioButton.hover.circle)}
          }
          &-select,
          &-empty {
            display: none;
          }
        }
      }
    }

    &:active ~ ${RadioButtonLabel} {
      ${RadioMark} {
        #radio-button {
          &-hover {
            ${getRadioButtonColor(theme.config.radioButton.active.circle)}
          }
          &-empty,
          &-select {
            display: none;
          }
        }
      }
    }

    &:checked {
      & ~ ${RadioButtonLabel} {
        ${!transparent &&
        css`
          background-color: ${label && theme.config.radioButton.checked.bg};
          border-color: ${theme.config.radioButton.checked.border};
        `};

        & > ${RadioMark} {
          #radio-button {
            &-select {
              ${getRadioButtonColor(theme.config.radioButton.checked.circle)}
            }
            &-empty,
            &-hover {
              display: none;
            }
          }
        }
      }

      &:disabled {
        & ~ ${RadioButtonLabel} {
          & > ${RadioMark} {
            #radio-button {
              &-select {
                ${getRadioButtonColor(theme.config.radioButton.disabled.circle)}
              }
              &-empty,
              &-hover {
                display: none;
              }
            }
          }
        }
      }
    }

    &:disabled {
      & ~ ${RadioButtonLabel} {
        ${!transparent &&
        css`
          border-color: ${theme.config.radioButton.disabled.border};
          background-color: ${theme.config.radioButton.disabled.bg};
        `};

        color: ${theme.config.radioButton.disabled.circle};

        cursor: initial;

        & > ${RadioMark} {
          #radio-button {
            &-empty {
              ${getRadioButtonColor(theme.config.radioButton.disabled.circle)}
            }
            &-hover,
            &-select {
              display: none;
            }
          }
        }

        & > ${StyledIcon} {
          svg {
            path {
              fill: ${theme.config.radioButton.disabled.circle};
            }
          }
        }
      }
    }
  `}
`;

export const GroupLabel = styled(Flex)`
  ${bodySemibold};
  gap: 4px;
`;

export const ItemBlock = styled.div<Pick<RadioGroupProps, 'gap' | 'grid'>>`
  ${({ gap, grid }) => css`
    ${grid &&
    css`
      display: grid;
      grid-gap: ${grid.gap};
      grid-template: ${grid.template};
    `}

    ${!grid &&
    css`
      display: flex;
      flex-wrap: wrap;
      gap: ${gap}px;
    `}
  `}
`;

export const ChildrenContainer = styled.div`
  padding: 12px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.cerulean.tertiary.standard};
`;
