/* eslint-disable perfectionist/sort-objects */
import Messages from './messages';

export { Messages };
export { Config } from './env';
export * as Themes from './themes';
export { AppRoutes } from './routes';

export const EmptyPlaceholder = '-';

export const states = {
  AL: { name: 'Alabama', tax: 4 },
  AK: { name: 'Alaska', tax: 0 },
  AZ: { name: 'Arizona', tax: 5.6 },
  AR: { name: 'Arkansas', tax: 6.5 },
  CA: { name: 'California', tax: 7.25 },
  CO: { name: 'Colorado', tax: 2.9 },
  CT: { name: 'Connecticut', tax: 6.35 },
  DE: { name: 'Delaware', tax: 1.82 },
  FL: { name: 'Florida', tax: 6 },
  GA: { name: 'Georgia', tax: 4 },
  HI: { name: 'Hawaii', tax: 4 },
  ID: { name: 'Idaho', tax: 6 },
  IL: { name: 'Illinois', tax: 6.25 },
  IN: { name: 'Indiana', tax: 7 },
  IA: { name: 'Iowa', tax: 6 },
  KS: { name: 'Kansas', tax: 6.5 },
  KY: { name: 'Kentucky', tax: 6 },
  LA: { name: 'Louisiana', tax: 4.45 },
  ME: { name: 'Maine', tax: 5.5 },
  MD: { name: 'Maryland', tax: 6 },
  MA: { name: 'Massachusetts', tax: 6.25 },
  MI: { name: 'Michigan', tax: 6 },
  MN: { name: 'Minnesota', tax: 6.875 },
  MS: { name: 'Mississippi', tax: 7 },
  MO: { name: 'Missouri', tax: 4.225 },
  MT: { name: 'Montana', tax: 0 },
  NE: { name: 'Nebraska', tax: 5.5 },
  NV: { name: 'Nevada', tax: 6.85 },
  NH: { name: 'New Hampshire', tax: 0 },
  NJ: { name: 'New Jersey', tax: 6.625 },
  NM: { name: 'New Mexico', tax: 5.125 },
  NY: { name: 'New York', tax: 4 },
  NC: { name: 'North Carolina', tax: 4.75 },
  ND: { name: 'North Dakota', tax: 5 },
  OH: { name: 'Ohio', tax: 5.75 },
  OK: { name: 'Oklahoma', tax: 4.5 },
  OR: { name: 'Oregon', tax: 0 },
  PA: { name: 'Pennsylvania', tax: 6 },
  RI: { name: 'Rhode Island', tax: 7 },
  SC: { name: 'South Carolina', tax: 6 },
  SD: { name: 'South Dakota', tax: 4.5 },
  TN: { name: 'Tennessee', tax: 7 },
  TX: { name: 'Texas', tax: 6.25 },
  UT: { name: 'Utah', tax: 4.85 },
  VT: { name: 'Vermont', tax: 6 },
  VA: { name: 'Virginia', tax: 5.3 },
  WA: { name: 'Washington', tax: 6.5 },
  WV: { name: 'West Virginia', tax: 6 },
  WI: { name: 'Wisconsin', tax: 5 },
  WY: { name: 'Wyoming', tax: 4 },
};
