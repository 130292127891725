import CryptoJS from 'crypto-js';

import { User } from 'types';
import { Config } from 'config';

const userKey = 'authUser';
const darkThemeKey = 'isDarkTheme';
const rememberedEmailKey = 'rememberedEmail';

export type TokenKey = 'accessToken' | 'refreshToken';

export interface Tokens {
  token?: string;
  refreshToken?: string;
}

const accessTokenKey: TokenKey = 'accessToken';
const refreshTokenKey: TokenKey = 'refreshToken';
const tokenKeys: TokenKey[] = [accessTokenKey, refreshTokenKey];

export const rememberEmail = (remember: boolean, email?: string) => {
  if (remember && email) {
    localStorage.setItem(rememberedEmailKey, email);
  } else {
    localStorage.removeItem(rememberedEmailKey);
  }
};

export const getRememberedEmail = () =>
  localStorage.getItem(rememberedEmailKey);

export const saveDarkTheme = (isDarkTheme?: boolean) => {
  if (typeof isDarkTheme === 'boolean') {
    localStorage.setItem(darkThemeKey, String(isDarkTheme));
  } else {
    localStorage.removeItem(darkThemeKey);
  }
};

export const getIsDarkTheme = (): boolean | undefined => {
  const isDarkTheme = localStorage.getItem(darkThemeKey);
  if (isDarkTheme === null) {
    return undefined;
  }
  return isDarkTheme === 'true';
};

export const clearTokens = () =>
  tokenKeys.forEach((tokenKey) => localStorage.removeItem(tokenKey));

export const clearUser = () => localStorage.removeItem(userKey);

export const clearAll = () => {
  clearTokens();
  clearUser();
};

export const saveToken = (tokenKey: TokenKey, token: string) =>
  localStorage.setItem(
    tokenKey,
    CryptoJS.AES.encrypt(token, Config.cryptoKey).toString()
  );

export const saveTokens = ({ token, refreshToken }: Tokens) => {
  if (token) {
    saveToken('accessToken', token);
  }
  if (refreshToken) {
    saveToken('refreshToken', refreshToken);
  }
};

export const getToken = (tokenKey: TokenKey = accessTokenKey) => {
  const token = localStorage.getItem(tokenKey);

  if (!token) {
    return;
  }

  try {
    return CryptoJS.AES.decrypt(token, Config.cryptoKey).toString(
      CryptoJS.enc.Utf8
    );
  } catch (err) {
    clearAll();
  }
};

export const decryptToken = (token: string) =>
  CryptoJS.AES.decrypt(token, Config.cryptoKey).toString(CryptoJS.enc.Utf8);

export const saveUser = (user: User) =>
  localStorage.setItem(userKey, JSON.stringify(user));

export const getUser = (): User | undefined => {
  const user = localStorage.getItem(userKey);
  if (!user) {
    return undefined;
  }
  return JSON.parse(user);
};

export const getTokens = () => ({
  token: getToken('accessToken'),
  refreshToken: getToken('refreshToken'),
});
