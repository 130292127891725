import styled from 'styled-components';

import { DatePicker as DatePickerComponent } from 'components/date-picker';

export const DatePicker: typeof DatePickerComponent = styled(
  DatePickerComponent
)`
  .react-datepicker {
    padding: 0;
  }
`;
