import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addParamsToUrl } from 'utils/query';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import { Deal, DealFormValues, CreateDealFormValues } from './types';

const tagType = 'deals';

export const dealsApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'dealsApi',
  endpoints: (build) => ({
    details: build.query<Deal, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.DEAL.replace(':id', id),
      }),
    }),

    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.DEAL.replace(':id', id),
      }),
    }),

    create: build.mutation<void, CreateDealFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        data,
        method: 'post',
        url: URL.DEALS,
      }),
    }),

    list: build.query<Deal[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: params ? addParamsToUrl(URL.DEALS, params) : URL.DEALS,
      }),
    }),

    edit: build.mutation<void, DealFormValues>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.DEAL.replace(':id', String(id)),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useEditMutation,
  useDetailsQuery,
  useRemoveMutation,
  useCreateMutation,
} = dealsApi;

export type { Deal, DealFormValues, CreateDealFormValues };
