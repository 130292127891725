import { formatTimezoneSpecific } from 'utils/date';
import { AnalyticsEmployee } from 'store/api/analytics';
import { joinStrings, formatPrice } from 'utils/helpers';
import { TemplateList } from 'components/transactions-list';

import { TodayEmployeesListProps } from './types';

export const employeeConfig = {
  template: 'repeat(5, 1fr)',
  headers: ['Employee', 'Locations', 'Sales amount', 'Sales QTY', 'Last Sales'],
  cellsMobile: [
    { row: '1/2', col: '1/3' },
    { row: '2/2', col: '1/3' },
    { row: '3/4', col: '1/2' },
    { row: '3/4', col: '2/3' },
  ],
};

export const TodayEmployeesList = ({
  data,
  title,
  setEmployee,
  timezoneCode,
}: TodayEmployeesListProps) => (
  <TemplateList<AnalyticsEmployee>
    {...employeeConfig}
    data={data}
    hasMinHeight={false}
    title={title || 'Today employees'}
    tabletTemplate={employeeConfig.template}
    maxHeight={data?.length ? 350 : undefined}
    onClick={setEmployee ? (e, value) => setEmployee(value) : undefined}
    render={(employeeInfo) => [
      employeeInfo.user.name,
      joinStrings(employeeInfo?.eventNames, ', '),
      formatPrice(employeeInfo.totalSales),
      employeeInfo.totalCheckouts,
      formatTimezoneSpecific(
        employeeInfo.lastSale.createdAt,
        timezoneCode || employeeInfo.lastSale?.event?.timezone?.code,
        'LT'
      ),
    ]}
  />
);
