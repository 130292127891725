import { useEffect, useCallback } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';

import { getErrorMessage } from 'utils/error';
import { HandleMutationProps, BaseHandleMutationProps } from 'types';

import { useAlert } from './alert';

export const baseHandleMutation = <T extends Record<string, any>>({
  data,
  error,
  reset,
  status,
  onError,
  onSuccess,
  showError,
}: BaseHandleMutationProps<T>) => {
  useEffect(() => {
    if (error) {
      if (onError) {
        onError(getErrorMessage(error), error);
      } else {
        showError(getErrorMessage(error));
      }
    }
  }, [error]);

  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      if (onSuccess) {
        onSuccess(data);
      }
      if (reset) {
        reset();
      }
    }
  }, [status, onSuccess, reset]);
};

export const handleMutation = <T extends Record<string, any>>(
  params: HandleMutationProps<T> & { successMessage?: string }
) => {
  const { showErrorAlert, showSuccessAlert } = useAlert();

  baseHandleMutation<T>({
    ...params,
    showError: showErrorAlert,
    onSuccess: useCallback((...successParams: [data?: T | void]) => {
      params.onSuccess?.(...successParams);

      if (params.successMessage) {
        showSuccessAlert(params.successMessage);
      }
    }, []),
  });
};
