import styled, { css } from 'styled-components';

import { Col } from 'components/grid';
import { Flex } from 'components/flex';
import { bodySemibold } from 'styles/mixins/fonts';
import { Checkbox as TCCheckbox } from 'components/checkbox';

export const SemiBold = styled.p`
  ${bodySemibold};
`;

export const Container = styled(Flex)`
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  flex-direction: column;
  ${({ theme }) => css`
    background: ${theme.config.background};
    box-shadow: ${theme.config.eventModal.shadow};
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  gap: 16px;
  display: flex;

  button {
    padding: 0;
  }
`;

export const SubtitleContainer = styled(Flex)`
  gap: 16px;
  padding: 16px 0;
  border-width: 1px 0;
  border-style: solid;
  justify-content: space-between;
  border-color: ${({ theme }) => theme.colors.grey.tertiary.action};
`;

export const Checkbox = styled(TCCheckbox)`
  margin: 16px 0;
`;

export const InfoRow = styled(Flex)`
  gap: 8px;
  min-width: 0;
  margin-top: 8px;

  svg path {
    fill: ${({ theme }) => theme.config.text.primary};
  }
`;

export const StyledCol = styled(Col)<{ withoutMargin: boolean }>`
  ${({ withoutMargin }) => css`
    margin-bottom: ${withoutMargin ? 0 : 16}px;
  `};
`;
