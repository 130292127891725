import styled, { css } from 'styled-components';

import { EllipsisText } from 'components/ellipsis-text';
import { bodyRegular, bodySemibold, captionRegular } from 'styles/mixins/fonts';

export const Container = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled(EllipsisText)`
  ${bodySemibold};
`;

export const InfoContainer = styled.div`
  width: 100%;
  min-height: 56px;
  padding: 0 16px;
  border: 1px solid;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    border-color: ${theme.config.common.border};
  `};
`;

export const Title = styled(EllipsisText)`
  ${bodyRegular};
`;

export const SubTitle = styled(EllipsisText)`
  ${captionRegular};
  color: ${({ theme }) => theme.config.input.placeholder};
`;
