import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { addSearchParams } from 'utils/query';
import { filterEmptyFields } from 'utils/object';
import { User, UserTypes, SearchRequest } from 'types';
import {
  cacheByIdArg,
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import { UserData, RoleData, UserFormValues } from './types';

const tagType = 'Users';

const handleFormData = (data: Partial<UserFormValues>) => {
  const resultData = { ...data };

  if (resultData.role && UserTypes.ops.includes(resultData.role)) {
    delete resultData.companyId;
  }

  return resultData;
};

export const usersApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'usersApi',
  endpoints: (build) => ({
    listRoles: build.query<RoleData[], void>({
      providesTags: [tagType],
      query: () => ({
        method: 'get',
        url: URL.USER_ROLES,
      }),
    }),
    get: build.query<UserData, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.USER_DETAILS.replace(':id', id),
      }),
    }),
    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.USER_DETAILS.replace(':id', id),
      }),
    }),
    list: build.query<User[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: addSearchParams(URL.USERS, params),
      }),
    }),
    create: build.mutation<void, UserFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => {
        return {
          method: 'post',
          url: URL.USERS,
          data: filterEmptyFields(handleFormData(data)),
        };
      },
    }),
    edit: build.mutation<void, UserFormValues>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => {
        return {
          method: 'patch',
          url: URL.USER_DETAILS.replace(':id', id),
          data: filterEmptyFields({
            ...handleFormData(data),
            id,
          }),
        };
      },
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useEditMutation,
  useListRolesQuery,
  useRemoveMutation,
  useCreateMutation,
} = usersApi;

export type { UserData, RoleData, UserFormValues };
