import styled, { css } from 'styled-components';

import { FlexProps } from './types';

export const Flex = styled.div<FlexProps>`
  ${({
    gap,
    flex,
    column,
    display,
    flexWrap,
    minWidth,
    fullWidth,
    maxHeight,
    minHeight,
    fullHeight,
    alignItems,
    flexDirection,
    justifyContent,
  }) => css`
    gap: ${gap || 0}px;
    display: ${display || 'flex'};
    flex-wrap: ${flexWrap || 'nowrap'};
    align-items: ${alignItems || 'stretch'};
    flex-direction: ${flexDirection || 'row'};
    justify-content: ${justifyContent || 'flex-start'};
    ${fullWidth && 'width: 100%;'};
    ${fullHeight && 'height: 100%;'};

    ${column &&
    css`
      flex-direction: column;
    `}

    ${typeof flex === 'number' &&
    css`
      flex: ${flex};
    `}

    ${typeof minWidth === 'number' &&
    css`
      min-width: ${minWidth}px;
    `}

    ${typeof minWidth === 'string' &&
    css`
      min-width: ${minWidth};
    `}

    ${typeof maxHeight === 'number' &&
    css`
      max-height: ${maxHeight}px;
    `}

    ${typeof minHeight === 'number' &&
    css`
      min-height: ${minHeight}px;
    `}

    ${typeof minHeight === 'string' &&
    css`
      min-height: ${minHeight};
    `}
  `}
`;
