import { useField } from 'formik';

import { Flex } from 'components/flex';
import { EllipsisText } from 'components/ellipsis-text';
import { Rating, RatingProps } from 'components/rating';
import { Label } from 'components/input-container/styles';

interface RatingFieldProps extends RatingProps {
  name?: string;
  label?: string;
}

export const RatingField = ({
  label,
  name = 'rating',
  ...props
}: RatingFieldProps) => {
  const [, { value }, { setValue }] = useField(name);

  return (
    <Flex column gap={4}>
      {label && (
        <Label>
          <EllipsisText>{label}</EllipsisText>
        </Label>
      )}

      <Rating
        initialValue={value}
        onClick={(rate) => setValue(rate)}
        {...props}
      />
    </Flex>
  );
};
