import * as Yup from 'yup';

import {
  getUploadedFile,
  makeSelectOptionsFromOptionsArray,
} from 'utils/helpers';
import {
  phoneNumber,
  validateZipCode,
  validateWebsiteUrl,
} from 'utils/validation';
import {
  EventType,
  EventTypeTitle,
  EventLocationType,
  EventLocationTypeTitle,
} from 'types';
import {
  Event,
  EventFormValues,
  eventPaymentTypeLabel,
  eventPaymentTypeOptions,
  eventPaymentTransactionLabel,
  eventPaymentTransactionOptions,
} from 'store/api/event';

const required = 'Required field';

export const validationSchema = Yup.object().shape({
  zip: validateZipCode(),
  link: validateWebsiteUrl,
  eventType: Yup.string().required(),
  name: Yup.string().required(required),
  locationType: Yup.string().required(),
  address: Yup.string().required(required),
  contactPhone: phoneNumber('contactPhone'),
  tax: Yup.number().min(0).max(100).required(),
  floorPlanLink: Yup.string().url('Must be a valid url').nullable(),
  payments: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(required),
    })
  ),
  locations: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
      })
    )
    .min(1),
});

export const getInitialValues = (data: Event | undefined): EventFormValues => {
  const { theme, product, ...restData } = (data ?? {}) as Event;

  return {
    ...restData,
    themeId: theme?.id ?? null,
    name: restData?.name ?? '',
    tax: data?.tax ?? undefined,
    productId: product?.id ?? null,
    address: restData?.address ?? '',
    link: restData?.link ?? undefined,
    eventType: restData?.eventType ?? '',
    photo: getUploadedFile(restData?.photoUrl),
    locationType: restData?.locationType ?? '',
    endDate: restData?.endDate ? new Date(restData.endDate) : undefined,
    startDate: restData?.startDate ? new Date(restData.startDate) : undefined,
    teamMembers: restData?.teamMembers
      ? restData.teamMembers.map((user) => user.id)
      : [],
    locations: restData?.locations?.length
      ? restData.locations
      : [
          {
            name: '',
          },
        ],
    payments: restData?.payments
      ? restData.payments.map((item) => ({
          ...item,
          date: item.date ? new Date(item.date) : undefined,
        }))
      : [],
  };
};

export const paymentTypeOptions = makeSelectOptionsFromOptionsArray(
  eventPaymentTypeOptions,
  eventPaymentTypeLabel
);

export const transactionOptions = makeSelectOptionsFromOptionsArray(
  eventPaymentTransactionOptions,
  eventPaymentTransactionLabel
);

export const eventLocationTypeOptions = Object.values(EventLocationType).map(
  (item) => ({
    value: item,
    label: EventLocationTypeTitle[item],
  })
);

export const eventTypeOptions = Object.values(EventType).map((item) => ({
  value: item,
  label: EventTypeTitle[item],
}));
