import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { providesList, invalidatesList } from 'utils/query-cache';

import { apiQuery } from '../../query';

import { Theme } from './types';

const tagType = 'themes';

export const themesApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'themesApi',
  endpoints: (build) => ({
    list: build.query<Theme[], void>({
      providesTags: providesList(tagType),
      query: () => ({
        method: 'get',
        url: URL.THEMES,
      }),
    }),
    save: build.mutation<void, Partial<Theme>[]>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        data,
        method: 'put',
        url: URL.THEMES,
      }),
    }),
  }),
});

export const { useListQuery, useSaveMutation } = themesApi;
export type { Theme };
