import dayjs from 'dayjs';

import { Timezone } from 'types';

import { Popup } from './popup';

interface RelativeDateProps {
  timezone: Timezone;
  date: dayjs.ConfigType;
}

export const RelativeDate = ({ date, timezone }: RelativeDateProps) => {
  const dateTimezone = timezone?.code ? dayjs(date).tz(timezone?.code) : date;
  return (
    <Popup on="hover" trigger={<div>{dayjs(dateTimezone).fromNow()}</div>}>
      <div style={{ padding: '4px' }}>{dayjs(dateTimezone).format('LLLL')}</div>
    </Popup>
  );
};
