import { ReactElement } from 'react';
import { Form, Formik } from 'formik';

import { Icon } from 'components';
import * as Responsive from 'components/responsive';

import * as Styles from './styles';
import { AuthLayoutProps } from './types';

export const AuthLayout: <T>(props: AuthLayoutProps<T>) => ReactElement = ({
  title,
  children,
  backgroundImage,
  validateOnBlur = false,
  validateOnChange = false,
  enableReinitialize = false,
  ...formikProps
}) => {
  return (
    <Styles.MainContainer>
      <Responsive.Desktop>
        <Styles.ImageContainer background={backgroundImage} />
      </Responsive.Desktop>

      <Styles.ContentContainer>
        <Styles.FormContainer>
          <Styles.LogoContainer>
            <Icon.Logo />
          </Styles.LogoContainer>

          <Responsive.Desktop>
            <h1>{title}</h1>
          </Responsive.Desktop>

          <Responsive.NotDesktop>
            <h2>{title}</h2>
          </Responsive.NotDesktop>

          <Formik<any>
            validateOnBlur={validateOnBlur}
            validateOnChange={validateOnChange}
            enableReinitialize={enableReinitialize}
            {...formikProps}
            children={(props) => {
              const render =
                // @ts-ignore
                children instanceof Function ? children(props) : children;
              return (
                <Styles.FormWrapper>
                  <Form>{render}</Form>
                </Styles.FormWrapper>
              );
            }}
          />
        </Styles.FormContainer>
      </Styles.ContentContainer>
    </Styles.MainContainer>
  );
};
