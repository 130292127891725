import { useRef, useEffect } from 'react';

import { withRef } from 'hoc/withRef';

import { PopupProps } from './types';
import { HideInput, StyledPopup } from './styles';

const Popup = ({
  isBlack,
  children,
  refForward,
  removeAutofocus,
  ...props
}: PopupProps): JSX.Element => {
  const autoFocusedElementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (removeAutofocus) {
      if (autoFocusedElementRef.current) {
        autoFocusedElementRef.current.blur();
      }
    }
  }, [removeAutofocus]);

  const hiddenInput = removeAutofocus && (
    <HideInput ref={autoFocusedElementRef} />
  );

  return (
    <StyledPopup ref={refForward} isBlack={isBlack} {...props}>
      {typeof children === 'function' ? (
        (close, isOpen) => (
          <>
            {hiddenInput}
            {children(close, isOpen)}
          </>
        )
      ) : (
        <>
          {hiddenInput}
          {children}
        </>
      )}
    </StyledPopup>
  );
};

const PopupWithRef = withRef(Popup);

export { Popup, PopupWithRef };
export type { PopupProps };
