import styled, { css } from 'styled-components';

export const Container = styled.section<{
  width: string;
  rightOffset: number;
  leftDirection?: boolean;
}>`
  ${({ theme, width, rightOffset, leftDirection }) => css`
    &.slide-panel {
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 210;
      overflow: hidden;
      position: absolute;
      pointer-events: none;
      right: ${rightOffset}px;

      .slide-panel-overlay {
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 0;
        left: -100vw;
        display: block;
        position: absolute;
        pointer-events: all;
        transition: all 300ms;
        -webkit-transition: all 300ms;
        background-color: ${theme.config.slidePanel.overlay};
      }

      .slide-panel-content {
        top: 0;
        bottom: 0;
        z-index: 100;
        display: flex;
        position: absolute;
        pointer-events: all;
        flex-direction: column;
        background-color: ${theme.config.background};
        ${leftDirection ? 'right: 100%' : 'left: 100%'};
        width: ${theme.responsive.isMobile ? '100%' : width};
      }
    }

    &.slide-panel-enter {
      .slide-panel-content {
        transform: translateX(0);
        box-shadow: none;
      }
      .slide-panel-overlay {
        display: block;
        opacity: 0;
      }
    }
    &.slide-panel-enter-active {
      .slide-panel-content {
        transition: transform 300ms;
        transform: ${`translateX(${leftDirection ? '100%' : '-100%'})`};
        box-shadow: ${theme.responsive.isMobile
          ? 'none'
          : theme.config.slidePanel.shadow};
      }
      .slide-panel-overlay {
        opacity: 1;
        display: block;
      }
    }
    &.slide-panel-enter-done {
      .slide-panel-content {
        transform: ${`translateX(${leftDirection ? '100%' : '-100%'})`};
        box-shadow: ${theme.responsive.isMobile
          ? 'none'
          : theme.config.slidePanel.shadow};
      }
      .slide-panel-overlay {
        opacity: 1;
        display: block;
      }
    }
    &.slide-panel-exit {
      .slide-panel-content {
        transform: ${`translateX(${leftDirection ? '100%' : '-100%'})`};
        box-shadow: ${theme.responsive.isMobile
          ? 'none'
          : theme.config.slidePanel.shadow};
      }
      .slide-panel-overlay {
        opacity: 0;
        display: none;
      }
    }
    &.slide-panel-exit-active {
      .slide-panel-content {
        transform: translateX(0);
        transition: transform 300ms;
        box-shadow: none;
      }
      .slide-panel-overlay {
        opacity: 0;
      }
    }
    &.slide-panel-exit-done {
      .slide-panel-content {
        transform: translateX(0);
        box-shadow: none;
      }
      .slide-panel-overlay {
        display: none;
      }
    }
  `};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  padding: 8px 0 8px 16px;
  z-index: 1;
`;

export const Title = styled.h3`
  margin-right: 16px;
`;

export const TitleWrap = styled.div`
  margin-right: 16px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  position: relative;
  z-index: 1;

  & > :not(:last-child) {
    margin-right: 24px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    top: 0;
    left: 0;
    right: 0;
    ${({ theme }) => css`
      background-color: ${theme.config.slidePanel.border};
    `};
  }
`;

export const Content = styled.div<{ padding: string }>`
  z-index: 1;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  ${({ padding }) => css`
    padding: ${padding};
  `};
`;
