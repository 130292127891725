import styled, { css } from 'styled-components';

import { bodySemibold } from 'styles/mixins/fonts';

import { CropWrapperProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  max-width: 532px;
  min-width: 300px;
  padding: 24px;
  border-radius: 16px;

  ${({ theme }) => css`
    background: ${theme.config.background};

    & * button {
      width: ${theme.responsive.isMobile ? '100%' : '112px'};
    }

    .crop-container {
      border-radius: ${theme.borderRadius};
    }

    .crop-area {
      border: none;
      color: ${theme.config.popup.modal};
    }
  `};
`;

export const CropWrapper = styled.div<CropWrapperProps>`
  display: flex;
  position: relative;
  height: 290px;
  width: 100%;
  margin: ${({ bottomMargin }) => (bottomMargin ? '24px 0' : '24px 0 0')};
`;

export const RotateWrap = styled.div`
  margin: 4px auto 16px auto;
  ${bodySemibold};

  ${({ theme }) => css`
    color: ${theme.config.link.default};

    & svg path,
    & svg rect {
      stroke: ${theme.config.link.default};
    }

    &:hover {
      & svg path,
      & svg rect {
        stroke: ${theme.config.link.hover};
      }
    }

    &:active {
      & svg path,
      & svg rect {
        stroke: ${theme.config.link.action};
      }
    }
  `};
`;
