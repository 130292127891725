import styled, { css } from 'styled-components';

export const SwitchLabel = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const SwitchSlider = styled.span<{ isRed?: boolean }>`
  cursor: pointer;
  border-radius: 16px;
  width: 48px;
  height: 24px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 100%;
  }

  ${({ theme, isRed }) => css`
    background-color: ${theme.config.switchCheckbox[isRed ? 'redBg' : 'bg']
      .default};
    transition: ${theme.transition.default};

    &:before {
      background-color: ${theme.config.switchCheckbox.slider.default};
      transition: ${theme.transition.default};
      box-shadow: ${theme.config.switchCheckbox.slider.shadow};
    }
  `}
`;

export const Switch = styled.input<{ isRed?: boolean; isHovered: boolean }>`
  position: absolute;
  -webkit-appearance: none;
  appearance: none;

  ${({ isRed, theme, isHovered }) => css`
    + ${SwitchSlider}:before {
      ${isHovered && 'transform: translateX(12px)'};
    }

    &:checked + ${SwitchSlider} {
      background-color: ${theme.config.switchCheckbox[isRed ? 'redBg' : 'bg'][
        isHovered ? 'hover' : 'checked'
      ]};

      &:before {
        transform: ${`translateX(${isHovered ? 12 : 25}px)`};
      }
    }

    &:disabled + ${SwitchSlider} {
      background-color: ${theme.config.switchCheckbox[isRed ? 'redBg' : 'bg']
        .disabled};

      &:before {
        background-color: ${theme.config.switchCheckbox.slider.disabled};
      }
    }
  `}
`;

export const SwitchText = styled.span`
  user-select: none;
  margin-left: 8px;
`;
