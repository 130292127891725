import styled, { css } from 'styled-components';

import { AvatarColor } from 'types';
import { flexCenter } from 'styles/mixins';
import { heading1, bodyRegular } from 'styles/mixins/fonts';

import { AvatarProps } from './types';

const placeholder = css`
  ${flexCenter};
  width: 100%;
  height: 100%;
  padding: 4px;
  border-radius: 50%;
`;

export const GreyPlaceholder = styled.div<{ square?: boolean }>`
  ${placeholder}
  ${({ theme, square }) => css`
    border-radius: ${square ? '16px' : '50%'};
    background-color: ${theme.config.avatar.background};

    svg path {
      fill: ${theme.config.avatar.icon};
    }
  `};
`;

export const ColorAvatarPlaceholder = styled.div<{
  square?: boolean;
  color?: AvatarColor;
}>`
  ${placeholder}
  ${({ theme, color, square, onClick }) =>
    color &&
    css`
      border-radius: ${square ? theme.borderRadius : '50%'};
      color: ${theme.config.avatarColor[color].textColor};
      background-color: ${theme.config.avatarColor[color].backgroundColor};
      transition: ${theme.transition.default};

      p {
        user-select: none;
        font-weight: 600 !important;
        ${square ? heading1 : bodyRegular};
      }

      ${onClick &&
      css`
        &:hover {
          background-color: ${theme.config.avatarColor[color].backgroundHover};
        }

        &:active {
          background-color: ${theme.config.avatarColor[color].backgroundActive};
        }
      `}
    `};
`;

export const LogoPlaceholder = styled.div<{ isDark?: boolean }>`
  ${placeholder}
  ${({ theme, isDark, onClick }) => css`
    background-color: ${isDark
      ? theme.colors.blue.primary.standard
      : theme.colors.blue.secondary.standard};
    transition: ${theme.transition.default};

    svg path {
      fill: ${isDark && '#fff'};
    }

    ${onClick &&
    css`
      &:hover {
        background-color: ${isDark
          ? theme.colors.blue.primary.hover
          : theme.colors.blue.secondary.hover};
      }

      &:active {
        background-color: ${isDark
          ? theme.colors.blue.primary.action
          : theme.colors.blue.secondary.action};
      }
    `}
  `};
`;

export const ImageMask = styled.div`
  ${flexCenter};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  user-select: none;

  ${({ theme }) => css`
    transition: ${theme.transition.default};

    svg path {
      fill: ${theme.config.avatar.imageIcon};
    }
  `};
`;

export const AvatarBlock = styled.div<{
  size?: number;
  square?: boolean;
  hasBorder: boolean;
  $highlight: boolean;
  $borderWidth?: AvatarProps['borderWidth'];
}>`
  position: relative;

  ${({
    size,
    theme,
    square,
    onClick,
    hasBorder,
    $highlight,
    $borderWidth,
  }) => css`
    min-width: ${size}px;
    min-height: ${size}px;
    width: ${size}px;
    height: ${size}px;
    border-radius: ${square ? '16px' : '50%'};

    ${hasBorder &&
    css`
      border: ${$highlight
        ? `${$borderWidth ?? '4px'} solid ${theme.colors.blue.primary.standard}`
        : `${$borderWidth ?? '2px'} solid ${theme.config.avatar.border}`};
    `}

    ${onClick &&
    css`
      cursor: pointer;
      ${GreyPlaceholder} {
        border: 1px dashed ${theme.config.dropImage.standard.bg.hover};
        transition: ${theme.transition.default};

        &:hover {
          background-color: ${theme.config.dropImage.standard.bg.hover};
        }

        &:active {
          background-color: ${theme.config.dropImage.standard.bg.active};
        }
      }

      ${ImageMask} {
        &:hover {
          background-color: ${theme.config.avatar.imageMask.hover};
        }

        &:active {
          background-color: ${theme.config.avatar.imageMask.hover};
        }
      }
    `}
  `}
`;
