import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  z-index: 2002;
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(3px);
  background: ${({ theme }) => theme.config.popup.modal96};

  > h3 {
    margin-top: 20px;
    margin-bottom: 8px;
    text-align: center;
  }
`;

export const Buttons = styled.div`
  margin-top: 24px;
  width: 248px;
  display: flex;

  & > :not(:last-child) {
    margin-right: 24px;
  }
`;
