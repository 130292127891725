import dayjs from 'dayjs';
import {
  EventContentArg,
  AllDayContentArg,
  DayHeaderContentArg,
  CustomContentGenerator,
} from '@fullcalendar/core';

import { SelectOption } from 'components/select';

import { Event } from './event';
import * as Styles from './styles';

export const viewTypeOptions: SelectOption[] = [
  { label: 'Month', value: 'dayGridMonth' },
  { label: 'Week', value: 'timeGridWeek' },
  { label: 'Day', value: 'timeGridDay' },
];

export const dayHeaderContent: CustomContentGenerator<DayHeaderContentArg> = (
  data
) => {
  const { date, view } = data;

  const dates = dayjs(date).format('ddd,D').split(',');
  const isMonthView = view.type === 'dayGridMonth';
  return (
    <div>
      <Styles.WeekdayLabel isMonthView={isMonthView}>
        {dates[0]}
      </Styles.WeekdayLabel>
      {!isMonthView && <Styles.DayLabel>{dates[1]}</Styles.DayLabel>}
    </div>
  );
};

export const eventContent: CustomContentGenerator<EventContentArg> = (data) => (
  <Event {...data} />
);

export const allDayContent: CustomContentGenerator<AllDayContentArg> = () => (
  <div style={{ minHeight: 24 }} />
);
