import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  flex: 1;
`;

export const Stick = styled.div`
  ${({ theme }) => css`
    width: 2px;
    height: 24px;
    min-height: 24px;
    margin-top: 12px;
    background-color: ${theme.config.progressBar.stick.default};
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    min-height: 24px;
  }

  svg path {
    fill: ${({ theme }) => theme.config.progressBar.icon.default};
  }
`;

export const BarContainer = styled.div<{
  isDone: boolean;
  longStick?: boolean;
}>`
  display: flex;

  h3 {
    margin-left: 16px;
  }

  ${({ theme, isDone }) =>
    isDone &&
    css`
      ${IconWrapper} {
        svg path {
          fill: ${theme.config.progressBar.icon.done};
        }
      }

      ${Stick} {
        background-color: ${theme.config.progressBar.stick.done};
      }
    `}

  ${({ longStick }) =>
    longStick &&
    css`
      flex: 1;
      ${Stick} {
        flex: 1;
        height: unset;
      }
    `}
`;
