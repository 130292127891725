import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';
import { Col as TCCol } from 'components/grid';
import { EllipsisText } from 'components/ellipsis-text';
import { bodySemibold, captionRegular } from 'styles/mixins/fonts';

export const MobileTitle = styled(EllipsisText)`
  ${bodySemibold};

  ${({ theme }) => css`
    ${!theme.responsive.isMobile && 'display: none;'};
    color: ${theme.config.table.head.title};
  `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => (theme.responsive.isDesktop ? 55 : 100)}%;
`;

export const Col = styled(TCCol)`
  display: flex;
  justify-content: ${({ theme }) =>
    theme.responsive.isDesktop ? 'start' : 'center'};
`;

export const Dot = styled.div<{ bg: string }>`
  width: 12px;
  height: 12px;
  min-width: 12px;
  margin-right: 4px;
  border-radius: 50%;
  background: ${(p) => p.bg};
`;

export const Subtitle = styled(EllipsisText)`
  ${captionRegular};
`;

export const Title = styled(EllipsisText)`
  ${bodySemibold};
`;

export const Wrapper = styled(Flex)`
  min-width: 0;
  flex-direction: column;
`;
