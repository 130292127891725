import { Placeholder } from 'components';

import { ProductList } from '../../product-list';

import { InventoryProps } from './types';
import { getInventoryStats } from './config';

export const Inventory = ({ data, event }: InventoryProps) => {
  const products = getInventoryStats(data, event);

  if (!products) {
    return <Placeholder text="No products in event yet" />;
  }

  return <ProductList products={products} />;
};
