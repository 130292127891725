import * as Responsive from '../../responsive';

import * as Styles from './styles';
import { GoToPageFormProps } from './types';

const GoToPageForm = ({
  onSubmit,
  forceMobileView = false,
  ...props
}: GoToPageFormProps) => {
  return (
    <Styles.Form onSubmit={onSubmit}>
      {!forceMobileView && (
        <Responsive.TabletUp>
          <Styles.Label htmlFor="go-to-page">Go to</Styles.Label>
        </Responsive.TabletUp>
      )}
      <Styles.Input
        type="text"
        name="go-to-page"
        forceMobileView={forceMobileView}
        {...props}
      />
    </Styles.Form>
  );
};

export { GoToPageForm };
