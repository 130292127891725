import { forwardRef } from 'react';

import { EllipsisText } from 'components/ellipsis-text';
import { CloseButton } from 'components/specified-buttons';

import * as Styles from './styles';
import { InfoBlockProps } from './types';

export const InfoBlock = forwardRef<HTMLDivElement, InfoBlockProps>(
  (
    { title, onClose, onClick, disabled, children, className, rightSlot },
    ref
  ) => {
    const hasButton = onClose !== undefined;
    return (
      <Styles.Container
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        className={className}
      >
        {(title || hasButton) && (
          <Styles.Header>
            <Styles.Title withButton={hasButton}>
              {typeof title === 'string' ? (
                <EllipsisText>{title}</EllipsisText>
              ) : (
                title
              )}
            </Styles.Title>
            {rightSlot}
            {hasButton && <CloseButton onClose={onClose} />}
          </Styles.Header>
        )}

        {children}
      </Styles.Container>
    );
  }
);
