import { isMobile } from 'hooks';
import { ButtonColors } from 'types';
import { Button } from 'components/button';

import * as Styles from './styles';

const Footer = ({ onSave, onCancel, monthsShown }: any) => {
  const mobile = isMobile();
  return (
    <Styles.Footer monthsShown={monthsShown}>
      <Button
        text="Cancel"
        fullWidth={mobile}
        onClick={onCancel}
        colorType={ButtonColors.LightBlue}
      />
      <Button text="Save" onClick={onSave} fullWidth={mobile} />
    </Styles.Footer>
  );
};
export default Footer;
