import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
  OptionProps,
  ControlProps,
  SingleValueProps,
  CSSObjectWithLabel,
} from 'react-select';

import { SelectOption } from './types';
import { multiValueStyles } from './components';

export const useStyles = (
  large?: boolean,
  error?: string,
  placeholderColorAsText?: boolean
): any => {
  const theme = useTheme();

  const customStyles = useMemo(
    () => ({
      menuList: (css: CSSObjectWithLabel) => ({
        ...css,
        padding: 0,
      }),
      menuPortal: (css: CSSObjectWithLabel) => ({
        ...css,
        zIndex: 9999,
      }),

      indicatorContainer: (css: CSSObjectWithLabel) => {
        return { ...css, padding: 0 };
      },

      indicatorSeparator: (css: CSSObjectWithLabel) => {
        return { ...css, display: 'none' };
      },

      multiValue: (css: CSSObjectWithLabel) => ({
        ...css,
        ...multiValueStyles(theme),
      }),

      container: (css: CSSObjectWithLabel) => ({
        ...css,

        '.rs-option-icon, .rs-search-icon, .rs-searchable-option-icon': {
          lineHeight: 0,
        },
      }),

      clearIndicator: (css: CSSObjectWithLabel) => {
        return {
          ...css,
          '.close-circle-icon': {
            path: { fill: theme.config.select.clearIcon },
          },
        };
      },

      indicatorsContainer: (css: CSSObjectWithLabel) => {
        return {
          ...css,
          display: 'flex',
          alignItems: 'center',
          maxHeight: large ? '48px' : '40px',
        };
      },

      placeholder: (css: CSSObjectWithLabel) => ({
        ...css,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        color: placeholderColorAsText
          ? theme.config.select.text.default
          : theme.config.select.text.disabled,
      }),

      input: (css: CSSObjectWithLabel) => ({
        ...css,
        color: theme.config.select.text.default,

        '& input': {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
          caretColor: theme.config.select.text.default,
        },
      }),

      singleValue: (
        css: CSSObjectWithLabel,
        state: SingleValueProps<SelectOption>
      ) => ({
        ...css,
        columnGap: 8,
        marginLeft: 0,
        marginRight: 0,
        color: state.isDisabled
          ? theme.config.input.text
          : theme.config.select.text.default,
      }),

      menu: (css: CSSObjectWithLabel) => ({
        ...css,
        zIndex: 2,
        marginTop: 0,
        border: 'none',
        marginBottom: 0,
        overflow: 'hidden',
        borderRadius: theme.borderRadius,
        backgroundColor: theme.config.select.bg,
        boxShadow: '0px 8px 24px 0px #001B4714',
      }),

      valueContainer: (
        css: CSSObjectWithLabel,
        state: ControlProps<SelectOption>
      ) => {
        const { value, isMulti } = state.selectProps;
        return {
          ...css,
          padding:
            theme.responsive.isMobile && isMulti && value?.length
              ? '2px 4px'
              : '2px 8px 2px 16px',
        };
      },

      dropdownIndicator: (
        css: CSSObjectWithLabel,
        state: ControlProps<SelectOption>
      ) => {
        const { isDisabled, menuIsOpen } = state.selectProps;

        return {
          ...css,
          padding: '0',
          svg: {
            transform: `rotate(${menuIsOpen ? '180deg' : 0})`,
            path: {
              fill: menuIsOpen
                ? theme.config.select.border.active
                : theme.config.select.text.default,
              ...(isDisabled && { fill: theme.config.select.text.disabled }),
            },
          },
        };
      },

      option: (css: CSSObjectWithLabel, state: OptionProps<SelectOption>) => ({
        ...css,
        columnGap: 8,
        fontWeight: 400,
        display: 'flex',
        fontSize: '14px',
        cursor: 'pointer',
        lineHeight: '24px',
        padding: '12px 16px',
        alignItems: 'center',
        ':hover': {
          backgroundColor: theme.config.select.option.selected,
        },

        color: state.isDisabled
          ? theme.config.select.text.disabled
          : theme.config.input.text,

        backgroundColor:
          ((state.isFocused || state.isSelected) &&
            theme.config.select.option.selected) ||
          theme.config.select.bg,
      }),

      control: (
        css: CSSObjectWithLabel,
        state: ControlProps<SelectOption>
      ) => ({
        ...css,
        cursor: 'pointer',
        boxShadow: 'none',
        padding: '0 10px 0 0px',
        borderRadius: theme.borderRadius,
        minHeight: large ? '48px' : '40px',
        transition: 'border-color 0.2s ease-in-out',

        '.rs-searchable-option-icon': {
          display: state.menuIsOpen ? 'none' : 'inherit',
        },

        background: state.isDisabled
          ? theme.config.input.bg.disabled
          : theme.config.select.bg,

        '&:hover': {
          borderColor: state.isFocused
            ? theme.config.select.border.active
            : (error && theme.config.select.border.errorHover) ||
              theme.config.select.border.hover,
        },

        '.rs-search-icon': {
          marginRight: '8px',
          display: state.menuIsOpen ? 'block' : 'none',
          transform: state.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',

          '& path': {
            fill: theme.config.select.text.default,
          },
        },

        border: `1px solid ${
          state.menuIsOpen || state.isFocused
            ? theme.config.select.border.active
            : state.isDisabled
              ? theme.config.input.border.disabled
              : (error && theme.config.select.border.error) ||
                theme.config.select.border.default
        }`,
      }),
    }),
    [theme, large, error]
  );

  return customStyles;
};
