export const GraphListTemplates = Object.freeze({
  Mobile: '135px 1fr',
  Tablet: '60% 1fr 1fr',
  Desktop: '50% 1fr 1fr',
});

export const GraphListCells = Object.freeze({
  Mobile: [
    { row: '1/2', col: '1/3' },
    { row: '2/3', col: '2/3' },
    { row: '3/4', col: '2/3' },
    { row: '2/3', col: '1/2' },
    { row: '3/4', col: '1/2' },
  ],
});
