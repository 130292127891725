import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 0;
`;

export const Main = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
`;
