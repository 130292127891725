import {
  BasePageProps,
  SearchRequest,
  SafeRefetchProps,
  UseQueryReturnProps,
} from '../types';

import { joinStrings } from './helpers';
import { filterEmptyFields } from './object';

export const addParamsToUrl = (
  url: string,
  params?: Record<string, any>
): string => {
  if (!params) {
    return url;
  }
  const urlParams = new URLSearchParams();
  Object.keys(filterEmptyFields(params)).forEach((key) =>
    urlParams.append(key, params[key])
  );
  return `${url}?${urlParams.toString()}`;
};

export const addSearchParams = (
  url: string,
  params?: SearchRequest | void
): string => addParamsToUrl(url, { search: params?.search, ...params });

export const mapQuery = ({
  error,
  isFetching,
  ...props
}: UseQueryReturnProps): BasePageProps => {
  return {
    ...props,
    error: error?.message,
    isLoading: isFetching,
  };
};

export type Refetches<T> = Record<keyof T, () => void>;

export const safeRefetch = (props: SafeRefetchProps) => () => {
  const { refetch, isLoading, isUninitialized } = props;
  if (!isUninitialized && !isLoading) {
    try {
      refetch();
    } catch (error) {
      console.error(error);
      const sentry = process.env.REACT_APP_SENTRY;
      if (!sentry) {
        throw error;
      }
    }
  }
};

export const mapQueries = (
  queries: Omit<UseQueryReturnProps, 'data'>[]
): BasePageProps => {
  const queriesState = queries.reduce<{
    errorStates: boolean[];
    loadingStates: boolean[];
    errors: (string | undefined)[];
    refetchFunctions: (() => void)[];
  }>(
    (result, query) => {
      const { error, refetch, isError, isFetching } = query;

      result.loadingStates.push(isFetching);
      result.errorStates.push(isError);

      if (isError) {
        result.errors.push(error?.message);
        result.refetchFunctions.push(refetch);
      }

      return result;
    },
    {
      errors: [],
      errorStates: [],
      loadingStates: [],
      refetchFunctions: [],
    }
  );

  return {
    isError: queriesState.errorStates.some(Boolean),
    isLoading: queriesState.loadingStates.some(Boolean),
    refetch: () =>
      queriesState.refetchFunctions.forEach((refetch) => {
        try {
          refetch();
        } catch (error) {
          console.error(error);
        }
      }),
    error:
      queriesState.errors.filter(Boolean).length > 0
        ? joinStrings(
            Array.from(new Set(queriesState.errors as string[])),
            '\n'
          )
        : undefined,
  };
};
