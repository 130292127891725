import styled from 'styled-components';

import { bodySemibold } from 'styles/mixins/fonts';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Label = styled.label<{ disabled?: boolean }>`
  display: flex;
  ${bodySemibold};
  color: ${(p) =>
    p.disabled
      ? p.theme.config.select.label.disabled
      : p.theme.config.select.label.default};
`;

export const Required = styled.span`
  color: ${(p) => p.theme.config.select.border.error};
`;

export const SelectAllOption = styled.span`
  ${bodySemibold};
  color: ${({ theme }) => theme.colors.blue.primary.standard};
`;
