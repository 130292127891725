import styled from 'styled-components';

import { flexCenter } from 'styles';

export const Container = styled.div`
  display: flex;

  &.upload-picture {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 201;

    .upload-picture-overlay {
      ${flexCenter};
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 300ms;
      background: ${({ theme }) => theme.config.popup.modal};
    }
  }

  &.upload-picture-enter {
    .upload-picture-overlay {
      opacity: 0;
    }
  }

  &.upload-picture-enter-active {
    .upload-picture-overlay {
      opacity: 1;
    }
  }

  &.upload-picture-exit {
    .upload-picture-overlay {
      opacity: 1;
    }
  }

  &.upload-picture-exit-active {
    .upload-picture-overlay {
      opacity: 0;
    }
  }
`;
