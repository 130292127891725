import { Badge } from 'components/badge';
import { PayoutStatus, PayoutStatusColor, PayoutStatusTitle } from 'types';

interface StatusProps {
  status: PayoutStatus;
}

export const PayoutStatusBadge = ({ status }: StatusProps) => (
  <Badge
    text={PayoutStatusTitle[status]}
    styleType={PayoutStatusColor[status]}
  />
);
