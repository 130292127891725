import { useNavigate } from 'hooks';
import { ButtonColors } from 'types';
import { Icon } from 'components/icon';
import { Button } from 'components/button';

import * as Styles from './styles';
import { BackButtonProps } from './types';

export const BackButton = ({
  to,
  delta,
  options,
  className,
  padding = '16px',
  ...props
}: BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <Styles.Wrapper className={className}>
      <Button
        padding={padding}
        icon={<Icon.ChevronLeft />}
        colorType={ButtonColors.Transparent}
        {...props}
        onClick={() => {
          if (to) {
            navigate(to, options);
          } else {
            navigate(delta ?? -1);
          }
        }}
      />
    </Styles.Wrapper>
  );
};
