import { useField } from 'formik';

import { CommonFieldProps } from 'types';
import { controlError } from 'utils/error';
import { Checkbox } from 'components/checkbox';
import { ErrorMessage } from 'components/error-message';

import { CheckboxProps } from '../checkbox';

export interface CheckboxFieldProps
  extends CommonFieldProps,
    Omit<CheckboxProps, 'name'> {}

export const CheckboxField = ({
  name,
  label,
  style,
  onChange,
  controlErrorLabel = true,
  ...props
}: CheckboxFieldProps) => {
  const [field, meta] = useField({ name, type: 'checkbox' });
  const error = controlError(meta, name, label, controlErrorLabel);

  return (
    <div style={style}>
      <Checkbox
        {...field}
        {...props}
        label={label}
        checked={field.value}
        onChange={async (e) => {
          field.onChange(e);
          onChange?.(e);
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};
