import styled, { css } from 'styled-components';

import { captionSemibold } from 'styles/mixins/fonts';

export const Container = styled.div`
  height: inherit;
`;

export const Tooltip = styled.div`
  ${captionSemibold};

  padding: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.04);

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    color: ${theme.config.barChart.tooltip.color};
    background-color: ${theme.config.barChart.tooltip.bg};
  `}
`;
