import * as Yup from 'yup';
import { FormikErrors } from 'formik';

import {
  ProductFormValues,
  CreateProductFormValues,
} from 'store/api/product/types';

export const validationSchema = Yup.object().shape({
  sku: Yup.string().required(),
  name: Yup.string().required(),
  unitPerCase: Yup.string().required(),
  minPrice: Yup.number().positive().required(),
  costPrice: Yup.number().positive().required(),
  retailPrice: Yup.number().positive().required(),
});

export const validate = <T extends ProductFormValues | CreateProductFormValues>(
  values: T
) => {
  const errors: FormikErrors<ProductFormValues> = {};

  const { attributeIds, attributeValueIds } = values;

  if (attributeIds && attributeIds.length > 0) {
    attributeIds.forEach((id) => {
      if (
        !Array.isArray(attributeValueIds[id]) ||
        attributeValueIds[id].length === 0
      ) {
        if (!errors.attributeValueIds) {
          errors.attributeValueIds = {};
        }
        errors.attributeValueIds[id] =
          'Each attribute must have selected values';
      }
    });
  }

  return errors;
};
