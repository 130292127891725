import { Flex } from 'components/flex';

import * as Styles from './styles';
import { SectionProps } from './types';

export const SectionHeader = ({
  title,
  style,
  padding,
  subTitle,
  leftSlot,
  className,
  rightSlot,
  withBorder = true,
}: SectionProps) => (
  <Styles.Container
    style={style}
    padding={padding}
    className={className}
    withBorder={withBorder}
  >
    <Flex gap={16} alignItems="center">
      {leftSlot}

      <Flex column flex={1}>
        <h3>{title}</h3>
        {subTitle && <p>{subTitle}</p>}
      </Flex>

      {rightSlot}
    </Flex>
  </Styles.Container>
);
