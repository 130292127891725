import { useState } from 'react';

import { isMobile } from 'hooks';
import { formatDate } from 'utils/date';
import { joinStrings } from 'utils/helpers';
import { useReportQuery } from 'store/api/event';
import { Tabs, Flex, Drawer, Loader, FlexProps } from 'components';

import { EventReportProps } from '../types';
import { EventHeader } from '../event-header';

import * as Styles from './styles';
import { Revenue } from './revenue';
import { Bonuses } from './bonuses';
import { Expenses } from './expenses';
// import { Received } from './received';
import { Inventory } from './inventory';
import { Tab, TabTitles } from './types';
import { Reimbursement } from './reimbursement';

const commonFlexProps: FlexProps = {
  gap: 24,
  minHeight: 0,
  fullHeight: true,
  flexDirection: 'column',
};

export const EventReport = ({ event, opened, onClose }: EventReportProps) => {
  const mobile = isMobile();
  const [tab, setTab] = useState<Tab>(Tab.Revenue);

  const { data, isLoading } = useReportQuery({
    id: String(event.id),
  });

  const tabs = Object.values(Tab).map((value) => ({
    to: value,
    label: TabTitles[value],
  }));

  return (
    <Drawer opened={opened} topOffset={mobile ? '10%' : '20%'}>
      <Loader isFullScreen={false} isLoading={isLoading}>
        {data && (
          <Flex {...commonFlexProps}>
            <EventHeader
              event={event}
              onClose={onClose}
              title="Event Summary Report"
              date={joinStrings(
                [formatDate(event.startDate), formatDate(event.endDate)],
                ' - '
              )}
            />

            <Flex {...commonFlexProps}>
              <Tabs
                value={tab}
                items={tabs}
                onChange={(value) => setTab(value as Tab)}
              />
              <Styles.TabContainer>
                {tab === Tab.Revenue && <Revenue data={data} />}
                {tab === Tab.Expenses && <Expenses data={data} event={event} />}
                {/* TODO: uncomment when it was ready */}
                {/* {tab === Tab.Received && <Received />} */}
                {tab === Tab.Inventory && (
                  <Inventory data={data} event={event} />
                )}
                {tab === Tab.Bonuses && <Bonuses event={event} />}
                {tab === Tab.Reimbursements && <Reimbursement event={event} />}
              </Styles.TabContainer>
            </Flex>
          </Flex>
        )}
      </Loader>
    </Drawer>
  );
};
