import { mockBaseQuery } from 'store/query';
import { salesMockApiHandlers } from 'store/api/sales/config';
import { expensesMockApiHandlers } from 'store/api/expenses/config';
import { settingsMockApiHandlers } from 'store/api/settings/config';

export const mockSalesApiQuery = mockBaseQuery({
  handlers: salesMockApiHandlers,
});

export const mockSettingsApiQuery = mockBaseQuery({
  handlers: settingsMockApiHandlers,
});

export const mockExpensesApiQuery = mockBaseQuery({
  handlers: expensesMockApiHandlers,
});
