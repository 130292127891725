export const titles = [
  'Name',
  'Address',
  'Contact name',
  'Contact email',
  'Contact mobile',
  'Contact landline',
  'Contact fax',
];

export const cellsMobile = [
  { row: '1/2', col: '1/2' },
  { row: '2/3', col: '1/3' },
  { row: '1/2', col: '2/3' },
  { row: '3/4', col: '1/2' },
  { row: '3/4', col: '2/3' },
];
