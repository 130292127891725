import styled from 'styled-components';

export const Container = styled.div`
  &.expand-enter {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &.expand-enter-active {
    height: 100%;
    opacity: 1;
    transition:
      height 300ms ease,
      opacity 300ms ease;
  }

  &.expand-exit {
    opacity: 1;
    height: 100%;
  }

  &.expand-exit-active {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition:
      height 300ms ease,
      opacity 300ms ease;
  }
`;
