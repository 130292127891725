import { lazy } from 'react';

import * as Tavla from './tavla';
import * as Revenue from './revenue';
import * as SalesTax from './sales-tax';
import * as CommissionStructe from './commission-structure';
import { EditExpenses, CreateExpenses } from './expenses/form';

const Home = lazy(() => import('./home'));
const Sales = lazy(() => import('./sales'));
const Deposit = lazy(() => import('./deposit'));
const Expenses = lazy(() => import('./expenses'));
const GiftCards = lazy(() => import('./gift-cards'));
const Chargebacks = lazy(() => import('./chargebacks'));
const Transactions = lazy(() => import('./transactions'));
const ExpensesDetails = lazy(() => import('./expenses/details'));
const ProfitAndLosses = lazy(() => import('./profit-and-losses'));
const ExpensesCategories = lazy(() => import('./expenses/categories'));

export {
  Home,
  Tavla,
  Sales,
  Deposit,
  Revenue,
  Expenses,
  SalesTax,
  GiftCards,
  Chargebacks,
  Transactions,
  EditExpenses,
  CreateExpenses,
  ExpensesDetails,
  ProfitAndLosses,
  CommissionStructe,
  ExpensesCategories,
};
