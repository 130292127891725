import * as Yup from 'yup';
import { useState } from 'react';
import { Form, Formik } from 'formik';

import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { useAppSelector } from 'store';
import { mapQueries } from 'utils/query';
import { useSearchQuery, handleMutation } from 'hooks';
import { BackOfficeRoles, ButtonStyleTypes } from 'types';
import { useListQuery as useCompaniesQuery } from 'store/api/company';
import { selectAuthUserRole, selectAuthUserType } from 'store/slices/auth';
import {
  Event,
  useOfferMutation,
  useListQuery as useEventsQuery,
} from 'store/api/event';
import {
  List,
  Flex,
  Dialog,
  Button,
  ListHeader,
  DialogType,
  SelectField,
  ButtonTypes,
  NewEntityButton,
} from 'components';

import { Card } from './card';
import { titlesByUserType, templateByUserType } from './config';

export const ListPage = () => {
  const { debounceQuery, ...search } = useSearchQuery();
  const [selectedEvent, setSelectedEvent] = useState<Event['id']>();
  const authUserType = useAppSelector(selectAuthUserType);
  const authUserRole = useAppSelector(selectAuthUserRole);

  const isSalesOrManager =
    authUserRole === BackOfficeRoles.SALES_REP ||
    authUserRole === BackOfficeRoles.MANAGER;

  useHeader({ pageName: 'Events' });

  const { data = [], ...eventsQuery } = useEventsQuery({
    search: debounceQuery,
  });
  const { data: companies, ...companiesQuery } = useCompaniesQuery();

  const [offer, offerMutation] = useOfferMutation();
  handleMutation({
    ...offerMutation,
    successMessage: 'Event offered',
  });

  const companiesOptions =
    companies?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? [];

  return (
    <>
      <List
        {...mapQueries([eventsQuery, companiesQuery])}
        {...search}
        count={data.length}
        mainWrapperStyles={{ paddingBottom: '16px' }}
        searchBarRightSlot={
          !isSalesOrManager ? (
            <NewEntityButton text="New event" path={AppRoutes.Events.New} />
          ) : undefined
        }
        header={
          <ListHeader
            titles={authUserType && titlesByUserType[authUserType]}
            template={authUserType && templateByUserType[authUserType]}
          />
        }
      >
        {data.map((item) => (
          <Card data={item} key={item.id} onEventOffer={setSelectedEvent} />
        ))}
      </List>

      <Dialog
        title="Select company"
        open={Boolean(selectedEvent)}
        onClose={() => setSelectedEvent(undefined)}
      >
        {(close, _, ButtonsComponent) => (
          <Formik
            initialValues={{ companyId: undefined }}
            onSubmit={(values) => {
              offer({ ...values, id: selectedEvent! });
              close();
            }}
            validationSchema={Yup.object().shape({
              companyId: Yup.string().required('Select company'),
            })}
          >
            <Form style={{ width: '100%' }}>
              <Flex gap={16} flexDirection="column">
                <SelectField
                  onlyValue
                  name="companyId"
                  options={companiesOptions}
                />

                <ButtonsComponent type={DialogType.Plain}>
                  <Button
                    text="Cancel"
                    onClick={close}
                    styleType={ButtonStyleTypes.Outline}
                  />

                  <Button text="Confirm" type={ButtonTypes.Submit} />
                </ButtonsComponent>
              </Flex>
            </Form>
          </Formik>
        )}
      </Dialog>
    </>
  );
};
