import styled from 'styled-components';

import { Flex } from 'components/flex';
import { Badge, Badges } from 'components/badge';

export const BadgesContainer = styled(Badges)`
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Container = styled(Flex)`
  min-width: 0;
`;

export const PopupBadge = styled(Badge)`
  min-width: auto;
`;
