import styled, { css } from 'styled-components';

import { captionSemibold } from 'styles/mixins/fonts';

import { ListItemContainerProps } from './types';

export const ListItemContainer = styled.div<ListItemContainerProps>`
  width: 100%;
  display: flex;
  min-height: 24px;
  flex-direction: row;
  ${({ gap, alignCenter }) => css`
    gap: ${gap}px;
    align-items: ${alignCenter ? 'center' : 'flex-start'};
  `}
`;

export const IndexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 24px;
  background-color: transparent;
  ${captionSemibold};

  ${({ theme }) => css`
    color: ${theme.colors.blue.primary.standard};
    border: 2px solid ${theme.colors.blue.primary.standard};
  `};
`;
