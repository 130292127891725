import { useMemo } from 'react';

import { Icon } from 'components/icon';
import { ButtonStyleTypes } from 'types';
import { Select } from 'components/select';
import { Button } from 'components/button';
import { isMobile as isMobileHook } from 'hooks';

import * as Styles from './styles';
import { viewTypeOptions } from './config';
import { EventCalendarHeaderProps } from './types';

export const Header = ({
  header,
  calendar,
  headerRightSlot,
}: EventCalendarHeaderProps) => {
  const isMobile = isMobileHook();
  const api = calendar?.getApi();
  // @ts-ignore
  const viewType = api?.view.type;

  const selectedType = useMemo(() => {
    if (!viewType) {
      return undefined;
    }
    return viewTypeOptions.find((option) => option.value === viewType);
  }, [viewType]);

  if (!api) {
    return null;
  }

  // @ts-ignore
  const title = api.currentDataManager?.getCurrentData().viewTitle;

  return (
    <Styles.Header>
      <Styles.Subheader>
        <Styles.HeaderLeft>
          <Button
            padding="0"
            nativeColorIcon
            onClick={() => api.prev()}
            icon={<Icon.ChevronLeft />}
            styleType={ButtonStyleTypes.Ghost}
          />
          <Button
            padding="0"
            nativeColorIcon
            onClick={() => api.next()}
            icon={<Icon.ChevronRight />}
            styleType={ButtonStyleTypes.Ghost}
          />
          {!isMobile && <h2>{title}</h2>}
        </Styles.HeaderLeft>

        <Styles.HeaderRight>
          <Button
            text="Today"
            onClick={() => api.today()}
            styleType={ButtonStyleTypes.Ghost}
          />

          <Select
            value={selectedType}
            options={viewTypeOptions}
            onChange={(value) => {
              // @ts-ignore
              api.changeView(value.value);
            }}
          />

          {!isMobile && headerRightSlot}
        </Styles.HeaderRight>
      </Styles.Subheader>

      {isMobile && (
        <>
          {headerRightSlot}
          <h3>{title}</h3>
        </>
      )}
      {header}
    </Styles.Header>
  );
};
