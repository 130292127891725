import * as Yup from 'yup';
import { useState } from 'react';
import { Formik, FormikConfig } from 'formik';

import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { useAppSelector } from 'store';
import { formatDate } from 'utils/date';
import { Form } from 'components/styles';
import { Messages, AppRoutes } from 'config';
import { selectAuthUser } from 'store/slices/auth';
import { BadgeColor, ButtonStyleTypes } from 'types';
import { useNavigate, useSearchQuery, handleMutation } from 'hooks';
import { useListQuery as useStoragesQuery } from 'store/api/storage';
import { formatPrice, makeSelectOptionsFromEntities } from 'utils/helpers';
import {
  List,
  Badge,
  Select,
  Dialog,
  Button,
  ListCard,
  ListHeader,
  DialogType,
  SelectField,
  ButtonTypes,
  SelectOption,
  ButtonWithRef,
  NewEntityButton,
} from 'components';
import {
  useListQuery,
  StockTransfer,
  StockTransferType,
  useAcceptMutation,
  useCompleteMutation,
  StockTransferStatus,
  useChangeStatusMutation,
  stockTransferStatusLabel,
  stockTransferStatusOptions,
} from 'store/api/stock-transfers';

import { titles } from './config';

interface AcceptTransferFormValues {
  destinationStorageId: number | null;
}

const ListPage = () => {
  const [acceptTransferItemId, setAcceptTransferItemId] = useState<
    StockTransfer['id'] | null
  >(null);

  const authUser = useAppSelector(selectAuthUser);

  const navigate = useNavigate();
  const { debounceQuery, ...search } = useSearchQuery();

  useHeader({ pageName: 'Transfers' });

  const { data = [], ...props } = useListQuery({
    search: debounceQuery,
  });
  const { data: storages } = useStoragesQuery();

  const [changeStatus, changeStatusMutation] = useChangeStatusMutation();
  const [complete, completeMutation] = useCompleteMutation();
  const [accept, acceptMutation] = useAcceptMutation();

  handleMutation({
    ...changeStatusMutation,
    successMessage: `Stock transfer ${Messages.UPDATED_SUCCESSFULLY}`,
  });

  handleMutation({
    ...completeMutation,
    successMessage: 'Stock transfer completed successfully',
  });

  handleMutation({
    ...acceptMutation,
    onSuccess: () => setAcceptTransferItemId(null),
    successMessage: 'Stock transfer accepted successfully',
  });

  const onStatusChange = (option: SelectOption, item: StockTransfer) => {
    if (option.value === StockTransferStatus.Completed) {
      if (item.type === StockTransferType.ClientToClient) {
        setAcceptTransferItemId(item.id);
      } else {
        complete(item.id);
      }
    } else if (option.value !== item.status) {
      changeStatus({
        id: item.id,
        status: option.value as StockTransferStatus,
      });
    }
  };

  const onAcceptTransfer: FormikConfig<AcceptTransferFormValues>['onSubmit'] = (
    values
  ) => {
    if (acceptTransferItemId) {
      accept({
        id: acceptTransferItemId,
        destinationStorageId: values.destinationStorageId,
      });
    }
  };

  const storageSelectOptions = makeSelectOptionsFromEntities(storages);

  return (
    <>
      <List
        {...mapQuery(props)}
        {...search}
        count={data.length}
        header={<ListHeader titles={titles} />}
        mainWrapperStyles={{ paddingBottom: '16px' }}
        searchBarRightSlot={
          <NewEntityButton
            text="New transfer"
            path={AppRoutes.Inventory.Transfers.New}
          />
        }
      >
        {data.map((item) => {
          const resultStatusOptions = stockTransferStatusOptions.filter(
            (option) =>
              item.type === StockTransferType.ClientToClient &&
              item.destinationCompany?.id !== authUser?.company?.id
                ? option.value !== StockTransferStatus.Completed
                : true
          );

          const values = [
            formatDate(item.date),
            item.sourceStorage?.name,
            item.destinationCompany?.name || item.destinationStorage?.name,
            formatPrice(item.wholesaleCost),
            item.status === StockTransferStatus.Completed ? (
              <Badge
                styleType={BadgeColor.Green}
                text={stockTransferStatusLabel[item.status]}
              />
            ) : (
              <Select
                options={resultStatusOptions}
                onChange={(option) =>
                  option && 'value' in option && onStatusChange(option, item)
                }
                value={{
                  value: item.status,
                  label: stockTransferStatusLabel[item.status],
                }}
              />
            ),
            item.createdBy.name,
          ];

          return (
            <ListCard
              key={item.id}
              values={values}
              headers={titles}
              onClick={() => navigate(String(item.id))}
            />
          );
        })}
      </List>

      <Dialog
        title="Accept transfer"
        open={Boolean(acceptTransferItemId)}
        onClose={() => setAcceptTransferItemId(null)}
        trigger={<ButtonWithRef text="Accept transfer" />}
      >
        {(close, _, ButtonsComponent) => (
          <Formik
            onSubmit={onAcceptTransfer}
            initialValues={
              { destinationStorageId: null } as AcceptTransferFormValues
            }
            validationSchema={Yup.object().shape({
              destinationStorageId: Yup.number().required(
                'Storage is required'
              ),
            })}
          >
            <Form gap={16}>
              <SelectField
                onlyValue
                label="Storage"
                name="destinationStorageId"
                options={storageSelectOptions}
              />

              <ButtonsComponent type={DialogType.Plain}>
                <Button
                  text="Cancel"
                  onClick={close}
                  styleType={ButtonStyleTypes.Outline}
                />

                <Button text="Confirm" type={ButtonTypes.Submit} />
              </ButtonsComponent>
            </Form>
          </Formik>
        )}
      </Dialog>
    </>
  );
};

export default ListPage;
