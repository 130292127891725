export const getRelease = (
  sentry?: string,
  serverEnv?: string,
  buildVersion?: string
) => {
  let release: string | undefined;
  let environment: string | undefined;
  if (sentry) {
    if (serverEnv && buildVersion) {
      switch (serverEnv) {
        case 'prod':
          environment = 'production';
          break;
        case 'stage':
          environment = 'staging';
          break;
        default:
          environment = 'development';
          break;
      }
      release = `frontend-${serverEnv}-${buildVersion}`;
    }
  }
  return { release, environment };
};
