import { useState, Dispatch, useEffect, SetStateAction } from 'react';

const useQuery = <T extends string = string>(
  name: string,
  initialValue = '' as T
): [T, Dispatch<SetStateAction<T>>] => {
  const [query, setQuery] = useState<T>(
    (new URLSearchParams(window.location?.search).get(name) ??
      initialValue) as T
  );

  useEffect(() => {
    const hasQuery = query?.length > 0;
    const params = new URLSearchParams(window.location?.search || '');
    if (hasQuery) {
      params.set(name, query);
    } else {
      params.delete(name);
    }
    const search = params.toString();
    window.history.replaceState(
      null,
      '',
      search.length > 0
        ? `${window.location.pathname}?${params.toString()}`.replace(
            /%2C/g,
            ','
          )
        : window.location.pathname
    );
  }, [query]);

  return [query, setQuery];
};

export { useQuery };
