import { useCallback } from 'react';

import { Messages, AppRoutes } from 'config';
import { useCreateMutation } from 'store/api/storage';
import { useAlert, useNavigate, handleMutation } from 'hooks';

import { Form } from './form';

export const Create = () => {
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [create, mutation] = useCreateMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Storage ${Messages.ADDED_SUCCESSFULLY}`);
    navigate(AppRoutes.Inventory.Storages.Home);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  return (
    <Form
      onSubmit={create}
      initialValues={{
        name: '',
        address: '',
        gateCode: '',
        monthlyPrices: [{ date: undefined, price: undefined }],
      }}
    />
  );
};
