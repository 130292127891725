export const defaultCellsTablet = [
  { row: '1/2', col: '1/4' },
  { row: '2/3', col: '1/4' },
  { row: '3/4', col: '1/2' },
  { row: '3/4', col: '2/3' },
  { row: '3/4', col: '3/4' },
];

export const defaultCellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/3' },
  { row: '3/4', col: '1/2' },
  { row: '3/4', col: '2/3' },
];
