import styled from 'styled-components';

import { flexCenter } from 'styles/mixins/index';

export const GraphContainer = styled.div`
  ${flexCenter};
  padding: 13px;
  width: ${({ theme }) => (theme.responsive.isDesktop ? 45 : 100)}%;
`;

export const Container = styled.div`
  ${flexCenter};
  width: 100%;
  padding: 24px 0;
  flex-direction: ${({ theme }) =>
    theme.responsive.isDesktop ? 'row' : 'column'};
`;
