import React, { lazy, PropsWithChildren } from 'react';
import {
  Outlet,
  Navigate,
  RouterProvider,
  createBrowserRouter,
  RouteObject as RouterRouteObject,
} from 'react-router-dom';

import { NotFound } from 'pages';
import { AppRoutes } from 'config';
import { Login } from 'pages/auth';
import { Role, Feature } from 'types';
import { useAppSelector } from 'store';
import { RouteObject, getRouteObjects } from 'config/route-objects';
import {
  HeaderContextProvider,
  SocketContextProvider,
  SidebarContextProvider,
} from 'context';
import {
  selectIsLoggedIn,
  selectAuthUserRole,
  selectAuthUserFeatures,
} from 'store/slices/auth';

const AppPrivate = lazy(() => import('./app-private'));

const AuthLayout = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  return isLoggedIn ? <Navigate to={AppRoutes.Home} /> : <Outlet />;
};

export const ProtectedRoute = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  return isLoggedIn ? (
    <SidebarContextProvider>
      <HeaderContextProvider>
        <SocketContextProvider>
          <AppPrivate />
        </SocketContextProvider>
      </HeaderContextProvider>
    </SidebarContextProvider>
  ) : (
    <Navigate to={AppRoutes.Auth.Login} />
  );
};

export const FeatureProtectedRoute = ({
  children,
  features,
}: PropsWithChildren<{
  features: Feature[];
}>) => {
  const authUserFeatures = useAppSelector(selectAuthUserFeatures);

  if (!features.some((permission) => authUserFeatures?.includes(permission))) {
    return <Navigate to={AppRoutes.Home} />;
  }

  return children || <Outlet />;
};

const mapLinks = (
  links: RouteObject[],
  authUserFeatures?: Feature[],
  userRole?: Role
): RouterRouteObject[] =>
  links
    .filter(({ features, hideForRoles }) => {
      if (userRole && hideForRoles?.includes(userRole)) {
        return false;
      }
      if (features) {
        return features.some((feature) => authUserFeatures?.includes(feature));
      }
      return true;
    })
    .map(({ path, index, element, children, features }) => ({
      path,
      index,
      children: children
        ? mapLinks(children, authUserFeatures, userRole)
        : undefined,
      element: features ? (
        <FeatureProtectedRoute features={features}>
          {element}
        </FeatureProtectedRoute>
      ) : (
        element
      ),
    })) as RouterRouteObject[];

const Router = () => {
  const userRole = useAppSelector(selectAuthUserRole);
  const authUserFeatures = useAppSelector(selectAuthUserFeatures);
  const filteredRoutes = mapLinks(
    getRouteObjects(authUserFeatures, userRole),
    authUserFeatures,
    userRole
  );

  const router = createBrowserRouter([
    {
      path: AppRoutes.Home,
      element: <ProtectedRoute />,
      children: [
        {
          index: true,
          element: (
            <Navigate
              replace
              to={filteredRoutes?.[0]?.path || AppRoutes.Home}
            />
          ),
        },
        ...filteredRoutes,
        {
          path: '*',
          element: <NotFound homePath={AppRoutes.Home} />,
        },
      ],
    },
    {
      path: AppRoutes.NotFound,
      element: <NotFound homePath={AppRoutes.Home} />,
    },
    {
      element: <AuthLayout />,
      children: [
        {
          element: <Login />,
          path: AppRoutes.Auth.Login,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
