import { Button } from 'components/button';
import { ButtonColors, ButtonStyleTypes } from 'types/enums';
import { ChoiceUserStatus } from 'components/choice-user-status';

import * as Styles from './styles';
import { AvatarPopupProps } from './types';

const AvatarPopup = ({
  user,
  options,
  onSignOut,
  profileLink,
}: AvatarPopupProps) => {
  return (
    <Styles.Container>
      {profileLink && (
        <>
          <Styles.Title>Current profile</Styles.Title>

          <Styles.UserWrap>{user?.email}</Styles.UserWrap>
        </>
      )}

      <ChoiceUserStatus options={options} />

      {/*<ChoiceTheme border />*/}

      <Styles.ButtonWrap>
        <Button
          fullWidth
          text="Sign out"
          onClick={onSignOut}
          colorType={ButtonColors.LightGrey}
          styleType={ButtonStyleTypes.Outline}
        />
      </Styles.ButtonWrap>
    </Styles.Container>
  );
};

export { AvatarPopup };
export type { AvatarPopupProps };
