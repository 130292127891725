import { User } from 'types';
import { useNavigate } from 'hooks';
import { Flex, Avatar, ListCard } from 'components';

import { titles } from './config';

export const Card = ({ data }: { data: User }) => {
  const navigate = useNavigate();

  return (
    <ListCard
      headers={titles}
      onClick={() => navigate(String(data.id))}
      values={[
        <Flex gap={16} alignItems="center">
          <Avatar size={56} url={data.photoUrl} />
          <p>{data.name}</p>
        </Flex>,
        data.email,
        data.company?.name,
        data.role?.name,
      ]}
    />
  );
};
