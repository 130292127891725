import { ThemeProvider } from 'styled-components';
import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';

import { DARK_THEME, LIGHT_THEME } from 'config/themes';
import { saveDarkTheme, getIsDarkTheme } from 'utils/storage';
import { isMobile, isTablet, isDesktop, isTabletUp } from 'hooks';
import { AppTheme, FontsConfig, DefaultConfig } from 'config/themes/types';

interface ThemeContextProps {
  theme: AppTheme;
  isDarkTheme: boolean;
  selectTheme?: (isDarkTheme?: boolean) => void;
}

interface ThemeContextProviderProps {
  isDarkMode?: boolean; // used to override theme by storybook
  fontsConfig?: FontsConfig;
}

const getIsCurrentThemeDark = () => {
  return (
    getIsDarkTheme() ??
    (window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches)
  );
};

const getInitialState = (): ThemeContextProps => {
  const isDarkTheme = false;
  return {
    isDarkTheme: isDarkTheme,
    theme: isDarkTheme ? DARK_THEME : LIGHT_THEME,
  };
};

export const ThemeContext = createContext<ThemeContextProps>(getInitialState());

export const ThemeContextProvider = ({
  children,
  isDarkMode,
  fontsConfig,
}: PropsWithChildren<ThemeContextProviderProps>): JSX.Element => {
  const [isDarkTheme, setDarkTheme] = useState(getInitialState().isDarkTheme);

  useEffect(() => {
    if (typeof isDarkMode === 'boolean') {
      setDarkTheme(isDarkMode);
    }
  }, [isDarkMode]);

  const responsive = {
    isTablet: isTablet(),
    isMobile: isMobile(),
    isDesktop: isDesktop(),
    isTabletUp: isTabletUp(),
  };

  const selectTheme = useCallback(
    (isDark?: boolean) => {
      saveDarkTheme(isDark);
      setDarkTheme(getIsCurrentThemeDark());
    },
    [isDarkTheme]
  );

  const value = useMemo(() => {
    const theme = isDarkTheme ? DARK_THEME : LIGHT_THEME;
    return {
      isDarkTheme,
      selectTheme,
      theme: {
        ...theme,
        responsive,
        fonts: {
          ...DefaultConfig,
          ...fontsConfig,
        },
      },
    };
  }, [
    isDarkTheme,
    selectTheme,
    responsive.isMobile,
    responsive.isTablet,
    responsive.isDesktop,
    responsive.isTabletUp,
  ]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const ThemeWrap = ({ children }: PropsWithChildren): JSX.Element => {
  const { theme } = useContext(ThemeContext);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
