import { Messages } from 'config';
import { useAppSelector } from 'store';
import { isMobile, handleMutation } from 'hooks';
import { PayoutType, ButtonColors } from 'types';
import { useListQuery } from 'store/api/employees';
import { selectAuthUser } from 'store/slices/auth';
import { useCreateMutation } from 'store/api/payout';
import {
  Row,
  Col,
  Dialog,
  Button,
  DialogType,
  ButtonTypes,
  SelectField,
  PaymentsForm,
  NewEntityButton,
} from 'components';

import { FormValues, CreateBonusDialogProps } from './types';
import { getInitialValues, validationSchema } from './config';

export const CreateBonusDialog = ({
  eventId,
  isAdvances,
}: CreateBonusDialogProps) => {
  const mobile = isMobile();
  const { data, isFetching } = useListQuery();
  const [create, mutation] = useCreateMutation();
  const authUser = useAppSelector(selectAuthUser);

  const title = isAdvances ? 'advance' : 'bonus';
  handleMutation({
    ...mutation,
    successMessage: `${title} ${Messages.ADDED_SUCCESSFULLY}`,
  });

  const employeeOptions = data
    ?.filter((value) => value?.user?.id !== authUser?.id)
    .map((value) => ({
      value: value.id,
      label: value.user?.name,
    }));

  return (
    <Dialog
      title={`Create ${title}`}
      trigger={<NewEntityButton text={`Add ${title}`} />}
    >
      {(close, _, ButtonWrap) => (
        <PaymentsForm<FormValues>
          isDialog
          isTopChildren
          hideBackButton
          isDisableEvent
          validationSchema={validationSchema}
          initialValues={getInitialValues(eventId)}
          onSubmit={async (values) => {
            const response = await create({
              ...values,
              amount: +values.amount,
              employeeId: +values.employeeId,
              type: isAdvances ? PayoutType.Advance : PayoutType.Bonus,
            }).unwrap();

            if (response.id) {
              close();
            }
          }}
          footerButtons={({ dirty, isSubmitting }) => (
            <ButtonWrap type={DialogType.Plain}>
              <Button
                text="Cancel"
                onClick={close}
                fullWidth={mobile}
                colorType={ButtonColors.LightBlue}
              />
              <Button
                text="Add"
                fullWidth={mobile}
                type={ButtonTypes.Submit}
                disabled={!dirty || isSubmitting}
              />
            </ButtonWrap>
          )}
        >
          <Row>
            <Col>
              <SelectField
                onlyValue
                label="Employee"
                name="employeeId"
                isDisabled={isFetching}
                options={employeeOptions}
              />
            </Col>
          </Row>
        </PaymentsForm>
      )}
    </Dialog>
  );
};
