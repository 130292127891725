import { useField } from 'formik';

import { RadioButton, RadioButtonProps } from 'components/radio-button';

export type RadioFieldProps = Partial<RadioButtonProps>;

const RadioField = ({
  name,
  value,
  onChange,
  ...props
}: RadioFieldProps): JSX.Element => {
  const [field, , helpers] = useField({
    name: name!,
    value: value,
    type: 'radio',
  });

  return (
    <RadioButton
      {...field}
      {...props}
      onChange={async (e) => {
        await field.onChange(e);
        onChange?.(e);
        helpers.setTouched(true);
      }}
    />
  );
};

export { RadioField };
