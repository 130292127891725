import styled from 'styled-components';

import { bodySemibold } from 'styles/mixins/fonts';
import { flexCenter, inlineFlexCenter } from 'styles';

export const PlaceholderContainer = styled.div`
  ${flexCenter};
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const PlaceholderIconContainer = styled.div`
  margin-bottom: 32px;
`;

export const ChildrenWrapper = styled.div`
  margin-top: 8px;
`;

export const PlaceholderButton = styled.div`
  ${inlineFlexCenter};
  margin-top: 16px;
  a {
    ${bodySemibold};
    display: flex;
    p {
      margin-left: 8px;
    }
  }
`;
