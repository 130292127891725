import styled, { css } from 'styled-components';

import { bodySemibold } from 'styles/mixins/fonts';

import { InfoBlockProps } from './types';

export const Container = styled.div<
  Pick<InfoBlockProps, 'onClick' | 'disabled'>
>`
  padding: 16px;
  border-radius: 16px;
  ${({ theme, onClick, disabled }) => css`
    background-color: ${theme.config.infoBlock.background};
    box-shadow: ${theme.config.infoBlock.shadow};
    ${onClick &&
    !disabled &&
    css`
      cursor: pointer;
      transition: ${theme.transition.default};
      &:hover {
        background-color: ${theme.config.infoBlock.hover};
      }
      &:active {
        background-color: ${theme.config.infoBlock.action};
      }
    `}

    ${disabled &&
    css`
      opacity: 0.5;
    `}
  `};
`;

export const Header = styled.div`
  gap: 16px;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;

export const Title = styled.div<{ withButton: boolean }>`
  flex: 1;
  display: flex;
  ${bodySemibold};
  ${({ theme, withButton }) => css`
    color: ${theme.config.input.placeholder};
    width: calc(100% - ${withButton ? 40 : 0}px);
  `}
`;
