import styled from 'styled-components';

import { Flex } from 'components/flex';
import { captionSemibold } from 'styles/mixins/fonts';

export const Title = styled.div`
  display: inline-flex;
  width: max-content;
  ${captionSemibold};
  cursor: pointer;
  user-select: none;

  color: ${({ theme }) => theme.config.link.default};

  &:hover {
    color: ${({ theme }) => theme.config.link.hover};
  }
`;

export const Container = styled(Flex)`
  min-width: 0;
  flex-direction: column;
`;

export const Options = styled(Flex)`
  gap: 8px;
  padding: 12px 16px;
  flex-direction: column;
`;
