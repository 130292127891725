import { Form, Formik } from 'formik';

import { Messages } from 'config';
import { handleMutation } from 'hooks';
import { ButtonStyleTypes } from 'types';
import { Event, useEditMutation, EventFormValues } from 'store/api/event';
import {
  Dialog,
  Button,
  DialogType,
  ButtonTypes,
  ButtonWithRef,
  EventTeamAssignSelects,
} from 'components';

export const AssignTeamDialog = ({ event }: { event: Event }) => {
  const [edit, mutation] = useEditMutation();

  handleMutation({
    ...mutation,
    successMessage: `Event ${Messages.UPDATED_SUCCESSFULLY}`,
  });

  return (
    <Dialog
      title="Assign team"
      trigger={
        <ButtonWithRef text="Assign Team" style={{ width: 'fit-content' }} />
      }
    >
      {(close, _, ButtonsComponent) => (
        <Formik<{ id: string } & Partial<EventFormValues>>
          onSubmit={edit}
          initialValues={{
            id: String(event.id),
            leaderId: event.leaderId,
            companyId: event.companyId,
            teamMembers: event.teamMembers.map((teamMember) => teamMember.id),
          }}
        >
          <Form style={{ width: '100%' }}>
            <EventTeamAssignSelects />

            <ButtonsComponent type={DialogType.Plain}>
              <Button
                text="Cancel"
                onClick={close}
                styleType={ButtonStyleTypes.Outline}
              />

              <Button text="Confirm" type={ButtonTypes.Submit} />
            </ButtonsComponent>
          </Form>
        </Formik>
      )}
    </Dialog>
  );
};
