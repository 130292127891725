import { useCallback } from 'react';

import { useAppSelector } from 'store';
import { Messages, AppRoutes } from 'config';
import { selectAuthUser } from 'store/slices/auth';
import { useCreateMutation } from 'store/api/stock-transfers';
import { useAlert, useNavigate, handleMutation } from 'hooks';
import { StockTransferType } from 'store/api/stock-transfers/types';

import { Form } from './form';

const Create = () => {
  const authUser = useAppSelector(selectAuthUser);

  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [create, mutation] = useCreateMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Transfer ${Messages.ADDED_SUCCESSFULLY}`);
    navigate(AppRoutes.Inventory.Transfers.Home);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  return (
    <Form
      onSubmit={create}
      initialValues={{
        items: [],
        date: undefined,
        sourceStorageId: undefined,
        companyTransferType: 'storage',
        destinationStorageId: undefined,
        type: StockTransferType.EventToStorage,
        sourceCompanyId: authUser?.company?.id,
      }}
    />
  );
};

export default Create;
