import { Icon } from 'components/icon';

import * as Styles from './styles';
import { DropImageProps } from './types';

const DropImage = ({
  isError,
  onClick,
  isLoading,
  width = '100%',
  height = '100%',
  allowedFileSize = 10,
}: DropImageProps) => {
  return (
    <Styles.Container
      width={width}
      height={height}
      isError={isError}
      onClick={onClick}
      isLoading={isLoading}
    >
      {isLoading && !isError ? (
        <Icon.Loader width="26px" height="26px" />
      ) : (
        <>
          {isError ? <Icon.ImageOff /> : <Icon.Image />}
          {isError && <Styles.Info>The photo was not uploaded</Styles.Info>}

          <Styles.Info>Click here or</Styles.Info>
          <Styles.Info>drag & drop to upload</Styles.Info>

          {!isError && (
            <Styles.Wrap>
              <Styles.Warning>File size cannot</Styles.Warning>
              <Styles.Warning>{`be more than ${allowedFileSize} MB`}</Styles.Warning>
            </Styles.Wrap>
          )}
        </>
      )}
    </Styles.Container>
  );
};

export { DropImage };
