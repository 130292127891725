import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { AuthEmail } from 'types';
import { parseAxiosError } from 'utils/error';
import { clearAll, saveUser, getTokens, saveTokens } from 'utils/storage';

import { setLoading } from '../loader';

import { types, AuthLogout } from './types';
import { clearAuth, setAuthError } from '.';

export const login = createAsyncThunk(
  types.auth,
  async (payload: AuthEmail, { rejectWithValue }) => {
    try {
      const data = await api.auth.login(payload);
      const authData = data?.data?.data;
      const { user, token, refreshToken } = authData;

      const isLoggedIn = user !== undefined;
      if (isLoggedIn) {
        saveUser(user);
        saveTokens({ token, refreshToken });
      }

      return { isLoggedIn, data: authData };
    } catch (error) {
      return rejectWithValue(parseAxiosError(error));
    }
  }
);

export const logout = createAsyncThunk(
  types.logout,
  async (values: AuthLogout, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      try {
        const { token, refreshToken } = getTokens();
        if (token && refreshToken) {
          await api.auth.logout({ token, refreshToken });
        }
      } catch (error: any) {
        console.error(error.message);
      }

      if (values.error) {
        const message = parseAxiosError(values.error);
        const axiosError = values.error as AxiosError;
        const restorePath = !(
          axiosError.code === '401' &&
          (axiosError.response?.data as any)?.error?.msg !==
            'Invalid authorization token'
        );
        dispatch(
          setAuthError({
            message,
            restorePath,
          })
        );
      }
    } catch (error) {
    } finally {
      clearAll();
      dispatch(setLoading(false));
      dispatch(clearAuth());
      return {};
    }
  }
);
