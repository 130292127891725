import { User } from 'types';

export interface AuthState {
  user?: User;
  error?: Error;
  path?: string;
  isLoggedIn: boolean;
}

export interface AuthLogout {
  error?: Error;
  byUser?: boolean;
}

export const types = {
  auth: 'auth/login',
  logout: 'auth/logout',
};
