import * as Yup from 'yup';
import { Formik, FieldArray, useFormikContext } from 'formik';

import { isMobile } from 'hooks';
import { Flex } from 'components/flex';
import { ButtonStyleTypes } from 'types';
import { Avatar } from 'components/avatar';
import { Checkbox } from 'components/checkbox';
import { ListCard } from 'components/list-card';
import * as CommonStyles from 'components/styles';
import { ListHeader } from 'components/list-header';
import { InputCounts } from 'components/input-counts';
import { Dialog, DialogType } from 'components/dialog';
import { CardValue } from 'components/list-card/types';
import { ErrorMessage } from 'components/error-message';
import { Button, ButtonTypes } from 'components/button';
import { AddButton, DeleteButton } from 'components/specified-buttons';

import * as Styles from './styles';
import { defaultCellsMobile, defaultCellsTablet } from './config';
import { FormValues, SelectProductsFieldSectionProps } from './types';

export const SelectProductsFieldSection = <T extends string = 'products'>({
  columns,
  products,
  hideUnit,
  isCostPrice,
  cellsMobile,
  cellsTablet,
  tabletTemplate,
  name = 'products' as T,
}: SelectProductsFieldSectionProps<T>) => {
  const mobile = isMobile();
  const { values, errors, setFieldValue } = useFormikContext<FormValues<T>>();

  const selectedProductsIds: number[] = values[name].map(
    ({ productId }) => productId
  );

  const initialValues = {
    productIds: selectedProductsIds,
  };

  const titles = ['Name', 'Count', ''];

  if (columns) {
    columns.forEach((column) => {
      titles.splice(column.position, 0, column.header);
    });
  }

  const error = errors[name];

  return (
    <>
      <FieldArray
        name={name}
        render={({ remove }) =>
          values[name].length > 0 && (
            <Styles.StyledList
              dataPlaceholder={null}
              count={values[name].length}
              header={<ListHeader titles={titles} />}
              mainWrapperStyles={mobile ? { padding: 0 } : undefined}
            >
              {values[name].map((selectedProduct, index) => {
                const productData = products?.find(
                  (data) => data.id === selectedProduct.productId
                );

                const cardValues: CardValue[] = [
                  <Flex gap={16} alignItems="center">
                    <Avatar url={productData?.photoUrl} />
                    <p>{productData?.name}</p>
                  </Flex>,
                  <InputCounts
                    hasTotalPrice
                    hideUnit={hideUnit}
                    product={productData}
                    name={`${name}.${index}`}
                    isCostPrice={isCostPrice}
                  />,
                  <DeleteButton
                    fullWidth={mobile}
                    onClick={() => remove(index)}
                  />,
                ];

                if (columns) {
                  columns.forEach((column) => {
                    cardValues.splice(
                      column.position,
                      0,
                      column.value(productData, selectedProduct.totalPrice)
                    );
                  });
                }

                return (
                  <ListCard
                    headers={titles}
                    values={cardValues}
                    key={productData?.id ?? index}
                    tabletTemplate={tabletTemplate}
                    cellsTablet={cellsTablet || defaultCellsTablet}
                    cellsMobile={cellsMobile || defaultCellsMobile}
                  />
                );
              })}
            </Styles.StyledList>
          )
        }
      />

      <Dialog
        title="Add product"
        trigger={
          <div>
            <AddButton text="Add product" />
          </div>
        }
      >
        {(close, _, ButtonsComponent) => (
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              productIds: Yup.array()
                .required('No product selected')
                .min(1, 'At least one product should be selected'),
            })}
            onSubmit={async (productsDialogValues) => {
              await setFieldValue(
                name,
                productsDialogValues.productIds.map((id) => {
                  const selectedProduct = products?.find(
                    (product) => product.id === id
                  );
                  return {
                    cases: '',
                    units: '',
                    productId: id,
                    maxCount: selectedProduct?.maxCount,
                    unitPerCase: selectedProduct?.unitPerCase,
                    price: isCostPrice
                      ? selectedProduct?.costPrice
                      : selectedProduct?.retailPrice,
                  };
                })
              );
              close();
            }}
          >
            {(productsDialogForm) => (
              <CommonStyles.Form gap={32}>
                <Flex column gap={16}>
                  {products && products.length > 0
                    ? products?.map((product) => (
                        <Flex gap={16} key={product.id}>
                          <Avatar url={product.photoUrl} />

                          <Checkbox
                            block
                            fullWidth
                            key={product.id}
                            label={product.name}
                            checked={productsDialogForm.values.productIds.includes(
                              product.id
                            )}
                            onChange={(e) =>
                              productsDialogForm.setFieldValue(
                                'productIds',
                                e.currentTarget.checked
                                  ? [
                                      ...productsDialogForm.values.productIds,
                                      product.id,
                                    ]
                                  : productsDialogForm.values.productIds.filter(
                                      (id) => id !== product.id
                                    )
                              )
                            }
                          />
                        </Flex>
                      ))
                    : 'No created products'}

                  {productsDialogForm.errors.productIds && (
                    <ErrorMessage>
                      {productsDialogForm.errors.productIds}
                    </ErrorMessage>
                  )}
                </Flex>

                <ButtonsComponent type={DialogType.Plain}>
                  <Button
                    text="Cancel"
                    onClick={close}
                    fullWidth={mobile}
                    styleType={ButtonStyleTypes.Outline}
                  />
                  <Button
                    text="Add"
                    fullWidth={mobile}
                    type={ButtonTypes.Submit}
                  />
                </ButtonsComponent>
              </CommonStyles.Form>
            )}
          </Formik>
        )}
      </Dialog>

      {error && typeof error === 'string' && (
        <ErrorMessage>{error}</ErrorMessage>
      )}
    </>
  );
};
