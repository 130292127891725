import { ButtonColors } from 'types';
import { Icon } from 'components/icon';
import { Button } from 'components/button';

import { ButtonProps } from '../button';

export const EditButton = (props: ButtonProps) => (
  <Button
    padding="0"
    tooltip="Edit"
    icon={<Icon.Edit />}
    colorType={ButtonColors.Transparent}
    {...props}
  />
);
