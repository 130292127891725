import styled, { css } from 'styled-components';

import { Select } from 'components/select';
import { heading3 } from 'styles/mixins/fonts';
import { SalesCompatitionPosition } from 'types';
import { InfoBlock } from 'components/info-block';
import { flexCenter, flexSpaceBetween } from 'styles';

export const FilterContainer = styled.div`
  ${flexSpaceBetween};
  gap: 16px;
  ${({ theme }) => css`
    flex-direction: ${theme.responsive.isMobile ? 'column' : 'row'};
  `}
`;

export const HeaderContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    theme.responsive.isDesktop ? '16px 16px 0' : '16px'};
`;

export const Role = styled.p`
  color: ${({ theme }) => theme.colors.grey.primary.standard};
`;

export const UserCard = styled(InfoBlock)`
  width: 100%;
  position: relative;
`;

export const Position = styled.div<{ position: SalesCompatitionPosition }>`
  ${heading3};
  ${flexCenter};
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  ${({ theme, position }) => css`
    color: ${theme.config.salesCompatition[position].text};
    background: ${theme.config.salesCompatition[position].background};
  `}
`;

export const StyledSelect = styled(Select)`
  width: ${({ theme }) => (theme.responsive.isMobile ? '100%' : 'unset')};
`;

export const TopEmployeeContainer = styled.div`
  gap: 16px;
  display: flex;
  overflow-x: ${({ theme }) => (theme.responsive.isMobile ? 'auto' : 'unset')};
`;
