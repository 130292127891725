import { useNavigate, handleMutation } from 'hooks';
import { Dialog, DeleteButtonWithRef } from 'components';

import { DeleteDialogProps } from './types';

export const DeleteDialog = ({
  id,
  name,
  action,
  mutation,
  onSuccess,
  navigateUrl,
  hideTrigger,
  successMessage,
  ...props
}: DeleteDialogProps) => {
  const navigate = useNavigate();

  handleMutation(
    mutation
      ? {
          ...mutation,
          successMessage,
          onSuccess: navigateUrl ? () => navigate(navigateUrl) : onSuccess,
        }
      : {}
  );

  return (
    <Dialog
      onConfirm={() => action(String(id))}
      trigger={!hideTrigger ? <DeleteButtonWithRef /> : undefined}
      text={
        <>
          You are about to delete <span className="semibold">{name}</span>. Are
          you sure you want to do this?
        </>
      }
      {...props}
    />
  );
};

export type { DeleteDialogProps };
