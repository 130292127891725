import styled, { css } from 'styled-components';

import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, applied, calendar }) => css`
    button,
    button:hover,
    button:active {
      & svg {
        & path:nth-child(6),
        & path:nth-child(5) {
          fill: ${applied && theme.config.filter.svg.applied} !important;
        }

        ${calendar &&
        css`
          & circle {
            fill: ${applied && theme.config.filter.svg.applied} !important;
          }
        `}
      }
    }
  `}
`;
