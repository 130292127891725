import { formatTimeData } from 'utils/date';

export const getOptions = (timeStep: number, minValue?: number) => {
  const options = [];
  const steps = 24 * (60 / timeStep);

  const initialStep =
    typeof minValue === 'number'
      ? Math.ceil(minValue / (timeStep * 60 * 1000)) + 1
      : 0;

  for (let step = initialStep; step <= steps; step++) {
    let duration = step * timeStep;
    if (steps === step) {
      duration -= 1;
    }

    const ms = duration * 60 * 1000;

    const label = formatTimeData(ms);

    options.push({
      label,
      value: String(ms),
    });
  }

  return options;
};
