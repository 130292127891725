import { Formik, FormikConfig } from 'formik';

import { useHeader } from 'context';
import { Roles, UserTypes, ButtonColors } from 'types';
import { isMobile, useAlert, useNavigate } from 'hooks';
import { handleFormSubmitWithFiles } from 'utils/helpers';
import { UserData, UserFormValues } from 'store/api/users';
import { useListQuery as useCompanyListQuery } from 'store/api/company';
import {
  Row,
  Col,
  Button,
  FormFooter,
  InputField,
  ButtonTypes,
  SelectField,
  CommonStyles,
  SectionHeader,
  FormPhotoFieldRow,
} from 'components';

import { validationSchema, getInitialValues } from './config';

export const Form = ({
  data,
  onSubmit,
  ...props
}: {
  data?: UserData;
} & Omit<FormikConfig<UserFormValues>, 'initialValues'>) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const { handleError } = useAlert();

  const { data: companies } = useCompanyListQuery();
  const isEdit = Boolean(data);
  useHeader({
    showBackButton: true,
    pageName: `${isEdit ? 'Edit' : 'New'} user`,
  });

  return (
    <Formik<UserFormValues>
      {...props}
      validationSchema={validationSchema}
      initialValues={getInitialValues(data)}
      onSubmit={handleFormSubmitWithFiles({
        data,
        onSubmit,
        handleError,
        files: [
          {
            formFieldName: 'photo',
            requestFieldName: 'photoUrl',
          },
        ],
      })}
    >
      {({ values, isSubmitting }) => {
        return (
          <CommonStyles.Form>
            <CommonStyles.FormInner>
              <FormPhotoFieldRow />

              <SectionHeader withBorder={false} title="General info" />
              <Row>
                <Col col={3}>
                  <InputField name="name" label="Name" placeholder="John Doe" />
                </Col>
                <Col col={3}>
                  <InputField
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Email"
                  />
                </Col>
                {!isEdit && (
                  <Col col={3}>
                    <InputField
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Password"
                      autoComplete="new-password"
                    />
                  </Col>
                )}
              </Row>

              <Row>
                <Col col={3}>
                  <SelectField
                    onlyValue
                    name="role"
                    label="Role"
                    options={[
                      Roles.SITE_ADMIN,
                      Roles.BACKOFFICE,
                      Roles.COMPANY_OWNER,
                    ].map((role) => ({
                      value: role,
                      label: role,
                    }))}
                  />
                </Col>

                {values.role &&
                  UserTypes.client.includes(values.role) &&
                  !isEdit && (
                    <Col col={3}>
                      <SelectField
                        onlyValue
                        label="Company"
                        name="companyId"
                        options={
                          companies?.map((company) => ({
                            value: company.id,
                            label: company.name,
                          })) || []
                        }
                      />
                    </Col>
                  )}
              </Row>
            </CommonStyles.FormInner>

            <FormFooter
              right={
                <CommonStyles.FormButtons>
                  <Button
                    text="Cancel"
                    fullWidth={mobile}
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.LightBlue}
                  />
                  <Button
                    fullWidth={mobile}
                    disabled={isSubmitting}
                    type={ButtonTypes.Submit}
                    text={isEdit ? 'Edit' : 'Create'}
                  />
                </CommonStyles.FormButtons>
              }
            />
          </CommonStyles.Form>
        );
      }}
    </Formik>
  );
};
