import { ComponentProps } from 'react';

import { Messages } from 'config';
import { useNavigate, handleMutation } from 'hooks';
import { useGetQuery, useEditMutation } from 'store/api/customers';

import { Form } from './form';

export const Edit = ({
  id,
  initialValues,
  employeeOptions,
}: { id: string | undefined } & Omit<
  ComponentProps<typeof Form>,
  'onSubmit'
>) => {
  const navigate = useNavigate();
  const { data } = useGetQuery(id!, { skip: !id });
  const [edit, mutation] = useEditMutation();

  handleMutation({
    ...mutation,
    onSuccess: () => navigate(-1),
    successMessage: `Customer ${Messages.UPDATED_SUCCESSFULLY}`,
  });

  return (
    <Form
      data={data}
      enableReinitialize
      initialValues={initialValues}
      employeeOptions={employeeOptions}
      onSubmit={(values) => {
        if (id && data) {
          edit({
            ...values,
            id,
            personId: data?.personId,
            companyId: data?.companyId,
          });
        }
      }}
    />
  );
};
