import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';
import { flexSpaceBetween } from 'styles/mixins';

import { SectionWrapProps } from './types';

export const SectionWrap = styled.div<SectionWrapProps>`
  ${flexSpaceBetween};
  cursor: pointer;
  min-height: 32px;
  user-select: none;
  padding: 16px 16px 12px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  ${({ open, theme }) => css`
    color: ${theme.config.filter.text};
    border-top: 1px solid ${theme.colors.grey.tertiary.action};

    & svg {
      ${open && 'transform: rotate(180deg)'};
      & path {
        fill: ${theme.config.text.primary};
      }
    }
  `}
`;

export const OptionWrap = styled.div<{
  isRadio?: boolean;
  nativeIconColor?: boolean;
  withSpaceBetween?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 3px;

  > p {
    flex: 1;
    display: flex;
  }

  ${({ withSpaceBetween }) =>
    withSpaceBetween &&
    css`
      ${flexSpaceBetween};
    `}

  ${({ theme, isRadio, nativeIconColor }) =>
    !nativeIconColor &&
    css`
      & svg {
        path {
          fill: ${theme.config.text.primary};
        }
        ${!isRadio &&
        css`
          circle {
            fill: ${theme.config.text.primary};
          }
        `}
      }
    `}
`;

export const FilterSectionInner = styled(Flex)`
  gap: 16px;
  padding: 0 16px;
  overflow: hidden;
  flex-direction: column;

  &.filter-section-enter {
    max-height: 0;
    transition: all 300ms;
  }

  &.filter-section-enter-active {
    max-height: 50%;
    transition: all 300ms;
  }

  &.filter-section-enter-done {
    max-height: 100%;
    transition: all 300ms;
  }

  &.filter-section-exit {
    max-height: 100%;
    transition: all 300ms;
  }

  &.filter-section-exit-active {
    max-height: 50%;
    transition: all 300ms;
  }

  &.filter-section-exit {
    max-height: 0;
    transition: all 300ms;
  }
`;
