import { Event, EventReport, EventReportProduct } from 'store/api/event';

export const getInventoryStats = (checkouts: EventReport[], event: Event) => {
  let productMap: Record<string, any> = {};

  checkouts.forEach((checkout) => {
    checkout.products.forEach((product) => {
      if (!productMap[product.productId]) {
        productMap[product.productId] = {
          totalSold: 0,
          price: product.price,
          productId: product.productId,
        };
      }
      productMap[product.productId].totalSold += product.totalSold;
    });
  });

  return event?.storage?.storageItems?.map((item) => {
    const totalSold = productMap[item.productId!]?.totalSold || 0;
    return {
      ...item,
      totalSold,
      price: item.price,
      remaining: item.count,
      name: item.product.name,
      productId: item.productId,
      totalRevenue: totalSold * +item.price!,
    };
  }) as EventReportProduct[];
};
