import { Flex } from 'components/flex';
import { RadioButton } from 'components/radio-button';

import * as Styles from './styles';
import { ChoiceUserStatusProps } from './types';

export const ChoiceUserStatus = ({ options }: ChoiceUserStatusProps) => {
  if (!options?.length) {
    return null;
  }

  return (
    <Styles.Container>
      <Styles.Title>User status</Styles.Title>

      <Flex column>
        {options.map((option) => (
          <RadioButton
            transparent
            padding="0 3px"
            name="userStatus"
            key={option.label}
            {...option}
          />
        ))}
      </Flex>
    </Styles.Container>
  );
};
