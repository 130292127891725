import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { useSearchQuery } from 'hooks';
import { useListQuery } from 'store/api/company';
import { List, ListHeader, NewEntityButton } from 'components';

import { Card } from './card';
import { titles, template } from './config';

export const ListPage = () => {
  const { debounceQuery, ...search } = useSearchQuery();
  const { data = [], ...props } = useListQuery({
    search: debounceQuery,
  });

  useHeader({ pageName: 'Companies' });

  return (
    <List
      {...mapQuery(props)}
      {...search}
      count={data.length}
      mainWrapperStyles={{ paddingBottom: '16px' }}
      header={<ListHeader titles={titles} template={template} />}
      searchBarRightSlot={
        <NewEntityButton text="New Company" path={AppRoutes.Company.New} />
      }
    >
      {data.map((item) => (
        <Card data={item} key={item.id} />
      ))}
    </List>
  );
};
