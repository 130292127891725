import { useFormikContext } from 'formik';

import { formatPrice } from 'utils/helpers';
import { useListQuery } from 'store/api/product';
import {
  Flex,
  ListCard,
  InfoBlock,
  SelectProductsFieldSection,
} from 'components';

interface FormValues {
  items: {
    count: number;
    price: number;
    productId: number;
    totalPrice: number;
  }[];
}

export const Products = () => {
  const { values } = useFormikContext<FormValues>();

  const { data: products } = useListQuery();

  if (!products) {
    return null;
  }

  return (
    <>
      <InfoBlock title="Product List">
        <Flex column gap={16}>
          <SelectProductsFieldSection
            isCostPrice
            name="items"
            products={products}
            columns={[
              {
                position: 2,
                header: 'Price',
                value: (product) => formatPrice(product?.costPrice),
              },
              {
                position: 3,
                header: 'Total',
                value: (_, totalPrice) => formatPrice(totalPrice),
              },
            ]}
          />

          <ListCard
            template="3fr 2fr"
            tabletTemplate="1fr 1fr"
            withPlaceholders={false}
            values={[
              <h3>Total:</h3>,
              <h3>
                {formatPrice(
                  values.items?.reduce(
                    (sum, { totalPrice }) => sum + totalPrice,
                    0
                  )
                )}
              </h3>,
            ]}
          />
        </Flex>
      </InfoBlock>
    </>
  );
};
