import { AnyAction, createSlice } from '@reduxjs/toolkit';

import { User } from 'types';
import { getUser, clearAll, saveUser } from 'utils/storage';

import { login, logout } from './thunks';
import { types, AuthState } from './types';

const getInitialValue = (): AuthState => {
  const authUser = getUser();
  return {
    user: authUser,
    path: undefined,
    error: undefined,
    isLoggedIn: !!authUser,
  };
};

const reset = (state: AuthState) => {
  state.user = undefined;
  state.isLoggedIn = false;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialValue(),
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { data, isLoggedIn } = action.payload ?? {};
      const { user } = data;
      state.user = user;
      state.isLoggedIn = isLoggedIn;
    });
    builder.addCase(login.rejected, (state) => {
      reset(state);

      clearAll();
    });

    builder.addCase(logout.fulfilled, (state) => {
      reset(state);
    });
    builder.addCase(logout.rejected, (state) => {
      reset(state);
    });
  },
  reducers: {
    clearAuth: (state) => {
      reset(state);
    },
    removeAuthPath: (state) => {
      state.path = undefined;
    },
    setAuthPath: (state, { payload }) => {
      if (payload.path) {
        state.path = payload.path;
      } else {
        state.path = window.location.pathname + window.location.search;
      }
    },
    updateAuthUser: (state, { payload }) => {
      const updatedUser: User = { ...state.user, ...payload };

      saveUser(updatedUser);

      return {
        ...state,
        user: updatedUser,
      };
    },
    setAuthError: (state, { payload }) => {
      const { message, restorePath } = payload || {};
      return {
        ...state,
        error: message,
        path:
          restorePath && message
            ? window.location.pathname + window.location.search
            : undefined,
      };
    },
  },
});

function isLogoutAction(action: AnyAction) {
  return (
    action.type === types.logout + '/fulfilled' ||
    action.type === types.logout + '/rejected'
  );
}

const {
  reducer: authReducer,
  actions: {
    clearAuth,
    setAuthPath,
    setAuthError,
    removeAuthPath,
    updateAuthUser,
  },
} = authSlice;

export {
  login,
  types,
  logout,
  clearAuth,
  setAuthPath,
  authReducer,
  setAuthError,
  isLogoutAction,
  removeAuthPath,
  updateAuthUser,
};

export * from './selectors';
