import { useMemo } from 'react';

import { Icon } from 'components/icon';
import { Flex } from 'components/flex';
import { textValue } from 'utils/helpers';
import { ButtonColors } from 'types/enums';
import { Button } from 'components/button';
import { EllipsisText } from 'components/ellipsis-text';

import * as Styles from './styles';
import { PageHeaderProps } from './types';

export const PageHeader = ({
  name,
  header,
  onEdit,
  footer,
  subtitle,
  className,
  topOffset,
  rightSlot,
  withBorder,
  footerStyle,
  wrapperStyles,
  titleRightSlot,
  rightSlotStyles,
  disableSticky = false,
  stickyWithHeader = true,
}: PageHeaderProps) => {
  const offset = useMemo(() => {
    const withHeader = 120;
    const withoutHeader = 64;

    return topOffset
      ? topOffset
      : stickyWithHeader
        ? withHeader
        : withoutHeader;
  }, [topOffset, stickyWithHeader]);

  return (
    <Styles.Container
      className={className}
      withBorder={withBorder}
      paddingBottom={footer ? undefined : 16}
    >
      {header && <Styles.Header>{header}</Styles.Header>}

      <Flex column fullWidth style={wrapperStyles}>
        <Styles.StyledSticky
          topOffset={-offset}
          disabled={disableSticky}
          positionRecheckInterval={1}
          stickyStyle={{ top: offset }}
        >
          <Flex justifyContent="space-between">
            <Styles.Name isSubtitle={!!subtitle}>
              <Flex column minWidth={0}>
                <EllipsisText>
                  <h2>{textValue(name)}</h2>
                </EllipsisText>
                {subtitle}
              </Flex>

              <Flex gap={16} alignItems="center">
                {onEdit && (
                  <Button
                    tooltip="Edit"
                    onClick={onEdit}
                    icon={<Icon.Edit />}
                    colorType={ButtonColors.LightBlue}
                  />
                )}
                {titleRightSlot}
              </Flex>
            </Styles.Name>

            {rightSlot && (
              <Styles.RightSlot style={rightSlotStyles}>
                {rightSlot}
              </Styles.RightSlot>
            )}
          </Flex>
        </Styles.StyledSticky>

        {footer && <Styles.Footer style={footerStyle}>{footer}</Styles.Footer>}
      </Flex>
    </Styles.Container>
  );
};

export type { PageHeaderProps };
