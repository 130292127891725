import { ButtonColors, ButtonStyleTypes } from 'types';
import { Button, ButtonTypes } from 'components/button';

import { ButtonProps } from '../button';

export const ResetButton = (props: ButtonProps) => (
  <Button
    transparent
    text="Reset"
    type={ButtonTypes.Button}
    colorType={ButtonColors.Blue}
    styleType={ButtonStyleTypes.Ghost}
    {...props}
  />
);
