import { useCallback } from 'react';
import {
  To,
  useLocation,
  NavigateOptions,
  useNavigate as useRouteNavigate,
} from 'react-router-dom';

import { LocationState } from 'types';

export const getNavigationState = () => ({
  backPath: window.location.pathname + window.location.search,
});

export const useNavigate = () => {
  const routeNavigate = useRouteNavigate();

  const navigate = useCallback(
    (to: number | To, options?: NavigateOptions) => {
      if (typeof to === 'number') {
        routeNavigate(to);
        return;
      }

      const { state, ...opts } = options ?? {};
      routeNavigate(to, {
        state: { ...state, ...getNavigationState() },
        ...opts,
      });
    },
    [routeNavigate]
  );

  return navigate;
};

export const useBackPath = (defaultPath: string) => {
  const location = useLocation();
  return (
    (location.state && (location.state as LocationState).backPath) ??
    defaultPath
  );
};
