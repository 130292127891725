import { AlertType } from 'react-alert';
import styled, { css } from 'styled-components';

export const AlertContainer = styled.div<{ variant?: AlertType }>`
  padding: 16px;
  margin: 16px;
  border-radius: 16px;
  pointer-events: all;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  ${({ theme, variant }) => css`
    width: ${theme.responsive.isMobile ? 'auto' : '399px'};
    box-shadow: ${theme.config.infoBlock.shadow};

    ${variant &&
    css`
      background-color: ${theme.config.alert.bg[variant]};
    `}
  `}
`;

export const ContentContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const TextContainer = styled.div`
  > div {
    white-space: break-spaces;
  }
`;

export const IconContainer = styled.div<{ variant?: AlertType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  svg {
    width: 48px;
    height: 48px;

    ${({ theme, variant }) =>
      variant &&
      css`
        path {
          fill: ${theme.config.alert.icon[variant]};
        }
      `}
  }
`;

export const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg path {
    fill: ${({ theme }) => theme.config.alert.close};
  }
`;

export const StyledAlertButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin: 0 20px 20px 0;
  }
`;
