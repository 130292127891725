import styled, { css } from 'styled-components';

import { captionRegular } from 'styles/mixins/fonts';

import { ChoiceThemeProps } from './types';

export const Container = styled.div<ChoiceThemeProps>`
  padding: 0 16px;

  ${({ theme, border, padding }) => css`
    padding: ${padding || '8px 16px'};
    box-shadow: ${border
      ? `inset 0 -1px 0 0 ${theme.config.sidebar.border}`
      : 'none'};
  `}
`;

export const Title = styled.h4`
  padding-bottom: 8px;
  color: ${({ theme }) => theme.config.input.placeholder};
  ${captionRegular};
`;

export const FilledCircle = styled.span<{ filled?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 6px;

  ${({ theme, filled }) => css`
    background-color: ${filled ? theme.config.circle.bg : 'white'};
    border: 1px solid ${theme.config.circle.border};
  `}
`;

export const HalfCircle = styled.span`
  position: relative;
  display: inline-block;
  width: 8px;
  height: 16px;
  margin-left: 11px;
  margin-right: 6px;
  border-radius: 0px 100px 100px 0;

  ${({ theme }) => css`
    background-color: ${theme.config.text.primary};
    border-top: 1px solid ${theme.config.circle.border};
    border-bottom: 1px solid ${theme.config.circle.border};
    border-right: 1px solid ${theme.config.circle.border};

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      box-sizing: border-box;
      width: 8px;
      height: 16px;
      top: -1px;
      left: -8px;
      border-radius: 0px 100px 100px 0;
      border-top: 1px solid ${theme.config.circle.border};
      border-bottom: 1px solid ${theme.config.circle.border};
      border-right: 1px solid ${theme.config.circle.border};
      transform: rotate(180deg);
    }
  `}
`;
