import * as Yup from 'yup';

import { url, phoneNumber } from 'utils/validation';

export const validationSchema = Yup.object().shape(
  {
    website: url.nullable(),
    phone: phoneNumber('phone'),
    name: Yup.string().required(),
    address: Yup.string().required(),
    workHoursFrom: Yup.number()
      .nullable()
      .when('workHoursTo', {
        is: (workHoursTo: number | null | undefined) =>
          typeof workHoursTo === 'number',
        then: (schema) =>
          schema.required(
            'Work hours from is required when work hours to is provided'
          ),
      }),
    workHoursTo: Yup.number()
      .nullable()
      .when('workHoursFrom', {
        is: (workHoursFrom: number | null | undefined) =>
          typeof workHoursFrom === 'number',
        then: (schema) =>
          schema.required(
            'Work hours to is required when work hours from is provided'
          ),
      }),
    monthlyPrices: Yup.array().of(
      Yup.object().shape(
        {
          date: Yup.date()
            .nullable()
            .when('price', {
              is: (price: string | null | undefined) => Boolean(price),
              then: (schema) =>
                schema.required('Date is required when price is provided'),
            }),
          price: Yup.number()
            .nullable()
            .when('date', {
              is: (date: Date | null | undefined) => date instanceof Date,
              then: (schema) =>
                schema.required('Price is required when date is provided'),
            }),
        },
        [['date', 'price']]
      )
    ),
  },
  [['workHoursFrom', 'workHoursTo']]
);
