import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { addParamsToUrl } from 'utils/query';

import {
  PriceApproval,
  PriceApprovalListRequest,
  PriceApprovalCreateRequest,
  PriceApprovalStatusRequest,
  PriceApprovalUpdateRequest,
} from './types';

const tagTypes = ['priceApproval'];

export const priceApprovalApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'priceApprovalApi',
  endpoints: (build) => ({
    delete: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: generatePath(URL.PRICE_APPROVAL, { id }),
      }),
    }),

    details: build.query<PriceApproval, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: generatePath(URL.PRICE_APPROVAL_DETAILS, { id }),
      }),
    }),

    create: build.mutation<PriceApproval, PriceApprovalCreateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.PRICE_APPROVAL,
      }),
    }),

    list: build.query<PriceApproval[], PriceApprovalListRequest>({
      providesTags: tagTypes,
      query: (query) => ({
        method: 'get',
        url: addParamsToUrl(URL.PRICE_APPROVAL, query),
      }),
    }),

    update: build.mutation<PriceApproval, PriceApprovalUpdateRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.PRICE_APPROVAL_DETAILS, { id }),
      }),
    }),

    status: build.mutation<PriceApproval, PriceApprovalStatusRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, status }) => ({
        method: 'post',
        data: { status },
        url: generatePath(URL.PRICE_APPROVAL_APPROVE, { id }),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useDeleteMutation,
  useCreateMutation,
  useStatusMutation,
  useUpdateMutation,
} = priceApprovalApi;

export type {
  PriceApprovalListRequest,
  PriceApprovalCreateRequest,
  PriceApprovalUpdateRequest,
  PriceApprovalStatusRequest,
};
