import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { Customers } from 'components';
import { useAppSelector } from 'store';
import { BackOfficeRoles } from 'types';
import { selectAuthUser } from 'store/slices/auth';

export const Create = () => {
  const authUser = useAppSelector(selectAuthUser);
  const authUserRole = authUser?.role?.name;

  const isSalesOrManager =
    authUserRole === BackOfficeRoles.MANAGER ||
    authUserRole === BackOfficeRoles.SALES_REP;

  useHeader({
    showBackButton: true,
    pageName: 'New customer',
  });

  return (
    <Customers.Create
      navigateUrl={AppRoutes.Customers.Home}
      employeeOptions={
        isSalesOrManager
          ? [
              {
                label: authUser?.name || '',
                value: authUser?.id ? String(authUser.id) : '',
              },
            ]
          : undefined
      }
    />
  );
};
