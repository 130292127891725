import { toast, ToastOptions } from 'react-toastify';

type Options = {
  cleanQueue?: boolean;
  toast?: ToastOptions;
};

export const showMessage = (message: string, options?: Options) => {
  if (options?.cleanQueue) {
    toast.dismiss();
    toast.clearWaitingQueue();
  }
  return toast(message, options?.toast);
};
