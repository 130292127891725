import styled, { css } from 'styled-components';

export const Container = styled.div<{ padding?: string; withBorder: boolean }>`
  ${({ theme, padding, withBorder }) => css`
    padding: ${padding || '24px 0'};

    ${withBorder &&
    css`
      box-shadow: inset 0 1px 0 ${theme.config.border};
    `}
  `}
`;

export const ContainerExp = styled.div`
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  align-items: center;
  gap: 16px;
  ${({ theme }) => css`
    background-color: ${theme.colors.grey.secondary.standard};
  `}
`;

export const Title = styled.div<{ withButton: boolean }>`
  display: flex;
  flex: 1;
  ${({ withButton }) => css`
    width: calc(100% - ${withButton ? 40 : 0}px);
  `}
`;
