import styled, { css } from 'styled-components';

export const BurgerButton = styled.button`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;

  ${({ theme }) => css`
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 2px;
      width: 20px;
      height: 2px;
      background-color: ${theme.config.sidebar.svg.default};
      border-radius: 2px;
    }

    &:before {
      top: 6px;
    }

    &:after {
      bottom: 6px;
      box-shadow: 0 -5px ${theme.config.sidebar.svg.default};
    }
  `}
`;
