import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { providesList, invalidatesList } from 'utils/query-cache';

import { apiQuery } from '../../query';

import { ProductType } from './types';

const tagType = 'product-types';

export const productTypesApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'productTypesApi',
  endpoints: (build) => ({
    list: build.query<ProductType[], void>({
      providesTags: providesList(tagType),
      query: () => ({
        method: 'get',
        url: URL.PRODUCT_TYPES,
      }),
    }),
    save: build.mutation<void, Pick<ProductType, 'name'>[]>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        method: 'put',
        url: URL.PRODUCT_TYPES,
        data: {
          data: JSON.stringify(data.filter((item) => item.name)),
        },
      }),
    }),
  }),
});

export const { useListQuery, useSaveMutation } = productTypesApi;
export type { ProductType };
