import styled from 'styled-components';

import { ellipsisText } from 'styles/mixins';

export const EllipsisDiv = styled.div`
  ${ellipsisText};

  > * {
    ${ellipsisText};
  }
`;

export const Container = styled.div`
  padding: 16px;
  word-break: break-word;
  white-space: pre-line;

  > * {
    line-height: 24px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
`;
