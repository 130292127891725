export const defaultColors = [
  '#DAA403',
  '#0BA5D3',
  '#88B3F7',
  '#E68F7C',
  '#0A51C5',
  '#1F6AEF',
  '#4187F2',
  '#88B3F7',
  '#0A8507',
  '#C89BEC',
  '#A70A0A',
];

export const blueColors = [
  '#1F6AEF',
  '#4187F2',
  '#70A4F5',
  '#A0C2F8',
  '#1755BF',
  '#5995F3',
  '#88B3F7',
];
