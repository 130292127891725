import { useMemo } from 'react';
import { useParams, generatePath } from 'react-router-dom';

import { AppRoutes } from 'config';
import { mapQuery } from 'utils/query';
import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { List } from 'components/list';
import { isDesktop, useNavigate } from 'hooks';
import { ListCard } from 'components/list-card';
import { useListQuery } from 'store/api/inventory';
import { ListHeader } from 'components/list-header';
import { textValue, formatPrice } from 'utils/helpers';
import { Avatar, AvatarPlaceholderType } from 'components/avatar';

import * as Styles from './styles';
import { StorageInventoryPageProps } from './types';

const { InventoryDetails } = AppRoutes.Inventory.Storages.Details;

const cellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/2' },
  { row: '2/3', col: '2/3' },
];

const getTitles = (hideCostPrice?: boolean) => {
  const titles = [
    'Product title',
    'SKU',
    'Current QTY',
    'Unit per case',
    'Min price',
    'Retail price',
  ];

  if (!hideCostPrice) {
    titles.splice(4, 0, 'Cost price');
  }

  return titles;
};

export const StorageInventoryPage = ({
  hideCostPrice,
  productDetailsPath,
}: StorageInventoryPageProps) => {
  const { id } = useParams();
  const desktop = isDesktop();
  const navigate = useNavigate();
  const { data, ...props } = useListQuery(id!, { skip: !id });

  const getPrice = (price?: number) => (price ? formatPrice(price) : null);

  const titles = useMemo(() => getTitles(hideCostPrice), [hideCostPrice]);

  return (
    <List
      {...mapQuery(props)}
      hasMinHeight
      count={data?.length ?? 0}
      header={<ListHeader titles={titles} />}
      mainWrapperStyles={desktop ? undefined : { padding: 0 }}
    >
      {data?.map((value) => {
        const values = [
          <Flex gap={8} alignItems="center">
            <Avatar
              square
              size={40}
              icon={<Icon.Template />}
              url={value?.product?.photoUrl}
              placeholder={AvatarPlaceholderType.GreyIcon}
            />
            <Flex flexDirection="column">
              <Styles.Title>{value?.product?.name}</Styles.Title>
              <Styles.Subtitle>
                Unit weight: {textValue(value?.product?.unitWeight)}
              </Styles.Subtitle>
            </Flex>
          </Flex>,
          value?.product?.sku,
          <Flex flexDirection="column">
            {value?.count}

            {!hideCostPrice && (
              <Styles.Subtitle>
                Cost:{' '}
                {formatPrice(
                  value?.count && value?.price && value.count * value.price
                )}
              </Styles.Subtitle>
            )}
          </Flex>,
          value?.product?.unitPerCase,
          getPrice(value?.product?.minPrice),
          getPrice(value?.product?.retailPrice),
        ];

        if (!hideCostPrice) {
          values.splice(4, 0, getPrice(value?.product?.costPrice));
        }

        return (
          <ListCard
            key={value.id}
            values={values}
            headers={titles}
            mobileTemplate="1fr 1fr"
            cellsMobile={cellsMobile}
            tabletTemplate="repeat(3, 1fr)"
            onClick={
              hideCostPrice
                ? undefined
                : () =>
                    navigate(
                      generatePath(productDetailsPath || InventoryDetails, {
                        inventoryId: value.productId,
                      })
                    )
            }
          />
        );
      })}
    </List>
  );
};
