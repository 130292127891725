import { useContext } from 'react';

import { Config } from 'config';
import { HeaderContext } from 'context';

import { Header } from '../header';

import { HeaderWrapProps } from './types';

export const HeaderWrap = ({
  onMenu,
  ...props
}: HeaderWrapProps): JSX.Element => {
  const { pageState } = useContext(HeaderContext);

  return (
    <Header
      {...pageState}
      {...props}
      handleBurger={onMenu}
      version={Config.buildVersion}
    />
  );
};
