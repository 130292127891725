import { Icon } from '../icon';
import * as Responsive from '../responsive';

import * as Styles from './styles';
import { PaginationMobile } from './mobile';
import { PaginationTabletUp } from './tablet-up';
import { PaginationProps, PaginationComponentsCommonProps } from './types';

const Pagination = ({
  className,
  totalCount,
  currentPage,
  onPageChange,
  pageSize = 20,
  siblingCount = 1,
  forceMobileView = false,
}: PaginationProps): JSX.Element | null => {
  const totalPageCount = Math.ceil(totalCount / pageSize);

  const onNext = () => onPageChange(currentPage + 1);
  const onPrevious = () => onPageChange(currentPage - 1);

  const onGoToPageChange: PaginationComponentsCommonProps['onGoToPageChange'] =
    (e, pageNumberInputSetter) => {
      const {
        target: { value },
      } = e;
      const filteredValue = value.replace(/\D/g, '');
      const pageNumber = +filteredValue;

      if (
        filteredValue !== '' &&
        (pageNumber < 1 || pageNumber > totalPageCount)
      ) {
        return;
      }

      pageNumberInputSetter(pageNumber === 0 ? '' : String(pageNumber));
    };

  const onGoToPageSubmit: PaginationComponentsCommonProps['onGoToPageSubmit'] =
    (e, pageNumberInputValue) => {
      e.preventDefault();
      if (pageNumberInputValue) {
        onPageChange(+pageNumberInputValue);
      }
    };

  const commonProps: PaginationComponentsCommonProps = {
    currentPage,
    totalPageCount,
    forceMobileView,
    onGoToPageChange,
    onGoToPageSubmit,
    prevPageButton: (
      <Styles.PaginationButton
        onClick={onPrevious}
        disabled={currentPage === 1}
      >
        <Icon.ChevronLeft className="svg-non-filled" />
      </Styles.PaginationButton>
    ),
    nextPageButton: (
      <Styles.PaginationButton
        onClick={onNext}
        disabled={currentPage === totalPageCount}
      >
        <Icon.ChevronRight className="svg-non-filled" />
      </Styles.PaginationButton>
    ),
  };

  return (
    <Styles.PaginationWrapper
      className={className}
      forceMobileView={forceMobileView}
    >
      {forceMobileView ? (
        <PaginationMobile {...commonProps} />
      ) : (
        <>
          <Responsive.TabletUp>
            <PaginationTabletUp
              onPageChange={onPageChange}
              siblingCount={siblingCount}
              {...commonProps}
            />
          </Responsive.TabletUp>
          <Responsive.Mobile>
            <PaginationMobile {...commonProps} />
          </Responsive.Mobile>
        </>
      )}
    </Styles.PaginationWrapper>
  );
};

export { Pagination };
