import { TabCountColor } from 'types';

import { TabsItem, TabsProps } from './types';
import { Tab, TabsList, BadgeCount, TabNavLink, TabsListItem } from './styles';

const Tabs = ({
  items,
  style,
  value,
  isLarge,
  onChange,
  className,
  innerStyle,
  block = false,
  countColor = TabCountColor.Red,
}: TabsProps) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <nav style={style} className={className}>
      <TabsList $block={block} style={innerStyle}>
        {items.map(({ icon, label, badgeCount, ...rest }, index) => {
          const props = {
            ...rest,
            large: isLarge ? 'true' : undefined,
          };
          const content = (
            <>
              {icon}
              {label}
              {!!badgeCount && (
                <BadgeCount countColor={countColor}>{badgeCount}</BadgeCount>
              )}
            </>
          );
          let tab;
          if (onChange) {
            tab = (
              <Tab
                $block={block}
                large={props.large}
                disabled={props.disabled}
                onClick={() => onChange(rest.to as string)}
                className={value === rest.to ? 'active' : undefined}
              >
                {content}
              </Tab>
            );
          } else {
            tab = (
              <TabNavLink $block={block} {...props}>
                {content}
              </TabNavLink>
            );
          }
          return <TabsListItem key={index}>{tab}</TabsListItem>;
        })}
      </TabsList>
    </nav>
  );
};

export { Tabs };
export type { TabsItem };
