import { Row, Col } from 'components/grid';
import { PhotoField } from 'components/fields';

export const FormPhotoFieldRow = () => (
  <Row>
    <Col>
      <PhotoField size={128} name="photo" />
    </Col>
  </Row>
);
