import styled from 'styled-components';

import { Font } from 'styles';

import { CalendarDayContainerProps } from './types';
import { getDayContainerStyle, getDayContainerBorderRadius } from './helpers';

export const DayContainer = styled.div<CalendarDayContainerProps>`
  flex-grow: 1;
  flex-basis: 0;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    ${Font.bodyRegular}
  }

  ${(props) => getDayContainerStyle(props)};
  ${(props) => getDayContainerBorderRadius(props)};
`;
