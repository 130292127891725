import styled, { css } from 'styled-components';

import { BooleanBadgeProps } from './types';

export const BooleanBadge = styled.div<BooleanBadgeProps>`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  padding: 8px 16px 8px 8px;

  ${({ theme, value }) => css`
    border-radius: ${theme.borderRadius};
    background-color: ${value
      ? theme.config.booleanBadge.positive.bg
      : theme.config.booleanBadge.negative.bg};

    svg path {
      fill: ${value
        ? theme.config.booleanBadge.positive.icon
        : theme.config.booleanBadge.negative.icon};
    }
  `}
`;
