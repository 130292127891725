import styled, { css } from 'styled-components';

import { bodyRegular } from 'styles/mixins/fonts';

import { CheckboxFieldGroupProps } from './types';

export const Subtitle = styled.p`
  ${bodyRegular};
`;

export const GroupContainer = styled.div<
  Pick<CheckboxFieldGroupProps, 'gap' | 'grid' | 'isInlineGrid'>
>`
  ${({ gap, grid, isInlineGrid }) => css`
    ${grid &&
    css`
      grid-gap: ${grid.gap};
      grid-template: ${grid.template};
      display: ${isInlineGrid && 'inline-'}grid;
    `}

    ${!grid &&
    css`
      display: flex;
      flex-wrap: wrap;
      gap: ${gap}px;
    `}
  `}
`;

export const CheckboxAllContainer = styled.div`
  margin-bottom: 16px;
`;
