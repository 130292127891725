import { useTimeout } from 'usehooks-ts';
import { useMemo, useState } from 'react';

import { getDuration } from 'utils/date';

import { TimeoutProps } from './types';

export const useTimeouts = (steps: TimeoutProps[]) => {
  const [date, setDate] = useState(Date.now());

  const timer = useMemo(() => {
    const elapsed = steps
      .map((step) => step.seconds - getDuration(step.date))
      .filter((duration) => duration > 0)
      .sort((d1, d2) => (d1 > d2 ? 1 : -1));
    return elapsed[0] * 1000;
  }, [steps, date]);

  useTimeout(() => setDate(Date.now()), timer);

  return date;
};

export type { TimeoutProps };
