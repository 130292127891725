import styled, { css, RuleSet } from 'styled-components';

import { inlineFlexCenter } from 'styles/mixins';

export const styledPaginationItem = css`
  ${inlineFlexCenter};
  border-radius: 8px;
  width: 32px;
  height: 32px;
  margin: 4px;
`;

export const styledActivePaginationItem: RuleSet<any> = css<{
  disabled?: boolean;
}>`
  ${styledPaginationItem};

  ${({ theme, disabled }) => css`
    ${disabled &&
    css`
      cursor: default;
    `}

    ${!disabled &&
    css`
      @media (hover: hover) {
        &:hover {
          background-color: ${theme.config.pagination.number.hover};
        }
      }

      &:active {
        background-color: ${theme.config.pagination.number.active};
      }
    `}
  `}
`;

export const PaginationWrapper = styled.div<{ forceMobileView: boolean }>`
  height: 40px;
  display: flex;
  user-select: none;
  align-items: center;

  ${({ theme, forceMobileView }) => css`
    ${(theme.responsive.isMobile || forceMobileView) &&
    css`
      justify-content: center;
    `}
  `}
`;

export const PaginationButton = styled.button<{ disabled: boolean }>`
  ${styledActivePaginationItem};

  ${({ theme, disabled }) => css`
    svg path {
      fill: ${theme.config.pagination.arrow.default};
    }

    ${disabled &&
    css`
      svg path {
        fill: ${theme.config.pagination.arrow.disabled};
      }
    `}
  `}
`;
