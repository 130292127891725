import { useState, useEffect } from 'react';
import { useDebounceValue } from 'usehooks-ts';

import { useAlert } from 'hooks';
import { Suspense } from 'components';
import { getErrorMessage } from 'utils/error';
import { useAppDispatch, useAppSelector } from 'store';
import { setAuthError, selectAuthError } from 'store/slices/auth';

import Router from './router';

const App = () => {
  const [error, setError] = useState<string | undefined>(undefined);

  const { showErrorAlert } = useAlert();
  const dispatch = useAppDispatch();

  const authError = useAppSelector(selectAuthError);

  const [errorDebounce] = useDebounceValue(error, 1000);

  useEffect(() => {
    if (authError) {
      setError(getErrorMessage(authError));
      dispatch(setAuthError(undefined));
    }
  }, [authError]);

  useEffect(() => {
    if (errorDebounce) {
      showErrorAlert(errorDebounce);
      setError(undefined);
    }
  }, [errorDebounce]);

  return (
    <Suspense>
      <Router />
    </Suspense>
  );
};

export default App;
