import { Badge } from 'components/badge';
import {
  ChargebackStatus,
  ChargebackStatusTitle,
  ChargebackStatusColor,
} from 'types';

export const ChargebackStatusBadge = ({
  status,
}: {
  status: ChargebackStatus;
}) => (
  <Badge
    text={ChargebackStatusTitle[status]}
    styleType={ChargebackStatusColor[status]}
  />
);
