import dayjs from 'dayjs';
import { useTheme } from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { EventClickArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';

import { ButtonColors } from 'types';
import { getPlural } from 'utils/helpers';
import { isMobile, useElementSize } from 'hooks';

import { Header } from './header';
import * as Styles from './styles';
import { eventContent, allDayContent, dayHeaderContent } from './config';
import {
  EventInput,
  EventSourceInput,
  EventCalendarPage,
  EventCalendarProps,
} from './types';

export const EventCalendar = ({
  height,
  events,
  header,
  datesSet,
  eventsSet,
  eventClick,
  calendarRef,
  headerRightSlot,
  removeEventScroll,
  autoscrollToNearest,
  ...props
}: EventCalendarProps) => {
  const mobile = isMobile();
  const ref = useRef<FullCalendar | null>(null);
  const [isEventsLoaded, setEventsLoaded] = useState(false);
  const [visible, setVisibleDates] = useState<EventCalendarPage>();
  const [headerRef, { height: headerHeight }] = useElementSize();
  const isMonthView = ref?.current?.getApi()?.view?.type === 'dayGridMonth';
  const theme = useTheme();

  useEffect(() => {
    if (calendarRef) {
      calendarRef.current = ref.current;
    }
  }, [calendarRef, ref.current]);

  const calendarHeight = useMemo(() => {
    if (!headerHeight) {
      return undefined;
    }
    return `calc(${height} - ${headerHeight}px)`;
  }, [height, headerHeight]);

  const eventsWrap = useMemo(() => {
    if (!events || !Array.isArray(events)) {
      return events;
    }
    if (!events.length) {
      return events;
    }

    const format = 'YYYY-MM-DD';
    const eventsData = [...events];
    const eventsMap: Record<string, EventInput[]> = {};
    for (const event of events) {
      const start = dayjs(event.start as Date).format(format);
      const end = dayjs(event.end as Date).format(format);

      let startArray = eventsMap[start] ?? [];
      startArray.push(event);
      eventsMap[start] = startArray;

      if (end !== start) {
        let endArray = eventsMap[end] ?? [];
        endArray.push(event);
        eventsMap[end] = endArray;
      }
    }

    if (!isMonthView) {
      for (const [date, dayEvents] of Object.entries(eventsMap)) {
        const day = dayjs(date, format);
        eventsData.push({
          allDay: true,
          start: day.startOf('day').toDate(),
          backgroundColor: theme.colors.green.additional,
          title: getPlural('event', dayEvents.length, { rightCount: true }),
        });
      }
    }
    return eventsData;
  }, [events, isMonthView, theme.colors.green.additional]);

  const allDaySlot = useMemo(() => {
    if (!visible || !eventsWrap || !Array.isArray(eventsWrap) || isMonthView) {
      return false;
    }
    const start = dayjs(visible.start);
    const end = dayjs(visible.end);
    if (!eventsWrap.length) {
      return false;
    }
    const visibleEvents = eventsWrap.filter((event) => {
      if (!event.allDay) {
        return false;
      }
      const day = dayjs(event.start as Date);
      return (day.isBefore(end) && day.isAfter(start)) || day.isSame(start);
    });
    return visibleEvents.length > 0;
  }, [eventsWrap, visible, isMonthView]);

  const onEventsSet = useCallback(() => {
    if (isEventsLoaded || !Array.isArray(events) || events.length === 0) {
      return;
    }
    let eventsToSearch = events.filter((event) => !event.allDay);
    if (eventsToSearch.length > 0) {
      eventsToSearch.sort((a, b) => {
        const diff = (a.start as Date).getTime() - (b.start as Date).getTime();
        return Math.sign(diff);
      });
      const dateToScroll = eventsToSearch[0].start as Date;
      ref.current?.getApi()?.scrollToTime(dayjs(dateToScroll).format('HH:mm'));
      setEventsLoaded(true);
    }
  }, [isEventsLoaded, events, ref.current]);

  useEffect(() => {
    if (ref.current) {
      onEventsSet();
    }
  }, [ref.current]);

  const handleEventClick = (event: EventClickArg) => {
    if (event.event.allDay) {
      return;
    }
    // Added this to fix the bug when modal is opened with event and popover is still open. Currently there is no api to close it.
    const popover = document.querySelector('.fc-popover-close');
    if (popover) {
      // @ts-ignore
      popover?.click();
    }
    eventClick?.(event);
  };

  return (
    <Styles.Container>
      <div ref={headerRef}>
        <Header
          header={header}
          calendar={ref.current}
          headerRightSlot={headerRightSlot}
        />
      </div>

      <FullCalendar
        ref={ref}
        firstDay={0}
        nowIndicator
        dayMaxEvents
        displayEventTime
        eventMaxStack={2}
        handleWindowResize
        events={eventsWrap}
        dayMaxEventRows={2}
        eventOverlap={false}
        height={calendarHeight}
        allDaySlot={allDaySlot}
        windowResize={onEventsSet}
        initialView="timeGridWeek"
        eventContent={eventContent}
        allDayContent={allDayContent}
        dayHeaderContent={dayHeaderContent}
        titleFormat={{ month: 'long', year: 'numeric' }}
        headerToolbar={{ left: '', right: '', center: '' }}
        eventClick={eventClick ? handleEventClick : undefined}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short',
        }}
        datesSet={(dates) => {
          setVisibleDates(dates);
          setEventsLoaded(false);
          datesSet?.(dates);
        }}
        slotLabelFormat={{
          hour: 'numeric',
          meridiem: 'short',
          minute: '2-digit',
          omitZeroMinute: false,
        }}
        eventsSet={(items) => {
          setEventsLoaded(false);
          eventsSet?.(items);
          if (!removeEventScroll) {
            onEventsSet();
          }
        }}
        moreLinkContent={
          mobile ? (
            <Styles.DotButton
              text="•"
              padding="0"
              transparent
              colorType={ButtonColors.LightBlue}
            />
          ) : undefined
        }
        {...props}
      />
    </Styles.Container>
  );
};

export type {
  EventInput,
  FullCalendar,
  EventSourceInput,
  EventCalendarPage,
  EventCalendarProps,
};
