import styled, { css } from 'styled-components';

import { Icon } from 'components/icon';

export const Clock = styled(Icon.Clock)<{ disabled: boolean }>`
  ${({ theme, disabled }) => css`
    margin-right: 4px;
    transform: none !important;
    path {
      fill: ${disabled
        ? theme.config.input.label.disabled
        : theme.config.input.text} !important;
    }
  `}
`;
