import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { mockSalesApiQuery } from 'store/mockQuery';

import { Sales, Refund, RefundStatusColor, RefundStatusTitle } from './types';

const tagType = 'sales';

export const salesApi = createApi({
  tagTypes: [tagType],
  reducerPath: 'salesApi',
  baseQuery: mockSalesApiQuery,
  endpoints: (build) => ({
    get: build.query<Sales, void>({
      providesTags: [tagType],
      query: () => ({
        method: 'get',
        url: URL.GET_SALES_INFO,
      }),
    }),
  }),
});

export const { useGetQuery } = salesApi;

export type { Sales, Refund };
export { RefundStatusColor, RefundStatusTitle };
