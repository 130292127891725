import styled, { css } from 'styled-components';

import { BaseDiv } from './types';

export const Footer = styled.footer<BaseDiv>`
  ${({ theme, mobile, hasGap }) => css`
    display: flex;
    margin-top: auto;
    padding: 12px 16px;
    background-color: ${theme.config.background};
    box-shadow: inset 0 1px 0 ${theme.config.border};
    z-index: 10;

    ${hasGap &&
    css`
      gap: 24px;
    `}

    ${!mobile &&
    css`
      justify-content: space-between;
    `}

    ${mobile &&
    css`
      flex-direction: column;
    `}
  `}
`;

export const Children = styled.div<BaseDiv>`
  ${({ mobile }) => css`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    > a {
      min-width: ${mobile ? '100%' : '136px'};
    }

    > button {
      min-width: ${mobile ? '100%' : '136px'};
    }
  `}
`;
