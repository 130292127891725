import * as Yup from 'yup';

import { formatDate } from 'utils/date';
import { Payout, PaymentHistory, MinusPayoutTypes } from 'store/api/payout';

import { FormValues } from './types';

export const getInitialValues = (data?: Payout): FormValues => ({
  notes: data?.notes || '',
  eventId: data?.eventId || undefined,
  amount: data?.amount?.toString() || '',
  date: data?.date ? formatDate(data?.date) : '',
});

export const validationSchema = Yup.object().shape({
  date: Yup.date().required(),
  eventId: Yup.number().required(),
  amount: Yup.number().min(1).required(),
  notes: Yup.string().min(5).max(200).required(),
});

export const transformPaymentHistoryData = (data?: PaymentHistory[]) => {
  const result = [];
  const dateMap: Record<string, any> = {};

  data?.forEach((entry) => {
    entry?.items?.forEach((item) => {
      const date = formatDate(entry.date);
      const key = item.eventId!;
      const isMinusType = MinusPayoutTypes.includes(item.type);
      if (dateMap[key]) {
        dateMap[key].value = isMinusType
          ? dateMap[key].value - +item.amount
          : dateMap[key].value + +item.amount;
      } else {
        dateMap[key] = {
          date,
          label: item?.event?.name,
          eventName: item?.event?.name,
          id: Object.keys(dateMap).length + 1,
          value: isMinusType ? -Number(item.amount) : Number(item.amount),
        };
      }
    });
  });

  for (const key in dateMap) {
    result.push(dateMap[key]);
  }

  return result;
};

export const getCommonListProps = (data?: any[]) => ({
  hasMinHeight: false,
  maxHeight: data?.length ? 350 : undefined,
});
