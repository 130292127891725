import styled, { css } from 'styled-components';

import { Button } from 'components/button';
import { Popup as TCPopup } from 'components/popup';

import { MenuItem } from './types';

export const Container = styled.div<{ maxHeight: number }>`
  position: relative;
  overflow: hidden;
  ${({ theme, maxHeight }) => css`
    max-height: ${maxHeight};
    border-radius: ${theme.borderRadius};
  `};
`;

export const List = styled.div<{ maxHeight: number }>`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${({ maxHeight }) => `${maxHeight - 40}px;`};
`;

export const StyledButton = styled(Button)<Pick<MenuItem, 'textColor'>>`
  border-radius: unset;

  ${({ theme, textColor }) =>
    textColor &&
    css`
      & > span,
      &:hover > span,
      &:active > span {
        color: ${theme.config.text[textColor]};
      }
    `}
`;

export const Popup = styled(TCPopup)`
  &-overlay {
    z-index: 10000 !important;
  }

  &-content {
    z-index: 10001 !important;
  }
`;
