import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { Icon } from '../icon';

import * as Styles from './styles';
import { CalendarProps } from './types';
import { CalendarDay } from './calendar-day';
import { getCurrentDate, generateDateRows, generateDateRange } from './helpers';

dayjs.extend(localeData);

/*eslint-disable*/

export const Calendar = ({
  range,
  onChange,
  initialDate,
  selectedDate,
  onChangeRange,
  selectionType = 'single',
}: CalendarProps): JSX.Element => {
  const [currentDate, setCurrentDate] = useState(
    getCurrentDate({
      initialDate,
      selectedDate,
      type: selectionType,
      selectedRange: range,
    })
  );

  useEffect(() => {
    if (onChange) onChange(undefined);
    if (onChangeRange) onChangeRange(undefined);
  }, [selectionType]);

  const dayRows = useMemo(() => {
    return generateDateRows(currentDate);
  }, [currentDate]);

  const increaseMonth = () => setCurrentDate(currentDate.add(1, 'month'));

  const decreaseMonth = () => setCurrentDate(currentDate.subtract(1, 'month'));

  const onDayClick = useCallback(
    (date: any) => {
      switch (selectionType) {
        case 'range':
          if (onChangeRange) {
            onChangeRange(generateDateRange({ selectedDate: date, range }));
          }

          break;

        case 'single':
          if (onChange) {
            const newDate = dayjs(date);
            const isSame = selectedDate?.isSame(newDate) || false;

            onChange(isSame ? undefined : newDate);
            if (newDate.isAfter(currentDate, 'month')) increaseMonth();
            if (newDate.isBefore(currentDate, 'month')) decreaseMonth();
          }

          break;
      }
    },
    [selectionType, range, currentDate, selectedDate]
  );

  return (
    <Styles.CalendarContainer>
      <Styles.MonthSelectContainer>
        <div onClick={decreaseMonth}>
          <Icon.ChevronLeft />
        </div>

        <p>{currentDate.format('MMMM YYYY')}</p>

        <div onClick={increaseMonth}>
          <Icon.ChevronRight />
        </div>
      </Styles.MonthSelectContainer>

      <Styles.WeekDaysContainer>
        {dayjs.weekdaysShort().map((weekday, index) => (
          <Styles.WeekDayContainer key={`calendar-weekday-${index.toString()}`}>
            <p>{weekday}</p>
          </Styles.WeekDayContainer>
        ))}
      </Styles.WeekDaysContainer>

      <Styles.DaysContainer>
        {dayRows.map((row, index) => (
          <Styles.DaysRowContainer key={`calendar-row-${index.toString()}`}>
            {row.map((day, dayIndex) => (
              <CalendarDay
                day={day}
                dateTo={range?.to}
                dateFrom={range?.from}
                currentDate={currentDate}
                selectedDate={selectedDate}
                onClick={() => onDayClick(day)}
                key={`calendar-day-container-${dayIndex.toString()}`}
              />
            ))}
          </Styles.DaysRowContainer>
        ))}
      </Styles.DaysContainer>
    </Styles.CalendarContainer>
  );
};

export type { CalendarProps };
