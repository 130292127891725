import styled, { css } from 'styled-components';

import { bodyRegular } from 'styles/mixins/fonts';

export const Rich = styled.div<{
  focused: boolean;
  $maxRows?: number;
  $withBorder: boolean;
  $transparent: boolean;
}>`
  ${bodyRegular};
  width: 100%;

  .ql-toolbar {
    border: none !important;
  }

  .ql-container {
    border: none !important;
    ${bodyRegular};
  }

  .ql-formats {
    margin-right: 16px !important;
  }

  .ql-editor {
    line-height: unset;
    ul {
      padding-left: 0;
      li {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    ol {
      padding-left: 0;
      li {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  ${({ theme, focused, $maxRows, $withBorder, $transparent }) => {
    const isChatInput = $transparent && !$withBorder;
    return css`
      .quill {
        border-radius: ${theme.borderRadius};
        border: ${!$withBorder
          ? 'unset'
          : `1px solid ${theme.config.input.border.default}`};
        background: ${$transparent ? 'unset' : theme.config.input.bg.default};

        ${$maxRows &&
        css`
          .ql-editor {
            overflow: auto;
            max-height: ${$maxRows * 24 + (isChatInput ? 12 : 4)}px;
          }
        `};

        ${isChatInput
          ? css`
              .ql-editor {
                padding: 12px 0 12px 0 !important;
              }

              .ql-toolbar {
                padding: 8px 0;
              }
            `
          : css`
              .ql-editor {
                padding: 4px 16px 12px 16px !important;
              }
            `}

        ${$withBorder &&
        css`
          ${focused &&
          css`
            border: 1px solid ${theme.config.input.border.active} !important;
          `}

          &:focus {
            border: 1px solid ${theme.config.input.border.active} !important;
          }

          &:hover {
            border: 1px solid ${theme.config.input.border.hover};
          }

          &:active {
            border: 1px solid ${theme.config.input.border.active};
          }

          &:disabled {
            border: 1px solid ${theme.config.input.border.disabled} !important;
          }
        `}
      }

      .ql-editor.ql-blank:before {
        font-family: Mukta, sans-serif;
        font-style: normal;
        left: ${isChatInput ? 0 : '16px'};
        right: ${isChatInput ? 0 : '16px'};
        color: ${theme.config.input.placeholder} !important;
      }

      .ql-tooltip {
        border: none;
        z-index: 2000;
        padding: 8 16px;
        color: ${theme.config.text.primary};
        background: ${theme.config.popup.bg};
        border-radius: ${theme.borderRadius} !important;
        box-shadow: ${theme.config.popup.shadow} !important;
      }

      .ql-error {
        border: 1px solid ${theme.config.input.border.error} !important;
      }

      .mention {
        color: ${theme.config.link.default};
      }

      .ql-snow {
        .ql-tooltip input[type='text'] {
          padding: 8px;
          color: ${theme.config.input.text};
          background: ${theme.config.input.bg.default};
          border-radius: ${theme.borderRadius};
          border: 1px solid ${theme.config.input.border.default};

          &::placeholder {
            color: ${theme.config.input.placeholder};
          }

          &:hover {
            border: 1px solid ${theme.config.input.border.hover};
          }

          &:focus {
            border: 1px solid ${theme.config.input.border.active};
            outline: none !important;
            outline-offset: none !important;
          }

          &:active {
            border: 1px solid ${theme.config.input.border.active};
          }
        }

        a {
          color: ${theme.config.link.default};

          &:hover {
            color: ${theme.config.link.hover};
          }

          &:active {
            color: ${theme.config.link.action};
          }
        }
      }

      .ql-mention-list-container {
        max-height: 160px;
        border-radius: 8px;
        position: relative;
        background: ${theme.config.popup.bg};

        @supports (overflow: overlay) {
          overflow-y: overlay;
        }

        @supports not (overflow: overlay) {
          overflow-y: scroll;
        }

        .ql-mention-list-item {
          padding: 8px;

          &.selected {
            background: ${theme.config.button.ghost.tetrieryGrey.hover.bg};
          }
        }
      }

      .ql-toolbar {
        button {
          .ql-stroke {
            stroke: ${theme.config.button.standard.blackWhite.default
              .text} !important;
          }

          .ql-fill {
            fill: ${theme.config.button.standard.blackWhite.default
              .text} !important;
          }

          &.ql-active {
            .ql-stroke {
              stroke: ${theme.config.button.standard.blue.default
                .bg} !important;
            }

            .ql-fill {
              fill: ${theme.config.button.standard.blue.default.bg} !important;
            }
          }

          &:hover {
            .ql-stroke {
              stroke: ${theme.config.button.standard.blue.hover.bg} !important;
            }

            .ql-fill {
              fill: ${theme.config.button.standard.blue.hover.bg} !important;
            }
          }

          &:active {
            .ql-stroke {
              stroke: ${theme.config.button.standard.blue.action.bg} !important;
            }

            .ql-fill {
              fill: ${theme.config.button.standard.blue.action.bg} !important;
            }
          }
        }
      }
    `;
  }}
`;
