import { PropsWithChildren } from 'react';

import { withRef } from 'hoc/withRef';
import { handleSelectionOnClick } from 'utils/helpers';
import { EllipsisText } from 'components/ellipsis-text';
import {
  BadgeColor,
  BadgeCursor,
  BadgePadding,
  BadgeBorderRadius,
} from 'types';

import { Dot } from './styles';
import * as Styles from './styles';
import { BadgeProps, BadgesProps } from './types';

const Badge: React.FC<BadgeProps> = ({
  text,
  icon,
  count,
  style,
  onClick,
  showDot,
  isActive,
  disabled,
  smallFont,
  withHover,
  className,
  noEllipsis,
  refForward,
  countColor,
  bold = false,
  nativeIconColor = false,
  styleType = BadgeColor.Green,
  cursor = BadgeCursor.Inherit,
  padding = BadgePadding.Default,
  borderRadius = BadgeBorderRadius.Default,
  ...props
}) => {
  const withOnClickHandler = onClick !== undefined;

  const getValue = () => {
    if (text) {
      return typeof text === 'string' && !noEllipsis ? (
        <EllipsisText>{text}</EllipsisText>
      ) : (
        text
      );
    }
    return null;
  };

  return (
    <Styles.Badge
      bold={bold}
      style={style}
      ref={refForward}
      padding={padding}
      disabled={disabled}
      isActive={isActive}
      smallFont={smallFont}
      styleType={styleType}
      className={className}
      noEllipsis={noEllipsis}
      borderRadius={borderRadius}
      onClick={handleSelectionOnClick(onClick)}
      cursor={withOnClickHandler ? BadgeCursor.Pointer : cursor}
      noHover={withHover === undefined ? !withOnClickHandler : !withHover}
      {...props}
    >
      {icon && (
        <Styles.Icon styleType={styleType} nativeIconColor={nativeIconColor}>
          {icon}
        </Styles.Icon>
      )}

      {showDot && <Dot styleType={styleType} />}

      {getValue()}

      {count && <Styles.Count color={countColor}>{count}</Styles.Count>}
    </Styles.Badge>
  );
};

const BadgeWithRef = withRef(Badge);

const Badges = ({
  label,
  inline,
  gap = 8,
  children,
  className,
  refForward,
}: PropsWithChildren<BadgesProps>): JSX.Element => {
  return (
    <Styles.Container ref={refForward}>
      {label && <Styles.Title>{label}</Styles.Title>}
      <Styles.Badges gap={gap} inline={inline} className={className}>
        {children}
      </Styles.Badges>
    </Styles.Container>
  );
};

const BadgesWithRef = withRef(Badges);

export { Badge, Badges, BadgeWithRef, BadgesWithRef, Dot as BadgeDot };
export type { BadgeProps };
