import { generatePath } from 'react-router-dom';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { parseAxiosError } from 'utils/error';
import { apiInstance, apiLoaderInstance } from 'api/instance';

interface Error {
  data?: any;
  status?: number;
  message?: string;
}

export const axiosBaseQuery =
  ({
    instance,
  }: {
    instance: AxiosInstance;
  }): BaseQueryFn<
    {
      url: string;
      data?: AxiosRequestConfig['data'];
      method: AxiosRequestConfig['method'];
      headers?: AxiosRequestConfig['headers'];
      timeout?: AxiosRequestConfig['timeout'];
    },
    unknown,
    Error
  > =>
  async ({ url, data, method, timeout, headers }, { signal }) => {
    try {
      const result = await instance({
        url,
        data,
        method,
        signal,
        headers,
        timeout,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          data: err.response?.data,
          status: err.response?.status,
          message: parseAxiosError(err),
        },
      };
    }
  };

export interface ResponseFuncProps {
  url: string;
  data: AxiosRequestConfig['data'];
}

export interface MockHandler {
  url: string;
  method: string;
  timeout?: number;
  responseData?: any;
  responseFunc?: (value: ResponseFuncProps) => any;
}

export const mockBaseQuery =
  ({
    handlers,
  }: {
    handlers: MockHandler[];
  }): BaseQueryFn<
    {
      url: string;
      data?: AxiosRequestConfig['data'];
      method: AxiosRequestConfig['method'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    Error
  > =>
  ({ url, data, method }) =>
    new Promise((resolve, reject) => {
      const handler = handlers.find(
        (hand) => hand.url === url && hand.method === method
      );

      if (!handler) {
        reject({
          error: {
            status: 404,
            message: 'No handler',
          },
        });
      }

      const { responseData, responseFunc } = handler!;

      setTimeout(() => {
        resolve({
          data: responseFunc ? responseFunc({ url, data }) : responseData,
        });
      }, handler!.timeout ?? 1500);
    });

export const apiQuery = axiosBaseQuery({
  instance: apiInstance,
});

export const apiLoaderQuery = axiosBaseQuery({
  instance: apiLoaderInstance,
});

export const generateAPIPath = (...args: Parameters<typeof generatePath>) => {
  const originalPath = args[0];
  if (!originalPath.startsWith('http')) {
    return generatePath(...args);
  }
  let newArgs: Parameters<typeof generatePath> = [...args];
  const url = new URL(originalPath).origin;
  newArgs[0] = originalPath.substring(url.length + 1);
  return `${url}/${generatePath(...newArgs)}`;
};
