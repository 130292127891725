import styled, { css } from 'styled-components';

import { Icon } from '../icon';

import { PhotoCardProps } from './types';

export const Container = styled.div`
  width: 352px;
  height: 346px;
  user-select: none;
  overflow: visible;
  position: relative;
`;

export const Card = styled.div<Pick<PhotoCardProps, 'angle'>>`
  gap: 11px;
  width: 330px;
  margin: 11px;
  display: flex;
  height: 324px;
  background: #fff;
  padding: 25px 20px;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  ${({ angle }) => css`
    ${angle &&
    css`
      transform: rotate(${angle}deg);
    `}
  `}
`;

export const Name = styled.p`
  color: black;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Oooh Baby', Mukta, sans-serif;
`;

export const Pin = styled(Icon.PinPhoto)`
  position: absolute;
  top: -10px;
  left: 48%;
`;
