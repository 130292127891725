import styled, {
  css,
  DefaultTheme,
  // @ts-ignore https://github.com/styled-components/styled-components/issues/4062
  FlattenSimpleInterpolation,
} from 'styled-components';

import { inlineFlexCenter } from 'styles';
import { heading2 } from 'styles/mixins/fonts';

import { PadProps, ContainerProps } from './types';

const getPadColors = ({
  theme,
  filled,
  selected,
  disabled,
}: {
  filled: boolean;
  disabled: boolean;
  selected: boolean;
  theme: DefaultTheme;
}): FlattenSimpleInterpolation => {
  let bg = theme.config.numpad.number.default.bg.default;
  let color = theme.config.numpad.number.default.text;
  let hoverBg = theme.config.numpad.number.default.bg.hover;
  let activeBg = theme.config.numpad.number.default.bg.active;

  if (disabled) {
    color = theme.config.numpad.number.disabled.text;
  } else if (selected) {
    bg = theme.config.numpad.number.selected.bg.default;
    hoverBg = theme.config.numpad.number.selected.bg.hover;
    activeBg = theme.config.numpad.number.selected.bg.active;
    color = theme.config.numpad.number.selected.text;
  } else if (filled) {
    bg = theme.config.numpad.number.filled.bg.default;
    hoverBg = theme.config.numpad.number.filled.bg.hover;
    activeBg = theme.config.numpad.number.filled.bg.active;
    color = theme.config.numpad.number.filled.text;
  }

  return css`
    color: ${color};
    background-color: ${bg};

    &:hover {
      background-color: ${hoverBg};
    }

    &:active {
      background-color: ${activeBg};
    }

    &:disabled {
      pointer-events: none;
    }
  `;
};

export const GridContainer = styled.div<ContainerProps>`
  display: inline-grid;

  ${({ gap, theme, padding, cellSize, withBorder }) => css`
    padding: ${padding};
    border-radius: ${theme.borderRadius};
    grid-template-columns: repeat(3, ${cellSize});
    grid-template-rows: repeat(4, ${cellSize});
    grid-gap: ${gap};

    ${withBorder &&
    css`
      border: 1px solid ${theme.config.numpad.border};
    `}
  `}
`;

export const Pad = styled.button<PadProps>`
  ${inlineFlexCenter};

  ${({ theme, filled, disabled, selected }) => css`
    ${heading2};
    border-radius: ${theme.borderRadius};
    border: 1px solid ${theme.config.numpad.border};

    ${getPadColors({ theme, filled, disabled, selected })};
  `}
`;
