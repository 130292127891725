import { useOutletContext } from 'react-router-dom';

import { isDesktop } from 'hooks';
import { Info } from 'components/info';
import { List } from 'components/list';
import { Link } from 'components/link';
import { Row, Col } from 'components/grid';
import { Storage } from 'store/api/storage';
import { ListCard } from 'components/list-card';
import { InfoBlock } from 'components/info-block';
import { ListHeader } from 'components/list-header';
import { formatDate, formatTimeData } from 'utils/date';
import { formatPhone, formatPrice } from 'utils/helpers';

import * as Styles from './styles';

const template = '1fr 1fr';
const titles = ['Month', 'Price'];

export const StorageDetailsGeneral = () => {
  const desktop = isDesktop();
  const data = useOutletContext<Storage>();

  return (
    <Styles.GeneralContainer>
      <InfoBlock>
        <Row>
          <Col col={3}>
            <Info title="Address" value={data.address} />
          </Col>
          <Col col={3}>
            <Info title="Units" value={data.unit} />
          </Col>
          <Col col={3}>
            <Info title="Gate code" value={data.gateCode} />
          </Col>
          <Col col={3}>
            <Info title="Type" value={data.type} />
          </Col>
        </Row>

        <Row>
          <Col col={4}>
            <Info title="Account under" value={data.accountUnder} />
          </Col>
          <Col col={4}>
            <Info title="Username" value={data.username} />
          </Col>
          <Col col={4}>
            <Info title="User password" value={data.password} />
          </Col>
        </Row>

        <Row>
          <Col col={4}>
            <Info title="Key" value={data.keyCode} />
          </Col>
          <Col col={4}>
            <Info
              title="Hours"
              value={
                data?.workHoursTo && data?.workHoursFrom
                  ? `${formatTimeData(data.workHoursFrom)} - ${formatTimeData(data.workHoursTo)}`
                  : null
              }
            />
          </Col>
          <Col col={4}>
            <Info title="Phone" value={formatPhone(data.phone)} />
          </Col>
        </Row>

        <Row>
          <Col col={4}>
            <Info
              noEllipsis
              title="Website"
              value={
                data.website ? <Link withoutButton url={data.website} /> : null
              }
            />
          </Col>
        </Row>
      </InfoBlock>

      <List
        header={<ListHeader titles={titles} />}
        count={data?.monthlyPrices?.length ?? 0}
        mainWrapperStyles={desktop ? undefined : { padding: 0 }}
      >
        {data?.monthlyPrices?.map((value) => (
          <ListCard
            key={value.id}
            headers={titles}
            mobileTemplate={template}
            tabletTemplate={template}
            values={[formatDate(value.date, 'MMMM'), formatPrice(value.price)]}
          />
        ))}
      </List>
    </Styles.GeneralContainer>
  );
};
