import { EventContentArg } from '@fullcalendar/core';

import { Flex } from 'components/flex';
import { useElementSize } from 'hooks';

import * as Styles from './styles';

export const Event = ({ event, timeText }: EventContentArg) => {
  const [containerRef, { height }] = useElementSize();
  const isHorizontal = height < 45;
  const { title, allDay, textColor, extendedProps } = event;
  const { clientId, subtitle } = extendedProps ?? {};
  const isTimeEllipsis = height >= clientId ? 61 : 45;

  return (
    <Styles.Event ref={containerRef}>
      <Styles.EventContainer allDay={allDay} isHorizontal={isHorizontal}>
        <Styles.EventTitle allDay={allDay}>{title}</Styles.EventTitle>
        {!isHorizontal && subtitle && (
          <Styles.EventText>{subtitle}</Styles.EventText>
        )}
        {!allDay && (
          <Flex
            gap={4}
            minWidth={0}
            flexDirection={height < 61 ? 'row' : 'column'}
          >
            {isTimeEllipsis ? (
              <Styles.EventTextEllipsis>{timeText}</Styles.EventTextEllipsis>
            ) : (
              <Styles.EventText>{timeText}</Styles.EventText>
            )}

            {clientId && (
              <Styles.Booked textColor={textColor}>
                <p>B</p>
              </Styles.Booked>
            )}
          </Flex>
        )}
      </Styles.EventContainer>
    </Styles.Event>
  );
};
