import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: inline;
  word-break: break-word;
  .standard-link {
    ${({ theme }) => css`
      color: ${theme.config.link.default};

      &:hover {
        color: ${theme.config.link.hover};
      }

      &:active {
        color: ${theme.config.link.action};
      }
    `};
  }

  svg path {
    fill: ${({ theme }) => theme.config.link.default};
  }
`;

export const LinkWithBackground = styled.div`
  ${({ theme }) => css`
    position: relative;
    box-shadow: ${theme.config.link.background.shadow};
    border-radius: ${theme.borderRadius};

    a {
      display: block;
      padding: 8px 48px 8px 16px;
      color: ${theme.config.link.bgTextColor};
      background-color: ${theme.config.link.background.default};
      border-radius: ${theme.borderRadius};
      transition: ${theme.transition.default};

      &:hover {
        color: ${theme.config.link.bgTextColor};
        background-color: ${theme.config.link.background.hover};
      }

      &:active {
        color: ${theme.config.link.bgTextColor};
        background-color: ${theme.config.link.background.action};
      }
    }

    button {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  `}
`;
