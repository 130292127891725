import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addParamsToUrl } from 'utils/query';
import { filterEmptyFields } from 'utils/object';
import { storagesApi, tagType as storagesApiTagType } from 'store/api/storage';

import { apiQuery } from '../../query';

import {
  Event,
  EventReport,
  EventStatus,
  EventCheckout,
  EventLocation,
  EventDayResult,
  EventTopSeller,
  EventFormValues,
  CashManagerType,
  ReceiptsRequest,
  EmployeeCheckout,
  DailyCountProduct,
  EventListResponse,
  ReportPdfResponse,
  EventQuestionnaire,
  EventReportRequest,
  ReceiptHTMLRequest,
  EventReportProduct,
  EventReportEmployee,
  EventCheckoutRequest,
  eventPaymentTypeLabel,
  ReceiptDetailsRequest,
  EventTopSellerRequest,
  EventEmployeesResponse,
  EventTopSellerResponse,
  eventPaymentTypeOptions,
  EmployeeCheckoutRequest,
  EventDashboardStatistics,
  EventOpenCloseDayRequest,
  EventCashManagerResponse,
  EventQuestionnaireRequest,
  eventPaymentTransactionLabel,
  eventPaymentTransactionOptions,
  EventDashboardStatisticsRequest,
} from './types';

const tagTypes = ['Event'];

export const eventApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'eventApi',
  endpoints: (build) => ({
    remove: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: URL.EVENT_DETAILS.replace(':id', id),
      }),
    }),

    checkoutInfo: build.query<EventCheckout, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_CHECKOUT.replace(':id', id),
      }),
    }),

    html: build.query<string, ReceiptHTMLRequest>({
      query: ({ id, receiptId }) => ({
        method: 'get',
        url: generatePath(URL.EVENT_RECEIPTS_HTML, { id, receiptId }),
      }),
    }),

    employees: build.query<EventEmployeesResponse[], string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_EMPLOYEES.replace(':id', id),
      }),
    }),

    details: build.query<Event, string>({
      keepUnusedDataFor: 0,
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_DETAILS.replace(':id', id),
      }),
    }),

    cashManager: build.query<EventCashManagerResponse[], string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: URL.EVENT_CASH_MANAGER.replace(':id', id),
      }),
    }),

    offer: build.mutation<void, Pick<Event, 'id' | 'companyId'>>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.EVENT_OFFER.replace(':id', String(id)),
      }),
    }),

    checkout: build.mutation<EventCheckout, EventCheckoutRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.EVENT_CHECKOUT.replace(':id', String(id)),
      }),
    }),

    openCloseDay: build.mutation<Event, EventOpenCloseDayRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.EVENT_CLOSE_DAY.replace(':id', String(id)),
      }),
    }),

    topSellers: build.query<
      EventTopSellerResponse,
      EventTopSellerRequest | void
    >({
      providesTags: tagTypes,
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.EVENT_TOP_SELLERS, params || {}),
      }),
    }),

    report: build.query<EventReport[], EventReportRequest>({
      providesTags: tagTypes,
      query: ({ id, isDailyReport }) => ({
        method: 'get',
        url: addParamsToUrl(URL.EVENT_REPORT.replace(':id', id), {
          isDailyReport,
        }),
      }),
    }),

    receipts: build.query<EventCheckout[], ReceiptsRequest>({
      providesTags: tagTypes,
      query: ({ id, ...query }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.EVENT_RECEIPTS.replace(':id', String(id)),
          query
        ),
      }),
    }),

    questionnaire: build.mutation<
      EventQuestionnaire,
      EventQuestionnaireRequest
    >({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'post',
        url: URL.EVENT_QUESTIONNAIRE.replace(':id', String(id)),
      }),
    }),

    generateReportPdf: build.query<ReportPdfResponse, EventReportRequest>({
      providesTags: tagTypes,
      query: ({ id, isDailyReport }) => ({
        method: 'get',
        url: addParamsToUrl(URL.EVENT_REPORT_PDF.replace(':id', id), {
          isDailyReport,
        }),
      }),
    }),

    receiptDetails: build.query<EventCheckout, ReceiptDetailsRequest>({
      providesTags: tagTypes,
      query: ({ id, receiptId }) => ({
        method: 'get',
        url: URL.EVENT_RECEIPT.replace(':id', String(id)).replace(
          ':receiptId',
          receiptId
        ),
      }),
    }),

    employeeCheckouts: build.query<EmployeeCheckout, EmployeeCheckoutRequest>({
      providesTags: tagTypes,
      query: ({ id, ...params }) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.EVENT_EMPLOYEE_CHECKOUTS.replace(':id', String(id)),
          params
        ),
      }),
    }),

    list: build.query<Event[], (SearchRequest & Partial<Event>) | void>({
      providesTags: tagTypes,
      transformResponse: (response: EventListResponse) => response.data,
      query: (params) => ({
        method: 'get',
        url: params ? addParamsToUrl(URL.EVENT_LIST, params) : URL.EVENT_LIST,
      }),
    }),

    dashboardStatistics: build.query<
      EventDashboardStatistics,
      EventDashboardStatisticsRequest
    >({
      providesTags: tagTypes,
      query: ({ id, date }) => ({
        method: 'get',
        url: addParamsToUrl(
          generatePath(URL.EVENT_DASHBOARD_STATISTICS, { id }),
          { date }
        ),
      }),
    }),
    create: build.mutation<void, EventFormValues>({
      invalidatesTags: tagTypes,
      query: ({ locations, ...data }) => ({
        method: 'post',
        url: URL.EVENT_LIST,
        data: filterEmptyFields({
          ...data,
          locations: locations.filter(
            (location) => Object.values(location).length
          ),
        }),
      }),
    }),

    edit: build.mutation<void, { id: string } & Partial<EventFormValues>>({
      invalidatesTags: tagTypes,
      query: ({ id, locations, companyId, ...data }) => ({
        method: 'patch',
        url: URL.EVENT_DETAILS.replace(':id', id),
        data: filterEmptyFields({
          ...data,
          locations: locations?.filter(
            (location) => Object.values(location).length
          ),
        }),
      }),
    }),

    accept: build.mutation<void, Pick<Event, 'id'> & { locationIds: number[] }>(
      {
        invalidatesTags: tagTypes,
        query: ({ id, locationIds }) => ({
          method: 'patch',
          data: {
            locationIds,
          },
          url: URL.EVENT_ACCEPT.replace(':id', String(id)),
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;

            dispatch(storagesApi.util.invalidateTags([storagesApiTagType]));
          } catch (error) {
            console.error(error);
          }
        },
      }
    ),
  }),
});

export const {
  useListQuery,
  useHtmlQuery,
  useReportQuery,
  useDetailsQuery,
  useEditMutation,
  useOfferMutation,
  useReceiptsQuery,
  useAcceptMutation,
  useRemoveMutation,
  useEmployeesQuery,
  useCreateMutation,
  useTopSellersQuery,
  useCashManagerQuery,
  useCheckoutMutation,
  useCheckoutInfoQuery,
  useReceiptDetailsQuery,
  useOpenCloseDayMutation,
  useQuestionnaireMutation,
  useEmployeeCheckoutsQuery,
  useGenerateReportPdfQuery,
  useDashboardStatisticsQuery,
  useLazyGenerateReportPdfQuery,
} = eventApi;

export type {
  Event,
  EventStatus,
  EventReport,
  EventCheckout,
  EventLocation,
  EventDayResult,
  EventTopSeller,
  EventFormValues,
  EmployeeCheckout,
  DailyCountProduct,
  EventQuestionnaire,
  EventReportProduct,
  EventReportEmployee,
  ReceiptDetailsRequest,
  EventDashboardStatistics,
};
export {
  tagTypes,
  CashManagerType,
  eventPaymentTypeLabel,
  eventPaymentTypeOptions,
  eventPaymentTransactionLabel,
  eventPaymentTransactionOptions,
};
