import { Company } from 'types';
import { useNavigate } from 'hooks';
import { Flex, Avatar, ListCard } from 'components';

import { DeleteCompanyDialog } from '../delete-company-dialog';

import { titles, template } from './config';

export const Card = ({ data }: { data: Company }) => {
  const navigate = useNavigate();

  return (
    <ListCard
      headers={titles}
      template={template}
      mobileTemplate={template}
      tabletTemplate={template}
      onClick={() => navigate(String(data.id))}
      values={[
        <Flex gap={16} alignItems="center">
          <Avatar size={56} url={data.photoUrl} />
          <h3>{data.name}</h3>
        </Flex>,
        `${data.processingFee}%`,
        <DeleteCompanyDialog data={data} />,
      ]}
    />
  );
};
