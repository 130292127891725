import { types } from 'react-alert';

import { Icon as IconComponent } from '../icon';

export const Icon = {
  [types.INFO]: null,
  [types.ERROR]: <IconComponent.AlertError />,
  [types.SUCCESS]: <IconComponent.AlertSuccess />,
};

export const Title = {
  [types.INFO]: 'Info',
  [types.ERROR]: 'Error',
  [types.SUCCESS]: 'Success',
};
