import { useField } from 'formik';

import { Flex } from 'components/flex';
import { CommonFieldProps } from 'types';
import { controlError } from 'utils/error';
import { ErrorMessage } from 'components/error-message';

import { Switch, SwitchProps } from '../switch';

type SwitchFieldProps = SwitchProps & CommonFieldProps;

const SwitchField = ({ name, label, onChange, ...props }: SwitchFieldProps) => {
  const [field, meta, { setValue }] = useField({ name, type: 'checkbox' });

  const error = controlError(meta, name, label);

  return (
    <Flex column>
      <Switch
        {...field}
        {...props}
        label={label}
        onChange={async (e) => {
          await setValue((e.target as HTMLInputElement).checked);
          onChange?.(e);
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Flex>
  );
};

export { SwitchField };
