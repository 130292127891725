import { useCallback } from 'react';

import { Messages } from 'config';
import { useRemoveMutation } from 'store/api/product';
import { DeleteDialog, DeleteDialogProps } from 'components';
import { useAlert, useNavigate, handleMutation } from 'hooks';

interface DeleteProductDialogProps
  extends Pick<DeleteDialogProps, 'id' | 'name'> {
  goBackOnSuccess?: boolean;
}

export const DeleteProductDialog = ({
  id,
  name,
  goBackOnSuccess,
}: DeleteProductDialogProps) => {
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [remove, mutation] = useRemoveMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Product ${Messages.DELETED_SUCCESSFULLY}`);
    if (goBackOnSuccess) {
      navigate(-1);
    }
  }, [goBackOnSuccess]);
  handleMutation({ ...mutation, onSuccess });

  return (
    <DeleteDialog
      id={id}
      name={name}
      action={remove}
      mutation={mutation}
      title="Delete product"
    />
  );
};
