import styled, { css } from 'styled-components';
import Lightbox from 'yet-another-react-lightbox';

import { bodyRegular } from 'styles/mixins/fonts';

const filter = css`
  filter: var(
    --yarl__button_filter,
    drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.8))
  );
`;

export const Gallery = styled(Lightbox)`
  --yarl__slide_captions_container_background: transparent;
  --yarl__portal_zindex: 8888;

  p {
    ${filter};
  }

  ${({ theme }) => css`
    --yarl__container_background_color: ${theme.colors.black.primary.standard};
  `}

  .yarl__slide_title {
    ${bodyRegular};
  }

  svg {
    path {
      fill: white !important;
    }
  }
`;

export const Footer = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  gap: 16px;
  padding: 24px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: flex-end;

  svg {
    ${filter};
  }
`;
