import styled, { css } from 'styled-components';

import { Button } from 'components/button';

export const Wrapper = styled.div`
  top: 0;
  z-index: 200;
  display: flex;
  position: sticky;
  background-color: ${({ theme }) => theme.config.background};
`;

export const AddButton = styled(Button)<{
  raw?: boolean;
  asRow?: boolean;
}>`
  padding-left: 0;
  justify-content: flex-start;
  ${({ raw, asRow }) => css`
    margin-top: ${raw ? 0 : asRow ? -16 : 8}px;
  `}
`;

export const BlockButtons = styled.div<{ marginTop: number }>`
  display: flex;
  gap: 16px;
  margin-top: ${(p) => p.marginTop}px;
`;
