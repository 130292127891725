import dayjs from 'dayjs';

import { getValueByPath } from 'utils/object';
import { toLocaleISOString } from 'utils/date';
import { FilterSection } from 'components/filter-section';

import * as Styles from './styles';
import { DateRangeFilterSectionProps } from './types';
import { useDateRangeFilterSectionError } from './use-date-range-filter-section-error';

export const DateRangeFilterSection = <
  TFilterValues extends Record<string, any>,
>({
  errors,
  setError,
  setFilter,
  resetFilter,
  filterValues,
  maxMonthsRange,
  datePickerProps,
  setRequiredError,
  filterSectionProps,
  endDateFieldName = 'endDate',
  startDateFieldName = 'startDate',
}: DateRangeFilterSectionProps<TFilterValues>) => {
  const parentFieldName = startDateFieldName?.includes('.')
    ? startDateFieldName?.split('.')[0]
    : undefined;

  const startDateValue = getValueByPath(filterValues, startDateFieldName);
  const endDateValue = getValueByPath(filterValues, endDateFieldName);

  return (
    <FilterSection
      title="Date"
      resetDisabled={!startDateValue && !endDateValue}
      error={
        parentFieldName
          ? errors?.[parentFieldName]
          : errors?.[startDateFieldName]
      }
      onReset={() => {
        if (parentFieldName) {
          resetFilter(parentFieldName);
        } else {
          resetFilter(startDateFieldName);
          resetFilter(endDateFieldName);
        }
        setRequiredError?.();
      }}
      {...filterSectionProps}
    >
      <Styles.DatePicker
        inline
        selectsRange
        maxDate={new Date()}
        endDate={endDateValue ? dayjs(endDateValue).toDate() : undefined}
        startDate={startDateValue ? dayjs(startDateValue).toDate() : undefined}
        onChange={(dates: any) => {
          const [start, end] = dates;

          if (setError) {
            if (setRequiredError) {
              if (!start && !end) {
                setError((prev) => ({ ...prev, required: true }));
              } else {
                setError((prev) => ({ ...prev, required: false }));
              }
            }

            if (maxMonthsRange) {
              const diffRange =
                dayjs(end).diff(start, 'month', true) > maxMonthsRange;
              setError((prev) => ({ ...prev, period: diffRange }));
            }

            if (start && !end) {
              setError((prev) => ({ ...prev, noRange: true }));
            } else {
              setError((prev) => ({ ...prev, noRange: false }));
            }
          }

          setFilter(
            start ? toLocaleISOString(start) : undefined,
            startDateFieldName
          );
          setFilter(end ? toLocaleISOString(end) : undefined, endDateFieldName);
        }}
        {...datePickerProps}
      />
    </FilterSection>
  );
};

export { useDateRangeFilterSectionError };
