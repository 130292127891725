import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { apiQuery } from 'store/query';
import { addParamsToUrl } from 'utils/query';

import {
  EventOrder,
  EventOrderListRequest,
  EventOrderCreateRequest,
  EventOrderUpdateRequest,
  EventOrderChangeStatusRequest,
} from './types';

const tagTypes = ['eventOrders'];

export const eventOrdersApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'eventOrdersApi',
  endpoints: (build) => ({
    generateCsv: build.query<string, void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.EVENT_ORDERS_CSV,
      }),
    }),

    details: build.query<EventOrder, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: generatePath(URL.EVENT_ORDER, { id }),
      }),
    }),

    delete: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: generatePath(URL.EVENT_ORDER, { id }),
      }),
    }),

    create: build.mutation<EventOrder, EventOrderCreateRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.EVENT_ORDERS,
      }),
    }),

    list: build.query<EventOrder[], EventOrderListRequest>({
      providesTags: tagTypes,
      query: (query) => ({
        method: 'get',
        url: addParamsToUrl(URL.EVENT_ORDERS, query),
      }),
    }),

    update: build.mutation<EventOrder, EventOrderUpdateRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.EVENT_ORDER, { id }),
      }),
    }),

    changeStatus: build.mutation<EventOrder, EventOrderChangeStatusRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.EVENT_ORDER_CHANGE_STATUS, { id }),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useDeleteMutation,
  useCreateMutation,
  useUpdateMutation,
  useGenerateCsvQuery,
  useLazyGenerateCsvQuery,
  useChangeStatusMutation,
} = eventOrdersApi;

export type {
  EventOrder,
  EventOrderListRequest,
  EventOrderCreateRequest,
  EventOrderUpdateRequest,
};
