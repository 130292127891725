import { useRef } from 'react';
import { createPortal } from 'react-dom';

import { useClickOutside } from 'hooks';

import * as Styles from './styles';
import { DrawerProps } from './types';
import { DrawerHeader } from './header';

export const Drawer = ({
  opened,
  onClose,
  children,
  topOffset,
  overModal,
  hideOverlay,
}: DrawerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, onClose);

  return createPortal(
    <>
      {!hideOverlay && <Styles.Overlay opened={opened} />}
      <Styles.RootStyles opened={opened} />
      <Styles.Container
        ref={ref}
        opened={opened}
        topOffset={topOffset}
        overModal={overModal}
      >
        {children}
      </Styles.Container>
    </>,
    document.body
  );
};

export { DrawerHeader };
export type { DrawerProps };
