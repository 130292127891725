import { ButtonColors } from 'types';
import { formatDate } from 'utils/date';
import { formatPrice } from 'utils/helpers';
import {
  Icon,
  Flex,
  Popup,
  ListCard,
  ListHeader,
  ButtonWithRef,
} from 'components';

import * as CommonStyles from '../styles';

import * as Styles from './styles';
import { PopupListProps, CommonReportProps } from './types';

const tabletTemplate = '1fr 1fr';
const template = 'repeat(6, 1fr) 64px';
const employeeTitles = ['Name', 'Gross Sales'];
const usersTitles = ['Name', 'Created check out'];
const titles = ['Dates', 'Check', 'Cash', 'Credit', 'Tip', 'Total', ''];

const commonButtonProps = {
  padding: '0',
  transparent: true,
  colorType: ButtonColors.BlackWhite,
};

const PopupList = ({
  count,
  trigger,
  elements,
  headerTitles,
}: PopupListProps) => (
  <Popup
    trigger={trigger}
    position={[
      'top left',
      'top right',
      'top center',
      'bottom left',
      'bottom right',
      'bottom center',
    ]}
  >
    <Styles.RevenueContainer>
      <CommonStyles.StyledList
        count={count}
        mainWrapperStyles={{ padding: 8 }}
        header={<ListHeader padding="8px" titles={headerTitles} />}
      >
        {elements}
      </CommonStyles.StyledList>
    </Styles.RevenueContainer>
  </Popup>
);

export const Revenue = ({ data }: CommonReportProps) => (
  <CommonStyles.StyledList
    count={data.length}
    mainWrapperStyles={{ padding: 0 }}
    header={<ListHeader padding="8px" titles={titles} template={template} />}
  >
    {data.map((value, index) => (
      <ListCard
        key={index}
        headers={titles}
        template={template}
        values={[
          formatDate(value.checkoutDate),
          formatPrice(value.paymentMethods.check),
          formatPrice(value.paymentMethods.cash),
          formatPrice(value.paymentMethods.credit),
          formatPrice(value.total.tip),
          formatPrice(value.total.total),
          <Flex gap={32}>
            <PopupList
              headerTitles={employeeTitles}
              count={value.employees.length}
              trigger={(open) => (
                <ButtonWithRef
                  {...commonButtonProps}
                  icon={open ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
                />
              )}
              elements={value.employees.map((employee, i) => (
                <ListCard
                  key={i}
                  headers={employeeTitles}
                  tabletTemplate={tabletTemplate}
                  values={[employee.name, formatPrice(employee.grossSales)]}
                />
              ))}
            />

            <PopupList
              headerTitles={usersTitles}
              count={value.users.length}
              trigger={
                <ButtonWithRef {...commonButtonProps} icon={<Icon.Info />} />
              }
              elements={value.users.map((user) => (
                <ListCard
                  key={user.userId}
                  headers={usersTitles}
                  tabletTemplate={tabletTemplate}
                  values={[user.name, user.createdCheckout]}
                />
              ))}
            />
          </Flex>,
        ]}
      />
    ))}
  </CommonStyles.StyledList>
);
