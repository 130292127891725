import { useParams } from 'react-router-dom';

import { Messages } from 'config';
import { useHeader } from 'context';
import { mapQuery } from 'utils/query';
import { formatPhone } from 'utils/helpers';
import { useAlert, useNavigate, handleMutation } from 'hooks';
import { useDetailsQuery, useDeleteMutation } from 'store/api/supplier';
import {
  Link,
  Details,
  InfoBlock,
  GeneralInfo,
  DeleteDialog,
} from 'components';

export const DetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const { data, ...props } = useDetailsQuery(id!);
  const [deleteSupplier, mutation] = useDeleteMutation();

  handleMutation({
    ...mutation,
    onSuccess: () => {
      showSuccessAlert(`Supplier ${Messages.DELETED_SUCCESSFULLY}`);
      navigate(-1);
    },
  });

  useHeader({ showBackButton: true, pageName: 'Suppliers' });

  const getWarehouses = () =>
    data
      ? data?.warehouses.map((value, index) => ({
          withBorder: false,
          title: `Warehouse ${index + 1} info`,
          rows: [
            [
              {
                col: 3,
                colTablet: 6,
                title: 'Name',
                value: value.name,
              },
              {
                col: 3,
                colTablet: 6,
                title: 'Phone',
                value: formatPhone(value.phone),
              },
              {
                col: 3,
                colTablet: 6,
                title: 'Emergency phone',
                value: formatPhone(value.emergencyPhone),
              },
            ],
            [
              {
                col: 3,
                colTablet: 6,
                title: 'Address',
                value: value.address,
              },
              {
                col: 3,
                colTablet: 6,
                title: 'State ',
                value: value.state,
              },
              {
                col: 3,
                colTablet: 6,
                title: 'ZIP',
                value: value.zip,
              },
              {
                col: 3,
                colTablet: 6,
                title: 'City',
                value: value.city,
              },
            ],
          ],
        }))
      : [];

  return (
    <Details {...mapQuery(props)}>
      {data && (
        <GeneralInfo
          withoutPhoto
          withPlaceholders
          name={data.name}
          stickyWithHeader={false}
          sectionWrapper={InfoBlock}
          onEdit={() => navigate('edit')}
          containerStyles={{ gap: '24px' }}
          titleRightSlot={
            <DeleteDialog
              id={id!}
              name="Supplier"
              title="Delete Supplier"
              action={deleteSupplier}
            />
          }
          sections={[
            {
              rows: [
                [
                  {
                    col: 3,
                    colTablet: 6,
                    colMobile: 6,
                    title: 'Address',
                    value: data.address,
                  },
                  {
                    col: 3,
                    colTablet: 6,
                    colMobile: 6,
                    title: 'State',
                    value: data.state,
                  },
                  {
                    col: 3,
                    colTablet: 6,
                    colMobile: 6,
                    title: 'ZIP',
                    value: data.zip,
                  },
                  {
                    col: 3,
                    colTablet: 6,
                    colMobile: 6,
                    title: 'City',
                    value: data.city,
                  },
                ],
              ],
            },
            {
              withBorder: false,
              title: 'Contact info',
              rows: [
                [
                  {
                    col: 4,
                    colTablet: 4,
                    title: 'Contact name',
                    value: data.contactName,
                  },
                  {
                    col: 4,
                    colTablet: 4,
                    title: 'Contact email',
                    value: (
                      <Link
                        withoutButton
                        text={data.contactEmail}
                        url={`mailto:${data.contactEmail}`}
                      />
                    ),
                  },
                ],
                [
                  {
                    col: 4,
                    colTablet: 4,
                    title: 'Contact mobile',
                    value: formatPhone(data.contactMobile),
                  },
                  {
                    col: 4,
                    colTablet: 4,
                    title: 'Contact landline',
                    value: formatPhone(data.contactLandline),
                  },
                  {
                    col: 4,
                    colTablet: 4,
                    title: 'Contact fax',
                    value: formatPhone(data.contactFax),
                  },
                ],
              ],
            },
            ...getWarehouses(),
          ]}
        />
      )}
    </Details>
  );
};
