import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { mockSettingsApiQuery } from 'store/mockQuery';

import { Devices, Integration } from './types';

const tagType = 'settings';

export const settingsApi = createApi({
  tagTypes: [tagType],
  reducerPath: 'settingsApi',
  baseQuery: mockSettingsApiQuery,
  endpoints: (build) => ({
    devices: build.query<Devices[], void>({
      providesTags: [tagType],
      query: () => ({
        method: 'get',
        url: URL.GET_DEVICES,
      }),
    }),

    integrations: build.query<Integration, void>({
      providesTags: [tagType],
      query: () => ({
        method: 'get',
        url: URL.GET_INTEGRATIONS,
      }),
    }),
  }),
});

export const { useDevicesQuery, useIntegrationsQuery } = settingsApi;

export type { Devices };
