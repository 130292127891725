import { generatePath, useOutletContext } from 'react-router-dom';

import { AppRoutes } from 'config';
import { Payout } from 'store/api/payout';
import { TransactionsList } from 'components';
import { useFilter, useNavigate, useSearchQuery } from 'hooks';

import { OutletContext } from '../../types';
import { getCommonListProps } from '../config';

const { New, Details } = AppRoutes.Profile.Advances;

export const Advances = () => {
  const navigate = useNavigate();
  const { payout, isCompanyOwner } = useOutletContext<OutletContext>();

  const { debounceQuery, ...search } = useSearchQuery();
  const filteredData = useFilter<Payout>({
    query: debounceQuery,
    data: payout?.advances || [],
    paths: ['event.name', 'amount', 'notes'],
  });

  return (
    <TransactionsList
      {...getCommonListProps(filteredData)}
      createPath={New}
      title="Advances"
      listProps={search}
      data={filteredData}
      createTitle={isCompanyOwner ? 'New advances' : undefined}
      onClick={(e, value) =>
        navigate(generatePath(Details, { advanceId: value.id }))
      }
    />
  );
};
