import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { providesList, cacheByIdArg } from 'utils/query-cache';
import { Inventory, InventoryDetailsRequest } from 'store/api/inventory/types';

import { apiQuery } from '../../query';

export const tagType = 'inventory';

export const inventoryApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'inventoryApi',
  endpoints: (build) => ({
    list: build.query<Inventory[], string>({
      providesTags: providesList(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.STORAGE_INVENTORY.replace(':id', id),
      }),
    }),

    details: build.query<Inventory, InventoryDetailsRequest>({
      providesTags: (result, error, arg) =>
        cacheByIdArg(tagType)(result, error, arg.inventoryId),
      query: ({ id, inventoryId }) => ({
        method: 'get',
        url: URL.STORAGE_INVENTORY_DETAILS.replace(':id', id).replace(
          ':inventoryId',
          inventoryId
        ),
      }),
    }),
  }),
});

export const { useListQuery, useDetailsQuery } = inventoryApi;

export type { Inventory };
