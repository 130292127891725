import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components';

import { FormProps } from './types';

export const Form = styled(FormikForm)<FormProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ gap, flex, padding, $hasMinHeight }) => css`
    ${padding &&
    css`
      padding: ${padding};
    `}
    ${typeof gap === 'number' &&
    css`
      gap: ${gap}px;
    `}
    ${typeof flex === 'number' &&
    css`
      flex: ${flex};
    `}
    ${$hasMinHeight &&
    css`
      min-height: 0;
    `}
  `}
`;

export const FormInner = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 16px;
`;

export const FormButtons = styled.div`
  gap: 16px;
  display: flex;
  ${({ theme }) => css`
    ${theme.responsive.isMobile && 'width: 100%;'};

    ${!theme.responsive.isMobile &&
    css`
      button {
        width: 120px;
      }
    `};
  `}
`;
