import { URL } from 'api/constants';
import { MockHandler } from 'store/query';

import { Devices, Integration } from './types';

const mockIntegrations: Integration = {
  shiphub: {
    id: 1,
    login: 'test',
    password: '12355',
    accountNumbers: 123566,
  },
  stamps: {
    id: 2,
    login: 'test',
    password: '12355',
    creditBalance: 100,
    purchaseHistory: [
      {
        id: 1,
        cost: 50,
        paymentMethod: 'CC',
        type: 'Funds Added',
        transactionId: 12351235,
        date: new Date().toISOString(),
      },
      {
        id: 2,
        cost: 550,
        paymentMethod: 'CC',
        type: 'Funds Added',
        transactionId: 12351235,
        date: new Date().toISOString(),
      },
      {
        id: 3,
        cost: 150,
        paymentMethod: 'CC',
        type: 'Funds Added',
        transactionId: 12351235,
        date: new Date().toISOString(),
      },
      {
        id: 4,
        cost: 50,
        paymentMethod: 'CC',
        type: 'Funds Added',
        transactionId: 12351235,
        date: new Date().toISOString(),
      },
    ],
    paymentMethod: [
      {
        id: 1,
        cvv: 123,
        state: 'OT',
        type: 'VISA',
        zip: '124512',
        isActive: true,
        city: 'Chicago',
        nickname: 'test1',
        cardNumber: 123512356,
        addressLineOne: 'test',
        addressLineTwo: 'test 2',
        cardHolderName: 'Jhon Doe',
        expDate: new Date().toISOString(),
      },
      {
        id: 2,
        cvv: 123,
        state: 'OT',
        type: 'VISA',
        zip: '124512',
        isActive: false,
        city: 'Chicago',
        nickname: 'test1',
        cardNumber: 123512356,
        addressLineOne: 'test',
        addressLineTwo: 'test 2',
        cardHolderName: 'Jhon Doe',
        expDate: new Date().toISOString(),
      },
    ],
  },
};

const mockDevices: Devices[] = [
  {
    id: 1,
    email: 'test@clix.com',
    location: 'Some test location',
  },
  {
    id: 2,
    serial: 115235,
    email: 'test@clix.com',
    location: 'Some test location',
  },
  {
    id: 3,
    serial: 52532,
    email: 'test@clix.com',
    location: 'Some test location',
  },
  {
    id: 4,
    serial: 1152526635,
    email: 'test@clix.com',
    location: 'Some test location',
  },
];

export const settingsMockApiHandlers: MockHandler[] = [
  {
    method: 'get',
    url: URL.GET_INTEGRATIONS,
    responseData: mockIntegrations,
  },
  {
    method: 'get',
    url: URL.GET_DEVICES,
    responseData: mockDevices,
  },
];
