import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { addParamsToUrl } from 'utils/query';
import { providesList, invalidatesList } from 'utils/query-cache';

import { apiQuery } from '../../query';

import { PaymentMethod } from './types';

const tagType = 'payment-methods';

export const paymentMethodsApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'paymentMethodsApi',
  endpoints: (build) => ({
    edit: build.mutation({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        data,
        method: 'put',
        url: URL.PAYMENT_METHOD.replace(':id', data.id),
      }),
    }),
    list: build.query<PaymentMethod[], Pick<PaymentMethod, 'status'>>({
      providesTags: providesList(tagType),
      query: (query) => ({
        method: 'get',
        url: addParamsToUrl(URL.PAYMENT_METHODS, query),
      }),
    }),
    saveAll: build.mutation<void, Partial<PaymentMethod>[]>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        method: 'put',
        url: URL.PAYMENT_METHODS,
        data: {
          data: JSON.stringify(data.filter((item) => item.name)),
        },
      }),
    }),
  }),
});

export const { useListQuery, useEditMutation, useSaveAllMutation } =
  paymentMethodsApi;
export type { PaymentMethod };
