import { useRef, useState, RefObject, useCallback } from 'react';
import { useResizeObserver, useDebounceCallback } from 'usehooks-ts';

type Size = {
  width: number;
  height: number;
};

/** Supports both array and object destructing */
type UseElementSizeResult<T extends HTMLElement = HTMLDivElement> = [
  RefObject<T>,
  Size,
] &
  (Size & {
    ref: RefObject<T>;
  });

export const useElementSize = <
  T extends HTMLElement = HTMLDivElement,
>(): UseElementSizeResult<T> => {
  const ref = useRef<T>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const setSizeWrap = useCallback(
    (newSize: Partial<Size>) =>
      setSize({ width: newSize.width || 0, height: newSize.height || 0 }),
    [setSize]
  );

  const onResize = useDebounceCallback(setSizeWrap, 200);

  useResizeObserver<T>({
    ref,
    onResize,
  });

  const result = [ref, size] as unknown as UseElementSizeResult<T>;

  result.ref = result[0];
  result.width = size.width;
  result.height = size.height;

  return result;
};
