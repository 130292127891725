import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { flexCenter } from 'styles';

export const Link = styled(NavLink)<{ disabled?: boolean }>`
  ${flexCenter}
  ${({ theme, disabled }) => css`
    position: relative;
    width: 100%;
    padding: 16px 0;
    transition: ${theme.transition.default};

    path {
      fill: ${theme.config.bottomTab.icon.default};
    }

    &:hover {
      path {
        fill: ${theme.config.bottomTab.icon.active};
      }
    }

    &.active {
      path {
        fill ${theme.config.bottomTab.icon.active}
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 3px;
        background-color: ${theme.config.bottomTab.dash};
        border-radius: 0 0 ${theme.borderRadius} ${theme.borderRadius};
      }
    }

    ${
      disabled &&
      css`
        pointer-events: none;
        path {
          fill: ${theme.config.bottomTab.icon.disabled};
        }
      `
    }
  `}
`;
