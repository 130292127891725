import { Bounce } from 'react-toastify';

import { ToastifyMessageProps } from './types';

export const toastifyParams: ToastifyMessageProps = {
  autoClose: 600,
  draggable: false,
  transition: Bounce,
  closeButton: false,
  hideProgressBar: true,
  position: 'bottom-center',
};
