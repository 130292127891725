import { HalfCircle, FilledCircle } from './styles';

export const radioButtonsByTheme = [
  {
    value: 'dark',
    label: 'Dark mode',
    circle: <FilledCircle filled />,
  },
  {
    value: 'light',
    label: 'Light mode',
    circle: <FilledCircle />,
  },
  {
    value: 'system',
    label: 'System mode',
    circle: <HalfCircle />,
  },
];
