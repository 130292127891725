import { mapQuery } from 'utils/query';
import { useAppSelector } from 'store';
import { Feature, PayoutType } from 'types';
import { formatPrice } from 'utils/helpers';
import { useFilter, useSearchQuery } from 'hooks';
import { selectAuthUser } from 'store/slices/auth';
import { Payout, useListQuery } from 'store/api/payout';
import { Flex, ListCard, ListHeader, PayoutStatusBadge } from 'components';

import * as CommonStyles from '../../styles';

import { CreateBonusDialog } from './create';
import { BonusesProps, CommonListProps } from './types';

export const titles = ['Expense itemized', 'Event name', 'Rep', 'Amount'];

const CommonList = ({
  data,
  eventId,
  isAdvances,
  mapQueryProps,
}: CommonListProps) => {
  const authUser = useAppSelector(selectAuthUser);
  const { debounceQuery, ...searchProps } = useSearchQuery();
  const filteredData = useFilter<Payout>({
    data: data || [],
    query: debounceQuery,
    paths: ['name', 'amount'],
  });

  const canCreateBonus = authUser?.features?.includes(Feature.EVENT_MANAGER);

  return (
    <CommonStyles.StyledList
      {...mapQuery(mapQueryProps)}
      {...searchProps}
      count={filteredData?.length}
      header={<ListHeader titles={titles} template="repeat(5, 1fr)" />}
      searchBarRightSlot={
        canCreateBonus ? (
          <CreateBonusDialog eventId={eventId} isAdvances={isAdvances} />
        ) : undefined
      }
    >
      {filteredData?.map((value) => (
        <ListCard
          padding="16px"
          key={value.id}
          headers={titles}
          values={[
            value.type,
            value.event?.name,
            value.employee.user.name,
            formatPrice(value.amount),
            <PayoutStatusBadge status={value.status} />,
          ]}
        />
      ))}
    </CommonStyles.StyledList>
  );
};

export const Bonuses = ({ event }: BonusesProps) => {
  const { data, ...props } = useListQuery({
    eventId: String(event.id),
    types: JSON.stringify([PayoutType.Advance, PayoutType.Bonus]),
  });

  const commonProps = {
    eventId: event.id,
    mapQueryProps: props,
  };

  return (
    <Flex gap={24} flexDirection="column">
      <CommonList {...commonProps} data={data?.bonuses} />
      <CommonList {...commonProps} isAdvances data={data?.advances} />
    </Flex>
  );
};
