import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { urlPattern, passwordPattern } from './regex';

export const password = Yup.string().trim().min(6).required();

export const confirmPassword = (schema: Yup.StringSchema) =>
  schema.test('passwordRepeat', 'Passwords must match', function (value) {
    return this.parent.password === value;
  });

export const phoneNumber = (name: string) =>
  Yup.string()
    .nullable()
    .test(name, 'Invalid phone number', function (value) {
      return value && value.length > 0 ? isValidPhoneNumber(value) : true;
    });

export const advancedPassword = Yup.string()
  .trim()
  .matches(
    passwordPattern,
    'Password should contain minimum 8 characters with one numeric, one lowercase and one uppercase character'
  )
  .required();

export const url = Yup.string().matches(urlPattern, 'Enter a valid url');

export const passwordValidationRules: Record<
  string,
  (value: string) => boolean
> = {
  '8 characters': (value) => value.length >= 8,
  '1 number': (value) => /^(?=.*[0-9])/.test(value),
  '1 lowercase': (value) => /^(?=.*[a-z])/.test(value),
  '1 uppercase': (value) => /^(?=.*[A-Z])/.test(value),
};

export const validateProducts = (
  fieldName: string = 'products',
  isUnitRequired: boolean = true
) => {
  let unitsSchema = Yup.number()
    .min(0, 'Unit must be equal to or higher than 0')
    .when('maxCount', {
      is: (maxCount?: number) => typeof maxCount === 'number',
      then: (schema) =>
        schema.max(
          Yup.ref('maxCount'),
          'Unit value exceeds the maximum available count'
        ),
    });

  let casesSchema = Yup.number()
    .min(0, 'Cases must be at least 0')
    .nullable()
    .when(['maxCount', 'unitPerCase'], {
      is: (maxCount?: number, unitPerCase?: number) =>
        typeof maxCount === 'number' && typeof unitPerCase === 'number',
      then: (schema) =>
        schema
          .test({
            name: 'cases-max',
            message: 'Total counts greater than the maximum count',
            test(value, context) {
              if (!value) {
                return true;
              }

              const units = context.parent.units || 0;
              const maxCount = context.parent.maxCount || 0;
              const unitPerCase = context.parent.unitPerCase || 0;
              const productCaseCount = (value ?? 0) * unitPerCase;

              return (
                productCaseCount <= maxCount &&
                productCaseCount + units <= maxCount
              );
            },
          })
          .test({
            name: 'total-count-max',
            message: 'Total counts greater than the maximum count',
            test(value, context) {
              if (!value) {
                return true;
              }

              const units = context.parent.units || 0;
              const maxCount = context.parent.maxCount || 0;
              const unitPerCase = context.parent.unitPerCase || 0;
              const totalCount = (value ?? 0) * unitPerCase + units;

              return totalCount <= maxCount;
            },
          }),
    });

  if (isUnitRequired) {
    unitsSchema = unitsSchema.required('Unit is required');
  } else {
    casesSchema = casesSchema.required('Cases are required');
  }

  return {
    [fieldName]: Yup.array()
      .min(1, 'At least one product is required')
      .of(
        Yup.object().shape({
          units: unitsSchema,
          cases: casesSchema,
          maxCount: Yup.number().nullable(),
          unitPerCase: Yup.number().nullable(),
          count: Yup.number()
            .required('Total count is required')
            .min(1, 'Total count must be greater than 0')
            .when('maxCount', {
              is: (maxCount: number) => typeof maxCount === 'number',
              then: (schema) =>
                schema.max(
                  Yup.ref('maxCount'),
                  'Total count exceeds maximum limit'
                ),
            }),
        })
      ),
  };
};

export const getMappedObject = <T extends Record<string, any>>(
  fields: string[],
  value: any
) => {
  const result = {} as T;
  fields.forEach((field) => ((result as Record<any, any>)[field] = value));
  return result;
};

export const getValidations = (fields: string[], isNumber?: boolean) =>
  getMappedObject(
    fields,
    isNumber ? Yup.number().required() : Yup.string().required()
  );

export const validateZipCode = (isZipRequired: boolean = true) => {
  let zipCodeSchema = Yup.string()
    .nullable()
    .test('length', 'Zip code must be 5 or 9 digits', (val) => {
      if (!val) {
        return !isZipRequired;
      }

      return val.length === 5 || val.length === 9;
    });

  if (isZipRequired) {
    zipCodeSchema = zipCodeSchema.required();
  }

  return zipCodeSchema;
};

export const validateCheckoutAmount = (amount: number) =>
  Yup.number().min(1).max(amount).required();

export const validateWebsiteUrl = Yup.string().matches(
  /((https?):\/\/)?(www.)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  'Enter correct website url'
);

export { isValidPhoneNumber };
