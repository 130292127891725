import * as Yup from 'yup';

import { FormValues } from './types';

export const getInitialValues = (eventId?: number): FormValues => ({
  eventId,
  date: '',
  notes: '',
  amount: '',
  employeeId: '',
});

export const validationSchema = Yup.object().shape({
  date: Yup.date().required(),
  eventId: Yup.number().required(),
  employeeId: Yup.number().required(),
  amount: Yup.number().min(1).required(),
  notes: Yup.string().min(5).max(200).required(),
});
