import { PayoutType } from 'types';
import { Payout } from 'store/api/payout';

import { TransformPayoutData } from './types';

export const transformPayoutData = (
  payouts?: Payout[]
): TransformPayoutData => {
  const data: TransformPayoutData = {
    sales: [],
    bonuses: [],
    refunds: [],
    advances: [],
    disputes: [],
    reimbursements: [],
    paymentHistory: [],
  };

  payouts?.forEach((payout) => {
    switch (payout.type) {
      case PayoutType.Bonus:
        data.bonuses?.push(payout);
        break;
      case PayoutType.Refund:
        data.refunds?.push(payout);
        break;
      case PayoutType.Advance:
        data.advances?.push(payout);
        break;
      case PayoutType.Dispute:
        data.disputes?.push(payout);
        break;
      case PayoutType.Expense:
        data.reimbursements?.push(payout);
        break;
      case PayoutType.Earnings:
        data.sales?.push(payout);
        data.paymentHistory?.push(payout);
        break;
      case PayoutType.PrepaidBonus:
        data.bonuses?.push(payout);
        break;
      case PayoutType.PrepaidAdvance:
        data.advances?.push(payout);
        break;
      case PayoutType.Reimbursements:
        data.reimbursements?.push(payout);
        break;
    }
  });

  return data;
};
