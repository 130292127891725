import { useRef, useState, useEffect } from 'react';

export const useOnceLoader = ({
  skip,
  isError,
  isFetching,
  resetTrigger,
  isUninitialized,
}: {
  skip?: boolean;
  isError: boolean;
  resetTrigger?: any;
  isFetching: boolean;
  isUninitialized: boolean;
}) => {
  const lastResultIsError = useRef(false);
  const [isLoading, setLoading] = useState(
    isFetching || (isUninitialized && !skip)
  );

  useEffect(() => {
    if (resetTrigger && !skip) {
      return () => setLoading(true);
    }
  }, [resetTrigger]);

  useEffect(() => {
    if (isUninitialized) {
      return;
    }
    if (isLoading && !isFetching) {
      setLoading(false);
      lastResultIsError.current = isError;
    } else if (isFetching && !isLoading && lastResultIsError.current) {
      lastResultIsError.current = false;
      setLoading(true);
    }
  }, [isFetching, isError, isLoading, isUninitialized]);

  return { isLoading, setLoading };
};
