import styled, { css, createGlobalStyle } from 'styled-components';

import { DrawerProps } from './types';

export const RootStyles = createGlobalStyle<Pick<DrawerProps, 'opened'>>`
  body {
    transition:  background-color 0.3s ease-in-out;
    ${({ theme, opened }) =>
      opened &&
      css`
        background-color: ${theme.colors.black.primary.standard};
      `}
  }
  #root {
    transition: transform 0.3s ease-in-out;
    ${({ opened }) =>
      opened &&
      css`
        overflow: hidden;
        border-radius: 16px;
        transform: scale(0.98);
      `}
  }
`;

export const Overlay = styled.div<Pick<DrawerProps, 'opened'>>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);

  ${({ opened }) =>
    !opened &&
    css`
      display: none;
    `};
`;

export const Container = styled.div<
  Pick<DrawerProps, 'opened' | 'topOffset' | 'overModal'>
>`
  position: fixed;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  transition: top 0.3s ease-in-out;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;

  ${({ theme, opened, overModal, topOffset }) => css`
    background-color: ${theme.config.background};
    padding: ${theme.responsive.isMobile ? 24 : 40}px;

    z-index: ${overModal ? 3000 : 51};

    ${opened &&
    css`
      overflow: auto;
      top: ${topOffset || '40%'};
    `}
  `}
`;
