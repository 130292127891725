import { PopupProps } from 'components';
import { Event, EventReport } from 'store/api/event';

export enum Tab {
  Revenue = 'revenue',
  Expenses = 'expenses',
  // TODO: uncomment when it was ready
  // Received = 'received',
  Inventory = 'inventory',
  Bonuses = 'bonuses',
  Reimbursements = 'reimbursements',
}

export const TabTitles = {
  [Tab.Revenue]: 'Revenue',
  [Tab.Inventory]: 'Inventory',
  // [Tab.Received]: 'Money received',
  [Tab.Bonuses]: 'Bonuses & Advances',
  [Tab.Expenses]: 'Expenses & Cash flow',
  [Tab.Reimbursements]: 'Reimbursements',
};

export interface CommonReportProps {
  data: EventReport[];
}

export interface ExpensesProps extends CommonReportProps {
  event: Event;
}

export interface PopupListProps extends Pick<PopupProps, 'trigger'> {
  count: number;
  headerTitles: string[];
  elements: JSX.Element[];
}
