import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { CommissionSet } from 'types';

import { apiQuery } from '../../query';

import {
  CreateCommissionSetRequest,
  UpdateCommissionSetRequest,
  DailyEmployeeCommissionResponse,
} from './types';

export const tagTypes = ['CommissionLevel'];

export const commissionLevelApi = createApi({
  tagTypes,
  baseQuery: apiQuery,
  reducerPath: 'commissionLevelApi',
  endpoints: (build) => ({
    list: build.query<CommissionSet[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_COMMISSION_SETS,
      }),
    }),

    get: build.query<CommissionSet, string>({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: generatePath(URL.UPDATE_COMMISSION_SET, { id }),
      }),
    }),

    remove: build.mutation<void, string>({
      invalidatesTags: tagTypes,
      query: (id) => ({
        method: 'delete',
        url: generatePath(URL.UPDATE_COMMISSION_SET, { id }),
      }),
    }),

    create: build.mutation<CommissionSet, CreateCommissionSetRequest>({
      invalidatesTags: tagTypes,
      query: (data) => ({
        data,
        method: 'post',
        url: URL.GET_COMMISSION_SETS,
      }),
    }),

    edit: build.mutation<CommissionSet, UpdateCommissionSetRequest>({
      invalidatesTags: tagTypes,
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: generatePath(URL.UPDATE_COMMISSION_SET, { id }),
      }),
    }),

    getDailyEmployeeCommission: build.query<
      DailyEmployeeCommissionResponse,
      string
    >({
      providesTags: tagTypes,
      query: (id) => ({
        method: 'get',
        url: generatePath(URL.GET_DAILY_EMPLOYEE_COMMISSION_LEVEL, { id }),
      }),
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useEditMutation,
  useRemoveMutation,
  useCreateMutation,
  useGetDailyEmployeeCommissionQuery,
} = commissionLevelApi;
