import { useCallback } from 'react';

import { Messages, AppRoutes } from 'config';
import { useCreateMutation } from 'store/api/supplier';
import { useAlert, useNavigate, handleMutation } from 'hooks';

import { Form } from './form';

export const Create = () => {
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [create, mutation] = useCreateMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Supplier ${Messages.ADDED_SUCCESSFULLY}`);
    navigate(AppRoutes.Inventory.Suppliers.Home);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  return (
    <Form
      onSubmit={create}
      initialValues={{
        name: '',
        address: '',
        contactFax: '',
        contactName: '',
        contactEmail: '',
        contactMobile: '',
        contactLandline: '',
        warehouses: [{ name: '' }],
      }}
    />
  );
};
