import Lottie from 'lottie-react';

import terminalApprove from '../../assets/lottie/terminal-approve.json';
import terminalDecline from '../../assets/lottie/terminal-decline.json';

const commonProps = {
  loop: false,
  autoPlay: true,
};

export const TerminalApprove = () => (
  <Lottie {...commonProps} animationData={terminalApprove} />
);

export const TerminalDecline = () => (
  <Lottie {...commonProps} animationData={terminalDecline} />
);
