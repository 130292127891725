import { AuthEmail } from 'types';

import { URL } from '../constants';
import { apiLoaderInstance } from '../instance';

import { Logout } from './types';

export const login = (payload: AuthEmail) => {
  return apiLoaderInstance.post(URL.LOGIN, {
    email: payload.email,
    password: payload.password,
  });
};

export const logout = (data: Logout) => {
  return apiLoaderInstance.post(URL.LOGOUT, data);
};
