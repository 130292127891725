import { useState } from 'react';

import { ButtonColors } from 'types';
import { Icon, Flex, Dialog, Button, ButtonProps } from 'components';

import * as Styled from './styles';
import { EventReport } from './event-report';
import { DailyReport } from './daily-report';
import { CreateReportDialogProps } from './types';

export const CreateReportDialog = ({ event }: CreateReportDialogProps) => {
  const [isDailyOpen, setDailyOpen] = useState(false);
  const [isSummaryOpen, setSummaryOpen] = useState(false);

  const buttons: ButtonProps[] = [
    {
      icon: <Icon.Case />,
      text: 'Daily report',
      onClick: () => setDailyOpen(true),
    },
    {
      text: 'Event summary',
      icon: <Icon.Billing />,
      onClick: () => setSummaryOpen(true),
    },
  ];

  return (
    <>
      <Dialog
        showCloseButton
        title="Create report"
        trigger={
          <Button text="Create report" colorType={ButtonColors.LightBlue} />
        }
      >
        {(close) => (
          <Flex gap={24} fullWidth flexDirection="column">
            {buttons.map(({ onClick, ...props }, i) => (
              <Styled.StyledButton
                {...props}
                key={i}
                fullWidth
                justifyContent="center"
                colorType={ButtonColors.LightBlue}
                onClick={(e) => {
                  close();
                  onClick?.(e);
                }}
              />
            ))}
          </Flex>
        )}
      </Dialog>

      {isDailyOpen && (
        <DailyReport
          event={event}
          opened={isDailyOpen}
          onClose={() => setDailyOpen(false)}
        />
      )}

      {isSummaryOpen && (
        <EventReport
          event={event}
          opened={isSummaryOpen}
          onClose={() => setSummaryOpen(false)}
        />
      )}
    </>
  );
};
