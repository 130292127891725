import { useParams } from 'react-router-dom';

import { useHeader } from 'context';
import { Customers } from 'components';

export const Edit = () => {
  const { id } = useParams();

  useHeader({
    showBackButton: true,
    pageName: 'Edit customer',
  });

  return <Customers.Edit id={id} />;
};
