export const getTitles = (hideDeleteButton?: boolean) => {
  const titles = ['Name', 'Address', 'Phone', 'Username', 'Work hours'];

  if (!hideDeleteButton) {
    titles.push('');
  }

  return titles;
};

export const cellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/3' },
  { row: '3/4', col: '1/2' },
  { row: '3/4', col: '2/3' },
];
