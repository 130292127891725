import { ButtonProps } from 'components/button';
import { ButtonColors, ButtonStyleTypes } from 'types';

import { EventModalProps } from './types';

export const getButtons = ({
  onTextOut,
  onTransfer,
  onOpenTask,
  onStartExam,
}: Pick<
  EventModalProps,
  'onTextOut' | 'onTransfer' | 'onOpenTask' | 'onStartExam'
>) => {
  const commonProps = { styleType: ButtonStyleTypes.Outline };

  const buttons: ButtonProps[] = [];
  if (onTextOut) {
    buttons.push({
      onClick: onTextOut,
      text: 'Text out to DVM',
      colorType: ButtonColors.LightGrey,
      ...commonProps,
    });
  }
  if (onTransfer) {
    buttons.push({
      onClick: onTransfer,
      text: 'Transfer call',
      colorType: ButtonColors.LightGrey,
      ...commonProps,
    });
  }
  if (onOpenTask) {
    buttons.push({
      text: 'Open task',
      onClick: onOpenTask,
      ...commonProps,
    });
  }
  if (onStartExam) {
    buttons.push({
      onClick: onStartExam,
      text: 'Start virtual exam',
      colorType: ButtonColors.LightBlue,
    });
  }

  return buttons;
};
