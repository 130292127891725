import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';

export const Card = styled.div`
  padding: 16px;

  ${({ theme }) => css`
    border-radius: ${theme.borderRadius};
    background-color: ${theme.config.background};
    box-shadow: ${theme.config.fieldCard.shadow};
  `}
`;

export const Heading = styled(Flex)`
  margin-bottom: 16px;
  justify-content: space-between;

  svg path {
    fill: ${({ theme }) => theme.config.common.icon};
  }
`;
