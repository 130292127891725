import { Flex } from 'components/flex';
import { formatNumber } from 'utils/helpers';

import * as Styles from './styles';
import { ChartClientTooltipProps } from './types';

export const ChartClientTooltip = ({
  id,
  name,
  color,
  label,
  value,
  subtitle,
  valueDescription,
  labelDescription,
  isRenderRawValue,
  isReversedValuePosition,
}: ChartClientTooltipProps) => {
  const valueComponent = (
    <Styles.Label>
      <Styles.Span>{`${
        isRenderRawValue ? value : `${formatNumber(value, '0,0.00')}%`
      } `}</Styles.Span>
      {valueDescription}
    </Styles.Label>
  );

  return (
    <Styles.Wrapper>
      <Styles.Header>
        <Styles.Dot bg={color} />
        <Flex column>
          <Styles.Name>{name ?? id}</Styles.Name>
          {subtitle && <Styles.Subtitle>{subtitle}</Styles.Subtitle>}
        </Flex>
      </Styles.Header>

      {isReversedValuePosition && valueComponent}

      <Styles.Label>
        <Styles.Span>{`${label} `}</Styles.Span>
        {labelDescription}
      </Styles.Label>

      {!isReversedValuePosition && valueComponent}
    </Styles.Wrapper>
  );
};

export type { ChartClientTooltipProps };
