import { ButtonColors } from 'types';
import { Icon } from 'components/icon';
import { Button } from 'components/button';

import { CloseButtonProps } from './types';

export const CloseButton = ({ onClose, ...props }: CloseButtonProps) => (
  <Button
    {...props}
    transparent
    padding="0"
    onClick={onClose}
    icon={<Icon.Close />}
    colorType={ButtonColors.BlackWhite}
  />
);
