import { Company } from 'types';
import { AppRoutes } from 'config';
import { DeleteDialog } from 'components';
import { useRemoveMutation } from 'store/api/company';

interface DeleteCompanyDialogProps {
  data: Company;
}

export const DeleteCompanyDialog = ({ data }: DeleteCompanyDialogProps) => {
  const [remove, mutation] = useRemoveMutation();
  return (
    <DeleteDialog
      id={data.id}
      action={remove}
      name={data.name}
      mutation={mutation}
      title="Delete company"
      navigateUrl={AppRoutes.Company.Home}
    />
  );
};
