import styled, { css } from 'styled-components';

import { bodySemibold, captionRegular } from 'styles/mixins/fonts';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  flex: 1 1 auto;

  ${({ theme }) => css`
    border: 1px solid ${theme.config.numberCard.border};
    background-color: ${theme.config.numberCard.bg};
  `}
`;

export const Heading = styled.h4`
  ${bodySemibold};
`;

export const SubHeading = styled.p`
  ${captionRegular};
  color: ${({ theme }) => theme.config.numberCard.subHeading};
`;

export const Number = styled.span`
  font-weight: 700;
  font-size: 16px;
`;
