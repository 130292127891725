import { AvatarColor, ForwardRefType } from 'types';

export enum AvatarPlaceholderType {
  Stethoscope = 'stethoscope',
  UserAvatar = 'userAvatar',
  Hospital = 'hospital',
  GreyIcon = 'greyIcon',
  Default = 'default',
  Photo = 'photo',
  Logo = 'logo',
  Icon = 'icon',
}

export interface AvatarProps extends ForwardRefType<HTMLDivElement> {
  size?: number;
  border?: boolean;
  square?: boolean;
  userName?: string;
  icon?: JSX.Element;
  editMode?: boolean;
  className?: string;
  url?: string | null;
  color?: AvatarColor;
  highlight?: boolean;
  onClick?: () => void;
  borderWidth?: string;
  placeholder?: AvatarPlaceholderType;
}
