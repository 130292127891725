import { useMemo } from 'react';
import { useDebounceValue } from 'usehooks-ts';

import { defaultColors } from 'utils/charts';
import { PieChart } from 'components/pie-chart';
import { isDesktop, useElementSize } from 'hooks';

import * as Styles from './styles';
import { GraphList } from './list';
import { PieChartLegendProps } from './types';

export const PieChartLegend = ({
  data,
  title,
  total,
  titles,
  autosize,
  mobileTitles,
  maxChartSize,
  chartSize = 272,
  valueDescription,
  labelDescription,
  verticalChartSpacing,
  horizontalChartSpacing,
  colors = defaultColors,
}: PieChartLegendProps) => {
  const [containerRef, { width }] = useElementSize();
  const [listRef, { height }] = useElementSize();
  const desktop = isDesktop();

  const [debouncedSize] = useDebounceValue(`${width}x${height}`, 10);
  const hasData = !!data.length;

  const renderSize = useMemo(() => {
    if (!autosize || !desktop || !hasData) {
      return `${chartSize}px`;
    }

    const maxWidth = width - (horizontalChartSpacing ?? 0);
    const maxHeight = height - (verticalChartSpacing ?? 0);
    let size = maxWidth;
    if (size > maxHeight) {
      size = maxHeight;
    }
    if (maxChartSize && size > maxChartSize) {
      size = maxChartSize;
    }
    return `${size}px`;
  }, [
    hasData,
    desktop,
    autosize,
    chartSize,
    maxChartSize,
    debouncedSize,
    verticalChartSpacing,
    horizontalChartSpacing,
  ]);

  return (
    <Styles.Container>
      <Styles.GraphContainer ref={containerRef}>
        <PieChart
          data={data}
          title={title}
          total={total}
          colors={colors}
          containerWidth={renderSize}
          containerHeight={renderSize}
          valueDescription={valueDescription}
          labelDescription={labelDescription}
        />
      </Styles.GraphContainer>

      <GraphList
        data={data}
        titles={titles}
        colors={colors}
        innerRef={listRef}
        mobileTitles={mobileTitles}
      />
    </Styles.Container>
  );
};

export type { PieChartLegendProps };
