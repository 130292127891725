import { getRelease } from './helpers';

const env = process.env;

const buildVersion = env.REACT_APP_CODEBUILD_BUILD_NUMBER;
const serverEnvironment = env.REACT_APP_SERVER_ENV;

const sentry = {
  config: env.REACT_APP_SENTRY,
};

export const Config = {
  /** The current build version of the application. Available in the Cloud. */
  buildVersion,
  /** Describes the server environment (e.g., development, production). */
  serverEnvironment,
  /** URL for ops service. */
  opsURL: env.REACT_APP_OPS_URL!,
  /** Base URL for API. */
  baseURL: env.REACT_APP_BASE_URL!,
  /** URL for socket. */
  socketURL: env.REACT_APP_SOCKET_URL!,
  /** Encryption key for local storage values. */
  cryptoKey: env.REACT_APP_CRYPTO_KEY || 'secret',
  /** The API key used for Google Maps services. */
  googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY,
  /** Enables debugging of HTML/PDF content. */
  isDebugHTMLEnabled: env.REACT_APP_DEBUG_HTML === 'true',
  /** Sentry configuration. */
  sentry: {
    ...sentry,
    ...getRelease(sentry.config, serverEnvironment, buildVersion),
  },
};
