import { Icon } from 'components/icon';
import { Button } from 'components/button';
import { ButtonColors, ButtonStyleTypes } from 'types';

import { ButtonProps } from '../button';

export const DeleteButton = (props: ButtonProps) => (
  <Button
    transparent
    padding="0"
    tooltip="Delete"
    icon={<Icon.Delete />}
    colorType={ButtonColors.Red}
    styleType={ButtonStyleTypes.Ghost}
    {...props}
  />
);
