import { Icon as IconComponent } from '../icon';

import { AlertProps } from './types';
import { Icon, Title } from './config';
import {
  CloseButton,
  IconContainer,
  TextContainer,
  AlertContainer,
  ContentContainer,
} from './styles';

const Alert = ({ close, message, options }: AlertProps): JSX.Element => {
  const { type, icon, title } = options;

  return (
    <AlertContainer variant={type}>
      <ContentContainer>
        {type && (
          <IconContainer variant={type}>{icon ?? Icon[type]}</IconContainer>
        )}

        <TextContainer>
          {type && <h3>{title ?? Title[type]}</h3>}
          <div data-testid="error-message">{message}</div>
        </TextContainer>
      </ContentContainer>

      <CloseButton type="button" onClick={close}>
        <IconComponent.CloseCircle />
      </CloseButton>
    </AlertContainer>
  );
};

export { Alert };
