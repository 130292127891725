import * as Yup from 'yup';

import { phoneNumber, validateZipCode } from 'utils/validation';

export const validationSchema = Yup.object().shape({
  zip: validateZipCode(false),
  name: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  contactName: Yup.string().required('Contact name is required'),
  contactFax: phoneNumber('contactFax').required('Contact fax is required'),
  contactMobile: phoneNumber('contactMobile').required(
    'Contact mobile is required'
  ),
  contactLandline: phoneNumber('contactLandline').required(
    'Contact landline is required'
  ),
  contactEmail: Yup.string()
    .email('Invalid email format')
    .required('Contact email is required'),
  warehouses: Yup.array().of(
    Yup.object().shape({
      zip: validateZipCode(false),
      name: Yup.string().required('Name is required'),
    })
  ),
});
