import styled, { css } from 'styled-components';

import { styledPaginationItem, styledActivePaginationItem } from '../styles';

export const PaginationListWrapper = styled.div`
  margin-right: 10px;
  display: flex;
`;

export const PaginationList = styled.ul`
  display: flex;
`;

export const PaginationListItem = styled.li<{
  selected?: boolean;
}>`
  ${styledActivePaginationItem};
  cursor: pointer;

  ${({ theme, selected }) => css`
    ${selected &&
    css`
      color: ${theme.config.pagination.number.selected};
    `}
  `};
`;

export const PaginationDots = styled.li`
  ${styledPaginationItem};
  cursor: default;
`;
