import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Badge } from 'components/badge';
import { flexCenter, ellipsisText } from 'styles';
import { Popup as TCPopup } from 'components/popup';
import { bodySemibold, captionRegular } from 'styles/mixins/fonts';

export const OpenButton = styled.button<{ isOpen: boolean }>`
  ${flexCenter};
  position: absolute;
  left: 64px;
  bottom: 68px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 11;

  ${({ theme, isOpen }) => css`
    background-color: ${theme.config.sidebar.default};
    transition: ${theme.transition.default};
    ${!(theme.responsive.isMobile && !isOpen) &&
    `box-shadow: ${theme.config.sidebar.boxShadow};`};

    svg {
      transition: ${theme.transition.default};
      transform: rotate(${isOpen ? -180 : 0}deg);

      path {
        fill: ${theme.config.link.default} !important;
      }
    }

    left: ${!theme.responsive.isDesktop && '-24px'};

    ${() =>
      isOpen &&
      css`
        left: 212px;

        left: ${theme.responsive.isMobile && '204px'};
        bottom: ${theme.responsive.isMobile && '60px'};
        width: ${theme.responsive.isMobile && '40px'};
        height: ${theme.responsive.isMobile && '40px'};
      `}
  `}
`;

export const LogoItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  min-height: 80px;
`;

export const ContextBlock = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  padding: 0 12px;
  align-items: center;

  ${({ theme }) => css`
    transition: ${theme.transition.default};

    ${!theme.responsive.isDesktop && 'padding: 0 24px;'};

    ${theme.responsive.isDesktop &&
    css`
      border-radius: 16px;
    `}
  `}
`;

export const TitleLink = styled.p`
  margin-left: 8px;
  ${bodySemibold};
  ${ellipsisText};
`;

export const UserInfoWrap = styled.div`
  padding: 16px 16px 8px;
  margin-bottom: 8px;
`;

export const ButtonWrap = styled.div`
  margin: 8px 0;

  button {
    justify-content: flex-start;
    height: 32px;
    padding: 0;
    &:hover,
    &:active {
      background-color: transparent;
    }
  }

  span {
    margin-left: 0;
  }
`;

export const ThemeBlock = styled.div`
  ${({ theme }) =>
    !theme.responsive.isDesktop &&
    css`
      padding-top: 8px;
      box-shadow: inset 0px 1px 0px ${theme.config.sidebar.border};
    `}
`;

export const SideBarLink = styled.div<{
  disabled: boolean;
  lastItemAtBottom: boolean;
}>`
  display: flex;
  cursor: pointer;
  min-height: 64px;
  position: relative;
  align-items: center;

  a {
    width: 100%;
  }

  ${({ theme, disabled, lastItemAtBottom }) => css`
    ${lastItemAtBottom &&
    css`
      &:last-child {
        margin-top: auto;
      }
    `}

    ${theme.responsive.isDesktop && 'padding: 0 16px;'};
    margin: ${!theme.responsive.isDesktop && 0};
    transition: ${theme.transition.default};
    a {
      color: ${theme.config.text.primary};
    }

    svg {
      flex-shrink: 0;
      path {
        fill: ${theme.config.sidebar.svg.default};
      }
    }

    &:hover {
      ${ContextBlock} {
        background-color: transparent;
      }

      ${TitleLink} {
        color: ${theme.config.sidebar.svg.selected};
      }

      svg {
        path {
          fill: ${theme.config.sidebar.svg.selected};
        }
      }
    }

    &:active {
      ${ContextBlock} {
        background-color: ${theme.config.sidebar.active};
      }

      svg {
        path {
          fill: ${theme.config.sidebar.svg.selected};
        }
      }
    }

    a.active {
      ${ContextBlock} {
        color: ${theme.config.sidebar.svg.selected};
        background-color: ${theme.config.sidebar.selected};
      }

      svg {
        path {
          fill: ${theme.config.sidebar.svg.selected};
        }
      }

      &:hover {
        ${ContextBlock} {
          background-color: ${theme.config.sidebar.hover};
        }
      }

      &:active {
        ${ContextBlock} {
          background-color: ${theme.config.sidebar.active};
        }
      }
    }

    ${disabled &&
    css`
      pointer-events: none;

      ${ContextBlock} {
        background-color: transparent;
      }

      ${TitleLink} {
        color: ${theme.config.sidebar.disabled};
      }

      svg {
        path {
          fill: ${theme.config.sidebar.disabled};
        }
      }
    `}
  `}
`;

export const SideBarBlock = styled.div<{ isOpen: boolean; isWidget?: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme, isOpen, isWidget }) => css`
    padding-bottom: ${isWidget ? 64 : 16}px;
    background-color: ${theme.config.sidebar.default};
    box-shadow: inset -1px 0px 0px ${theme.config.sidebar.border};
    transition: ${theme.transition.default};

    & ${TitleLink} {
      display: none;
    }

    transform: translateX(${!theme.responsive.isDesktop && '-100%'});

    ${theme.responsive.isDesktop &&
    css`
      width: 80px;
    `}

    ${isOpen &&
    css`
      width: 228px;

      ${ContextBlock} {
        width: 100%;
      }

      & ${TitleLink} {
        display: flex;
      }

      transform: translateX(${!theme.responsive.isDesktop && 0});
    `}
  `}

  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

export const Count = styled(Badge)`
  left: 28px;
  position: absolute;
  top: calc(50% - 22px);
  ${({ theme }) => css`
    color: ${theme.colors.white.primary.standard};
  `}
`;

export const Tooltip = styled.div`
  padding: 8px;
`;

export const Popup = styled(TCPopup)`
  &-content {
    z-index: 1501 !important;
    transform: translateX(-16px);
  }
`;

export const Title = styled.h4`
  ${captionRegular};
  padding: 0 16px;
  ${({ theme }) => css`
    color: ${theme.config.input.placeholder};
  `}
`;

export const Break = styled.div`
  height: 24px;
  margin: 8px 16px;
`;
