/* eslint-disable perfectionist/sort-objects */
export default {
  SOMETHING_WENT_WRONG: 'Ooops, something went wrong',

  DATE_RANGE_ERROR: 'Please select a period not longer than 3 months',
  DATE_REQUIRED_ERROR: 'Please choose a period in the date',
  DATE_NORANGE_ERROR: 'Please choose a period rather than a day',

  ADDED_SUCCESSFULLY: `added successfully`,
  UPDATED_SUCCESSFULLY: 'updated successfully',
  DELETED_SUCCESSFULLY: 'deleted successfully',
  CREATED_SUCCESSFULLY: 'created successfully',
};
