import styled from 'styled-components';

import { DotsColor } from 'types';

export const Loader = styled.div<{ size: number; colorType: DotsColor }>`
  width: 30px;
  height: ${({ size }) => size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3px;
  & > div {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: ${({ theme, colorType }) =>
      theme.config.dotsLoader[colorType]};
    animation: bounce 1400ms ease-in-out infinite;
    animation-fill-mode: both;
  }

  & .bounce1 {
    animation-delay: -0.32s;
  }

  & .bounce2 {
    animation-delay: -0.16s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;
