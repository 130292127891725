import { formatPrice } from 'utils/helpers';
import { useListQuery } from 'store/api/inventory';
import { SelectProductsFieldSection } from 'components';

export const StorageProductsSelectSection = ({
  storageId,
}: {
  storageId: number;
}) => {
  const { data: sourceStorageProducts } = useListQuery(String(storageId));

  return (
    <SelectProductsFieldSection
      isCostPrice
      name="items"
      tabletTemplate="repeat(4, 1fr)"
      products={
        sourceStorageProducts?.map(({ count, product }) => ({
          ...product,
          maxCount: count,
        })) ?? []
      }
      cellsTablet={[
        { row: '1/2', col: '1/5' },
        { row: '2/3', col: '1/5' },
        { row: '3/4', col: '1/2' },
        { row: '3/4', col: '2/3' },
        { row: '3/4', col: '3/4' },
        { row: '3/4', col: '4/5' },
      ]}
      columns={[
        {
          position: 2,
          header: 'Count in storage',
          value: (product) => product?.maxCount,
        },
        {
          position: 3,
          header: 'Price',
          value: (product) => formatPrice(product?.costPrice),
        },
        {
          position: 4,
          header: 'Total',
          value: (_, totalPrice) => formatPrice(totalPrice),
        },
      ]}
    />
  );
};
