import styled, { css } from 'styled-components';

import { flexCenter } from 'styles/mixins/index';

import { Icon } from '../icon';

export const ImageBlock = styled.div<{ clickable?: boolean }>`
  ${flexCenter}

  ${({ theme, clickable }) =>
    clickable &&
    css`
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.colors.black.primary.standard};
        z-index: 10;
        opacity: 0;
        transition: ${theme.transition.default};
      }

      &:hover&:before {
        opacity: 0.2;
      }

      &:active&:before {
        opacity: 0.3;
      }
    `}
`;

export const ImageView = styled.img<{ isLoaded: boolean }>`
  width: 100%;
  height: 100%;

  ${({ isLoaded }) => css`
    display: ${isLoaded ? 'none !important' : 'flex'};
  `}
`;

export const Loader = styled(Icon.Loader)`
  ${({ theme }) => css`
    svg {
      path {
        fill: ${theme.config.text.primary};
      }
    }
  `}
`;
