import styled, { css } from 'styled-components';

import { flexCenter } from 'styles';
import { messagesRegular } from 'styles/mixins/fonts';
import { EllipsisText } from 'components/ellipsis-text';

import { IconCardStyles } from './types';

export const IconContainer = styled.div<IconCardStyles>`
  ${flexCenter};
  width: 56px;
  height: 56px;
  border-radius: 16px;
  ${({ icon, background }) => css`
    background-color: ${background};
    svg path {
      fill: ${icon};
    }
  `}
`;

export const Title = styled(EllipsisText)`
  ${messagesRegular};
  color: ${({ theme }) => theme.colors.grey.primary.standard};
`;
