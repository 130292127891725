import { PaymentMethod } from 'types';
import { capitalize } from 'utils/string';
import { formatPrice } from 'utils/helpers';
import { TemplateList } from 'components/transactions-list';

import { PaymentMethodsListProps } from './types';

export const PaymentMethodsList = ({ data }: PaymentMethodsListProps) => {
  return (
    <TemplateList<PaymentMethod>
      data={data}
      title="Payment Methods"
      render={(value) => [capitalize(value.type), formatPrice(value.amount)]}
    />
  );
};
