import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { EllipsisText } from 'components/ellipsis-text';
import { bodySemibold, captionRegular } from 'styles/mixins/fonts';

export const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  ${({ theme }) => css`
    ${!theme.responsive.isMobile &&
    css`
      width: 224px;
      min-width: 224px;
      box-shadow: inset -1px 0px 0px ${theme.config.subMenu.hover};
    `}

    ${theme.responsive.isMobile &&
    css`
      width: 100%;
    `}
  `}
`;

export const Title = styled.div`
  padding: 8px 16px;
  height: 32px;

  ${captionRegular};

  ${({ theme }) => css`
    background: ${theme.config.subMenu.title.bg};
    color: ${theme.config.subMenu.title.color};
    box-shadow: inset 0px -1px 0px ${theme.config.subMenu.hover};
  `}
`;

export const TextLink = styled(EllipsisText)`
  ${bodySemibold};
`;

export const SubLink = styled(NavLink)<{ disabled?: boolean }>`
  ${bodySemibold};
  display: flex;
  height: 48px;
  padding: 12px 16px;
  user-select: none;

  ${({ theme, disabled }) => css`
    transition: ${theme.transition.default};
    color: ${theme.config.text.primary};
    box-shadow: inset 0px -1px 0px ${theme.config.subMenu.hover};

    &:hover {
      background-color: ${theme.config.subMenu.hover};
    }

    &:active {
      background-color: ${theme.config.subMenu.active};
    }

    ${disabled &&
    css`
      pointer-events: none;
      background-color: ${theme.config.subMenu.disabled.bg};
      color: ${theme.config.subMenu.disabled.color};
    `}

    &.active {
      color: ${theme.config.subMenu.selected.text};
      background-color: ${theme.config.subMenu.selected.bg};
    }
  `}
`;

export const MenuButton = styled.div`
  padding: 16px;
`;
