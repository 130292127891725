import { useField } from 'formik';

import { Flex } from 'components/flex';
import { CommonFieldProps } from 'types';
import { controlError } from 'utils/error';
import { RadioGroup } from 'components/radio-button';
import { ErrorMessage } from 'components/error-message';

import { RadioGroupProps } from '../radio-button';

export interface RadioFieldGroupProps
  extends CommonFieldProps,
    Omit<RadioGroupProps, 'value' | 'onChange'> {
  onChange?: RadioGroupProps['onChange'];
}

const RadioFieldGroup = ({
  name,
  label,
  onChange,
  controlErrorLabel = true,
  ...props
}: RadioFieldGroupProps) => {
  const [field, meta, helpers] = useField(name);
  const error = controlError(meta, name, label, controlErrorLabel);
  return (
    <Flex column>
      <RadioGroup
        label={label}
        value={field.value}
        onChange={async (value) => {
          await helpers.setValue(value, true);
          await helpers.setTouched(true, true);
          onChange?.(value);
        }}
        {...props}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Flex>
  );
};

export { RadioFieldGroup };
