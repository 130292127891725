import { MultiValueProps } from 'react-select';
import styled, { DefaultTheme } from 'styled-components';

import { bodyRegular } from 'styles/mixins/fonts';

import { MultiValueWrapper } from '../multi-value-wrapper';

type Props = MultiValueProps<any>;

const Text = styled.div`
  ${bodyRegular};
  color: ${(p) => p.theme.config.select.multiValue.text};
`;

export const MultiValue = (props: Props) => {
  return (
    <MultiValueWrapper {...props}>
      <Text>{props.children}</Text>
    </MultiValueWrapper>
  );
};

export const multiValueStyles = (theme: DefaultTheme) => {
  return {
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',

    padding: '0 2px 0 8px',
    borderRadius: theme.borderRadius,
    backgroundColor: theme.config.select.multiValue.bg,

    "div[role='button']": {
      paddingLeft: '0px',
      backgroundColor: 'transparent',
      '&:hover': { backgroundColor: 'transparent' },
      '&:active': { backgroundColor: 'transparent' },

      svg: {
        width: '14px',
        height: '14px',
        path: { fill: theme.config.select.multiValue.text },
      },
    },
  };
};
