import styled, { css } from 'styled-components';

import { TextColor } from 'types';

export const Container = styled.div<{ iconColor: TextColor }>`
  display: flex;
  margin-top: 8px;
  padding-left: 16px;

  p {
    margin-left: 8px;
  }

  ${({ theme, iconColor }) => css`
    svg {
      flex-shrink: 0;
      path,
      rect {
        fill: ${theme.config.text[iconColor]};
      }
    }
  `}
`;
