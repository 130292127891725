import styled, { css } from 'styled-components';

import { Badge } from 'components/badge';
import { Button } from 'components/button';
import { heading2 } from 'styles/mixins/fonts';
import { EllipsisText } from 'components/ellipsis-text';

export const StyledHeader = styled.header<{ hideAvatar?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  right: 0;
  top: 0;
  height: 64px;
  z-index: 3;

  ${({ theme, hideAvatar }) => css`
    background-color: ${theme.config.background};
    box-shadow: inset 0px -1px 0px ${theme.config.sidebar.border};
    ${theme.responsive.isDesktop && !hideAvatar
      ? 'padding-left: 16px;'
      : 'padding: 0 16px;'};
  `}
`;

export const WrapperAction = styled.div<{ infoWidth: number }>`
  display: flex;
  align-items: center;
  gap: 16px;
  ${({ infoWidth }) => css`
    width: calc(100% - ${infoWidth + 16}px);
  `}
`;

export const WrapperInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLogo = styled.div`
  flex-shrink: 0;
  margin-left: ${({ theme }) => (theme.responsive.isMobile ? 0 : '8px')};
  margin-right: 8px;
`;

export const PageName = styled(EllipsisText)`
  width: 100%;
  ${heading2};
`;

export const BadgeBlock = styled.div`
  display: flex;
  margin-left: auto;

  > * {
    margin-right: 16px;
  }
`;

export const AvatarWrap = styled.div<{ isCompact?: boolean }>`
  padding: 12px 16px;
  cursor: pointer;
  ${({ theme, isCompact }) => css`
    box-shadow: inset 1px 0 0 ${theme.config.sidebar.border};
    width: ${isCompact ? 64 : 200}px;
  `}
`;

export const Count = styled(Badge)`
  margin-left: 8px;
`;

export const Version = styled.span`
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 10px;
  line-height: 12px;
  ${({ theme }) => css`
    color: ${theme.config.input.placeholder};
  `}
`;

export const AccessoryWrap = styled.div`
  display: flex;
  padding: 0 8px;
`;

export const StyledButton = styled(Button)`
  flex-shrink: 0;
`;
