import { components, MultiValueProps } from 'react-select';

export const MultiValueWrapper = ({ children, ...props }: MultiValueProps) => {
  const { index, selectProps } = props;
  const { isOverflow, showAllValues, maxVisibleValues } = selectProps;

  if (
    !maxVisibleValues ||
    !isOverflow ||
    showAllValues ||
    index < maxVisibleValues
  ) {
    return <components.MultiValue {...props}>{children}</components.MultiValue>;
  }

  return null;
};
