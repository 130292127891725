import { useMemo, useState } from 'react';

import { Messages } from 'config';

import { initialErrorValue } from './config';

export const useDateRangeFilterSectionError = (
  fieldName: string = 'startDate'
) => {
  const [isError, setError] = useState(initialErrorValue);

  const error = useMemo(
    () =>
      isError.period
        ? { [fieldName]: Messages.DATE_RANGE_ERROR }
        : isError.noRange
          ? { [fieldName]: Messages.DATE_NORANGE_ERROR }
          : isError.required
            ? { [fieldName]: Messages.DATE_REQUIRED_ERROR }
            : undefined,
    [isError]
  );

  const setRequiredError = () =>
    setError({ ...initialErrorValue, required: true });

  const reset = () => setError(initialErrorValue);

  return { reset, error, setError, setRequiredError };
};
