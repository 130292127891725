import dayjs from 'dayjs';
import { useMemo, useState, useCallback } from 'react';

import { Row } from 'components/grid';
import { Icon } from 'components/icon';
import { Flex } from 'components/flex';
import { Popup } from 'components/popup';
import { ButtonStyleTypes } from 'types';
import { Dialog } from 'components/dialog';
import { Button } from 'components/button';
import { formatPhone } from 'utils/helpers';
import { isMobile as isMobileHook } from 'hooks';
import { EllipsisText } from 'components/ellipsis-text';
import {
  EditButton,
  CloseButton,
  DeleteButton,
} from 'components/specified-buttons';

import * as Styles from './styles';
import { getButtons } from './config';
import { EventApi, InfoProps, EventModalProps } from './types';

const Info = ({ title, value }: InfoProps) => (
  <div>
    <Styles.SemiBold>{title}</Styles.SemiBold>
    <p>{value}</p>
  </div>
);

export const EventModal = ({
  style,
  event,
  onEdit,
  onClose,
  onDelete,
  className,
  onTextOut,
  onTransfer,
  onOpenTask,
  onStartExam,
  isVirtualExam,
}: EventModalProps) => {
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [isDeleteGrouped, setDeleteGrouped] = useState(false);

  const isMobile = isMobileHook();
  const { end, start, title, extendedProps } = event;
  const {
    phone,
    notes,
    client,
    subtitle,
    employee,
    customer,
    isGrouped,
    protocolLink,
    event: extendedEvent,
  } = extendedProps;
  const { name, pets, phone: clientPhone } = client ?? {};

  const { date, time } = useMemo(() => {
    const startDayjs = dayjs(start);
    const endDayjs = dayjs(end);
    return {
      date: startDayjs.format('dddd, MMMM D'),
      time: `${startDayjs.format('h:mm a')} - ${endDayjs.format('h:mm a')}`,
    };
  }, [start, end]);

  const buttons = useMemo(
    () => getButtons({ onTextOut, onTransfer, onOpenTask, onStartExam }),
    [onTextOut, onTransfer, onOpenTask, onStartExam]
  );

  const length = buttons.length;

  const getColProps = useCallback(
    (index: number) => {
      const col = length === 2 ? 6 : 12;
      return {
        col,
        colTablet: col,
        withoutMargin: length <= 2 && !isMobile ? true : index === length - 1,
      };
    },
    [length, isMobile]
  );

  return (
    <>
      <Popup
        removeAutofocus
        open={!isConfirmVisible}
        width={isMobile ? '80vw' : '324px'}
        onClose={isConfirmVisible ? undefined : onClose}
      >
        <Styles.Container style={style} className={className}>
          <Styles.Header>
            <h3>{title}</h3>
            <Styles.Buttons>
              {!isMobile && (
                <>
                  {onEdit && (
                    <EditButton onClick={onEdit} icon={<Icon.EditLine />} />
                  )}
                  {onDelete && (
                    <DeleteButton onClick={() => setConfirmVisible(true)} />
                  )}
                </>
              )}
              <CloseButton onClose={onClose} />
            </Styles.Buttons>
          </Styles.Header>

          {(subtitle || phone || protocolLink) && (
            <Styles.SubtitleContainer>
              {(subtitle || phone) && (
                <Flex column flex={1}>
                  {subtitle && <Styles.SemiBold>{subtitle}</Styles.SemiBold>}
                  {phone && <p>{formatPhone(phone)}</p>}
                </Flex>
              )}

              {protocolLink && (
                <Button
                  transparent
                  padding="0"
                  to={protocolLink}
                  text="View protocol"
                  styleType={ButtonStyleTypes.Ghost}
                />
              )}
            </Styles.SubtitleContainer>
          )}

          <Info title={date} value={time} />

          {(notes || employee || extendedEvent || customer) && (
            <Flex gap={8} flexDirection="column">
              {extendedEvent && (
                <Info title="Event" value={extendedEvent.name} />
              )}
              {customer && (
                <Info
                  title="Customer"
                  value={`${customer?.person?.firstName} ${customer?.person?.lastName}`}
                />
              )}
              {employee && (
                <Info title="Employee" value={employee?.user?.name} />
              )}
              {notes && <Info title="Notes" value={notes} />}
            </Flex>
          )}

          {(name || clientPhone || pets?.length) && (
            <div>
              <Styles.SemiBold>Booked by:</Styles.SemiBold>
              {name && (
                <Styles.InfoRow>
                  <Icon.User />
                  <EllipsisText>{name}</EllipsisText>
                </Styles.InfoRow>
              )}
              {clientPhone && (
                <Styles.InfoRow>
                  <Icon.Phone />
                  <EllipsisText>{formatPhone(clientPhone)}</EllipsisText>
                </Styles.InfoRow>
              )}
              {!!pets?.length &&
                pets.map((pet: string, idx: number) => (
                  <Styles.InfoRow key={idx}>
                    <Icon.Pawprint />
                    <EllipsisText>{pet}</EllipsisText>
                  </Styles.InfoRow>
                ))}
            </div>
          )}

          {!!length && (
            <Row>
              {buttons.map((buttonProps, indx) => (
                <Styles.StyledCol {...getColProps(indx)} key={indx}>
                  <Button fullWidth {...buttonProps} />
                </Styles.StyledCol>
              ))}
            </Row>
          )}
        </Styles.Container>
      </Popup>

      {isConfirmVisible && (
        <Dialog
          open
          confirmText="Delete"
          title="Delete event"
          onClose={() => setConfirmVisible(false)}
          onConfirm={() => onDelete?.(isDeleteGrouped)}
          text={
            <>
              <p>You are going to delete schedule:</p>
              <Styles.SemiBold>{date}</Styles.SemiBold>
              <Styles.SemiBold>{time}</Styles.SemiBold>
              {isGrouped && !isVirtualExam && (
                <Styles.Checkbox
                  checked={isDeleteGrouped}
                  label="Remove recurring events"
                  onChange={() => setDeleteGrouped(!isDeleteGrouped)}
                />
              )}
              <p>Are you sure you want to do that?</p>
            </>
          }
        />
      )}
    </>
  );
};

export type { EventApi };
