import styled, { css } from 'styled-components';

import { captionRegular } from 'styles/mixins/fonts';

export const Container = styled.div`
  top: 0;
  z-index: 10;
  position: sticky;
  padding: 8px 16px;
  ${captionRegular};
  ${({ theme }) => css`
    background: ${theme.config.subMenu.title.bg};
    color: ${theme.config.subMenu.title.color};
    box-shadow:
      inset 0px 1px 0px ${theme.config.border},
      inset 0px -1px 0px ${theme.config.border};
  `}
`;
