import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.config.background};
`;

export const ImageContainer = styled.div<{ background: any }>`
  flex: 1;
  height: 100%;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(p) => `url(${p.background})`};
`;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 16px;
  overflow: auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(p) => (p.theme.responsive.isDesktop ? '56px' : '40px')};
  margin-bottom: ${(p) => (p.theme.responsive.isDesktop ? '64px' : '48px')};
`;

export const FormContainer = styled.div`
  flex: 1;
  margin: 0 auto;
  margin-top: ${(p) => `0 ${p.theme.responsive.isDesktop ? 'auto' : '16px'}`};
  max-width: ${(p) => (p.theme.responsive.isDesktop ? '416px' : 'unset')};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  h1 {
    text-align: left;
    width: 100%;
  }

  h2 {
    text-align: left;
    width: 100%;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
`;
