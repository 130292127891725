import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addSearchParams } from 'utils/query';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import { Supplier, CreateFormValues } from './types';

const tagType = 'Supplier';

export const supplierApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'supplierApi',
  endpoints: (build) => ({
    details: build.query<Supplier, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.SUPPLIER_DETAILS.replace(':id', id),
      }),
    }),

    delete: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.SUPPLIER_DETAILS.replace(':id', id),
      }),
    }),

    create: build.mutation<void, CreateFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        data,
        method: 'post',
        url: URL.SUPPLIER_LIST,
      }),
    }),

    list: build.query<Supplier[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: addSearchParams(URL.SUPPLIER_LIST, params),
      }),
    }),

    edit: build.mutation<void, Supplier>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        data,
        method: 'patch',
        url: URL.SUPPLIER_DETAILS.replace(':id', String(id)),
      }),
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useEditMutation,
  useDeleteMutation,
  useCreateMutation,
} = supplierApi;
