import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';
import { captionRegular } from 'styles/mixins/fonts';

import { StyledSliderProps } from './types';

const getRangeBackground = (
  filled: string,
  standard: string,
  max?: number | string,
  value?: string | number | readonly string[]
) => {
  const onePercentValue = Number(max || 100) / 100;
  const percent = Number(value || 0) / onePercentValue;

  return `linear-gradient(to right, ${filled} 0%, ${filled} ${percent}%, ${standard} ${percent}%, ${standard} 100%);`;
};

export const Slider = styled.input.attrs({ type: 'range' })<StyledSliderProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  height: 4px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  background: ${({ max, color, value, theme }) =>
    getRangeBackground(
      theme.config.button.standard[color].default.bg,
      theme.config.input.range,
      max,
      value
    )};

  &:hover {
    background: ${({ max, color, value, theme }) =>
      getRangeBackground(
        theme.config.button.standard[color].hover.bg,
        theme.config.input.range,
        max,
        value
      )};
  }

  &:active {
    background: ${({ max, color, value, theme }) =>
      getRangeBackground(
        theme.config.button.standard[color].action.bg,
        theme.config.input.range,
        max,
        value
      )};
  }

  &:disabled {
    pointer-events: none;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    ${({ theme, color, withoutThumb }) => {
      if (withoutThumb) {
        return css`
          background: none;
          border: none;
        `;
      }

      return css`
        background: ${theme.config.background};
        border: 2px solid ${theme.config.button.standard[color].default.bg};

        &:hover {
          border-color: ${theme.config.button.standard[color].hover.bg};
        }

        &:active {
          border-color: ${theme.config.button.standard[color].action.bg};
        }
      `;
    }};
  }

  ::-moz-range-thumb {
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    ${({ theme, color }) => css`
      background: ${theme.config.background};
      border: 2px solid ${theme.config.button.standard[color].default.bg};

      &:hover {
        border-color: ${theme.config.button.standard[color].hover.bg};
      }

      &:active {
        border-color: ${theme.config.button.standard[color].action.bg};
      }
    `};
  }
`;

export const MinMax = styled.p`
  white-space: nowrap;
  ${captionRegular};
`;

export const SliderWrap = styled(Flex)`
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
