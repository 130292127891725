import styled, { css } from 'styled-components';

import { ListCard } from 'components/list-card';

export const Footer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.config.settingRow.border};
`;

export const Card = styled(ListCard)`
  ${({ theme, selected }) =>
    selected &&
    css`
      background: ${theme.config.table.default.hover.bg};
      border-color: ${theme.config.table.default.hover.bg};
    `}
`;
