import RcSlider from 'rc-slider';
import styled, { css } from 'styled-components';

import { flexCenter } from 'styles';
import { Flex } from 'components/flex';
import { captionRegular } from 'styles/mixins/fonts';

export const Slider: typeof RcSlider = styled(RcSlider)`
  .rc-slider-dot {
    display: none;
  }

  ${({ theme }) => css`
    .rc-slider-track {
      background-color: ${theme.config.rangeSlider.track};
      border-radius: 4px;
    }

    .rc-slider-rail {
      background-color: ${theme.config.rangeSlider.rail};
      border-radius: 4px;
    }

    .rc-slider-handle {
      border: 4px solid ${theme.config.rangeSlider.handle};
      opacity: 1;

      &:active {
        box-shadow: none;
        border-color: ${theme.config.rangeSlider.handle};
      }
    }

    .rc-slider-mark-text {
      ${captionRegular};
      color: ${theme.config.text.primary};
    }
  `}
`;

export const CustomTooltip = styled(Flex)<{
  isVisible: boolean;
}>`
  ${flexCenter};
  ${captionRegular};
  position: absolute;
  bottom: 16px;
  min-width: 24px;
  padding: 2px 4px;
  border-radius: 4px;

  ${({ theme, isVisible }) => css`
    ${!isVisible && 'display: none;'};
    color: ${theme.config.text.primary};
    background-color: ${theme.config.select.bg};
    box-shadow: ${theme.config.popup.shadow};
  `}
`;
