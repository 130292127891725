import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Provider } from 'react-redux';
// import * as Sentry from '@sentry/react';
import minMax from 'dayjs/plugin/minMax';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import { createRoot } from 'react-dom/client';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isPropValid from '@emotion/is-prop-valid';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import { StyleSheetManager } from 'styled-components';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Provider as AlertProvider } from 'react-alert';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { App } from 'app';
import { store } from 'store';
import { interceptor } from 'api/instance';
import { ThemeWrap, ThemeContextProvider } from 'context';
import { Alert, LoaderRedux, ToastifyMessage } from 'components';

import { GlobalStyles } from './styles';
import reportWebVitals from './reportWebVitals';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

// const sentry = process.env.REACT_APP_SENTRY;
// const { release, environment } = getRelease();

// if (sentry && release) {
//   console.log(`VERSION ${release}`);
//   try {
//     Sentry.init({
//       release,
//       dsn: sentry,
//       environment,
//       tracesSampleRate: 1.0,
//       integrations: [new Sentry.BrowserTracing()],
//       ignoreErrors: [
//         'AxiosError',
//         'TimeoutError',
//         'Loading chunk',
//         "Unexpected token '<'",
//         'request was interrupted by a call to pause',
//         'possibly because the user denied permission',
//         "failed because the user didn't interact with the document first",
//       ],
//     });
//   } catch (error) {
//     console.error(error);
//   }
// }

interceptor(store.dispatch);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <StyleSheetManager
      shouldForwardProp={(propName, elementToBeRendered) =>
        typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
      }
    >
      <ThemeContextProvider>
        <ThemeWrap>
          <AlertProvider
            timeout={5000}
            template={Alert}
            position="top right"
            containerStyle={{ zIndex: 10000 }}
          >
            <LoaderRedux>
              <GlobalStyles />
              <App />
              <ToastifyMessage />
            </LoaderRedux>
          </AlertProvider>
        </ThemeWrap>
      </ThemeContextProvider>
    </StyleSheetManager>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
