export const titles = [
  'Product',
  'SKU',
  'Supplier',
  'Storage',
  'Cost price',
  'Min price',
  'Sale price',
  '1t order',
  '2d order',
  '',
];

export const cellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/2' },
  { row: '2/3', col: '2/3' },
  { row: '3/4', col: '1/2' },
  { row: '3/4', col: '2/3' },
];
