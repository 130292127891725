import { useFormikContext } from 'formik';

import { isDesktop } from 'hooks';
import { makeSelectOptionsFromEntities } from 'utils/helpers';
import { useListQuery as useEventsQuery } from 'store/api/event';
import { CreateStockOrderFormValues } from 'store/api/stock-orders';
import { useListQuery as useStoragesQuery } from 'store/api/storage';
import { useListQuery as useCarriersQuery } from 'store/api/carriers';
import { useListQuery as useSuppliersQuery } from 'store/api/supplier';
import {
  Row,
  Col,
  Icon,
  Input,
  InfoBlock,
  InputField,
  SelectField,
  DatePickerField,
  RadioFieldGroup,
  AddressAutoCompleteInputField,
} from 'components';

export const OrderInfo = () => {
  const desktop = isDesktop();
  const { values } = useFormikContext<CreateStockOrderFormValues>();

  const { data: events } = useEventsQuery();
  const { data: suppliers } = useSuppliersQuery();
  const { data: storages } = useStoragesQuery();
  const { data: carriers } = useCarriersQuery();

  const eventsSelectOptions = makeSelectOptionsFromEntities(events);
  const supplierSelectOptions = makeSelectOptionsFromEntities(suppliers);
  const carrierSelectOptions = makeSelectOptionsFromEntities(carriers);

  return (
    <>
      <Row>
        <Col col={3} colTablet={4}>
          <SelectField
            onlyValue
            name="supplierId"
            label="From (Supplier)"
            options={supplierSelectOptions}
          />
        </Col>

        {desktop && <Icon.ArrowRight style={{ marginTop: '32px' }} />}

        <Col col={3} colTablet={4}>
          <RadioFieldGroup
            label="To"
            name="recipient"
            items={[
              { value: 'event', label: 'Event' },
              { value: 'storage', label: 'Storage' },
            ]}
          />
        </Col>

        <Col col={3} colTablet={4}>
          {values.recipient === 'storage' && (
            <SelectField
              onlyValue
              label="Storage"
              name="storageId"
              options={makeSelectOptionsFromEntities(
                storages?.filter(({ type }) => type === 'storage')
              )}
            />
          )}

          {values.recipient === 'event' && (
            <SelectField
              onlyValue
              label="Event"
              name="storageId"
              options={events?.map(({ name, storage }) => ({
                label: name,
                value: storage?.id,
              }))}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col col={3} colTablet={6}>
          <DatePickerField name="date" label="Date" />
        </Col>

        <Col col={3} colTablet={6}>
          <InputField number name="number" label="Number" />
        </Col>
      </Row>

      <InfoBlock>
        {values.recipient === 'event' && (
          <Row>
            <Col>
              <RadioFieldGroup
                name="orderTo"
                items={[
                  {
                    value: 'storage',
                    label: 'Storage',
                  },
                  {
                    value: 'terminal',
                    label: 'Terminal',
                  },
                ]}
              />
            </Col>
          </Row>
        )}

        {values.recipient === 'event' && (
          <>
            {values.orderTo === 'terminal' && (
              <>
                <Row>
                  <Col col={3} colTablet={6}>
                    <AddressAutoCompleteInputField isAddressAutoFill={false} />
                  </Col>
                </Row>

                <Row>
                  <Col col={3} colTablet={4}>
                    <InputField name="contactName" label="Contact Name" />
                  </Col>

                  <Col col={3} colTablet={4}>
                    <InputField type="tel" name="phone" label="Phone" />
                  </Col>

                  <Col col={3} colTablet={4}>
                    <InputField
                      type="tel"
                      name="emergencyPhone"
                      label="Emergency Phone"
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

        {values.recipient === 'storage' && (
          <Row>
            <Col col={3}>
              <SelectField
                isMulti
                onlyValue
                label="Events"
                name="eventIds"
                options={eventsSelectOptions}
              />
            </Col>
          </Row>
        )}
      </InfoBlock>

      <InfoBlock title="Shipment info">
        <Row>
          <Col col={3} colTablet={6}>
            <SelectField
              onlyValue
              label="Carrier"
              name="carrierId"
              options={carrierSelectOptions}
            />
          </Col>

          <Col col={3} colTablet={6}>
            <Input
              disabled
              label="Link to Carrier Website"
              defaultValue={
                carriers?.find(({ id }) => id === values.carrierId)?.link
              }
            />
          </Col>
        </Row>

        <Row>
          <Col col={3} colTablet={3}>
            <InputField label="Tracking #" name="trackingNumber" />
          </Col>

          <Col col={3} colTablet={3}>
            <DatePickerField
              name="estimatedDeliveryDate"
              label="Estimated Delivery Date"
            />
          </Col>

          <Col col={3} colTablet={3}>
            <DatePickerField name="dateShipped" label="Date Shipped" />
          </Col>

          <Col col={3} colTablet={3}>
            <InputField number decimal name="ltlPrice" label="LTL Price" />
          </Col>
        </Row>
      </InfoBlock>
    </>
  );
};
