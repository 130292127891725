import { diff } from 'deep-object-diff';

import { UnknownObject } from '../types';

export const filterEmptyFields = <T extends Record<string, any>>(
  obj: T
): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj).filter((entry) => Boolean(entry[1]))
  ) as Partial<T>;

export const shallowDifference = <T extends Record<string, any>>(
  obj1: T,
  obj2: T
): UnknownObject =>
  Object.keys(diff(obj1, obj2)).reduce(
    (acc, key) => ({ ...acc, [key]: obj2[key] }),
    {}
  );

export const getValueByPath = (
  obj: { [field: string]: any },
  name: string
): any =>
  name.includes('.')
    ? name
        .split('.')
        .reduce((childObj, fieldName) => childObj?.[fieldName], obj)
    : obj[name];

export const pick = <T extends Record<string, any> = Record<string, any>>(
  values: T,
  fieldsNames: (keyof T)[]
): T =>
  Object.fromEntries(
    fieldsNames
      .map((fieldName) => [fieldName, values[fieldName]])
      .filter((entry) => Boolean(entry[1]))
  );

export const replaceFieldsValue = <T extends UnknownObject>(
  values: T,
  options: { value: any; replacer: any }
) => {
  const payload = { ...values };
  Object.entries(payload).map(([key, value]) => {
    if (value === options.value) {
      payload[key as keyof T] = options.replacer;
    }
  });
  return payload;
};
