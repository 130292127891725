// import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { mockExpensesApiQuery } from 'store/mockQuery';

import {
  Deposit,
  GiftCard,
  DepositFee,
  Chargeback,
  TavlaSummary,
  TavlaPayment,
  RevenueCredit,
  RevenueSummary,
  RevenueDeposit,
  TavlaBackOffice,
  TavlaTransaction,
  DepositTransaction,
} from './types';

const tagTypes = ['expensesApi'];

export const expensesApi = createApi({
  tagTypes,
  reducerPath: 'expensesApi',
  baseQuery: mockExpensesApiQuery,
  endpoints: (build) => ({
    deposits: build.query<Deposit[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_DEPOSITS,
      }),
    }),

    giftCards: build.query<GiftCard[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_GIFT_CARDS,
      }),
    }),

    chargebacks: build.query<Chargeback[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_CHARGEBACKS,
      }),
    }),

    tavlaSummary: build.query<TavlaSummary[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_TAVLA_SUMMARY,
      }),
    }),

    tavlaPayments: build.query<TavlaPayment[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_TAVLA_PAYMENTS,
      }),
    }),

    revenueCredits: build.query<RevenueCredit[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_REVENUE_CREDIT,
      }),
    }),

    revenueSummary: build.query<RevenueSummary[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_REVENUE_SUMMARY,
      }),
    }),

    revenueDeposits: build.query<RevenueDeposit[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_REVENUE_DEPOSITS,
      }),
    }),

    tavlaBackOffice: build.query<TavlaBackOffice[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_TAVLA_BACK_OFFICE,
      }),
    }),

    tavlaTransactions: build.query<TavlaTransaction[], void>({
      providesTags: tagTypes,
      query: () => ({
        method: 'get',
        url: URL.GET_ACCOUNTING_TAVLA_TRANSACTIONS,
      }),
    }),
  }),
});

export const {
  useDepositsQuery,
  useGiftCardsQuery,
  useChargebacksQuery,
  useTavlaSummaryQuery,
  useTavlaPaymentsQuery,
  useRevenueCreditsQuery,
  useRevenueSummaryQuery,
  useRevenueDepositsQuery,
  useTavlaBackOfficeQuery,
  useTavlaTransactionsQuery,
} = expensesApi;

export type {
  Deposit,
  GiftCard,
  DepositFee,
  Chargeback,
  TavlaSummary,
  TavlaPayment,
  RevenueCredit,
  RevenueSummary,
  RevenueDeposit,
  TavlaBackOffice,
  TavlaTransaction,
  DepositTransaction,
};
