import {
  useState,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';

import { HeaderPageProps } from 'components';

interface HeaderContextProps {
  pageState: HeaderPageProps;
  setPageState: (data: HeaderPageProps) => void;
}

export const HeaderContext = createContext<HeaderContextProps>({
  pageState: {},
  setPageState: () => {},
});

export const HeaderContextProvider = ({
  children,
}: PropsWithChildren<HeaderPageProps>) => {
  const [pageState, setPageState] = useState({});

  return (
    <HeaderContext.Provider
      value={{
        pageState,
        setPageState,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = (props: HeaderPageProps) => {
  const { setPageState } = useContext(HeaderContext);
  useEffect(() => {
    setPageState(props);
  }, [
    props.tabs,
    props.count,
    props.badges,
    props.pageName,
    props.backPath,
    props.backState,
    props.showBackButton,
    props.rightAccessory,
  ]);
};
