import { useState } from 'react';

import { DOTS, usePagination } from 'hooks/use-pagination';

import { GoToPageForm } from '../go-to-page-form';

import * as Styles from './styles';
import { PaginationTabletUpProps } from './types';

const PaginationTabletUp = ({
  currentPage,
  onPageChange,
  siblingCount,
  totalPageCount,
  prevPageButton,
  nextPageButton,
  onGoToPageChange,
  onGoToPageSubmit,
}: PaginationTabletUpProps): JSX.Element | null => {
  const [goToPageValue, setGoToPageValue] = useState('');

  const paginationRange = usePagination({
    currentPage,
    siblingCount,
    totalPageCount,
  });

  if (currentPage === 0 || !paginationRange) {
    return null;
  }

  const clearGoToPageValue = (): void => setGoToPageValue('');

  return (
    <>
      <Styles.PaginationListWrapper>
        {prevPageButton}
        <Styles.PaginationList>
          {paginationRange.map((pageNumber, index) => {
            const key = String(pageNumber) + index;

            if (pageNumber === DOTS) {
              return (
                <Styles.PaginationDots key={key}>
                  &hellip;
                </Styles.PaginationDots>
              );
            }

            return (
              <Styles.PaginationListItem
                key={key}
                selected={pageNumber === currentPage}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </Styles.PaginationListItem>
            );
          })}
        </Styles.PaginationList>
        {nextPageButton}
      </Styles.PaginationListWrapper>
      <GoToPageForm
        value={goToPageValue}
        onBlur={clearGoToPageValue}
        onSubmit={(e) => onGoToPageSubmit(e, goToPageValue)}
        onChange={(e) => onGoToPageChange(e, setGoToPageValue)}
      />
    </>
  );
};
export { PaginationTabletUp };
