import { useState, useCallback } from 'react';
import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts';

export const useEllipsis = () => {
  const [ref, setRef] = useState<any | null>(null);
  const [use, setUse] = useState<boolean>(false);

  const deps = [
    ref?.offsetWidth,
    ref?.scrollWidth,
    ref?.offsetHeight,
    ref?.scrollHeight,
  ];

  const handleSize = useCallback(() => {
    const offsetWidth = ref?.offsetWidth || 0;
    const scrollWidth = ref?.scrollWidth || 0;
    const offsetHeight = ref?.offsetHeight || 0;
    const scrollHeight = ref?.scrollHeight || 0;
    const widthRatio = scrollWidth / offsetWidth;
    const heightRatio = scrollHeight / offsetHeight;
    setUse(widthRatio > 1.0 || heightRatio > 1.5);
  }, deps);

  useEventListener('resize', handleSize);

  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, deps);

  return { use, setRef };
};

export default useEllipsis;
