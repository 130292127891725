import styled, { css } from 'styled-components';

import { ColProps } from './types';
import { width, gutter, gutterMobile } from './config';

export const RowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${({ theme }) => (theme.responsive.isMobile ? gutterMobile : gutter)};
  margin-right: -${({ theme }) => (theme.responsive.isMobile ? gutterMobile : gutter)};
`;

const colStyle = (
  col: number,
  offsetLeft?: number,
  offsetRight?: number
): any => css`
  flex: ${width * col}% 0 0;

  ${offsetLeft &&
  css`
    margin-left: ${width * col}%;
  `}

  ${offsetRight &&
  css`
    margin-right: ${width * col}%;
  `}
`;

export const ColWrap = styled.div<ColProps>`
  min-width: 0;
  flex: 100% 0 0;
  margin-bottom: 24px;
  padding-left: ${({ theme }) =>
    theme.responsive.isMobile ? gutterMobile : gutter};
  padding-right: ${({ theme }) =>
    theme.responsive.isMobile ? gutterMobile : gutter};

  ${({
    col,
    theme,
    colMobile,
    colTablet,
    offsetLeftTablet,
    offsetLeftMobile,
    offsetRightTablet,
    offsetRightMobile,
    offsetLeftDesktop,
    offsetRightDesktop,
  }) => css`
    ${theme.responsive.isDesktop &&
    col &&
    css`
      flex: ${width * col}% 0 0;

      ${offsetLeftDesktop &&
      css`
        margin-left: ${width * col}%;
      `}

      ${offsetRightDesktop &&
      css`
        margin-right: ${width * col}%;
      `}
    `}

    ${theme.responsive.isDesktop &&
    col &&
    colStyle(col, offsetLeftDesktop, offsetRightDesktop)}

    ${theme.responsive.isTablet &&
    colTablet &&
    colStyle(colTablet, offsetLeftTablet, offsetRightTablet)}

    ${theme.responsive.isMobile &&
    colMobile &&
    colStyle(colMobile, offsetLeftMobile, offsetRightMobile)}
  `}
`;
