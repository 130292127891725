import styled, { css } from 'styled-components';

import { TextColor } from 'types';
import { Flex } from 'components/flex';
import { flexSpaceBetween } from 'styles';
import { heading3 } from 'styles/mixins/fonts';

import { DialogType, DialogTypeProps } from './types';

export const Container = styled.div<DialogTypeProps>`
  padding: 24px;
  display: flex;
  flex-direction: column;
  ${({ type }) => css`
    align-items: ${type === DialogType.Plain ? 'flex-start' : 'center'};
  `}
`;

export const Header = styled.div<DialogTypeProps>`
  ${heading3};
  ${flexSpaceBetween};
  width: 100%;

  ${({ type }) => css`
    margin-bottom: ${type === DialogType.Plain ? 16 : 8}px;
    ${type === DialogType.Complex &&
    css`
      text-align: center;
    `}
  `}
`;

export const Text = styled.div<DialogTypeProps>`
  width: 100%;
  ${({ type }) => css`
    margin-bottom: ${type === DialogType.Plain ? 40 : 24}px;
    ${type === DialogType.Complex &&
    css`
      text-align: center;
    `}
  `}
`;

export const Icon = styled.div<{ color?: TextColor }>`
  margin-bottom: 16px;

  > svg {
    width: 40px;
    height: 40px;

    & > * {
      fill: ${({ theme, color }) =>
        theme.config.text[color ?? TextColor.Primary]};
    }
  }
`;

export const ButtonsWrapper = styled(Flex)<DialogTypeProps>`
  gap: 24px;
  display: flex;
  width: 100%;

  ${({ type, theme }) => css`
    ${type === DialogType.Plain &&
    theme.responsive.isTabletUp &&
    css`
      justify-content: flex-end;
      > * {
        width: 112px;
      }
    `}

    ${type === DialogType.Complex &&
    css`
      width: 100%;
      flex-direction: column;
    `}
  `}
`;
