import styled, { css } from 'styled-components';

import { EllipsisText } from 'components/ellipsis-text';
import {
  captionRegular,
  captionSemibold,
  messagesSmallSemibold,
} from 'styles/mixins/fonts';

export const Wrapper = styled.div`
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  max-width: 260px;

  ${({ theme }) => css`
    background: ${theme.config.popup.bg};
    border-radius: ${theme.borderRadius};
    box-shadow: ${theme.config.infoBlock.shadow};
  `}
`;

export const Dot = styled.div<{ bg: string }>`
  width: 12px;
  height: 12px;
  min-width: 12px;
  margin-right: 4px;
  border-radius: 50%;
  background: ${(p) => p.bg};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px 6px 6px 6px;
  margin-bottom: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.config.input.border.default};
`;

export const Name = styled.p`
  ${messagesSmallSemibold};
`;

export const Label = styled.p`
  ${captionRegular};
`;

export const Span = styled.span`
  ${captionSemibold};
`;

export const Subtitle = styled(EllipsisText)`
  ${captionRegular};
`;
