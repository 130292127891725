import { urlPattern } from 'utils/regex';

export const protocols = ['https://', 'http://', 'mailto:', 'tel:'];

export const cropUrl = (url: string) => {
  let result = url.slice();

  protocols.forEach((item) => (result = result.replace(item, '')));

  if (result.endsWith('/')) {
    result.slice(0, -1);
  }

  return result;
};

export const getHref = (url: string) => {
  const result = url.slice();

  if (protocols.some((item) => url.includes(item))) {
    return result;
  } else if (result.match(urlPattern)) {
    return `http://${result}`;
  }

  return result;
};
