import { createSelector } from '@reduxjs/toolkit';

import { Roles } from 'types';
import { RootState } from 'store';

import { types } from './types';
import { login, logout } from './thunks';

const authStore = (store: RootState) => store?.auth;

const selectIsLoggedIn = createSelector(
  [authStore],
  (store) => store?.isLoggedIn ?? false
);

const selectAuthError = createSelector([authStore], (store) => store?.error);

const selectAuthRestorePath = createSelector(
  [authStore],
  (store) => store?.path
);

const selectAuthUser = createSelector([authStore], (store) => store?.user);

const selectAuthUserFeatures = createSelector(
  [authStore],
  (store) => store?.user?.features
);

const selectAuthUserRole = createSelector(
  [authStore],
  (store) => store?.user?.role?.name
);

const isAuthCompanyUser = createSelector(
  [authStore],
  (store) => store?.user?.role?.name === Roles.COMPANY_OWNER
);

const selectAuthUserType = createSelector(
  [authStore],
  (store) => store?.user?.userType
);

export {
  login,
  types,
  logout,
  selectAuthUser,
  selectAuthError,
  selectIsLoggedIn,
  isAuthCompanyUser,
  selectAuthUserRole,
  selectAuthUserType,
  selectAuthRestorePath,
  selectAuthUserFeatures,
};
