import * as Yup from 'yup';

import { validateProducts } from 'utils/validation';
import { StockTransferType } from 'store/api/stock-transfers';

export const validationSchema = Yup.object().shape({
  ...validateProducts('items'),
  type: Yup.string().required(),
  date: Yup.string().required('Date is required'),
  sourceStorageId: Yup.number().required('Source storage is required'),
  destinationCompanyId: Yup.number()
    .nullable()
    .when('type', {
      then: (schema) => schema.required('Destination company is required'),
      is: (value: StockTransferType) =>
        value === StockTransferType.ClientToClient,
    }),
  destinationStorageId: Yup.number()
    .nullable()
    .when('type', {
      then: (schema) => schema.required('Destination storage is required'),
      is: (value: StockTransferType) =>
        value !== StockTransferType.ClientToClient,
    }),
});
