import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Messages } from 'config';
import { Details } from 'components';
import { mapQuery } from 'utils/query';
import { getUploadedFile } from 'utils/helpers';
import { ProductFormValues } from 'store/api/product/types';
import { useAlert, useNavigate, handleMutation } from 'hooks';
import { useEditMutation, useDetailsQuery } from 'store/api/product';

import { Form } from './form';

export const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [edit, mutation] = useEditMutation();

  const { data, ...props } = useDetailsQuery(id!);

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Product ${Messages.UPDATED_SUCCESSFULLY}`);
    navigate(-1);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  const getContent = () => {
    if (data) {
      const { supplier, attributeValues, ...restValues } = data;

      return (
        <Form<ProductFormValues>
          data={data}
          onSubmit={edit}
          initialValues={{
            ...restValues,
            supplierId: supplier?.id,
            photo: getUploadedFile(data.photoUrl),
            attributeIds: Array.from(
              new Set(attributeValues.map(({ attributeId }) => attributeId))
            ),
            attributeValueIds: attributeValues.reduce<Record<string, number[]>>(
              (result, current) => {
                if (current.attributeId in result) {
                  result[current.attributeId].push(current.id);
                } else {
                  result[current.attributeId] = [current.id];
                }

                return result;
              },
              {}
            ),
          }}
        />
      );
    }
  };

  return <Details {...mapQuery(props)}>{getContent()}</Details>;
};
