import styled from 'styled-components';

import { Font } from 'styles';

export const CalendarContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  user-select: none;
`;

export const MonthSelectContainer = styled.div`
  width: 100%;
  height: 32px;
  padding: 0 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
    path {
      fill: ${(p) => p.theme.config.calendar.container.primaryText};
    }
  }

  p {
    ${Font.bodySemibold};
    color: ${(p) => p.theme.config.calendar.container.primaryText};
  }
`;

export const WeekDaysContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const WeekDayContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  aspect-ratio: 1.5 / 1;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    ${Font.chatTimeRegular}
    color: ${(p) => p.theme.config.calendar.container.secondaryText};
  }
`;

export const DaysContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DaysRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
