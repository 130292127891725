import { Messages } from 'config';
import { Button } from 'components/button';
import { ButtonStyleTypes } from 'types/enums';
import { Placeholder } from 'components/placeholder';

import { RetryRequestProps } from './types';

const RetryRequest = ({ error, refetch }: RetryRequestProps): JSX.Element => {
  return (
    <Placeholder text={error ?? Messages.SOMETHING_WENT_WRONG}>
      {refetch && (
        <Button
          onClick={refetch}
          text="Reload page"
          styleType={ButtonStyleTypes.Ghost}
        />
      )}
    </Placeholder>
  );
};

export { RetryRequest };
