import { withRef } from 'hoc';
import { isMobile } from 'hooks';
import { ButtonColors } from 'types';
import { Icon } from 'components/icon';
import { Button } from 'components/button';

import { NewEntityButtonProps } from './types';

export const NewEntityButton = ({
  path,
  text,
  ...props
}: NewEntityButtonProps) => {
  const mobile = isMobile();

  return (
    <Button
      text={mobile ? undefined : text}
      colorType={ButtonColors.LightBlue}
      icon={mobile ? <Icon.Plus /> : undefined}
      to={path ? location.pathname + `/${path}` : undefined}
      {...props}
    />
  );
};

export const NewEntityButtonWithRef = withRef(NewEntityButton);
