import { Link } from 'react-router-dom';

import { Icon } from 'components/icon';

import * as Styles from './styles';
import { PlaceholderProps } from './types';

const Placeholder = ({
  text,
  action,
  children,
  className,
  iconContainerStyle,
}: PlaceholderProps): JSX.Element => {
  return (
    <Styles.PlaceholderContainer className={className}>
      <Styles.PlaceholderIconContainer style={iconContainerStyle}>
        <Icon.Placeholder width={256} height={256} />
      </Styles.PlaceholderIconContainer>
      <p>{text}</p>
      {action && (
        <Styles.PlaceholderButton>
          <Link to={action.link}>
            {action.icon}
            <p>{action.text}</p>
          </Link>
        </Styles.PlaceholderButton>
      )}
      {children && <Styles.ChildrenWrapper>{children}</Styles.ChildrenWrapper>}
    </Styles.PlaceholderContainer>
  );
};

export { Placeholder };
