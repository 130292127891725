export const titles = [
  'Name',
  'Employee',
  'Event',
  'Phone',
  'Email',
  'Registered',
  'Birthday',
];

export const cellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/2' },
  { row: '2/3', col: '2/3' },
];
