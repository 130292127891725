import { states } from 'config';

import { SelectField, SelectFieldProps } from '.';

export const StatesSelectField = (props: SelectFieldProps) => (
  <SelectField
    {...props}
    onlyValue
    isSearchable
    label="State"
    placeholder="Select a state"
    options={Object.entries(states).map(([value, label]) => ({
      value,
      label: label.name,
    }))}
  />
);
