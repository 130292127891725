import styled from 'styled-components';

import { bodySemibold } from 'styles/mixins/fonts';
import { DatePickerField } from 'components/fields';

export const Container = styled.div`
  padding: 24px;
  border-radius: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

export const Date: typeof DatePickerField = styled(DatePickerField)`
  margin: 16px 0;

  .react-datepicker {
    padding: 0;
    padding-top: 24px;
    h3 {
      ${bodySemibold};
      top: 0;
      left: 0;
    }
  }
`;
