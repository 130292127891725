import styled, { css } from 'styled-components';

import { Badge } from 'components/badge';
import { GeneralInfo } from 'components/general-info';

export const BadgeContainer = styled.div`
  margin: 0 16px 16px;
`;

export const StyledBadge = styled(Badge)`
  width: 100%;
  justify-content: flex-start;
`;

export const StyledGeneralInfo = styled(GeneralInfo)<{
  withoutPadding?: boolean;
}>`
  ${({ withoutPadding }) =>
    withoutPadding &&
    css`
      padding: unset;
    `}
`;
