import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';
import { bodyRegular } from 'styles/mixins/fonts';

import { SpoilerProps } from './types';

export const Container = styled.div`
  word-break: break-word;
`;

export const Content = styled(Flex)<{
  $maxHeight?: number;
  $transitionDuration: SpoilerProps['transitionDuration'];
}>`
  overflow: hidden;
  flex-direction: column;
  transition-property: max-height;

  ${({ $maxHeight, $transitionDuration }) => css`
    transition-duration: ${$transitionDuration}ms;

    ${$maxHeight &&
    css`
      max-height: ${$maxHeight}px;
    `}
  `}
`;

export const Button = styled.button`
  ${bodyRegular};

  &:hover {
    text-decoration: underline;
  }

  ${({ theme }) => css`
    color: ${theme.config.link.default};

    &:hover {
      color: ${theme.config.link.hover};
    }

    &:active {
      color: ${theme.config.link.action};
    }
  `}
`;
