import { User, Company, IdObject } from 'types';
import { makeSelectOptionsFromOptionsArray } from 'utils/helpers';

import { Product } from '../product/types';

export enum StockTransferStatus {
  Open = 'Open',
  Paused = 'Paused',
  InTransit = 'InTransit',
  Completed = 'Completed',
}

export enum StockTransferType {
  EventToStorage = 'EventToStorage',
  StorageToStorage = 'StorageToStorage',
  StorageToEvent = 'StorageToEvent',
  EventToEvent = 'EventToEvent',
  ClientToClient = 'ClientToClient',
}

export interface StockTransfer extends IdObject {
  date: string;
  createdBy: User;
  companyId: number;
  wholesaleCost: number;
  type: StockTransferType;
  sourceStorage?: Storage;
  status: StockTransferStatus;
  destinationStorage?: Storage;
  destinationCompany?: Company;
  items: {
    cases: number;
    count: number;
    price: number;
    units: number;
    maxCount: number;
    product: Product;
    productId: number;
  }[];
}

export const stockTransferTypeLabel: Record<StockTransferType, string> = {
  [StockTransferType.EventToEvent]: 'Event - Event',
  [StockTransferType.StorageToEvent]: 'Storage - Event',
  [StockTransferType.EventToStorage]: 'Event - Storage',
  [StockTransferType.ClientToClient]: 'Client - Client',
  [StockTransferType.StorageToStorage]: 'Storage - Storage',
};

export const stockTransferTypeOptions = makeSelectOptionsFromOptionsArray(
  Object.values(StockTransferType),
  stockTransferTypeLabel
);

export const stockTransferStatusLabel: Record<StockTransferStatus, string> = {
  [StockTransferStatus.Open]: 'Open',
  [StockTransferStatus.Paused]: 'Paused',
  [StockTransferStatus.Completed]: 'Completed',
  [StockTransferStatus.InTransit]: 'In transit',
};

export const stockTransferStatusOptions = makeSelectOptionsFromOptionsArray(
  Object.values(StockTransferStatus),
  stockTransferStatusLabel
);

export interface StockTransferFormValues
  extends Omit<StockTransfer, 'sourceStorage' | 'destinationStorage'> {
  sourceStorageId: number | null;
  sourceCompanyId?: number | null;
  destinationStorageId: number | null;
  destinationCompanyId?: number | null;
  companyTransferType?: 'event' | 'storage';
}

export type StockTransferListType = 'other' | 'company';

export interface StockTrnasferAcceptRequest
  extends Pick<StockTransfer, 'id'>,
    Pick<StockTransferFormValues, 'destinationStorageId'> {
  eventId?: number;
}
