import { useMemo, ReactNode } from 'react';
import { AlertInstance, useAlert as useReactAlert } from 'react-alert';

import { AlertOptionsType } from 'types';
import { getErrorMessage } from 'utils/error';

export const useAlert = () => {
  const alert = useReactAlert();

  const data = useMemo(() => {
    const handleError = (error: any): AlertInstance =>
      alert.error(getErrorMessage(error), { timeout: 30000 });

    const showSuccessAlert = (
      message?: ReactNode,
      options?: AlertOptionsType
    ): AlertInstance => alert.success(message, options);

    const showErrorAlert = (
      message?: ReactNode,
      options?: AlertOptionsType
    ): AlertInstance => alert.error(message, { timeout: 30000, ...options });

    return { handleError, showErrorAlert, showSuccessAlert };
  }, [alert]);

  return data;
};
