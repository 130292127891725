import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { Feature } from 'types';
import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { useAppSelector } from 'store';
import { Flex, Tabs, TabsItem } from 'components';
import { selectAuthUserFeatures } from 'store/slices/auth';

const Shipments = () => {
  const authUserFeatures = useAppSelector(selectAuthUserFeatures);

  useHeader({
    pageName: 'Shipments',
  });

  const tabs = useMemo(() => {
    const result: TabsItem[] = [];

    if (authUserFeatures?.includes(Feature.SHIPMENTS)) {
      result.push({
        label: 'Stock orders',
        to: AppRoutes.Shipments.Children.StockOrders.Home,
      });
    }

    if (authUserFeatures?.includes(Feature.EVENT_ORDERS)) {
      result.push({
        label: 'Event orders',
        to: AppRoutes.Shipments.Children.EventOrders.Home,
      });
    }

    return result;
  }, [authUserFeatures]);

  return (
    <Flex column fullWidth>
      {tabs.length && <Tabs items={tabs} style={{ padding: '16px' }} />}

      <Outlet />
    </Flex>
  );
};

export default Shipments;
