import styled from 'styled-components';

import { bodySemibold } from 'styles/mixins/fonts';

export const Wrapper = styled.div`
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;

  svg {
    & > path {
      fill: ${(p) => p.theme.config.text.primary};
    }
  }

  & p,
  & h2 {
    user-select: none;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

export const Title = styled.p`
  ${bodySemibold};
`;

export const Row = styled.div`
  gap: 4px;
  display: flex;
  user-select: none;
  align-items: center;

  & svg {
    flex-shrink: 0;
    cursor: pointer;
    & > path {
      fill: ${(p) => p.theme.config.text.primary};
    }

    &:hover {
      & > path {
        fill: ${(p) => p.theme.config.link.default};
      }
    }

    &:active {
      & > path {
        fill: ${(p) => p.theme.config.link.action};
      }
    }
  }
`;
