import styled, { css } from 'styled-components';

import { CheckboxColor } from 'types';
import { flexCenter } from 'styles/mixins';
import { bodySemibold } from 'styles/mixins/fonts';
import { EllipsisText } from 'components/ellipsis-text';

export const CheckboxLabel = styled.label<{
  block: boolean;
  checked: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  colorType: CheckboxColor;
}>`
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${({ theme }) => css`
    transition: ${theme.transition.default};
  `}

  ${({ theme, block, checked, disabled, colorType, fullWidth }) =>
    block &&
    css`
      min-height: 40px;
      padding: 8px 12px;
      background-color: ${checked
        ? theme.config.checkbox[colorType].checked.box
        : theme.config.checkbox[colorType].default.box};
      border: 1px solid
        ${checked
          ? theme.config.checkbox[colorType].checked.border
          : theme.config.checkbox[colorType].default.border};
      border-radius: ${theme.borderRadius};
      ${disabled && 'pointer-events: none'};
      ${fullWidth && 'width: 100%;'};

      &:hover {
        background-color: ${theme.config.checkbox[colorType].hover.box};
        border-color: ${theme.config.checkbox[colorType].hover.border};
      }

      &:active {
        background-color: ${theme.config.checkbox[colorType].active.box};
        border-color: ${theme.config.checkbox[colorType].active.border};
      }

      ${disabled &&
      css`
        background-color: ${theme.config.checkbox[colorType].disabled.box};
        border-color: ${theme.config.checkbox[colorType].disabled.border};
      `};
    `};
`;

export const CheckboxMark = styled.span<{
  label?: string;
  colorType: CheckboxColor;
}>`
  ${flexCenter};
  position: relative;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 6px;

  ${({ theme, label, colorType }) => css`
    border: 2px solid ${theme.config.checkbox[colorType].default.bg};
    margin-left: 3px;
    margin-right: ${label ? 11 : 3}px;

    > svg {
      opacity: 0;

      path,
      rect {
        fill: ${theme.config.checkbox.svg.default};
      }
    }
  `}
`;

export const StyledIcon = styled.span<{ positionRightIcon?: boolean }>`
  ${flexCenter};
  margin-right: 4px;

  ${({ positionRightIcon }) => css`
    order: ${positionRightIcon ? 1 : 0};
  `}
`;

export const CheckboxText = styled.span`
  cursor: pointer;
  word-break: break-word;
`;

export const StyledCheckbox = styled.input<{
  block: boolean;
  transparent: boolean;
  colorType: CheckboxColor;
}>`
  position: absolute;
  -webkit-appearance: none;
  appearance: none;

  ${({ theme, colorType, transparent }) => css`
    &:checked + ${CheckboxMark} {
      background-color: ${theme.config.checkbox[colorType].default.bg};
      > svg {
        opacity: 1;

        path,
        rect {
          fill: ${transparent
            ? theme.config.checkbox[colorType].checked.box
            : theme.config.checkbox.svg.checked};
        }
      }
    }

    &:hover:not(:checked, :disabled) + ${CheckboxMark} {
      border: 2px solid ${theme.config.checkbox[colorType].hover.bg};

      > svg {
        opacity: 1;

        path,
        rect {
          fill: ${theme.config.checkbox[colorType].hover.bg};
        }
      }
    }

    &:active:not(:checked, :disabled) + ${CheckboxMark} {
      border: 2px solid ${theme.config.checkbox[colorType].active.bg};

      > svg {
        opacity: 1;

        path,
        rect {
          fill: ${theme.config.checkbox[colorType].active.bg};
        }
      }
    }

    &:disabled {
      & ~ ${StyledIcon} {
        cursor: initial;

        > svg path,
        > svg rect {
          fill: ${theme.config.checkbox[colorType].disabled.bg};
        }
      }

      & ~ ${CheckboxText} {
        cursor: initial;
        color: ${theme.config.checkbox[colorType].disabled.bg};
      }

      & ~ ${CheckboxMark} {
        cursor: initial;
        border-color: ${theme.config.checkbox[colorType].disabled.bg};

        > svg path,
        > svg rect {
          fill: ${theme.config.checkbox[colorType].disabled.box};
        }
      }

      &:checked ~ ${CheckboxMark} {
        cursor: initial;
        background-color: ${theme.config.checkbox[colorType].disabled.bg};
      }
    }
  `};
`;

export const Title = styled(EllipsisText)`
  ${bodySemibold};
  margin-bottom: 8px;
`;
