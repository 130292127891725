import Input from 'react-phone-number-input/input';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css, RuleSet } from 'styled-components';

import { captionRegular } from 'styles/mixins/fonts';

import { InputAutofillType, CommonTextareaProps } from './types';

const textarea: RuleSet<any> = css<CommonTextareaProps>`
  width: 100%;
  resize: none;
  overflow: auto;
  box-sizing: border-box;
  outline: none;
  transition: border 150ms linear;
  padding: 12px 16px;

  ${(p) => css`
    color: ${p.theme.config.input.text};

    ${!p.$transparent &&
    css`
      background: ${p.theme.config.input.bg.default};
    `}

    ${p.$withBorder &&
    css`
      border-radius: ${p.theme.borderRadius};
      border: 1px solid
        ${p.error
          ? p.theme.config.input.border.error
          : p.theme.config.input.border.default};

      &:focus {
        border: 1px solid ${p.theme.config.input.border.active};
      }

      &:hover {
        border: 1px solid
          ${p.error
            ? p.theme.config.input.border.errorHover
            : p.theme.config.input.border.hover};
      }

      &:active {
        border: 1px solid ${p.theme.config.input.border.active};
      }

      &:disabled {
        border: 1px solid ${p.theme.config.input.border.disabled};
      }
    `}

    &::placeholder {
      color: ${p.theme.config.input.placeholder};
    }

    &:disabled {
      color: ${p.theme.config.input.text};
      background-color: ${p.theme.config.input.bg.disabled};
    }
  `}
`;

const counter = css`
  position: absolute;
  right: 8px;
  bottom: 8px;
  ${captionRegular};
`;

export const inputBaseCss = css<{
  error?: string;
  large?: boolean;
  lefticon?: boolean;
  righticon?: boolean;
  autofill?: InputAutofillType;
}>`
  width: 100%;
  font-weight: 400;
  box-sizing: border-box;
  outline: none;
  transition: 150ms linear;

  ${(p) => css`
    height: ${p.large ? '48px' : '40px'};
    color: ${p.theme.config.input.text};

    padding: ${`8px 
    ${p.righticon ? '50px' : '16px'} 
    8px 
    ${p.lefticon ? '50px' : '16px'}`};

    background: ${p.theme.config.input.bg.default};
    border-radius: ${p.theme.borderRadius};
    border: 1px solid
      ${p.error
        ? p.theme.config.input.border.error
        : p.theme.config.input.border.default};

    &::placeholder {
      color: ${p.theme.config.input.placeholder};
    }

    &:hover {
      border: 1px solid
        ${p.error
          ? p.theme.config.input.border.errorHover
          : p.theme.config.input.border.hover};
    }

    &:focus {
      border: 1px solid ${p.theme.config.input.border.active};
    }

    &:active {
      border: 1px solid ${p.theme.config.input.border.active};
    }

    &:disabled {
      color: ${p.theme.config.input.text};
      background-color: ${p.theme.config.input.bg.disabled};
      border: 1px solid ${p.theme.config.input.border.disabled};
    }

    ${p.autofill === 'on'
      ? `
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 ${p.large ? '48px' : '40px'} ${
            p.theme.config.input.bg.default
          } inset !important;

          -webkit-text-fill-color: ${p.theme.config.input.text} !important;
          transition: background-color 5000s ease-in-out 0s;
          }
        `
      : ''};
  `}
`;

export const StyledInput = styled.input<{
  error?: string;
  large?: boolean;
  lefticon?: boolean;
  righticon?: boolean;
  autofill?: InputAutofillType;
}>`
  ${inputBaseCss};
`;

export const PhoneInput = styled(Input)<{
  large?: number;
  error?: string;
  lefticon?: number;
  righticon?: number;
  autofill?: InputAutofillType;
}>`
  width: 100%;
  min-width: 120px;
  font-weight: 400;
  box-sizing: border-box;
  outline: none;

  transition: 150ms linear;

  ${(p) => css`
    height: ${p.large ? '48px' : '40px'};
    color: ${p.theme.config.input.text};
    padding: ${`8px ${p.righticon ? '50px' : '16px'} 8px ${
      p.lefticon ? '50px' : '16px'
    }`};
    background: ${p.theme.config.input.bg.default};
    border-radius: ${p.theme.borderRadius};
    border: 1px solid
      ${p.error
        ? p.theme.config.input.border.error
        : p.theme.config.input.border.default};

    &::placeholder {
      color: ${p.theme.config.input.placeholder};
    }

    &:hover {
      border: 1px solid
        ${p.error
          ? p.theme.config.input.border.errorHover
          : p.theme.config.input.border.hover};
    }

    &:focus {
      border: 1px solid ${p.theme.config.input.border.active};
    }

    &:active {
      border: 1px solid ${p.theme.config.input.border.active};
    }

    &:disabled {
      color: ${p.theme.config.input.text};
      background-color: ${p.theme.config.input.bg.disabled};
      border: 1px solid ${p.theme.config.input.border.disabled};
    }

    ${p.autofill === 'on' &&
    css`
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 ${p.large ? '48px' : '40px'}
          ${p.theme.config.input.bg.default} inset !important;

        -webkit-text-fill-color: ${p.theme.config.input.text} !important;
        transition: background-color 5000s ease-in-out 0s;
      }
    `};
  `}
`;

export const Counter = styled.span<{ error?: string }>`
  ${counter};
  color: ${(p) =>
    p.error ? p.theme.config.input.border.error : p.theme.config.input.text};
`;

export const StyledTextareaAutosize = styled(
  TextareaAutosize
)<CommonTextareaProps>`
  ${textarea};
`;

export const TextArea = styled.textarea<CommonTextareaProps>`
  ${textarea};
  min-height: 106px;
`;

export const MaxLengthCounter = styled.span`
  ${counter};
  padding: 2px;
  border-radius: 2px;
  ${({ theme }) => css`
    color: ${theme.config.input.placeholder};
    background-color: ${theme.config.background};
  `}
`;

export const CurrentLengthCount = styled.span<{ hasValue: boolean }>`
  ${({ theme, hasValue }) =>
    hasValue &&
    css`
      color: ${theme.colors.blue.primary.standard};
    `}
`;
