import styled, { css } from 'styled-components';

import { captionRegular } from 'styles/mixins/fonts';

export const Container = styled.div`
  padding: 16px 0 12px;
  max-width: 240px;
`;

export const Title = styled.h4<{ padding?: string }>`
  ${captionRegular};
  ${({ theme, padding }) => css`
    padding: ${padding ?? '0 16px'};
    color: ${theme.config.input.placeholder};
  `}
`;

export const UserWrap = styled.div`
  padding: 8px 16px;
`;

export const ButtonWrap = styled.div`
  padding: 0 16px;
  margin-top: 12px;
`;

export const Break = styled.div`
  height: 24px;
  margin: 8px 16px 16px 16px;
`;
