import dayjs from 'dayjs';

import { URL } from 'api/constants';
import { MockHandler } from 'store/query';

import { Employee } from '../employees';

import { Sales, RefundStatus } from './types';

const mockSalesInfo: Sales = {
  refunds: [
    {
      id: 1,
      tip: 150,
      saleAmount: 100,
      commission: 1200,
      refundAmount: 120,
      date: '2024/06/03',
      status: RefundStatus.Deducted,
      event: 'Some Conference Kansas',
    },
    {
      id: 2,
      saleAmount: 1250,
      commission: 1000,
      refundAmount: 155,
      date: '2024/07/12',
      event: 'Some Conference Kansas',
      status: RefundStatus.NotYetDeducted,
    },
    {
      id: 3,
      commission: 55,
      saleAmount: 598,
      refundAmount: 23,
      date: '2024/07/22',
      status: RefundStatus.Deducted,
      event: 'Pinners Conference Kansas',
    },
  ],

  info: {
    info: {
      sales: 295,
      comission: 15,
      salesPerHour: 12,
      unpaidEvent: 'Topsfield Fair',
    },
    sales: [
      {
        id: 1,
        amount: 120,
        check: 12345,
        date: '2024/06/03',
        paymnetMethod: 'Cash',
        event: 'Oregon State Fair',
      },
      {
        id: 2,
        amount: 240,
        check: 1212345,
        date: '2024/07/03',
        paymnetMethod: 'Cash',
        event: 'Topsfield Fair',
        pdfUrl:
          'https://www.generalblue.com/five-day-appointment-sheet-template/p/t6qy1gt3t/f/printable-five-day-appointment-sheet-template.pdf?v=6bbd91b647ea819b513b8f50978e8de5',
      },
      {
        id: 3,
        amount: 952,
        check: 992545,
        date: '2024/07/21',
        paymnetMethod: 'Cash',
        event: 'Oregon State Fair',
        pdfUrl:
          'https://www.generalblue.com/five-day-appointment-sheet-template/p/t6qy1gt3t/f/printable-five-day-appointment-sheet-template.pdf?v=6bbd91b647ea819b513b8f50978e8de5',
      },
    ],
  },
  events: [
    {
      id: 1,
      title: 'John Doe',
      notes: 'Some test notes',
      start: dayjs().toString(),
      end: dayjs().add(2, 'hour').toString(),
      event: {
        id: 1,
        name: 'Some test event',
      },
      customer: {
        id: 1,
        lastName: 'Doe',
        firstName: 'John',
      },
      employee: {
        id: 1,
        user: { name: 'Robert Fox' } as Employee['user'],
      },
    },
    {
      id: 2,
      title: 'Sales events',
      notes: 'Some test notes',
      start: dayjs().add(1, 'day').toString(),
      end: dayjs().add(1, 'day').add(2, 'hour').toString(),
      event: {
        id: 1,
        name: 'Some test event',
      },
      customer: {
        id: 1,
        lastName: 'Doe',
        firstName: 'John',
      },
      employee: {
        id: 1,
        user: { name: 'Robert Fox' } as Employee['user'],
      },
    },
    {
      id: 3,
      title: 'Weekly meeting',
      notes: 'Some test notes',
      end: dayjs().subtract(2, 'hour').toString(),
      start: dayjs().subtract(3, 'hour').toString(),
      event: {
        id: 1,
        name: 'Some test event',
      },
      customer: {
        id: 1,
        lastName: 'Doe',
        firstName: 'John',
      },
      employee: {
        id: 1,
        user: { name: 'Robert Fox' } as Employee['user'],
      },
    },
  ],
};

export const salesMockApiHandlers: MockHandler[] = [
  {
    method: 'get',
    url: URL.GET_SALES_INFO,
    responseData: mockSalesInfo,
  },
];
