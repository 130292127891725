import { components, ClearIndicatorProps } from 'react-select';

import { Icon } from 'components/icon';

type Props = ClearIndicatorProps<any>;

export const ClearIndicator = ({ children, ...props }: Props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon.CloseCircle className="close-circle-icon" />
    </components.ClearIndicator>
  );
};
