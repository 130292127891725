import { useMemo, useState, useEffect } from 'react';

import { ButtonColors } from 'types';
import { Icon } from 'components/icon';
import { ButtonWithRef } from 'components/button';

import * as Styles from './styles';
import { MenuItem, MenuProps } from './types';

const commonProps = {
  weight: 400,
  padding: '8px',
  fullWidth: true,
  colorType: ButtonColors.BlackWhite,
};

const Menu = ({
  data,
  buttonProps,
  buttonAlignStart,
  keepTooltipInside,
  ...rest
}: MenuProps) => {
  const [optionData, setOptionData] = useState<MenuItem[]>();

  const renderData = useMemo(() => optionData ?? data, [data, optionData]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [optionData]);

  const maxHeight = useMemo(() => {
    let height: number = window.innerHeight / 2;
    if (typeof keepTooltipInside === 'string') {
      const selector = document.querySelector(keepTooltipInside);
      if (selector) {
        height = selector.getBoundingClientRect().height / 2;
      }
    }
    return height;
  }, [keepTooltipInside, window.innerHeight]);

  return (
    <Styles.Popup
      width="178px"
      repositionOnResize
      keepTooltipInside={keepTooltipInside}
      trigger={<ButtonWithRef {...buttonProps} />}
      {...rest}
    >
      {(close: () => void) => (
        <Styles.Container
          maxHeight={maxHeight}
          onClick={(e) => e.stopPropagation()}
        >
          {optionData && (
            <Styles.StyledButton
              justifyContent="flex-start"
              icon={<Icon.ChevronLeft />}
              onClick={() => setOptionData(undefined)}
              {...commonProps}
            />
          )}
          <Styles.List maxHeight={maxHeight}>
            {renderData.map((value, idx) => {
              const { onClick, data: itemData, ...props } = value;

              return (
                <Styles.StyledButton
                  key={`option_${idx}`}
                  rightIcon={itemData ? <Icon.ChevronRight /> : undefined}
                  justifyContent={
                    buttonAlignStart ? 'flex-start' : 'space-between'
                  }
                  onClick={() => {
                    if (itemData) {
                      setOptionData(itemData);
                    } else {
                      onClick?.();
                      setOptionData(undefined);
                      close();
                    }
                  }}
                  {...commonProps}
                  {...props}
                />
              );
            })}
          </Styles.List>
        </Styles.Container>
      )}
    </Styles.Popup>
  );
};

export { Menu };
export type { MenuItem, MenuProps };
