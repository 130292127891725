import * as Yup from 'yup';

import { ScheduledFormProps } from './types';

export const initialValues: ScheduledFormProps = {
  time: undefined,
  date: undefined,
};

export const validationSchema = Yup.object().shape({
  date: Yup.date().required(),
  time: Yup.number().required(),
});
