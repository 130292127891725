import { useParams } from 'react-router-dom';

import { useHeader } from 'context';
import { useNavigate } from 'hooks';
import { mapQuery } from 'utils/query';
import { useDetailsQuery } from 'store/api/product';
import { Details, ProductDetails } from 'components';

import { DeleteProductDialog } from './delete-product-dialog';

export const DetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, ...props } = useDetailsQuery(id!);

  useHeader({ pageName: 'Product', showBackButton: true });

  return (
    <Details {...mapQuery(props)}>
      {data && (
        <ProductDetails
          data={data}
          onEdit={() => navigate('edit')}
          rightSlot={
            <DeleteProductDialog
              id={data.id}
              goBackOnSuccess
              name={data.name}
            />
          }
        />
      )}
    </Details>
  );
};
