import { Formik, FieldArray } from 'formik';

import { api } from 'api';
import { ButtonColors } from 'types';
import { isMobile, useAlert } from 'hooks';
import { Dialog, DialogType } from 'components/dialog';
import { Button, ButtonTypes, ButtonWithRef } from 'components/button';
import {
  Col,
  Row,
  Icon,
  Flex,
  AddButton,
  InputField,
  CommonStyles,
  ErrorMessage,
} from 'components';

import * as Styles from './styles';
import { FormValues, SendReceiptDialogProps } from './types';
import { getInitialValues, validationSchema } from './config';

export const SendReceiptDialog = ({
  data,
  ...buttonProps
}: SendReceiptDialogProps) => {
  const mobile = isMobile();
  const { showSuccessAlert } = useAlert();

  return (
    <Dialog
      showCloseButton
      title="Send Receipt"
      trigger={
        <ButtonWithRef
          fullWidth={mobile}
          text="Send Receipt"
          icon={<Icon.Mail />}
          disabled={!data?.pdfUrl}
          colorType={ButtonColors.LightBlue}
          {...buttonProps}
        />
      }
    >
      {(close, _, ButtonWrap) => (
        <Formik<FormValues>
          validationSchema={validationSchema}
          initialValues={getInitialValues(data)}
          onSubmit={async (values) => {
            const filterEmptyValues = (array?: string[]) =>
              array?.filter(Boolean);
            const response = await api.mail.sendCheckoutMail({
              checkoutId: data!.id,
              emails: filterEmptyValues(values?.emails),
              phoneNumbers: filterEmptyValues(values?.phoneNumbers),
            });

            if (response.status === 200) {
              showSuccessAlert('Your check has been successfully sent');
              close();
            }
          }}
        >
          {({ errors, values, isSubmitting }) => (
            <CommonStyles.Form>
              {errors?.hasContactInfo && (
                <ErrorMessage>{errors.hasContactInfo}</ErrorMessage>
              )}
              <FieldArray
                name="emails"
                render={({ push, remove }) => (
                  <>
                    <Flex gap={16} fullWidth flexDirection="column">
                      {values?.emails?.map((email, index) => (
                        <Flex gap={16} fullWidth key={index}>
                          <InputField
                            label="Email"
                            placeholder="Email"
                            name={`emails.${index}`}
                          />
                          {index > 0 && (
                            <div>
                              <Styles.StyledDeleteButton
                                onClick={() => remove(index)}
                              />
                            </div>
                          )}
                        </Flex>
                      ))}
                    </Flex>
                    <Row>
                      <Col>
                        <AddButton text="Add email" onClick={() => push('')} />
                      </Col>
                    </Row>
                  </>
                )}
              />

              <FieldArray
                name="phoneNumbers"
                render={({ push, remove }) => (
                  <>
                    <Flex gap={16} fullWidth flexDirection="column">
                      {values?.phoneNumbers?.map((phone, index) => (
                        <Flex gap={16} fullWidth key={index}>
                          <InputField
                            number
                            type="tel"
                            label="Phone Number"
                            placeholder="Phone Number"
                            name={`phoneNumbers.${index}`}
                          />

                          {index > 0 && (
                            <div>
                              <Styles.StyledDeleteButton
                                onClick={() => remove(index)}
                              />
                            </div>
                          )}
                        </Flex>
                      ))}
                    </Flex>
                    <Row>
                      <Col>
                        <AddButton text="Add phone" onClick={() => push('')} />
                      </Col>
                    </Row>
                  </>
                )}
              />

              <ButtonWrap type={DialogType.Plain}>
                <Button
                  text="Cancel"
                  onClick={close}
                  colorType={ButtonColors.LightBlue}
                />
                <Button
                  text="Send"
                  disabled={isSubmitting}
                  type={ButtonTypes.Submit}
                />
              </ButtonWrap>
            </CommonStyles.Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
