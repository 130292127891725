import { AppRoutes } from 'config';
import { useHeader } from 'context';
import { useSearchQuery } from 'hooks';
import { mapQuery } from 'utils/query';
import { useListQuery } from 'store/api/product';
import { List, ListHeader, NewEntityButton } from 'components';

import { Card } from './card';
import { titles } from './config';

export const ListPage = () => {
  const { debounceQuery, ...search } = useSearchQuery();

  useHeader({ pageName: 'Products' });

  const { data = [], ...props } = useListQuery({ search: debounceQuery });

  return (
    <List
      {...mapQuery(props)}
      {...search}
      count={data.length}
      header={<ListHeader titles={titles} />}
      mainWrapperStyles={{ paddingBottom: '16px' }}
      searchBarRightSlot={
        <NewEntityButton
          text="New product"
          path={AppRoutes.Inventory.Products.New}
        />
      }
    >
      {data.map((item) => (
        <Card data={item} key={item.id} />
      ))}
    </List>
  );
};
