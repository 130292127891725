import styled, { css } from 'styled-components';

import { flexCenter } from 'styles/mixins/index';
import { bodySemibold } from 'styles/mixins/fonts';

import { ContainerProps } from './types';

const commonStyles = css`
  text-anchor: middle;
  dominant-baseline: central;
`;

export const Container = styled.div<ContainerProps>`
  ${flexCenter}
  ${(p) => css`
    width: ${p.containerWidth};
    height: ${p.containerHeight};
  `}
`;

const total = css`
  font-size: 48px;
  line-height: 64px;
  font-weight: 700;
`;

export const Total = styled.text`
  ${total};
  ${commonStyles};
  fill: ${({ theme }) => theme.config.text.primary};
`;

export const Title = styled.text`
  ${bodySemibold};
  ${commonStyles};
  fill: ${({ theme }) => theme.config.table.head.title};
`;

export const Circle = styled.div`
  ${flexCenter};
  position: relative;
  width: 248px;
  height: 248px;
  border-radius: 50%;
  box-sizing: border-box;
  ${({ theme }) => css`
    background: conic-gradient(${theme.config.graph.gradient});
  `}
`;

export const InnerCircle = styled.div`
  ${flexCenter};
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background: ${({ theme }) => theme.config.background};
`;

export const PlaceholderTitle = styled.p`
  ${bodySemibold};
  color: ${({ theme }) => theme.config.table.head.title};
`;

export const PlaceholderTotal = styled.p`
  ${total};
`;
