import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import { handleMutation } from 'hooks';
import { ButtonStyleTypes } from 'types';
import { Event, useAcceptMutation } from 'store/api/event';
import {
  Row,
  Col,
  Dialog,
  Button,
  DialogType,
  SelectField,
  ButtonTypes,
  ButtonWithRef,
} from 'components';

export const AcceptEventDialog = ({ event }: { event: Event }) => {
  const [accept, acceptMutation] = useAcceptMutation();

  handleMutation({
    ...acceptMutation,
    successMessage: 'Event accepted',
  });

  const locationOptions = event.locations.map((location) => ({
    value: location.id,
    label: location.name || String(location.id),
  }));

  return (
    <Dialog
      title="Accept event"
      trigger={
        <ButtonWithRef text="Accept event" style={{ width: 'fit-content' }} />
      }
    >
      {(close, _, ButtonsComponent) => (
        <Formik
          onSubmit={(values) => accept({ id: event.id, ...values })}
          initialValues={{
            locationIds: [],
          }}
          validationSchema={Yup.object().shape({
            locationIds: Yup.array().min(1),
          })}
        >
          <Form style={{ width: '100%' }}>
            <Row>
              <Col>
                <SelectField
                  isMulti
                  onlyValue
                  label="Locations"
                  name="locationIds"
                  options={locationOptions}
                  placeholder="Select locations"
                />
              </Col>
            </Row>

            <ButtonsComponent type={DialogType.Plain}>
              <Button
                text="Cancel"
                onClick={close}
                styleType={ButtonStyleTypes.Outline}
              />

              <Button text="Confirm" type={ButtonTypes.Submit} />
            </ButtonsComponent>
          </Form>
        </Formik>
      )}
    </Dialog>
  );
};
