import styled, { css } from 'styled-components';

import { flexCenter } from 'styles';
import { Button } from 'components/button';
import { EllipsisText } from 'components/ellipsis-text';
import {
  bodyRegular,
  captionBold,
  bodySemibold,
  captionRegular,
  chatTimeRegular,
  captionSemibold,
} from 'styles/mixins/fonts';

export const Container = styled.div`
  width: 100%;

  --fc-today-bg-color: transparent;
  --fc-event-border-color: transparent;

  ${({ theme }) => css`
    --fc-border-color: ${theme.config.eventCalendar.border};
  `}

  .fc-header-toolbar {
    display: none;
  }

  .fc-col-header-cell-cushion {
    height: 44px;
    padding: 6px 0;
  }

  .fc-timegrid-slot-label-cushion {
    margin-right: 4px;
  }

  .fc-timegrid-slot-label-cushion {
    ${bodyRegular};
  }

  .fc-daygrid-more-link {
    ${({ theme }) =>
      theme.responsive.isMobile &&
      css`
        width: 100%;
      `}
  }

  .fc-timegrid-now-indicator-line {
    border-width: 2px 0 0;
  }

  .fc-daygrid-day-number {
    color: ${({ theme }) => theme.config.text.black};
  }

  .fc-day-today {
    .fc-daygrid-day-number {
      ${bodySemibold};
      color: ${({ theme }) => theme.config.text.blue};
    }
  }

  .fc-timegrid-axis {
    border: none;
  }

  .fc-scrollgrid {
    border: none;
  }

  .fc-timegrid-divider {
    padding: 0;
    border: none;
    background: transparent;
  }

  .fc-event {
    border-radius: 4px;
  }

  .fc-timegrid-event {
    box-shadow: none;
  }

  .fc-view {
    z-index: 1;
  }

  .fc-event-main {
    padding: 0;
    z-index: 1;
  }

  .fc-col-header-cell {
    ${({ theme }) => css`
      a {
        ${bodySemibold};
        color: ${theme.config.text.primary};
      }

      &.fc-day-today {
        a {
          color: ${theme.config.text.blue};
        }
      }
    `}
  }
`;

export const Header = styled.header`
  gap: 16px;
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  ${({ theme }) => css`
    box-shadow: 0px -1px 0px 0px ${theme.config.eventCalendar.border} inset;
  `}
`;

export const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLeft = styled.div`
  gap: 24px;
  display: flex;
  align-items: center;
`;

export const HeaderRight = styled.div`
  ${flexCenter};
  gap: 16px;
`;

export const Event = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const EventContainer = styled.div<{
  allDay: boolean;
  isHorizontal: boolean;
}>`
  ${({ allDay, isHorizontal }) => css`
    height: 100%;
    padding: 2px;
    overflow: hidden;
    ${isHorizontal &&
    css`
      gap: 4px;
      display: flex;
    `}
    ${allDay &&
    css`
      padding: 0 8px;
    `}
  `}
`;

export const EventTitle = styled(EllipsisText)<{
  allDay: boolean;
}>`
  min-height: 16px;
  ${({ allDay }) => css`
    ${allDay ? chatTimeRegular : captionBold};
  `}
`;

export const EventText = styled.p`
  ${captionRegular};
`;

export const EventTextEllipsis = styled(EllipsisText)`
  ${captionRegular};
`;

export const WeekdayLabel = styled.p<{ isMonthView?: boolean }>`
  ${({ isMonthView }) => (isMonthView ? bodyRegular : chatTimeRegular)}
`;

export const DayLabel = styled.p`
  ${captionSemibold};
`;

export const Booked = styled.div<{ textColor?: string }>`
  ${flexCenter};
  width: 16px;
  height: 16px;
  min-width: 16px;
  padding: 0 4px;
  border-radius: 4px;

  ${({ theme, textColor }) => css`
    ${textColor && `background: ${textColor};`};

    p {
      ${chatTimeRegular};
      color: ${theme.config.text.inverse};
    }
  `};
`;

export const DotButton = styled(Button)`
  width: 100%;
  span {
    line-height: unset;
  }
`;
