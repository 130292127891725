export enum BadgeColor {
  SecondaryPigmentIndigo = 'secondaryPigmentIndigo',
  SecondaryCerulean = 'secondaryCerulean',
  TetrieryCerulean = 'tetrieryCerulean',
  SecondaryYellow = 'secondaryYellow',
  TertiaryOrange = 'tertiaryOrange',
  AdditionalGrey = 'additionalGrey',
  SecondaryGreen = 'secondaryGreen',
  TertiaryGreen = 'tertiaryGreen',
  AdditionalRed = 'additionalRed',
  TetrieryBlue = 'tetrieryBlue',
  SecondaryRed = 'secondaryRed',
  Transparent = 'transparent',
  LightGrey = 'lightGrey',
  RedAlert = 'redAlert',
  Between = 'between',
  Yellow = 'yellow',
  Green = 'green',
  Grey = 'grey',
  Blue = 'blue',
  Pink = 'pink',
  Red = 'red',
  Tag = 'tag',
  New = 'new',
}

export enum BadgeBorderRadius {
  Default = '8px',
  Small = '4px',
  Big = '16px',
}

export enum BadgePadding {
  WideMedium = '2px 8px',
  Default = '4px 8px',
  Medium = '2px 4px',
  Big = '8px 16px',
  Small = '0 4px',
}

export enum BadgeCursor {
  Inherit = 'inherit',
  Default = 'default',
  Pointer = 'pointer',
}

export enum ButtonStyleTypes {
  Standard = 'standard',
  Outline = 'outline',
  Ghost = 'ghost',
}

export enum ButtonColors {
  LightCerulean = 'lightCerulean',
  PigmentIndigo = 'pigmentIndigo',
  TetrieryGrey = 'tetrieryGrey',
  LightYellow = 'lightYellow',
  LightPurple = 'lightPurple',
  Transparent = 'transparent',
  BlackWhite = 'blackWhite',
  WhiteBlack = 'whiteBlack',
  LightGreen = 'lightGreen',
  LightBlue = 'lightBlue',
  LightGrey = 'lightGrey',
  WhiteBlue = 'whiteBlue',
  LightRed = 'lightRed',
  Yellow = 'yellow',
  Green = 'green',
  Blue = 'blue',
  Red = 'red',
}

export enum ListCardStyle {
  LightRed = 'lightRed',
  LightPuprle = 'lightPuprle',
  LightCerulean = 'lightCerulean',
  Default = 'default',
  Green = 'green',
  Grey = 'grey',
}

export enum TextColor {
  Secondary = 'secondary',
  Primary = 'primary',
  Inverse = 'inverse',
  Mention = 'mention',
  Green = 'green',
  White = 'white',
  Black = 'black',
  Blue = 'blue',
  Red = 'red',
}

export enum CheckboxColor {
  SecondaryBlue = 'secondaryBlue',
  Green = 'green',
  Blue = 'blue',
  Red = 'red',
}

export enum TabCountColor {
  Blue = 'blue',
  Red = 'red',
}

export enum ErrorColor {
  LightGreen = 'lightGreen',
  LightBlue = 'lightBlue',
  LightRed = 'lightRed',
  Yellow = 'yellow',
  Orange = 'orange',
  Black = 'black',
  Green = 'green',
  Grey = 'grey',
  Blue = 'blue',
  Red = 'red',
}

export enum ErrorPadding {
  Default = '12px 16px',
  Small = '8px 16px',
}

export enum TeamMemberColor {
  Cerulean = '#0BA5D3',
  Purple = '#C15CAB',
  Orange = '#C8791B',
  Yellow = '#DAA403',
  Green = '#01B55B',
  Blue = '#2576FA',
  Red = '#FE4B4B',
}

export enum FileType {
  Image = 'image',
  Video = 'video',
  File = 'file',
}

export enum DotsColor {
  Primary = 'primary',
  Grey = 'grey',
}

export enum AvatarColor {
  Cerulean = 'cerulean',
  Yellow = 'yellow',
  Orange = 'orange',
  Green = 'green',
  Blue = 'blue',
  Pink = 'pink',
}

export enum BackOfficeRoles {
  RESEARCH_MANAGER = 'Research Manager',
  RESEARCH_REP = 'Research Rep',
  ACCOUNTING = 'Accounting',
  CUSTOMER_SERVICE_REP = 'Customer Service Rep',
  ADMIN_REP = 'Admin Rep',
  SALES_REP = 'Sales Rep',
  MANAGER = 'Manager',
}

export enum Roles {
  SITE_ADMIN = 'Site Admin',
  COMPANY_OWNER = 'Company Owner',
  BACKOFFICE = 'Backoffice',
}

export type Role = Roles | BackOfficeRoles;

export enum Feature {
  ANALYTICS = 'analytics',
  USERS = 'users',
  ROLES = 'roles',
  EVENTS = 'events',
  COMPANIES = 'companies',
  CARRIERS = 'carriers',
  ACCOUNTING = 'accounting',
  USER_PROFILE = 'user_profile',
  EVENT_MANAGER = 'event_manager',
  CUSTOMER_SUPPORT = 'customer_support',
  SALES = 'sales',
  REIMBURSEMENTS = 'reimbursements',
  PAYMENT_METHODS = 'payment_methods',
  PRODUCT_TYPES = 'product_types',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  INVENTORY = 'inventory',
  SHIPMENTS = 'shipments',
  EMPLOYEES = 'employees',
  CUSTOMERS = 'customers',
  EVENT_ORDERS = 'event_orders',
  THEMES = 'themes',
  PRICE_APPROVAL = 'price_approval',
}

export enum CommissionType {
  Percent = 'percent',
}

export enum CashCardType {
  Cash = 'cash',
  Out = 'out',
  Balance = 'balance',
}

export enum TotalCardType {
  GrossTotal = 'grossTotal',
  NetTotal = 'netTotal',
  MonthlySales = 'monthlySales',
  YearlySales = 'yearlySales',
}

export enum PaymentMethodType {
  Gift = 'gift',
  Cash = 'cash',
  Check = 'check',
  Credit = 'credit',
}

export enum TitleLevel {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
}

export enum EventType {
  Indoor = 'indoor',
  Outdoor = 'outdoor',
}

export enum EventLocationType {
  Corner = 'corner',
  Endcap = 'endcap',
  Inline = 'inline',
  Island = 'island',
  Sqf = 'sqf',
}

export enum EventOrderStatus {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  EXCEPTION = 'exception',
  IN_TRANSIT = 'inTransit',
  NOT_SHIPPED = 'notShipped',
}

export enum PriceApprovalStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum PayoutStatus {
  Paid = 'paid',
  Pending = 'pending',
  Approved = 'approved',
  InProgress = 'inProgress',
  NotApproved = 'notApproved',
}

export enum PayoutType {
  Fee = 'Fee',
  Bonus = 'Bonus',
  Refund = 'Refund',
  Expense = 'Expense',
  Advance = 'Advance',
  Dispute = 'Dispute',
  Penalty = 'Penalty',
  Earnings = 'Earnings',
  Adjustment = 'Adjustment',
  PrepaidBonus = 'Prepaid Bonus',
  Reimbursements = 'Reimbursements',
  PrepaidAdvance = 'Prepaid Advance',
}

export enum ExpensesInfo {
  Amount = 'Amount',
  Expenses = 'Expenses',
}

export enum ExpensesPaymentMethod {
  Cash = 'cash',
  Credit = 'credit',
}

export enum DepositType {
  Deposit = 'deposit',
  Withdrawals = 'withdrawals',
  ResolvedTransactions = 'resolvedTransactions',
  HeldFunds = 'heldFunds',
}

export enum ChargebackStatus {
  Review = 'underReview',
  ClosedWon = 'closedWon',
  ClosedLost = 'closedLost',
  ActionRequired = 'actionRequired',
}

export enum ChargebackReason {
  Defective = 'defective',
  Duplicate = 'duplicate',
  IncorrectAmount = 'incorrectAmount',
  CancelledService = 'cancelledService',
  GoodsCancelled = 'goodsCancelled',
  GoodsReturned = 'goodsReturned',
  AbsentEnvironment = 'absentEnvironment',
}

export enum SalesCompatitionPosition {
  Second = 2,
  First = 1,
  Third = 3,
}

export enum CardType {
  Visa = 'visa',
  UnionPay = 'unionPay',
  Discover = 'discover',
  Mastercard = 'mastercard',
  AmericanExpress = 'americanExpress',
}

export enum MonthlyTaxStatus {
  Due = 'due',
  Paid = 'paid',
  PartialPaid = 'partialPaid',
}

export enum CustomerStatus {
  New = 'new',
  Existing = 'existing',
}
