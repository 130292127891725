import styled from 'styled-components';

import { List } from 'components/list';
import { Button } from 'components/button';

export const StyledButton = styled(Button)`
  min-height: 88px;
`;

export const StyledList = styled(List)`
  height: unset;
`;
