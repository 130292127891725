import { useCallback } from 'react';

import { Messages, AppRoutes } from 'config';
import { useCreateMutation } from 'store/api/product';
import { useAlert, useNavigate, handleMutation } from 'hooks';

import { Form } from './form';

export const Create = () => {
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [create, mutation] = useCreateMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Product ${Messages.ADDED_SUCCESSFULLY}`);
    navigate(AppRoutes.Inventory.Products.Home);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  return (
    <Form
      onSubmit={create}
      initialValues={{
        sku: '',
        name: '',
        minPrice: undefined,
        costPrice: undefined,
        dailyCountable: false,
        attributeValueIds: {},
        unitPerCase: undefined,
        retailPrice: undefined,
      }}
    />
  );
};
