import { PieTooltipProps, PieCustomLayerProps } from '@nivo/pie';

import { Flex } from 'components/flex';
import { ChartClientTooltip } from 'components/chart-client-tooltip';

import * as Styles from './styles';
import { PieChartProps, PieChartDataProps } from './types';

export const Placeholder = ({
  title,
  total,
}: {
  title?: string;
  total?: string;
}) => {
  return (
    <Styles.Circle>
      <Styles.InnerCircle>
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          {total && <Styles.PlaceholderTotal>{total}</Styles.PlaceholderTotal>}
          {title && <Styles.PlaceholderTitle>{title}</Styles.PlaceholderTitle>}
        </Flex>
      </Styles.InnerCircle>
    </Styles.Circle>
  );
};

export const getTooltip = (
  colors: string[],
  data: PieChartProps['data'],
  labelDescription?: string,
  valueDescription?: string
) => {
  return ({ datum }: PieTooltipProps<PieChartDataProps>) => {
    const index = data.map((item) => item.id).indexOf(datum.id);
    const currentData = data[index] ?? {};
    const color = colors[index >= 0 ? index % colors.length : 0];

    return (
      <ChartClientTooltip
        {...currentData}
        {...datum}
        color={color}
        labelDescription={labelDescription}
        valueDescription={valueDescription}
      />
    );
  };
};

export const getCenteredMetric = (title?: string, total?: string) => {
  return ({
    centerX,
    centerY,
  }: PieCustomLayerProps<PieChartDataProps>): JSX.Element => {
    const hasTotal = total?.length;
    return (
      <>
        {hasTotal && (
          <Styles.Total x={centerX} y={centerY - 12}>
            {total}
          </Styles.Total>
        )}

        {title && (
          <Styles.Title x={centerX} y={centerY + (hasTotal ? 32 : 0)}>
            {title}
          </Styles.Title>
        )}
      </>
    );
  };
};
