import styled, { css } from 'styled-components';

export const InputContainer = styled.div<{ isOpened: boolean }>`
  cursor: pointer;

  ${({ theme, isOpened }) => css`
    ${isOpened &&
    css`
      svg path {
        fill: ${theme.config.input.border.active};
      }
    `}

    input {
      cursor: pointer;

      ${isOpened &&
      css`
        border-color: ${theme.config.input.border.active};

        &:hover {
          border-color: ${theme.config.input.border.active};
        }
      `}
    }
  `}
`;
