import styled, { css } from 'styled-components';

import { flexCenter } from 'styles/mixins/index';
import { bodySemibold } from 'styles/mixins/fonts';

export const Container = styled.div`
  .Toastify__toast-container {
    width: auto;
  }

  .Toastify__toast {
    ${flexCenter};
    min-height: 40px;
    width: max-content;
    padding: 8px 16px;
    box-shadow: none;
    ${({ theme }) => css`
      color: ${theme.config.toastify.text};
      background: ${theme.config.toastify.bg};
      border-radius: ${theme.borderRadius};
    `}
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
    height: auto;
    ${flexCenter};
    ${bodySemibold};
  }
`;
