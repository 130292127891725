import { useNavigate } from 'hooks';
import { formatPhone } from 'utils/helpers';
import { formatTimeData } from 'utils/date';
import { ListCard, EllipsisText, DeleteDialog } from 'components';

import { CardProps } from './types';
import { getTitles, cellsMobile } from './config';

export const Card = ({ data, action, hideDeleteButton }: CardProps) => {
  const navigate = useNavigate();

  const values = [
    <EllipsisText className="semibold">{data.name}</EllipsisText>,
    data.address,
    formatPhone(data.phone),
    data.username,
    typeof data.workHoursFrom === 'number' &&
    typeof data.workHoursTo === 'number'
      ? `${formatTimeData(data.workHoursFrom)} - ${formatTimeData(data.workHoursTo)}`
      : undefined,
  ];

  if (!hideDeleteButton) {
    values.push(
      <DeleteDialog
        id={data.id}
        action={action}
        name={data.name}
        title="Delete stroage"
      />
    );
  }

  return (
    <ListCard
      values={values}
      cellsMobile={cellsMobile}
      headers={getTitles(hideDeleteButton)}
      onClick={() => navigate(String(data.id))}
    />
  );
};
