import * as Yup from 'yup';

import { User } from 'types';
import { getUploadedFile } from 'utils/helpers';
import { CustomerData } from 'store/api/customers/types';
import { phoneNumber, validateZipCode } from 'utils/validation';

import { CustomerFormValues } from './types';

export const customerValidationSchema = Yup.object().shape({
  zip: validateZipCode(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  address: Yup.string().required(),
  lastName: Yup.string().required(),
  firstName: Yup.string().required(),
  phone: phoneNumber('Phone number is invalid').required(),
  email: Yup.string().email('Invalid email address').required(),
  shipments: Yup.array(
    Yup.object().shape({
      phone: phoneNumber('phone'),
      zip: validateZipCode(false),
      emergencyPhone: phoneNumber('emergencyPhone'),
    })
  ),
});

export const getCustomerFormInitialValues = ({
  data,
  authUser,
}: {
  authUser?: User;
  data?: Omit<Partial<CustomerData>, 'event'> & {
    event: Partial<CustomerData['event']>;
  };
}): CustomerFormValues => ({
  zip: data?.person?.zip || '',
  city: data?.person?.city || '',
  state: data?.person?.state || '',
  phone: data?.person?.phone || '',
  email: data?.person?.email || '',
  address: data?.person?.address || '',
  lastName: data?.person?.lastName || '',
  firstName: data?.person?.firstName || '',
  photo: getUploadedFile(data?.person?.photoUrl),
  companyId: data?.companyId ?? authUser?.company?.id,
  eventId: data?.event?.id ? String(data.event.id) : '',
  registered: data?.registered ? new Date(data.registered) : undefined,
  birthday: data?.person?.birthday ? new Date(data.person.birthday) : undefined,
  shipments:
    data?.shipments && data.shipments.length > 0 ? data.shipments : [{}],
  employeeId: data?.employee?.id
    ? String(data.employee.id)
    : authUser?.id
      ? String(authUser.id)
      : '',
});
