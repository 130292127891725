import { useMemo, useState } from 'react';

import { withRef } from 'hoc';
import { InputContainer } from 'components/input-container';

import { RichInputWithRef } from './rich';
import { InputProps, InputOnChangeEventType } from './types';
import {
  Counter,
  TextArea,
  PhoneInput,
  StyledInput,
  MaxLengthCounter,
  CurrentLengthCount,
  StyledTextareaAutosize,
} from './styles';

const Input = ({
  max,
  file,
  name,
  large,
  label,
  value,
  error,
  style,
  number,
  footer,
  testId,
  search,
  decimal,
  counter,
  maxRows,
  tooltip,
  rawInput,
  leftIcon,
  disabled,
  rightIcon,
  className,
  refForward,
  richBounds,
  requiredText,
  type = 'text',
  onHeightChange,
  autofill = 'on',
  onLeftIconClick,
  onRightIconClick,
  withBorder = true,
  placeholder = label,
  transparent = false,
  ...props
}: InputProps): JSX.Element => {
  const [visibleType, setVisibleType] = useState(type);

  const id = useMemo(() => name || label, [label, name]);

  const input = () => {
    if (type === 'textarea-rich') {
      return (
        <RichInputWithRef
          id={id}
          value={value}
          maxRows={maxRows}
          readOnly={disabled}
          bounds={richBounds}
          withBorder={withBorder}
          refForward={refForward}
          onChange={props.onChange}
          placeholder={placeholder}
          transparent={transparent}
          onKeyDown={props.onKeyDown}
          onBlur={
            props.onBlur
              ? (e) => {
                  props.onBlur?.(e);
                }
              : undefined
          }
        />
      );
    }
    if (type === 'textarea') {
      return (
        <>
          <TextArea
            id={id}
            value={value}
            error={error}
            disabled={disabled}
            $withBorder={withBorder}
            placeholder={placeholder}
            $transparent={transparent}
            {...props}
          />
          {props.maxLength && (
            <MaxLengthCounter>
              <CurrentLengthCount hasValue={Boolean(value)}>
                {value?.length ?? 0}
              </CurrentLengthCount>{' '}
              / {props.maxLength}
            </MaxLengthCounter>
          )}
          {counter && <Counter error={error}>{value?.length ?? 0}</Counter>}
        </>
      );
    }

    if (type === 'textarea-autosize') {
      return (
        <StyledTextareaAutosize
          id={id}
          value={value}
          error={error}
          ref={refForward}
          maxRows={maxRows}
          disabled={disabled}
          $withBorder={withBorder}
          placeholder={placeholder}
          $transparent={transparent}
          onHeightChange={onHeightChange}
          {...props}
        />
      );
    }

    if (type === 'tel') {
      return (
        <PhoneInput
          id={id}
          country="US"
          international
          value={value}
          error={error}
          autofill={autofill}
          disabled={disabled}
          withCountryCallingCode
          placeholder={placeholder}
          large={Number(large || 0)}
          onChange={(e: any) => props?.onChange?.(e)}
          lefticon={Number(!!leftIcon || search || 0)}
          righticon={Number(!!leftIcon || search || 0)}
          {...props}
        />
      );
    }

    return (
      <StyledInput
        id={id}
        max={max}
        name={name}
        large={large}
        value={value}
        error={error}
        ref={refForward}
        type={visibleType}
        autoComplete="off"
        autofill={autofill}
        disabled={disabled}
        placeholder={placeholder}
        lefticon={!!leftIcon || search}
        righticon={!!rightIcon || search}
        {...props}
        onBlur={
          number && decimal
            ? (e) => {
                const figure = e.target.value;
                if (figure.endsWith('.')) {
                  const newValue = figure.slice(0, figure.length - 1);

                  props.onChange?.(newValue);
                }
                props.onBlur?.(e);
              }
            : props.onBlur
        }
        onChange={(e) => {
          if (number) {
            let digits;

            if (decimal) {
              digits = e.target.value.replace(
                /([^\.]+\.?)(.*)/,
                (match, predec, dec) =>
                  predec.replace(/[^\d\.]/g, '') +
                  dec.replace(/\D/g, '').slice(0, 2)
              );
            } else {
              digits = e.target.value.replace(/\D/g, '');
            }

            if (max && +digits > +max) {
              digits = max.toString();
            }

            e.target.value = digits;
          }
          props.onChange?.(e);
        }}
      />
    );
  };

  if (rawInput) {
    return input();
  }

  return (
    <InputContainer
      file={file}
      type={type}
      style={style}
      value={value}
      error={error}
      large={large}
      label={label}
      footer={footer}
      search={search}
      testId={testId}
      tooltip={tooltip}
      leftIcon={leftIcon}
      disabled={disabled}
      rightIcon={rightIcon}
      className={className}
      visibleType={visibleType}
      requiredText={requiredText}
      setVisibleType={setVisibleType}
      onLeftIconClick={onLeftIconClick}
      onRightIconClick={onRightIconClick}
    >
      {input()}
    </InputContainer>
  );
};

const InputWithRef = withRef(Input);

export { Input, InputWithRef };
export type { InputProps, InputOnChangeEventType };
