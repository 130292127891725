import { useMemo, useEffect } from 'react';

export const useTitle = (...args: string[]) => {
  const compoundTitle = useMemo(() => {
    const components = args.filter((title) => title.length > 0);
    const title = components.join(' | ');
    return title;
  }, [args]);

  useEffect(() => {
    if (compoundTitle.length) {
      window.document.title = compoundTitle;
    }
  }, [compoundTitle]);
};
