import dayjs from 'dayjs';
import { useMemo } from 'react';

import { Button } from 'components/button';
import { ButtonColors, ButtonStyleTypes } from 'types';

import * as Styles from './styles';
import { SidebarProps, RangeSelector } from './types';

const Sidebar = ({ onSelect, additionalRanges }: SidebarProps) => {
  const rangeSelectors = useMemo(() => {
    let items: RangeSelector[] = [
      {
        label: 'Last 14 days',
        range: [dayjs().subtract(14, 'day').toDate(), dayjs().toDate()],
      },
      {
        label: 'This week',
        range: [
          dayjs().startOf('week').toDate(),
          dayjs().endOf('week').toDate(),
        ],
      },
      {
        label: 'Last week',
        range: [
          dayjs().subtract(1, 'week').startOf('week').toDate(),
          dayjs().subtract(1, 'week').endOf('week').toDate(),
        ],
      },
      {
        label: 'This month',
        range: [
          dayjs().startOf('month').toDate(),
          dayjs().endOf('month').toDate(),
        ],
      },
      {
        label: 'Last month',
        range: [
          dayjs().subtract(1, 'month').startOf('month').toDate(),
          dayjs().subtract(1, 'month').endOf('month').toDate(),
        ],
      },
    ];
    if (additionalRanges) {
      items = items.concat(additionalRanges);
    }
    return items;
  }, [JSON.stringify(additionalRanges)]);

  return (
    <Styles.Sidebar>
      {rangeSelectors.map((selector) => {
        return (
          <li key={selector.label}>
            <Button
              text={selector.label}
              styleType={ButtonStyleTypes.Ghost}
              colorType={ButtonColors.LightBlue}
              onClick={() => {
                onSelect(selector.range);
              }}
            />
          </li>
        );
      })}
    </Styles.Sidebar>
  );
};
export default Sidebar;
