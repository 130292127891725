import styled, { css } from 'styled-components';

import { Col } from 'components/grid';

export const StyledCol = styled(Col)`
  position: relative;

  ${({ theme }) =>
    !theme.responsive.isMobile &&
    css`
      margin: 0;
      padding-bottom: 24px;

      &:nth-child(odd) {
        padding-left: 0px;
        padding-right: 40px;
        border-right: 1px solid ${theme.config.common.border};
      }

      &:nth-child(even) {
        padding-left: 40px;
        padding-right: 0px;
      }

      &:last-child {
        padding-bottom: 0;
        border-right: none;
      }
    `}
`;
