import { css } from 'styled-components';

import { CalendarStyledProps } from './types';

export const getDayContainerStyle = (props: CalendarStyledProps) => {
  const { theme, isSelected, isCurrentDay, isDateBetween, isCurrentMonth } =
    props;

  const config = theme.config.calendar.day;

  let defaultTheme = { ...config.default };

  if (isCurrentDay) {
    defaultTheme = { ...defaultTheme, ...config.currentDay };
  }

  if (isSelected) {
    defaultTheme = { ...defaultTheme, ...config.selectedDay };
  }

  if (!isCurrentMonth) {
    defaultTheme = {
      ...defaultTheme,
      opacity: isSelected ? '1.0' : '0.3',
    };
  }

  if (isDateBetween) {
    defaultTheme = { ...defaultTheme, ...config.dateBetween };
  }

  return css`
    background-color: ${defaultTheme.backgroundColor};

    p {
      opacity: ${defaultTheme.opacity};
      color: ${defaultTheme.textColor};
    }

    &:hover {
      background-color: ${defaultTheme.hoverBackgroundColor};
      p {
        color: ${defaultTheme.hoverTextColor};
      }
    }
  `;
};

export const getDayContainerBorderRadius = (props: CalendarStyledProps) => {
  const {
    theme,
    isDateTo,
    isDateFrom,
    isSelected,
    isDateBetween,
    isOneRangeSelected,
  } = props;

  const { borderRadius } = theme.config.calendar.day;

  let radius = borderRadius.none;
  let hoverRadius = borderRadius.all;

  switch (true) {
    case isDateFrom && !isOneRangeSelected: {
      radius = borderRadius.left;
      hoverRadius = borderRadius.left;
      break;
    }

    case isDateTo && !isOneRangeSelected: {
      radius = borderRadius.right;
      hoverRadius = borderRadius.right;
      break;
    }

    case isSelected || isOneRangeSelected: {
      radius = borderRadius.all;
      hoverRadius = borderRadius.all;
      break;
    }

    case isDateBetween: {
      radius = borderRadius.none;
      hoverRadius = borderRadius.none;
      break;
    }

    default:
      break;
  }

  return css`
    border-radius: ${radius};
    &:hover {
      border-radius: ${hoverRadius};
    }
  `;
};
