import { useParams } from 'react-router-dom';

import { mapQuery } from 'utils/query';
import { formatPrice } from 'utils/helpers';
import { Details } from 'components/details';
import { useDetailsQuery } from 'store/api/inventory';
import { ProductDetails } from 'components/product-details';

import { StorageInventoryDetailsProps } from './types';

export const StorageInventoryDetails = ({
  productId,
}: StorageInventoryDetailsProps) => {
  const { id, inventoryId } = useParams();

  const { data, ...props } = useDetailsQuery({
    id: id!,
    inventoryId: productId || inventoryId!,
  });

  return (
    <Details {...mapQuery(props)}>
      {data && (
        <ProductDetails
          data={data.product}
          rows={[
            [
              {
                col: 4,
                colTablet: 4,
                colMobile: 6,
                title: 'Count',
                value: data.count,
              },
              {
                col: 4,
                colTablet: 4,
                colMobile: 6,
                title: 'Price',
                value: formatPrice(data.price),
              },
            ],
          ]}
        />
      )}
    </Details>
  );
};
