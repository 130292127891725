import { Fragment, ReactNode } from 'react';

import { ErrorMessage } from 'components/error-message';

import { RadioGroupProps } from './types';
import { RadioButton, RadioGroupItem } from './radio';
import { ItemBlock, GroupLabel, ChildrenContainer } from './styles';

const RadioGroup = ({
  grid,
  items,
  value,
  label,
  padding,
  gap = 16,
  onChange,
  children,
  className,
  transparent,
  requiredText,
  buttonsContainerStyle,
  childrenContainerStyle,
}: RadioGroupProps): JSX.Element => {
  const handleChildren = (
    defaultValue: string | number,
    itemChildren?: ReactNode,
    withContainer: RadioGroupItem['withChildrenContainer'] = true
  ) => {
    if (value === defaultValue && itemChildren) {
      if (withContainer) {
        return (
          <ChildrenContainer style={childrenContainerStyle}>
            {itemChildren}
          </ChildrenContainer>
        );
      }

      return itemChildren;
    }
  };

  return (
    <div className={className}>
      {label && (
        <GroupLabel>
          <p>{label}</p>
          {requiredText && <ErrorMessage>{requiredText}</ErrorMessage>}
        </GroupLabel>
      )}
      {children}
      <ItemBlock gap={gap} grid={grid} style={buttonsContainerStyle}>
        {items.map(
          ({
            id,
            hasAccess = true,
            value: defaultValue,
            withChildrenContainer,
            children: itemChildren,
            ...props
          }) =>
            hasAccess && (
              <Fragment key={id ?? defaultValue}>
                <RadioButton
                  {...props}
                  padding={padding}
                  value={defaultValue}
                  transparent={transparent}
                  checked={value === defaultValue}
                  id={id || `${label}-${defaultValue}`}
                  onChange={() => onChange(defaultValue)}
                />

                {handleChildren(
                  defaultValue,
                  itemChildren,
                  withChildrenContainer
                )}
              </Fragment>
            )
        )}
      </ItemBlock>
    </div>
  );
};

export { RadioGroup };
