import { Field } from 'formik';
import styled, { css } from 'styled-components';

import { inlineFlexCenter } from 'styles';

import { ColorPickerStylesProps } from './types';

export const ColorList = styled.ul`
  gap: 8px;
  display: flex;
  margin-bottom: 8px;
`;

export const ColorLabel = styled.label`
  ${inlineFlexCenter};

  width: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  border-radius: 24px;
`;

export const ColorRadioField = styled(Field)<ColorPickerStylesProps>`
  appearance: none;
  visibility: hidden;
  position: absolute;
  -webkit-appearance: none;

  ${({ value, theme }) => {
    const config = theme.config.teamMember[value];

    return css`
      + ${ColorLabel} {
        color: ${config.default.color};
        background-color: ${config.default.bg};

        &:hover {
          background-color: ${config.hover.bg};
        }
      }

      &:checked + ${ColorLabel} {
        border: 2px solid ${config.selected.border};
        background-color: ${config.selected.bg};
      }

      &:disabled + ${ColorLabel} {
        background-color: ${config.disabled.bg};
      }
    `;
  }}
`;
