import styled, { css } from 'styled-components';

export const Item = styled.div<{ $spacing: number }>`
  position: relative;
  padding-left: 32px;

  &::before,
  &::after {
    left: 16px;
    content: '';
    position: absolute;
    transform: translateX(-50%);
  }

  &::before {
    top: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  ${({ theme, $spacing }) => css`
    &::after {
      bottom: 0;
      width: 2px;
      height: calc(100% - ${16 + $spacing}px);
      background-color: ${theme.config.eventCard.line};
    }

    &::before {
      background-color: ${theme.config.eventCard.dot};
    }
  `}
`;
