import { useMemo } from 'react';
import { useField } from 'formik';

import { CommonFieldProps } from 'types';
import { controlError } from 'utils/error';
import { getSelectValue } from 'utils/helpers';
import { Select, SelectOption, AutocompleteSelect } from 'components/select';

import { SelectProps } from '../select';

export interface SelectFieldProps
  extends CommonFieldProps,
    Omit<SelectProps, 'name'> {
  onlyValue?: boolean;
  clearToNull?: boolean;
  isFirstStep?: boolean;
  isAutocomplete?: boolean;
}

export const SelectField = ({
  name,
  options,
  onChange,
  onlyValue,
  clearToNull,
  isCreatable,
  isFirstStep,
  isAutocomplete,
  controlErrorLabel = true,
  ...props
}: SelectFieldProps) => {
  const [field, meta, helpers] = useField(name);

  const value = useMemo(
    () =>
      getSelectValue({ options, onlyValue, isCreatable, value: field.value }),
    [onlyValue, options, field.value, isCreatable]
  );

  const error = controlError(meta, name, props.label, controlErrorLabel);

  const Component = isAutocomplete ? AutocompleteSelect : Select;

  return (
    <Component
      {...field}
      {...props}
      error={error}
      value={value}
      options={options}
      isCreatable={isCreatable}
      onChange={async (option, action) => {
        const changeValue = async () => {
          let newValue;

          if (clearToNull && action.action === 'clear') {
            newValue = null;
          } else {
            newValue = onlyValue
              ? Array.isArray(option)
                ? option.map((item) => item.value)
                : // @ts-ignore
                  option?.value
              : option;
          }

          if (
            !isFirstStep ||
            (isFirstStep && (option as SelectOption)?.isSingle)
          ) {
            await helpers.setValue(newValue);
          }

          onChange?.(option, action);
        };

        await changeValue();
      }}
    />
  );
};
