import { lazy } from 'react';

const List = lazy(() => import('./list'));
const Create = lazy(() => import('./form/create'));
const Edit = lazy(() => import('./form/edit'));
const Details = lazy(() => import('./details'));

export { Products } from './form/products';
export { OrderInfo } from './form/order-info';
export { List, Edit, Create, Details };
