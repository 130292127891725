import { useSelector } from 'react-redux';
import { PropsWithChildren } from 'react';

import { selectIsLoading } from 'store/slices/loader';

import { Loader } from '../index';

export const LoaderRedux = ({ children }: PropsWithChildren) => {
  const isLoading = useSelector(selectIsLoading);
  return <Loader isLoading={isLoading}>{children}</Loader>;
};
