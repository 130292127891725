import { useMemo, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

import { getByPath } from 'utils/helpers';

import { useQuery } from '../use-query';

import {
  UseSearch,
  UseFilter,
  UseSearchQuery,
  UseSearchReturn,
  UseSearchReturnOptional,
} from './types';

const useSearch: UseSearch = (defaultSearchQuery, debounceDelay = 500) => {
  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery);
  const [debounceQuery] = useDebounceValue(searchQuery, debounceDelay);

  return { searchQuery, setSearchQuery, debounceQuery: debounceQuery.trim() };
};

const useSearchQuery: UseSearchQuery = (debounceDelay = 500) => {
  const [searchQuery, setSearchQuery] = useQuery('query');
  const [debounceQuery] = useDebounceValue(searchQuery, debounceDelay);

  return { searchQuery, setSearchQuery, debounceQuery: debounceQuery.trim() };
};

function useFilter<T>({ data, query, paths, filter }: UseFilter<T>) {
  const filtered = useMemo(() => {
    if (!query && !filter) {
      return data;
    }
    const filters: ((item: T) => boolean)[] = [];
    if (filter) {
      filters.push(filter);
    }
    if (query) {
      const lowercasedQuery = query.toLowerCase().trim();
      if (lowercasedQuery.length === 0) {
        return data;
      }
      filters.push((item) => {
        const index = paths.findIndex((path) => {
          const value = getByPath(item, path);
          if (!value) {
            return false;
          }
          const lowercased = String(value).toLowerCase();
          return lowercased.includes(lowercasedQuery);
        });
        return index !== -1;
      });
    }

    return data.filter((item) => {
      const index = filters.findIndex((currentFilter) => {
        return currentFilter(item);
      });
      return index !== -1;
    });
  }, [data, query, paths, filter]);
  return filtered;
}

export { useFilter, useSearch, useSearchQuery };
export type { UseSearchReturn, UseSearchReturnOptional };
