import { useCallback } from 'react';

import { Messages, AppRoutes } from 'config';
import { useCreateMutation } from 'store/api/event';
import { useAlert, useNavigate, handleMutation } from 'hooks';

import { Form } from './form';

export const Create = () => {
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [create, mutation] = useCreateMutation();

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Event ${Messages.ADDED_SUCCESSFULLY}`);
    navigate(AppRoutes.Events.Home);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  return <Form onSubmit={create} />;
};
