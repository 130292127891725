import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import * as Styles from './styles';
import { ErrorMessageProps } from './types';

const ErrorMessage = ({
  children,
  className,
  ...props
}: PropsWithChildren<ErrorMessageProps>) => {
  return (
    <Styles.ErrorMessage
      className={classNames('error-message', className)}
      {...props}
    >
      {children}
    </Styles.ErrorMessage>
  );
};

export { ErrorMessage };
