import { useField } from 'formik';

import { controlError } from 'utils/error';
import { ErrorMessage } from 'components/error-message';
import { UploadPhoto, UploadPhotoProps } from 'components/upload-photo';

interface PhotoFieldProps extends Omit<UploadPhotoProps, 'setImage'> {
  name: string;
}

const PhotoField = ({ name, ...props }: PhotoFieldProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);

  const error = controlError(meta, name, 'Photo');

  return (
    <div>
      <UploadPhoto
        setImage={helpers.setValue}
        testId={`photo-field-${name}`}
        image={typeof field.value === 'object' ? field.value?.src : field.value}
        {...props}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

export { PhotoField };
