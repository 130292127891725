import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { inlineFlexCenter } from 'styles/mixins';

import { TabComponent, BadgeCountProps } from './types';

export const TabsList = styled.ul<Pick<TabComponent, '$block'>>`
  display: flex;
  overflow: auto;

  ${({ theme, $block }) =>
    !$block &&
    css`
      box-shadow: inset 0px -1px 0px ${theme.config.tab.shadow};
    `}

  & > :not(:last-child) {
    margin-right: 32px;
  }
`;

export const TabsListItem = styled.li`
  flex: 0 0 auto;
`;

const getStyles = ({ theme, large, $block, disabled }: TabComponent) => css`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  position: relative;

  font-size: ${large ? 20 : 14}px;
  padding: ${large ? 16 : 8}px ${$block ? 12 : 0}px;
  color: ${theme!.config.tab.text.default};

  svg {
    path {
      fill: currentColor;
    }
    circle {
      fill: currentColor;
    }
  }

  ${$block &&
  css`
    border-radius: ${theme!.borderRadius};

    &:hover {
      color: ${theme!.config.tab.text.default};
    }

    &.active {
      color: ${theme!.config.tab.block.text.active};
      background-color: ${theme!.config.tab.block.bg.active};
    }
  `}

  &.active {
    font-weight: 700;
    color: ${$block
      ? theme!.config.tab.block.text.active
      : theme!.config.tab.text.active};

    ${!$block &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${theme!.config.tab.text.active};
        height: 2px;
        border-radius: 4px 4px 0 0;
      }
    `}
  }

  ${disabled
    ? css`
        pointer-events: none;
        color: ${theme!.config.tab.text.disabled};
      `
    : css`
        cursor: pointer;

        ${!$block &&
        css`
          &:hover {
            color: ${theme!.config.tab.text.active};
          }
        `}
      `}
`;

export const TabNavLink = styled(NavLink)<TabComponent>`
  ${(props) => getStyles(props)};
`;

export const Tab = styled.div<TabComponent>`
  ${(props) => getStyles(props)};
`;

export const BadgeCount = styled.div<BadgeCountProps>`
  ${inlineFlexCenter};
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  margin-left: 8px;
  border-radius: 50%;

  ${({ theme, countColor }) => css`
    color: ${theme.config.tab.badge};
    background-color: ${theme.config.tab.count[countColor]};
  `}
`;
