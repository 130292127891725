import { Fragment } from 'react';

import { isDesktop } from 'hooks';
import { generateTemplate } from 'utils/helpers';
import { EllipsisText } from 'components/ellipsis-text';

import * as Styles from './styles';
import {
  ListHeaderProps,
  ListHeaderWrapProps,
  ListHeaderScrollbarProps,
} from './types';

export const ListHeader = ({
  titles,
  className,
  isScrolled,
  gap = '24px',
  hasScrollbar,
  padding = '8px 24px',
  template = generateTemplate(titles?.length ?? 0),
}: ListHeaderProps) => {
  const desktop = isDesktop();

  if (!desktop || !titles) {
    return null;
  }

  return (
    <Styles.Container
      gap={gap}
      padding={padding}
      template={template}
      className={className}
      isScrolled={isScrolled ?? false}
      hasScrollbar={hasScrollbar ?? false}
    >
      {titles.map((title, index) => {
        if (typeof title !== 'string') {
          return <Fragment key={index}>{title}</Fragment>;
        }
        return <EllipsisText key={index}>{title}</EllipsisText>;
      })}
    </Styles.Container>
  );
};

export const ListHeaderWrap = ({ children, ...props }: ListHeaderWrapProps) =>
  children(props);

export type { ListHeaderScrollbarProps };
