import { useMemo } from 'react';

import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { Button } from 'components/button';
import { ButtonColors, ButtonStyleTypes } from 'types';
import { EllipsisText } from 'components/ellipsis-text';

import * as Styles from './styles';
import { LinkProps } from './types';
import { cropUrl, getHref } from './helpers';

const Link = ({
  url,
  text,
  icon,
  withoutButton,
  as: CustomTag,
  withBackground,
  ellipsis = true,
  ...props
}: LinkProps) => {
  const [link, href] = useMemo(() => {
    return [cropUrl(url), getHref(url)];
  }, [url]);

  const anchor = CustomTag ? (
    <CustomTag>{text || link}</CustomTag>
  ) : (
    <a
      href={href}
      target={href.startsWith(window.location.origin) ? undefined : '_blank'}
      {...props}
    >
      {text || link}
    </a>
  );

  const linkElement = ellipsis ? (
    <EllipsisText className={!withBackground ? 'standard-link' : undefined}>
      {anchor}
    </EllipsisText>
  ) : (
    anchor
  );

  const content = (
    <>
      {icon}
      {linkElement}
      {!withoutButton && (
        <Button
          padding="4px"
          tooltip="Copy"
          icon={<Icon.Copy />}
          styleType={ButtonStyleTypes.Ghost}
          colorType={ButtonColors.TetrieryGrey}
          onClick={() => {
            navigator.clipboard.writeText(link);
          }}
        />
      )}
    </>
  );

  return (
    <Styles.Container onClick={(e) => e.stopPropagation()}>
      {withBackground ? (
        <Styles.LinkWithBackground>{content}</Styles.LinkWithBackground>
      ) : (
        <Flex
          gap={8}
          alignItems="center"
          display={ellipsis ? 'flex' : 'inline-flex'}
        >
          {content}
        </Flex>
      )}
    </Styles.Container>
  );
};

export { Link };
export type { LinkProps };
