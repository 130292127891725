import * as Yup from 'yup';

import { phoneNumber } from 'utils/validation';
import { EventCheckout } from 'store/api/event';

import { FormValues } from './types';

export const getInitialValues = (data?: EventCheckout): FormValues => ({
  hasContactInfo: false,
  emails: [data?.customer?.person?.email || ''],
  phoneNumbers: [data?.customer?.person?.phone || ''],
});

export const validationSchema = Yup.object().shape({
  phoneNumbers: Yup.array().of(phoneNumber('phoneNumbers')),
  emails: Yup.array().min(1).of(Yup.string().email('Email must be a valid')),
  hasContactInfo: Yup.boolean()
    .when(['emails', 'phoneNumbers'], {
      otherwise: (schema) => schema,
      then: (schema) =>
        schema.oneOf(
          [true],
          'At least one email or phone number must be provided'
        ),
      is: (emails: string[], phoneNumbers: string[]) => {
        const hasEmail = !!emails.filter((email) => email && email.length > 0)
          .length;
        const hasPhoneNumber = !!phoneNumbers.filter(
          (phone) => phone && phone.length
        ).length;

        return !(hasEmail || hasPhoneNumber);
      },
    })
    .required(),
});
