import { cloneElement } from 'react';

import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { Tabs } from 'components/tabs';
import { Popup } from 'components/popup';
import { Burger } from 'components/burger';
import { ButtonTypes } from 'components/button';
import { AvatarPopup } from 'components/avatar-popup';
import { Badge, BadgeWithRef } from 'components/badge';
import { EllipsisText } from 'components/ellipsis-text';
import { Avatar, AvatarPlaceholderType } from 'components/avatar';
import { useTitle, isDesktop, useNavigate, useElementSize } from 'hooks';
import {
  BadgeColor,
  BadgePadding,
  ButtonColors,
  ButtonStyleTypes,
} from 'types/enums';

import * as Styles from './styles';
import { HeaderProps, HeaderPageProps } from './types';

const Header = ({
  user,
  tabs,
  count,
  badges,
  compact,
  version,
  options,
  pageName,
  backPath,
  tabsProps,
  onSignOut,
  hideAvatar,
  profileLink,
  handleBurger,
  rightAccessory,
  userInfoTriggerProps,
  showBackButton = false,
}: HeaderProps) => {
  const navigate = useNavigate();
  const [infoRef, { width: infoWidth }] = useElementSize();
  const desktop = isDesktop();

  useTitle(pageName || '');

  return (
    <Styles.StyledHeader hideAvatar={hideAvatar}>
      <Styles.WrapperAction infoWidth={infoWidth}>
        {showBackButton && (
          <Styles.StyledButton
            type={ButtonTypes.Button}
            icon={<Icon.ChevronLeft />}
            colorType={ButtonColors.LightBlue}
            styleType={ButtonStyleTypes.Standard}
            onClick={() => navigate(backPath || -1)}
          />
        )}

        {tabs && tabs.length > 0 && (
          <Tabs isLarge items={tabs} style={{ marginTop: 8 }} {...tabsProps} />
        )}

        {!tabs && pageName && (
          <>
            <Styles.PageName>{pageName}</Styles.PageName>
            {!!count && (
              <Styles.Count
                smallFont
                text={String(count)}
                padding={BadgePadding.Small}
                styleType={BadgeColor.RedAlert}
              />
            )}
          </>
        )}
      </Styles.WrapperAction>

      <Styles.WrapperInfo ref={infoRef}>
        {rightAccessory && (
          <Styles.AccessoryWrap>{rightAccessory}</Styles.AccessoryWrap>
        )}

        {badges && badges.length > 0 && (
          <Styles.BadgeBlock>
            {badges.map(({ popup, ...badge }, index) => {
              return popup ? (
                <Popup
                  key={String(index)}
                  position={['bottom center', 'bottom right']}
                  trigger={<BadgeWithRef key={String(index)} {...badge} />}
                >
                  {(close: () => void) => cloneElement(popup, { close })}
                </Popup>
              ) : (
                <Badge bold key={String(index)} {...badge} />
              );
            })}
          </Styles.BadgeBlock>
        )}

        {(desktop || compact) && !hideAvatar && (
          <Popup
            position="bottom right"
            trigger={
              <Styles.AvatarWrap
                {...userInfoTriggerProps}
                isCompact={compact && !desktop}
              >
                <Flex gap={16} alignItems="center">
                  <Avatar
                    url={user?.photoUrl}
                    data-testid="profile-photo"
                    placeholder={AvatarPlaceholderType.Photo}
                  />

                  <Flex column minWidth={0}>
                    <EllipsisText>
                      <b>{user?.name}</b>
                    </EllipsisText>

                    {user?.role?.name && (
                      <EllipsisText>{user.role.name}</EllipsisText>
                    )}
                  </Flex>
                </Flex>
              </Styles.AvatarWrap>
            }
          >
            {(close: any) => (
              <AvatarPopup
                user={user}
                onProfile={close}
                options={options}
                onSignOut={onSignOut}
                profileLink={profileLink}
              />
            )}
          </Popup>
        )}

        {handleBurger && !desktop && !hideAvatar && (
          <Burger onClick={handleBurger} />
        )}
      </Styles.WrapperInfo>

      {version && <Styles.Version>{`v${version}`}</Styles.Version>}
    </Styles.StyledHeader>
  );
};

export { Header };
export type { HeaderProps, HeaderPageProps };
