import { Flex } from 'components';
import { CheckboxColor } from 'types';
import { handleSelectionOnClick, handleSelectionOnChange } from 'utils/helpers';

import { Icon } from '../icon';
import { Linkify } from '../linkify';

import { CheckboxProps } from './types';
import {
  Title,
  StyledIcon,
  CheckboxText,
  CheckboxMark,
  CheckboxLabel,
  StyledCheckbox,
} from './styles';

const Checkbox = ({
  id,
  icon,
  label,
  title,
  topSlot,
  disabled,
  onChange,
  className,
  fullWidth,
  linkifyProps,
  block = false,
  checked = false,
  transparent = false,
  positionRightIcon = false,
  checkedIcon = <Icon.Check />,
  colorType = CheckboxColor.Blue,
  ...props
}: CheckboxProps) => {
  const checkboxId = id || label;
  const onSelect = handleSelectionOnClick<HTMLLabelElement | HTMLInputElement>(
    (e) => {
      if (e.detail > 1) {
        e.preventDefault();
      }
    }
  );

  const internalContent = (
    <>
      <StyledCheckbox
        block={block}
        id={checkboxId}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        colorType={colorType}
        transparent={transparent}
        onChange={handleSelectionOnChange(onChange)}
        {...props}
      />

      <CheckboxMark label={label} colorType={colorType}>
        {checkedIcon}
      </CheckboxMark>

      {icon && (
        <StyledIcon positionRightIcon={positionRightIcon}>{icon}</StyledIcon>
      )}

      {label && (
        <CheckboxText>
          <Linkify {...linkifyProps}>{label}</Linkify>
        </CheckboxText>
      )}
    </>
  );

  const mainElement = (
    <CheckboxLabel
      block={block}
      checked={checked}
      disabled={disabled}
      htmlFor={checkboxId}
      fullWidth={fullWidth}
      colorType={colorType}
      className={className}
      onMouseDown={onSelect}
    >
      {topSlot ? (
        <Flex column gap={4}>
          {topSlot}
          <Flex alignItems="center">{internalContent}</Flex>
        </Flex>
      ) : (
        internalContent
      )}
    </CheckboxLabel>
  );

  return title ? (
    <Flex column display="inline-flex">
      <Title as="p">{title}</Title>
      {mainElement}
    </Flex>
  ) : (
    mainElement
  );
};

export { Checkbox };
export type { CheckboxProps };
