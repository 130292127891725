import { types } from 'react-alert';

import { TeamMemberColor } from 'types';
import {
  TextColor,
  DotsColor,
  BadgeColor,
  ErrorColor,
  AvatarColor,
  ButtonColors,
  CashCardType,
  ListCardStyle,
  CheckboxColor,
  TabCountColor,
  ButtonStyleTypes,
  SalesCompatitionPosition,
} from 'types/enums';

import { badgeDefaultState } from '../constants';

//  white
export const white: string = '#ffffff';
export const whiteHover: string = '#F5F5F5';
export const whiteAction: string = '#EBEBEB';

//  blue
export const blue: string = '#0A51C5';
export const blueHover: string = '#0949B2';
export const blueAction: string = '#07398B';

export const secondaryBlue: string = '#E7EEFA';
export const secondaryBlueHover: string = '#D6E2F6';
export const secondaryBlueAction: string = '#CEDCF4';

export const tetrieryBlue: string = '#F1F5FB';
export const tetrieryBlueHover: string = '#E9EFF9';
export const tetrieryBlueAction: string = '#DDE7F5';

export const additionalBlue: string = '#07398B';

export const transparentBlue: string = '#29489929';

//  black
export const black: string = '#202020';
export const blackHover: string = '#343434';
export const blackAction: string = '#484848';

//  grey
export const grey: string = '#8F8F8F';
export const greyHover: string = '#7B7B7B';
export const greyAction: string = '#5D5D5D';

export const secondaryGrey: string = '#E6E6E6';
export const secondaryGreyHover: string = '#D2D2D2';
export const secondaryGreyAction: string = '#BEBEBE';

export const tetrieryGrey: string = '#F8F8F8';
export const tetrieryGreyHover: string = '#F3F3F3';
export const tetrieryGreyAction: string = '#EBEBEB';

export const additionalGrey: string = '#FCFCFC';

//  green
export const green: string = '#047E41';
export const greenHover: string = '#036A37';
export const greenAction: string = '#024C28';
export const everglade: string = '#1A3C2B';
export const tePapaGreen: string = '#203C45';

export const secondaryGreen: string = '#D6F3E4';
export const secondaryGreenHover: string = '#C6EEDA';
export const secondaryGreenAction: string = '#B6E9D0';

export const tetrieryGreen: string = '#EDF8F2';
export const tetrieryGreenHover: string = '#E6F5ED';
export const tetrieryGreenAction: string = '#DFF2E8';

export const additionalGreen: string = '#01783D';

//  red
export const red: string = '#DD2F2F';
export const redHover: string = '#CC2121';
export const redAction: string = '#BA1E1E';

export const secondaryRed: string = '#FFE2E2';
export const secondaryRedHover: string = '#FFCECE';
export const secondaryRedAction: string = '#FFBABA';

export const tetrieryRed: string = '#FDF2F2';
export const tetrieryRedHover: string = '#FCE9E9';
export const tetrieryRedAction: string = '#FBE5E5';

export const additionalRed: string = '#C90606';

// purple
export const purple: string = '#BA4AA1';
export const purpleHover: string = '#A63F8F';
export const purpleAction: string = '#973982';

export const secondaryPurple: string = '#FFEAFA';
export const secondaryPurpleHover: string = '#FFD6F5';
export const secondaryPurpleAction: string = '#FFC2F0';

export const tetrieryPurple: string = '#FDF5FB';
export const tetrieryPurpleHover: string = '#FBEDF8';
export const tetrieryPurpleAction: string = '#FAE9F6';

export const additionalPurple: string = '#A80884';

//  yellow
export const yellow: string = '#C19203';
export const yellowHover: string = '#A37B03';
export const yellowAction: string = '#8F6C03';

export const secondaryYellow: string = '#FFF2CC';
export const secondaryYellowHover: string = '#FFEDB8';
export const secondaryYellowAction: string = '#FFE8A4';

export const tetrieryYellow: string = '#FDF8E9';
export const tetrieryYellowHover: string = '#FCF5E0';
export const tetrieryYellowAction: string = '#FAF1D8';

export const additionalYellow: string = '#876600';

//  cerulean
export const cerulean: string = '#0B7B9E';
export const ceruleanHover: string = '#0A6C8B';
export const ceruleanAction: string = '#095D78';

export const secondaryCerulean: string = '#E2F1F6';
export const secondaryCeruleanHover: string = '#D2E9F1';
export const secondaryCeruleanAction: string = '#CAE5EF';

export const tetrieryCerulean: string = '#F1F7FA';
export const tetrieryCeruleanHover: string = '#E9F3F7';
export const tetrieryCeruleanAction: string = '#DFEDF2';

export const additionalCerulean: string = '#07536B';

//  orange
export const orange: string = '#EF9931';
export const orangeHover: string = '#D28527';
export const orangeAction: string = '#B26E1A';

export const secondaryOrange: string = '#E2F1F6';
export const secondaryOrangeHover: string = '#D2E9F1';
export const secondaryOrangeAction: string = '#CAE5EF';

export const tetrieryOrange: string = '#FFEFDC';
export const tetrieryOrangeHover: string = '#FEEBD5';
export const tetrieryOrangeAction: string = '#FADFC0';

export const additionalOrange: string = '#8A5311';

export const pigmentIndigo: string = '#590381';
export const pigmentIndigoHover: string = '#4C026E';
export const pigmentIndigoAction: string = '#3A0154';

export const secondaryPigmentIndigo: string = '#DBD2F1';
export const secondaryPigmentIndigoHover: string = '#D2C2E9';
export const secondaryPigmentIndigoAction: string = '#CEBAE5';

export const tertiaryPigmentIndigo: string = '#F3F0FA';
export const tertiaryPigmentIndigoHover: string = '#EDE6F6';
export const tertiaryPigmentIndigoAction: string = '#E7DDF2';

export const additionalPigmentIndigo: string = '#3A0154';

const regularShadow: string =
  '0 8px 10px rgba(0, 0, 0, 0.04), 0 3px 14px rgba(0, 0, 0, 0.04), 0 4px 5px rgba(0, 0, 0, 0.04)';
const infoShadow: string =
  '0px 1px 16px rgba(0, 0, 0, 0.04), 1px 8px 24px rgba(0, 0, 0, 0.04)';
const cardShadow: string = '0px 8px 24px rgba(0, 27, 71, 0.08)';

const common = {
  icon: black,
  divider: tetrieryGrey,
  border: secondaryGrey,
};

const avatar = Object.freeze({
  icon: black,
  imageIcon: white,
  statusBorder: white,
  border: additionalGrey,
  background: secondaryGrey,
  imageMask: { hover: `${black}99`, action: `${black}B3` },
});

const circle = Object.freeze({
  bg: black,
  border: black,
});

const badge = Object.freeze({
  [BadgeColor.New]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      bg: black,
      text: white,
      border: 'inherit',
    },
  },
  [BadgeColor.Tag]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: black,
      border: 'inherit',
      bg: additionalGrey,
    },
  },
  [BadgeColor.LightGrey]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: black,
      bg: secondaryGrey,
      border: 'inherit',
    },
  },
  [BadgeColor.AdditionalGrey]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: black,
      bg: tetrieryGrey,
      border: 'inherit',
    },
  },
  [BadgeColor.Between]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryCerulean,
    },
  },
  [BadgeColor.TetrieryCerulean]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: black,
      border: 'inherit',
      bg: tetrieryCerulean,
    },
  },
  [BadgeColor.SecondaryCerulean]: {
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: black,
      border: 'inherit',
      bg: secondaryCeruleanHover,
    },
  },
  [BadgeColor.Grey]: {
    dot: greyAction,
    hover: badgeDefaultState,
    active: badgeDefaultState,
    default: {
      text: black,
      border: 'inherit',
      bg: secondaryGreyHover,
    },
  },
  [BadgeColor.TertiaryGreen]: {
    hover: badgeDefaultState,
    default: {
      ...badgeDefaultState,
      bg: tetrieryGreen,
    },
    active: {
      bg: green,
      text: white,
      border: 'inherit',
    },
  },
  [BadgeColor.Pink]: {
    active: badgeDefaultState,
    default: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryPurple,
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryPurpleHover,
    },
  },
  [BadgeColor.SecondaryRed]: {
    hover: {
      ...badgeDefaultState,
      bg: secondaryRedAction,
    },
    default: {
      ...badgeDefaultState,
      bg: secondaryRedHover,
    },
    active: {
      ...badgeDefaultState,
      text: white,
      bg: additionalRed,
    },
  },
  [BadgeColor.RedAlert]: {
    hover: {
      text: white,
      bg: redHover,
      border: 'inherit',
    },
    active: {
      text: white,
      bg: redAction,
      border: 'inherit',
    },
    default: {
      text: white,
      border: 'inherit',
      bg: additionalRed,
    },
  },
  [BadgeColor.SecondaryGreen]: {
    hover: {
      ...badgeDefaultState,
      bg: secondaryGreenAction,
    },
    default: {
      ...badgeDefaultState,
      bg: secondaryGreenHover,
    },
    active: {
      ...badgeDefaultState,
      text: white,
      bg: additionalGreen,
    },
  },
  [BadgeColor.SecondaryYellow]: {
    hover: {
      ...badgeDefaultState,
      bg: secondaryYellowAction,
    },
    default: {
      ...badgeDefaultState,
      bg: secondaryYellowHover,
    },
    active: {
      ...badgeDefaultState,
      text: white,
      bg: additionalYellow,
    },
  },
  [BadgeColor.TertiaryOrange]: {
    dot: orangeHover,
    hover: {
      ...badgeDefaultState,
      bg: tetrieryOrangeHover,
    },
    active: {
      ...badgeDefaultState,
      bg: tetrieryOrangeHover,
    },
    default: {
      ...badgeDefaultState,
      bg: tetrieryOrangeHover,
    },
  },
  [BadgeColor.Blue]: {
    default: {
      text: 'inherit',
      bg: secondaryBlue,
      border: 'inherit',
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryBlueHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryBlueAction,
    },
  },
  [BadgeColor.Transparent]: {
    hover: {
      ...badgeDefaultState,
      border: `1px solid ${secondaryGrey}`,
    },
    active: {
      ...badgeDefaultState,
      border: `1px solid ${secondaryGrey}`,
    },
    default: {
      bg: white,
      text: black,
      border: `1px solid ${secondaryGrey}`,
    },
  },
  [BadgeColor.TetrieryBlue]: {
    default: {
      text: 'inherit',
      bg: tetrieryBlue,
      border: 'inherit',
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: tetrieryBlueHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: tetrieryBlueAction,
    },
  },
  [BadgeColor.Red]: {
    dot: additionalRed,
    default: {
      text: 'inherit',
      bg: secondaryRed,
      border: 'inherit',
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryRedHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryRedAction,
    },
  },
  [BadgeColor.Yellow]: {
    dot: yellow,
    default: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryYellow,
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryYellowHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryYellowAction,
    },
  },
  [BadgeColor.Green]: {
    dot: additionalGreen,
    default: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryGreen,
    },
    hover: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryGreenHover,
    },
    active: {
      text: 'inherit',
      border: 'inherit',
      bg: secondaryGreenAction,
    },
  },
  [BadgeColor.SecondaryPigmentIndigo]: {
    dot: pigmentIndigo,
    default: {
      text: black,
      border: 'inherit',
      bg: secondaryPigmentIndigo,
    },
    hover: {
      text: black,
      border: 'inherit',
      bg: secondaryPigmentIndigoHover,
    },
    active: {
      text: black,
      border: 'inherit',
      bg: secondaryPigmentIndigoAction,
    },
  },
  [BadgeColor.AdditionalRed]: {
    active: {
      text: white,
      bg: additionalRed,
      border: `1px solid ${additionalRed}`,
    },
    hover: {
      text: additionalRed,
      bg: secondaryRedHover,
      border: `1px solid ${additionalRed}`,
    },
    default: {
      ...badgeDefaultState,
      text: additionalRed,
      border: `1px solid ${additionalRed}`,
    },
  },
});

const checkbox = Object.freeze({
  svg: { default: white, checked: white },
  [CheckboxColor.Blue]: {
    default: { bg: blue, box: white, border: secondaryGrey },
    checked: { bg: blue, box: secondaryBlue, border: secondaryBlue },
    hover: { bg: blueHover, box: whiteHover, border: secondaryGrey },
    active: { bg: blueAction, box: whiteAction, border: secondaryGrey },
    disabled: { box: white, border: secondaryGrey, bg: secondaryGreyAction },
  },
  [CheckboxColor.Green]: {
    default: { bg: green, box: white, border: secondaryGrey },
    hover: { bg: greenHover, box: whiteHover, border: secondaryGrey },
    checked: { bg: green, box: secondaryGreen, border: secondaryGreen },
    active: { bg: greenAction, box: whiteAction, border: secondaryGrey },
    disabled: { box: white, border: secondaryGrey, bg: secondaryGreyAction },
  },
  [CheckboxColor.Red]: {
    hover: { bg: black, box: secondaryRed, border: secondaryRed },
    disabled: { box: white, border: secondaryGrey, bg: secondaryGreyAction },
    active: {
      bg: black,
      box: secondaryRedHover,
      border: secondaryRedHover,
    },
    checked: {
      bg: black,
      box: tetrieryRedAction,
      border: tetrieryRedAction,
    },
    default: {
      bg: black,
      box: tetrieryRedAction,
      border: tetrieryRedAction,
    },
  },
  [CheckboxColor.SecondaryBlue]: {
    disabled: { box: white, border: secondaryGrey, bg: secondaryGreyAction },
    hover: { bg: black, box: secondaryBlueHover, border: secondaryBlueHover },
    default: {
      bg: black,
      box: secondaryBlue,
      border: secondaryBlue,
    },
    checked: {
      bg: black,
      box: secondaryBlue,
      border: secondaryBlue,
    },
    active: {
      bg: black,
      box: secondaryBlueAction,
      border: secondaryBlueAction,
    },
  },
});

const radioButton = Object.freeze({
  default: { bg: white, circle: blue, border: secondaryGrey },
  checked: { circle: blue, bg: secondaryBlue, border: secondaryBlue },
  hover: { bg: whiteHover, circle: blueHover, border: secondaryGrey },
  active: { bg: whiteAction, circle: blueAction, border: secondaryGrey },
  disabled: { bg: white, border: secondaryGrey, circle: secondaryGreyAction },
});

const switchCheckbox = Object.freeze({
  slider: {
    default: white,
    shadow: regularShadow,
    disabled: tetrieryGreyAction,
  },
  redBg: {
    default: red,
    checked: green,
    hover: redHover,
    disabled: redAction,
  },
  bg: {
    checked: green,
    default: secondaryGrey,
    hover: secondaryGreyHover,
    disabled: secondaryGreyHover,
  },
});

const sidebar = Object.freeze({
  default: white,
  border: whiteHover,
  disabled: secondaryGrey,
  selected: secondaryBlue,
  hover: secondaryBlueHover,
  active: secondaryBlueAction,
  svg: { default: black, selected: blue },
  boxShadow: `0px 4px 7px 0px ${transparentBlue}`,
});

const subMenu = Object.freeze({
  hover: whiteHover,
  active: whiteAction,
  selected: { text: blue, bg: secondaryBlue },
  disabled: { bg: white, color: secondaryGrey },
  title: { color: greyAction, bg: additionalGrey },
});

const button = Object.freeze({
  [ButtonStyleTypes.Outline]: {
    [ButtonColors.LightRed]: null,
    [ButtonColors.WhiteBlue]: null,
    [ButtonColors.LightBlue]: null,
    [ButtonColors.LightGreen]: null,
    [ButtonColors.LightPurple]: null,
    [ButtonColors.LightYellow]: null,
    [ButtonColors.TetrieryGrey]: null,
    [ButtonColors.PigmentIndigo]: null,
    [ButtonColors.LightCerulean]: null,
    [ButtonColors.Blue]: {
      default: { text: blue, border: blue, bg: 'transparent' },
      disabled: { text: blue, border: blue, bg: 'transparent' },
      hover: { text: blueHover, bg: tetrieryBlue, border: blueHover },
      action: { text: blueAction, border: blueAction, bg: tetrieryBlueHover },
    },
    [ButtonColors.WhiteBlack]: {
      disabled: { bg: black, text: white, border: 'none' },
      default: { text: black, border: black, bg: 'transparent' },
      hover: { border: 'none', text: blackHover, bg: tetrieryGreyHover },
      action: { border: 'none', text: blackAction, bg: tetrieryGreyAction },
    },
    [ButtonColors.BlackWhite]: {
      disabled: { bg: white, text: black, border: 'none' },
      default: { text: white, border: white, bg: 'transparent' },
      hover: { border: 'none', text: whiteHover, bg: tetrieryGreyHover },
      action: { border: 'none', text: whiteAction, bg: tetrieryGreyAction },
    },
    [ButtonColors.Transparent]: {
      default: { text: black, border: blue, bg: 'transparent' },
      disabled: { text: black, border: blue, bg: 'transparent' },
      hover: { text: blackHover, border: blueHover, bg: 'transparent' },
      action: { bg: 'transparent', text: blackAction, border: blueAction },
    },
    [ButtonColors.Red]: {
      default: { border: red, bg: 'transparent', text: additionalRed },
      disabled: { border: red, bg: 'transparent', text: additionalRed },
      hover: { bg: tetrieryRed, border: redHover, text: additionalRed },
      action: { border: redAction, text: additionalRed, bg: tetrieryRedHover },
    },
    [ButtonColors.LightGrey]: {
      hover: { text: black, bg: whiteHover, border: secondaryGrey },
      action: { text: black, bg: whiteAction, border: secondaryGrey },
      default: { text: black, bg: 'transparent', border: secondaryGrey },
      disabled: {
        text: black,
        bg: 'transparent',
        border: secondaryGrey,
      },
    },
    [ButtonColors.Green]: {
      default: { border: green, bg: 'transparent', text: additionalGreen },
      disabled: { border: green, bg: 'transparent', text: additionalGreen },
      hover: { bg: tetrieryGreen, border: greenHover, text: additionalGreen },
      action: {
        border: greenAction,
        text: additionalGreen,
        bg: tetrieryGreenHover,
      },
    },
    [ButtonColors.Yellow]: {
      default: { border: yellow, bg: 'transparent', text: additionalYellow },
      disabled: { border: yellow, bg: 'transparent', text: additionalYellow },
      hover: {
        bg: tetrieryYellow,
        border: yellowHover,
        text: additionalYellow,
      },
      action: {
        border: yellowAction,
        text: additionalYellow,
        bg: tetrieryYellowHover,
      },
    },
  },

  [ButtonStyleTypes.Ghost]: {
    [ButtonColors.LightRed]: null,
    [ButtonColors.LightGrey]: null,
    [ButtonColors.BlackWhite]: null,
    [ButtonColors.LightGreen]: null,
    [ButtonColors.LightPurple]: null,
    [ButtonColors.LightYellow]: null,
    [ButtonColors.PigmentIndigo]: null,
    [ButtonColors.LightCerulean]: null,
    [ButtonColors.WhiteBlack]: {
      hover: { text: white, border: 'none', bg: blackHover },
      action: { text: white, border: 'none', bg: blackAction },
      default: { text: white, border: 'none', bg: 'transparent' },
      disabled: { text: white, border: 'none', bg: 'transparent' },
    },
    [ButtonColors.Red]: {
      default: { text: red, border: 'none', bg: 'transparent' },
      disabled: { text: red, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: redHover, bg: tetrieryRedHover },
      action: { border: 'none', text: redAction, bg: tetrieryRedAction },
    },
    [ButtonColors.WhiteBlue]: {
      disabled: { text: grey, border: 'none', bg: 'transparent' },
      default: { text: black, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: blueHover, bg: 'transparent' },
      action: { border: 'none', text: blueAction, bg: 'transparent' },
    },
    [ButtonColors.TetrieryGrey]: {
      disabled: { text: black, border: 'none', bg: tetrieryGrey },
      default: { text: black, border: 'none', bg: 'transparent' },
      hover: { text: black, border: 'none', bg: tetrieryGreyHover },
      action: { text: black, border: 'none', bg: tetrieryGreyAction },
    },
    [ButtonColors.Blue]: {
      default: { text: blue, border: 'none', bg: 'transparent' },
      disabled: { text: blue, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: blueHover, bg: tetrieryBlueHover },
      action: { border: 'none', text: blueAction, bg: tetrieryBlueAction },
    },
    [ButtonColors.Transparent]: {
      default: { text: black, border: 'none', bg: 'transparent' },
      disabled: { text: black, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: blackHover, bg: 'transparent' },
      action: { border: 'none', text: blackAction, bg: 'transparent' },
    },
    [ButtonColors.Green]: {
      default: { text: green, border: 'none', bg: 'transparent' },
      disabled: { text: green, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: greenHover, bg: tetrieryGreenHover },
      action: { border: 'none', text: greenAction, bg: tetrieryGreenAction },
    },
    [ButtonColors.LightBlue]: {
      default: { text: black, border: 'none', bg: 'transparent' },
      hover: { border: 'none', text: blueHover, bg: secondaryBlueHover },
      action: { border: 'none', text: blueHover, bg: secondaryBlueHover },
      disabled: {
        border: 'none',
        text: blackAction,
        bg: 'transparent',
      },
    },
    [ButtonColors.Yellow]: {
      default: { border: 'none', bg: 'transparent', text: yellowAction },
      disabled: { border: 'none', bg: 'transparent', text: yellowAction },
      hover: { border: 'none', bg: tetrieryYellow, text: additionalYellow },
      action: {
        border: 'none',
        text: additionalYellow,
        bg: tetrieryYellowHover,
      },
    },
  },

  [ButtonStyleTypes.Standard]: {
    [ButtonColors.Transparent]: null,
    [ButtonColors.Red]: {
      default: { bg: red, text: white, border: 'none' },
      disabled: { bg: red, text: white, border: 'none' },
      hover: { text: white, bg: redHover, border: 'none' },
      action: { text: white, bg: redAction, border: 'none' },
    },
    [ButtonColors.Blue]: {
      default: { bg: blue, text: white, border: 'none' },
      disabled: { bg: blue, text: white, border: 'none' },
      hover: { text: white, bg: blueHover, border: 'none' },
      action: { text: white, border: 'none', bg: blueAction },
    },
    [ButtonColors.Green]: {
      default: { bg: green, text: white, border: 'none' },
      disabled: { bg: green, text: white, border: 'none' },
      hover: { text: white, border: 'none', bg: greenHover },
      action: { text: white, border: 'none', bg: greenAction },
    },
    [ButtonColors.BlackWhite]: {
      default: { bg: white, text: black, border: 'none' },
      disabled: { bg: white, text: black, border: 'none' },
      hover: { text: black, border: 'none', bg: whiteHover },
      action: { text: black, border: 'none', bg: whiteAction },
    },
    [ButtonColors.WhiteBlack]: {
      default: { bg: black, text: white, border: 'none' },
      disabled: { bg: black, text: white, border: 'none' },
      hover: { text: white, border: 'none', bg: blackHover },
      action: { text: white, border: 'none', bg: blackAction },
    },
    [ButtonColors.Yellow]: {
      default: { bg: yellow, text: white, border: 'none' },
      disabled: { bg: yellow, text: white, border: 'none' },
      hover: { text: white, border: 'none', bg: yellowHover },
      action: { text: white, border: 'none', bg: yellowAction },
    },
    [ButtonColors.LightGrey]: {
      hover: { text: grey, border: 'none', bg: secondaryGrey },
      action: { text: grey, border: 'none', bg: secondaryGrey },
      default: { text: grey, border: 'none', bg: secondaryGrey },
      disabled: { text: grey, border: 'none', bg: secondaryGrey },
    },
    [ButtonColors.TetrieryGrey]: {
      default: { text: black, border: 'none', bg: tetrieryGrey },
      disabled: { text: black, border: 'none', bg: tetrieryGrey },
      hover: { text: black, border: 'none', bg: tetrieryGreyHover },
      action: { text: black, border: 'none', bg: tetrieryGreyAction },
    },
    [ButtonColors.WhiteBlue]: {
      default: { text: blue, border: 'none', bg: secondaryBlue },
      disabled: { text: blue, border: 'none', bg: secondaryBlue },
      hover: { border: 'none', text: blueHover, bg: secondaryBlueHover },
      action: { border: 'none', text: blueAction, bg: secondaryBlueAction },
    },
    [ButtonColors.LightBlue]: {
      default: { text: blue, border: 'none', bg: secondaryBlue },
      disabled: { text: blue, border: 'none', bg: secondaryBlue },
      hover: { border: 'none', text: blueHover, bg: secondaryBlueHover },
      action: { border: 'none', text: blueAction, bg: secondaryBlueAction },
    },
    [ButtonColors.LightRed]: {
      default: { border: 'none', bg: secondaryRed, text: additionalRed },
      disabled: { border: 'none', bg: secondaryRed, text: additionalRed },
      hover: { border: 'none', text: additionalRed, bg: secondaryRedHover },
      action: { border: 'none', text: additionalRed, bg: secondaryRedAction },
    },
    [ButtonColors.LightPurple]: {
      default: { border: 'none', text: purpleAction, bg: secondaryPurple },
      disabled: { border: 'none', text: purpleAction, bg: secondaryPurple },
      hover: { border: 'none', text: purpleAction, bg: secondaryPurpleHover },
      action: { border: 'none', text: purpleAction, bg: secondaryPurpleAction },
    },
    [ButtonColors.LightYellow]: {
      default: { border: 'none', text: yellowAction, bg: secondaryYellow },
      disabled: { border: 'none', text: yellowAction, bg: secondaryYellow },
      hover: { border: 'none', text: yellowAction, bg: secondaryYellowHover },
      action: { border: 'none', text: yellowAction, bg: secondaryYellowAction },
    },
    [ButtonColors.LightGreen]: {
      default: { border: 'none', bg: secondaryGreen, text: additionalGreen },
      disabled: { border: 'none', bg: secondaryGreen, text: additionalGreen },
      hover: { border: 'none', text: additionalGreen, bg: secondaryGreenHover },
      action: {
        border: 'none',
        text: additionalGreen,
        bg: secondaryGreenAction,
      },
    },
    [ButtonColors.LightCerulean]: {
      default: { border: 'none', text: ceruleanAction, bg: secondaryCerulean },
      disabled: { border: 'none', text: ceruleanAction, bg: secondaryCerulean },
      hover: {
        border: 'none',
        text: ceruleanAction,
        bg: secondaryCeruleanHover,
      },
      action: {
        border: 'none',
        text: ceruleanAction,
        bg: secondaryCeruleanAction,
      },
    },
    [ButtonColors.PigmentIndigo]: {
      default: {
        border: 'none',
        text: pigmentIndigo,
        bg: secondaryPigmentIndigo,
      },
      disabled: {
        border: 'none',
        text: pigmentIndigo,
        bg: secondaryPigmentIndigo,
      },
      hover: {
        border: 'none',
        text: pigmentIndigoHover,
        bg: secondaryPigmentIndigoHover,
      },
      action: {
        border: 'none',
        text: pigmentIndigoAction,
        bg: secondaryPigmentIndigoAction,
      },
    },
  },
});

const input = Object.freeze({
  text: black,
  placeholder: grey,
  range: secondaryGrey,
  label: { default: black, disabled: grey },
  bg: { default: white, disabled: whiteHover },
  border: {
    error: red,
    active: blue,
    errorHover: redHover,
    default: secondaryGrey,
    hover: secondaryGreyHover,
    disabled: secondaryGreyHover,
  },
});

const text = Object.freeze({
  [TextColor.Red]: red,
  [TextColor.Blue]: blue,
  [TextColor.Green]: green,
  [TextColor.White]: white,
  [TextColor.Black]: black,
  [TextColor.Inverse]: white,
  [TextColor.Primary]: black,
  [TextColor.Secondary]: grey,
  [TextColor.Mention]: '#2576FA',
});

const select = Object.freeze({
  bg: white,
  clearIcon: grey,
  option: { selected: whiteHover },
  text: { default: black, disabled: grey },
  label: { default: black, disabled: grey },
  countryMultiValue: { bg: tetrieryGreyHover },
  multiValue: { text: black, bg: secondaryCerulean },
  border: {
    error: red,
    active: blue,
    errorHover: redHover,
    default: secondaryGrey,
    disabled: secondaryGrey,
    hover: secondaryGreyHover,
  },
});

const link = Object.freeze({
  default: blue,
  hover: blueHover,
  bgTextColor: black,
  action: blueAction,
  background: {
    default: white,
    hover: whiteHover,
    shadow: cardShadow,
    action: whiteAction,
  },
});

const tab = Object.freeze({
  badge: white,
  shadow: whiteHover,
  count: {
    [TabCountColor.Blue]: blue,
    [TabCountColor.Red]: additionalRed,
  },
  text: {
    active: blue,
    default: text.primary,
    disabled: secondaryGreyAction,
  },
  block: {
    text: {
      active: blue,
    },
    bg: {
      active: secondaryBlue,
    },
  },
});

const selection = Object.freeze({
  border: tetrieryGrey,
  text: {
    selected: blue,
    default: text.primary,
    disabled: secondaryGrey,
  },
  bg: {
    default: white,
    hover: tetrieryBlue,
    selected: secondaryBlue,
    active: tetrieryBlueHover,
  },
});

const eventCard = Object.freeze({
  dot: blue,
  date: grey,
  line: secondaryGrey,
});

const alert = Object.freeze({
  close: black,
  icon: {
    [types.INFO]: black,
    [types.SUCCESS]: green,
    [types.ERROR]: redAction,
  },
  bg: {
    [types.INFO]: tetrieryBlueHover,
    [types.ERROR]: tetrieryRedHover,
    [types.SUCCESS]: tetrieryGreenHover,
  },
});

const popup = Object.freeze({
  bg: white,
  shadow: regularShadow,
  modal: `${black}66` as string,
  black: { bg: black, text: white },
  modal96: `${additionalGrey}F5` as string,
});

const pagination = Object.freeze({
  container: {
    shadow: whiteHover,
  },
  arrow: {
    default: black,
    disabled: secondaryGrey,
  },
  number: {
    selected: blue,
    hover: secondaryBlue,
    active: secondaryBlueHover,
  },
});

const table = Object.freeze({
  innerDivider: tetrieryGreyHover,

  head: {
    title: grey,
    shadow: `inset 0px -1px 0px ${whiteHover}`,
  },

  [ListCardStyle.Default]: {
    disabled: { bg: white, text: grey, border: 'none' },
    default: { bg: white, text: black, border: 'none' },
    hover: { text: black, border: 'none', bg: whiteHover },
    action: { text: black, border: 'none', bg: whiteAction },
    selected: {
      bg: white,
      text: black,
      border: `1px solid ${secondaryGrey}`,
    },
  },

  [ListCardStyle.Green]: {
    hover: { text: black, border: 'none', bg: tetrieryGreen },
    action: { text: black, border: 'none', bg: tetrieryGreen },
    disabled: { text: grey, border: 'none', bg: tetrieryGreen },
    default: { text: black, border: 'none', bg: tetrieryGreen },
    selected: {
      text: black,
      bg: tetrieryGreen,
      border: `1px solid ${greenAction}`,
    },
  },

  [ListCardStyle.Grey]: {
    disabled: { text: grey, border: 'none', bg: tetrieryGrey },
    default: { text: black, border: 'none', bg: tetrieryGrey },
    hover: { text: black, border: 'none', bg: tetrieryGreyHover },
    action: { text: black, border: 'none', bg: tetrieryGreyAction },
    selected: {
      text: black,
      bg: tetrieryGrey,
      border: `1px solid ${secondaryGrey}`,
    },
  },

  [ListCardStyle.LightRed]: {
    default: { text: black, border: 'none', bg: tetrieryRed },
    hover: { text: black, border: 'none', bg: tetrieryRedHover },
    action: { text: black, border: 'none', bg: tetrieryRedAction },
    disabled: {
      text: grey,
      border: 'none',
      bg: tetrieryRedAction,
    },
    selected: {
      text: black,
      bg: tetrieryRed,
      border: `1px solid ${redAction}`,
    },
  },

  [ListCardStyle.LightCerulean]: {
    default: { text: black, border: 'none', bg: tetrieryCerulean },
    disabled: { text: black, border: 'none', bg: tetrieryCerulean },
    hover: { text: black, border: 'none', bg: tetrieryCeruleanHover },
    action: { text: black, border: 'none', bg: tetrieryCeruleanAction },
    selected: {
      text: black,
      bg: tetrieryCerulean,
      border: `1px solid ${ceruleanAction}`,
    },
  },

  [ListCardStyle.LightPuprle]: {
    hover: { text: black, border: 'none', bg: tetrieryPurple },
    action: { text: black, border: 'none', bg: tetrieryPurple },
    default: { text: black, border: 'none', bg: tetrieryPurple },
    disabled: {
      text: grey,
      border: 'none',
      bg: tetrieryPurpleAction,
    },
    selected: {
      text: black,
      bg: tetrieryPurple,
      border: `1px solid ${purpleAction}`,
    },
  },
});

const filter = Object.freeze({
  text: greyAction,
  shadow: whiteHover,
  svg: { applied: red, active: greenHover },
});

const slidePanel = Object.freeze({
  border: whiteAction,
  overlay: `${black}66` as string,
  shadow:
    `-4px 0px 24px rgba(0, 0, 0, 0.04), inset 1px 0px 0px ${whiteAction}` as string,
});

const numberCard = Object.freeze({
  subHeading: grey,
  border: secondaryGrey,
  bg: 'inherit' as string,
});

const calendar = Object.freeze({
  container: {
    background: 'transparent',
    primaryText: text.primary,
    secondaryText: text.secondary,
  },

  day: {
    currentDay: {
      textColor: blue,
      hoverTextColor: blueHover,
    },

    dateBetween: {
      textColor: black,
      backgroundColor: tetrieryBlue,
    },

    borderRadius: {
      all: '8px 8px 8px 8px',
      left: '8px 0px 0px 8px',
      none: '0px 0px 0px 0px',
      right: '0px 8px 8px 0px',
    },

    selectedDay: {
      textColor: white,
      backgroundColor: blue,
      hoverTextColor: whiteHover,
      hoverBackgroundColor: blueHover,
    },

    default: {
      opacity: '1.0',
      textColor: text.primary,
      hoverTextColor: blackHover,
      backgroundColor: 'transparent',
      hoverBackgroundColor: tetrieryBlue,
    },
  },
});

const toastify = Object.freeze({
  bg: black,
  text: white,
});

const dropImage = Object.freeze({
  error: {
    text: additionalRed,
    bg: { default: tetrieryRed, hover: tetrieryRedHover },
  },
  standard: {
    text: black,
    bg: {
      default: tetrieryGrey,
      hover: tetrieryGreyHover,
      active: tetrieryGreyAction,
    },
  },
});

const numpad = Object.freeze({
  border: secondaryGrey,
  number: {
    disabled: {
      text: input.placeholder,
    },
    selected: {
      text: text.white,
      bg: {
        default: blue,
        hover: blueHover,
        active: blueAction,
      },
    },
    default: {
      text: 'inherit',
      bg: {
        hover: whiteHover,
        default: 'inherit',
        active: whiteAction,
      },
    },
    filled: {
      text: 'inherit',
      bg: {
        default: secondaryBlue,
        hover: secondaryBlueHover,
        active: secondaryBlueAction,
      },
    },
  },
});

const booleanBadge = Object.freeze({
  negative: {
    icon: red,
    bg: tetrieryRedHover,
  },
  positive: {
    bg: tetrieryGreen,
    icon: additionalGreen,
  },
});

const error = Object.freeze({
  color: red,
});

const tree = Object.freeze({
  line: secondaryGreyHover,
});

const infoBlock = Object.freeze({
  background: white,
  hover: whiteHover,
  shadow: infoShadow,
  action: whiteAction,
});

const chat = Object.freeze({
  shadow: additionalGrey,
  placeholder: {
    text: grey,
  },
  main: {
    bg: additionalGrey,
  },
  icon: {
    bg: white,
    color: blue,
  },
});

const graph = Object.freeze({
  gradient: `${grey} 70%, ${tetrieryGrey} 0 100% `,
});

const settingRow = Object.freeze({
  border: whiteAction,
});

const errorColor = Object.freeze({
  [ErrorColor.Blue]: {
    text: white,
    icon: white,
    background: blue,
  },
  [ErrorColor.Green]: {
    text: white,
    icon: white,
    background: green,
  },
  [ErrorColor.Black]: {
    text: white,
    icon: white,
    background: black,
  },
  [ErrorColor.Grey]: {
    text: black,
    icon: black,
    background: secondaryGrey,
  },
  [ErrorColor.LightRed]: {
    text: black,
    icon: black,
    background: secondaryRed,
  },
  [ErrorColor.Red]: {
    text: black,
    icon: black,
    background: secondaryRedHover,
  },
  [ErrorColor.LightBlue]: {
    icon: blue,
    text: black,
    background: secondaryBlue,
  },
  [ErrorColor.Orange]: {
    text: black,
    icon: black,
    background: secondaryOrangeHover,
  },
  [ErrorColor.Yellow]: {
    text: black,
    icon: black,
    background: secondaryYellowHover,
  },
  [ErrorColor.LightGreen]: {
    text: black,
    icon: black,
    background: secondaryGreenHover,
  },
});

const progressBar = Object.freeze({
  text: black,
  icon: { done: blue, default: black },
  stick: {
    done: blue,
    default: secondaryGrey,
  },
});

const bottomTab = Object.freeze({
  dash: blue,
  icon: { active: blue, disabled: grey, default: black },
});

const fieldCard = Object.freeze({
  shadow: '0 8px 24px rgba(0, 27, 71, 0.08)' as string,
});

const eventModal = Object.freeze({
  shadow: '0px 8px 16px rgba(0, 0, 0, 0.04);',
});

const teamMember = Object.freeze({
  [TeamMemberColor.Blue]: {
    hover: { bg: secondaryBlueHover },
    default: { color: blue, bg: secondaryBlue },
    selected: { border: blue, bg: secondaryBlue },
    disabled: { text: blackHover, bg: secondaryGrey },
  },
  [TeamMemberColor.Red]: {
    hover: { bg: secondaryRedHover },
    disabled: { text: blackHover, bg: secondaryGrey },
    default: { bg: secondaryRed, color: additionalRed },
    selected: { bg: secondaryRed, border: additionalRed },
  },
  [TeamMemberColor.Green]: {
    hover: { bg: secondaryGreenHover },
    disabled: { text: blackHover, bg: secondaryGrey },
    default: { bg: secondaryGreen, color: additionalGreen },
    selected: { bg: secondaryGreen, border: additionalGreen },
  },
  [TeamMemberColor.Orange]: {
    hover: { bg: tetrieryOrangeAction },
    disabled: { text: blackHover, bg: secondaryGrey },
    default: { bg: tetrieryOrange, color: additionalOrange },
    selected: { bg: tetrieryOrange, border: additionalOrange },
  },
  [TeamMemberColor.Yellow]: {
    hover: { bg: secondaryYellowHover },
    disabled: { text: blackHover, bg: secondaryGrey },
    default: { bg: secondaryYellow, color: additionalYellow },
    selected: { bg: secondaryYellow, border: additionalYellow },
  },
  [TeamMemberColor.Purple]: {
    hover: { bg: secondaryPurpleHover },
    disabled: { text: blackHover, bg: secondaryGrey },
    default: { bg: secondaryPurple, color: additionalPurple },
    selected: { bg: secondaryPurple, border: additionalPurple },
  },
  [TeamMemberColor.Cerulean]: {
    hover: { bg: secondaryCeruleanHover },
    disabled: { text: blackHover, bg: secondaryGrey },
    default: { bg: secondaryCerulean, color: additionalCerulean },
    selected: {
      bg: secondaryCerulean,
      border: additionalCerulean,
    },
  },
});

const barChart = Object.freeze({
  axisLine: greyHover,
  defaultBarColor: grey,
  textColor: text.primary,
  gridLineStroke: tetrieryGreyAction,
  tooltip: {
    bg: white,
    color: text.primary,
  },
});

const rangeSlider = Object.freeze({
  track: blue,
  handle: blue,
  rail: tetrieryGrey,
  tooltip: {
    bg: black,
    color: white,
  },
});

const reaction = Object.freeze({
  text: {
    includesMy: white,
  },
  background: {
    includesMy: blue,
    grey: tetrieryGreyAction,
  },
});

export const dotsLoader = Object.freeze({
  [DotsColor.Grey]: grey,
  [DotsColor.Primary]: black,
});

const avatarColor = Object.freeze({
  [AvatarColor.Orange]: {
    icon: orange,
    textColor: orangeAction,
    backgroundColor: tetrieryOrange,
    backgroundHover: tetrieryOrangeHover,
    backgroundActive: tetrieryOrangeAction,
  },
  [AvatarColor.Blue]: {
    icon: additionalBlue,
    textColor: additionalBlue,
    backgroundColor: secondaryBlue,
    backgroundHover: secondaryBlueHover,
    backgroundActive: secondaryBlueAction,
  },
  [AvatarColor.Cerulean]: {
    icon: cerulean,
    textColor: cerulean,
    backgroundColor: secondaryCerulean,
    backgroundHover: secondaryCeruleanHover,
    backgroundActive: secondaryCeruleanAction,
  },
  [AvatarColor.Green]: {
    icon: additionalGreen,
    textColor: additionalGreen,
    backgroundColor: secondaryGreen,
    backgroundHover: secondaryGreenHover,
    backgroundActive: secondaryGreenAction,
  },
  [AvatarColor.Pink]: {
    icon: additionalPurple,
    textColor: additionalPurple,
    backgroundColor: secondaryPurple,
    backgroundHover: secondaryPurpleHover,
    backgroundActive: secondaryPurpleAction,
  },
  [AvatarColor.Yellow]: {
    icon: additionalYellow,
    textColor: additionalYellow,
    backgroundColor: secondaryYellow,
    backgroundHover: secondaryYellowHover,
    backgroundActive: secondaryYellowAction,
  },
});

const eventCalendar = Object.freeze({
  border: whiteHover,
});

const cashCard = Object.freeze({
  [CashCardType.Out]: {
    icon: red,
    background: tetrieryRed,
  },
  [CashCardType.Cash]: {
    icon: orange,
    background: tetrieryOrange,
  },
  [CashCardType.Balance]: {
    icon: green,
    background: secondaryGreenAction,
  },
});

const salesCompatition = Object.freeze({
  [SalesCompatitionPosition.Second]: {
    text: white,
    background: grey,
  },
  [SalesCompatitionPosition.First]: {
    text: white,
    background: yellow,
  },
  [SalesCompatitionPosition.Third]: {
    text: white,
    background: orange,
  },
});

const LIGHT_CONFIG = Object.freeze({
  tab,
  chat,
  tree,
  link,
  text,
  alert,
  error,
  badge,
  input,
  popup,
  table,
  graph,
  common,
  avatar,
  button,
  circle,
  filter,
  select,
  numpad,
  subMenu,
  sidebar,
  reaction,
  calendar,
  checkbox,
  toastify,
  barChart,
  cashCard,
  fieldCard,
  bottomTab,
  infoBlock,
  eventCard,
  dropImage,
  selection,
  eventModal,
  errorColor,
  numberCard,
  pagination,
  slidePanel,
  teamMember,
  settingRow,
  dotsLoader,
  avatarColor,
  rangeSlider,
  progressBar,
  radioButton,
  booleanBadge,
  eventCalendar,
  switchCheckbox,
  salesCompatition,
  background: white,
  border: blackHover,
});

export { LIGHT_CONFIG };
