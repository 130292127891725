import { addParamsToUrl } from 'utils/query';

import { URL } from '../constants';
import { apiInstance } from '../instance';

import { GetTaxByZipcodeRequest, GetTaxByZipcodeResponse } from './types';

export const getTaxByZipcode = ({ zip, city }: GetTaxByZipcodeRequest) =>
  apiInstance.get<GetTaxByZipcodeResponse[]>(
    addParamsToUrl(URL.EVENT_GET_TAX_RATE, { city, zip_code: zip })
  );
