import { useContext } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { defaultColors } from 'utils/charts';

import { ThemeContext } from '../../context';

import * as Styles from './styles';
import { BarChartProps, BarChartItemProps, ResponsiveBarProps } from './types';

export const BarChart = ({
  data,
  theme,
  tooltip,
  axisLeft,
  className,
  axisBottom,
  customTooltip,
  padding = 0.8,
  getTooltipValue,
  enableLabel = false,
  colors = defaultColors,
  ...props
}: BarChartProps): JSX.Element | null => {
  const { theme: mainTheme } = useContext(ThemeContext);
  const { axisLine, textColor, gridLineStroke } = mainTheme.config.barChart;

  const getTooltip: ResponsiveBarProps['tooltip'] = (bar) =>
    data?.length ? (
      <Styles.Tooltip>
        {getTooltipValue?.(bar.data) ?? bar.data.value}
      </Styles.Tooltip>
    ) : null;

  const getConfigColor: ResponsiveBarProps['colors'] = (bar) =>
    colors[bar.index % colors.length];

  return (
    <Styles.Container className={className}>
      <ResponsiveBar
        data={data}
        padding={padding}
        colors={getConfigColor}
        enableLabel={enableLabel}
        tooltip={tooltip ?? customTooltip ?? getTooltip}
        axisBottom={{
          tickSize: 0,
          ...axisBottom,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 23,
          ...axisLeft,
        }}
        theme={{
          text: {
            fontSize: 12,
            fill: textColor,
          },
          grid: {
            line: {
              stroke: gridLineStroke,
            },
          },
          axis: {
            domain: {
              line: {
                stroke: axisLine,
              },
            },
          },
          ...theme,
        }}
        {...props}
      />
    </Styles.Container>
  );
};

export type { BarChartItemProps };
