import { Icon } from 'components/icon';
import { ButtonColors } from 'types/enums';
import { Button } from 'components/button';
import { EllipsisText } from 'components/ellipsis-text';

import * as Styles from './styles';
import { SectionExandableProps } from './types';

export const SectionHeaderExpandable = ({
  title,
  isOpened,
  onToggle,
  className,
  rightSlot,
}: SectionExandableProps) => {
  const hasButton = typeof isOpened === 'boolean' && onToggle !== undefined;
  return (
    <Styles.ContainerExp className={className}>
      <Styles.Title withButton={hasButton}>
        {typeof title === 'string' ? (
          <EllipsisText as="h3">{title}</EllipsisText>
        ) : (
          title
        )}
      </Styles.Title>
      {rightSlot}
      {hasButton && (
        <Button
          padding="0"
          onClick={() => onToggle(!isOpened)}
          colorType={ButtonColors.Transparent}
          icon={isOpened ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
        />
      )}
    </Styles.ContainerExp>
  );
};
