import { useContext } from 'react';
import { ResponsiveLine } from '@nivo/line';

import { Flex } from 'components/flex';
import { ThemeContext } from 'context';
import { blueColors } from 'utils/charts';

import * as Common from '../bar-chart/styles';

import * as Styles from './styles';
import {
  LineChartProps,
  LineChartPoint,
  LineChartDataProps,
  LineChartItemProps,
} from './types';

const defaultMargin = { top: 20, left: 50, right: 20, bottom: 40 };

const LineChart = ({
  data,
  theme,
  height,
  tooltip,
  axisLeft,
  className,
  axisBottom,
  sliceTooltip,
  enableSlices,
  lineWidth = 2,
  useMesh = true,
  getTooltipValue,
  curve = 'monotoneX',
  colors = blueColors,
  margin = defaultMargin,
  enableCrosshair = false,
  ...props
}: LineChartProps): JSX.Element => {
  const { theme: mainTheme } = useContext(ThemeContext);
  const { axisLine, textColor, gridLineStroke } = mainTheme.config.barChart;

  const dataCount = data?.length;

  const getTooltip: LineChartProps['tooltip'] = ({ point }) =>
    dataCount ? (
      <Common.Tooltip>
        {getTooltipValue?.(point) ?? `$${point.data.y}`}
      </Common.Tooltip>
    ) : null;

  const getSliceTooltip: LineChartProps['sliceTooltip'] = ({ slice }) => {
    return dataCount ? (
      <Common.Tooltip>
        <Flex fullWidth flexDirection="column">
          {slice.points?.map((item, idx) => (
            <p key={idx}>{getTooltipValue?.(item) ?? `$${item.data.y}`}</p>
          ))}
        </Flex>
      </Common.Tooltip>
    ) : null;
  };

  return (
    <Styles.Container height={height} className={className}>
      <ResponsiveLine
        data={data}
        pointSize={6}
        curve={curve}
        margin={margin}
        colors={colors}
        useMesh={useMesh}
        lineWidth={lineWidth}
        enableSlices={enableSlices}
        tooltip={tooltip ?? getTooltip}
        enableCrosshair={enableCrosshair}
        sliceTooltip={
          enableSlices ? (sliceTooltip ?? getSliceTooltip) : undefined
        }
        axisLeft={
          axisLeft === null
            ? axisLeft
            : {
                tickSize: 0,
                tickPadding: 12,
                tickRotation: 0,
                ...axisLeft,
              }
        }
        axisBottom={
          axisBottom === null
            ? axisBottom
            : {
                tickSize: 0,
                tickPadding: 12,
                tickRotation: 0,
                ...axisBottom,
              }
        }
        theme={{
          text: {
            fontSize: 10,
            fill: textColor,
          },
          grid: {
            line: {
              stroke: gridLineStroke,
            },
          },
          axis: {
            domain: {
              line: {
                stroke: axisLine,
              },
            },
          },
          ...theme,
        }}
        {...props}
      />
    </Styles.Container>
  );
};

export { LineChart };
export type {
  LineChartProps,
  LineChartPoint,
  LineChartDataProps,
  LineChartItemProps,
};
