import { AppRoutes } from 'config';
import { useNavigate, handleMutation } from 'hooks';
import { useCreateMutation } from 'store/api/company';

import { Form } from './form';

export const Create = () => {
  const navigate = useNavigate();
  const [create, mutation] = useCreateMutation();

  handleMutation<{ id: number }>({
    ...mutation,
    onSuccess: (data) =>
      data && navigate(AppRoutes.Company.Home, { replace: true }),
  });

  return <Form onSubmit={create} />;
};
