import styled from 'styled-components';

import { Tabs } from 'components/tabs';

export const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  min-height: 0;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`;

export const StyledTabs = styled(Tabs)`
  padding: 0 16px;
`;
