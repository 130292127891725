import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import * as Styles from './styles';
import { toastifyParams } from './config';
import { ToastifyMessageProps } from './types';

const ToastifyMessage = (props: ToastifyMessageProps = toastifyParams) => {
  return (
    <Styles.Container>
      <ToastContainer {...props} />
    </Styles.Container>
  );
};

export { ToastifyMessage };
