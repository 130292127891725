import { useMemo, useState } from 'react';

import { User } from 'types';
import { isMobile } from 'hooks';
import { Flex } from 'components/flex';
import { List } from 'components/list';
import { Info } from 'components/info';
import { formatDate } from 'utils/date';
import { mapQueries } from 'utils/query';
import { formatPrice } from 'utils/helpers';
import { useListQuery } from 'store/api/event';
import { ListCard } from 'components/list-card';
import { SelectOption } from 'components/select';
import { DatePicker } from 'components/date-picker';
import { ListHeader } from 'components/list-header';
import { Avatar, AvatarPlaceholderType } from 'components/avatar';
import { useDetailsQuery, useTopSellersQuery } from 'store/api/event';
import {
  isMultiSelectValue,
  makeSelectOptionsFromEntities,
} from 'utils/helpers';

import * as Styles from './styles';
import { EmployeeCompetitionListProps } from './types';

const titles = ['Name', 'Net Sales', 'Total Sales'];
const cellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/2' },
  { row: '2/3', col: '2/3' },
];

interface UserCardProps {
  user?: User;
  isHideAvatar?: boolean;
}

const UserCard = ({ user, isHideAvatar }: UserCardProps) => (
  <Flex gap={16} alignItems="center">
    {!isHideAvatar && (
      <Avatar
        square
        size={56}
        url={user?.photoUrl}
        userName={user?.name}
        placeholder={AvatarPlaceholderType.UserAvatar}
      />
    )}
    <Flex flexDirection="column">
      <h4>{user?.name}</h4>
      <Styles.Role>{user?.role?.name}</Styles.Role>
    </Flex>
  </Flex>
);

export const EmployeeCompetitionList = ({
  currentEventId,
}: EmployeeCompetitionListProps) => {
  const mobile = isMobile();
  const [date, setDate] = useState(new Date());
  const [eventIds, setEventIds] = useState<SelectOption[]>([]);

  const { data: event, ...eventProps } = useDetailsQuery(
    String(currentEventId!),
    { skip: !currentEventId }
  );

  const { data: events, ...eventsProps } = useListQuery(undefined, {
    skip: !!currentEventId,
  });

  const { data, ...props } = useTopSellersQuery({
    date: date ? formatDate(date) : undefined,
    ids: eventIds.length
      ? JSON.stringify(eventIds.map((value) => value.value))
      : undefined,
  });

  const eventOptions = useMemo(
    () => makeSelectOptionsFromEntities(events),
    [events]
  );

  return (
    <List
      {...mapQueries([props, eventProps, eventsProps])}
      count={data?.topSellers?.length}
      header={<ListHeader titles={titles} />}
      topHeader={
        <Styles.HeaderContainer>
          <Styles.FilterContainer>
            {!!currentEventId ? (
              <h3>All Company Events</h3>
            ) : (
              <Styles.StyledSelect
                isMulti
                value={eventIds}
                options={eventOptions}
                placeholder="Select events"
                onChange={(value) => {
                  if (isMultiSelectValue(value)) {
                    setEventIds(value as SelectOption[]);
                  }
                }}
              />
            )}

            <Flex gap={8} alignItems="center">
              {data?.checkoutsTotal ? (
                <h4>Gross Sales: {formatPrice(data?.checkoutsTotal)}</h4>
              ) : undefined}
              <DatePicker
                selected={date}
                onChange={setDate}
                maxDate={new Date()}
              />
            </Flex>
          </Styles.FilterContainer>

          <Styles.TopEmployeeContainer>
            {data?.topSellers?.slice(0, 3)?.map((value, index) => (
              <Styles.UserCard key={index}>
                <Flex gap={16} flexDirection="column">
                  <Flex alignItems="center" justifyContent="space-between">
                    <UserCard user={value?.user} isHideAvatar={mobile} />

                    {!mobile && (
                      <Styles.Position position={index + 1}>
                        {index + 1}
                      </Styles.Position>
                    )}
                  </Flex>

                  <Info
                    gap={0}
                    title="Net Sales"
                    value={<h2>{formatPrice(value.netSales)}</h2>}
                  />
                </Flex>
              </Styles.UserCard>
            ))}
          </Styles.TopEmployeeContainer>
        </Styles.HeaderContainer>
      }
    >
      {data?.topSellers
        .slice(3)
        ?.map((value) => (
          <ListCard
            headers={titles}
            key={value.userId}
            cellsMobile={cellsMobile}
            values={[
              <UserCard user={value?.user} />,
              formatPrice(value.netSales),
              formatPrice(value.totalSales),
            ]}
          />
        ))}
    </List>
  );
};
