import { Icon } from 'components/icon';
import { Button, ButtonTypes } from 'components/button';
import { ButtonColors, ButtonStyleTypes } from 'types/enums';

import { Wrapper } from './styles';
import { FilterButtonProps } from './types';

const FilterButton = ({
  applied,
  calendar,
  onButtonClick,
  ...props
}: FilterButtonProps): JSX.Element => {
  return (
    <Wrapper applied={applied} calendar={calendar}>
      <Button
        nativeColorIcon
        type={ButtonTypes.Button}
        colorType={ButtonColors.WhiteBlue}
        styleType={ButtonStyleTypes.Standard}
        tooltip={calendar ? 'Calendar' : 'Filters'}
        icon={calendar ? <Icon.Calendar /> : <Icon.Filters />}
        onClick={() => {
          onButtonClick();
        }}
        {...props}
      />
    </Wrapper>
  );
};

export { FilterButton };
