import { PropsWithChildren } from 'react';

import { withRef } from 'hoc';

import * as Styles from './styles';
import { FlexProps } from './types';

const Flex = ({
  children,
  refForward,
  ...props
}: PropsWithChildren<FlexProps>): JSX.Element => {
  return (
    <Styles.Flex ref={refForward} {...props}>
      {children}
    </Styles.Flex>
  );
};

export const FlexWithRef = withRef(Flex);

export { Flex };
export type { FlexProps };
