import styled, { css, RuleSet } from 'styled-components';

import { Popup } from 'components/popup';
import { FlexWithRef } from 'components/flex';
import { bodySemibold } from 'styles/mixins/fonts';
import { CommonIconProps } from 'components/input/types';

const iconWrapper: RuleSet<any> = css<CommonIconProps>`
  display: flex;
  align-items: center;

  position: absolute;

  width: 48px;

  ${({ large, theme, disabled, withOnClick }) => css`
    height: ${large ? '48px' : '40px'};
    pointer-events: ${disabled ? 'none' : 'auto'};

    & > svg {
      ${withOnClick &&
      css`
        cursor: pointer;
      `}

      > path, circle {
        fill: ${disabled
          ? theme.config.input.label.disabled
          : theme.config.input.text} !important;
      }
    }
  `}
`;

export const InputFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const LeftSvgWrap = styled.div<CommonIconProps>`
  ${iconWrapper};

  left: 0;
  bottom: 0;

  padding-left: 16px;
`;

export const RightSvgWrap = styled.div<CommonIconProps>`
  ${iconWrapper};

  justify-content: flex-end;

  right: 0;
  bottom: 0;

  padding-right: 16px;

  & > span {
    ${bodySemibold};
    margin-left: 4px;
    cursor: pointer;
    color: ${(p) =>
      p.disabled
        ? p.theme.config.input.label.disabled
        : p.theme.config.input.text};
  }
`;

export const InputWrap = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

export const IconWrapper = styled(FlexWithRef)`
  align-items: center;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${({ theme }) => theme.config.text.primary};
    }
  }
`;

export const StyledPopup = styled(Popup)`
  &-content {
    padding: 8px !important;
    max-width: ${({ theme }) => (theme.responsive.isMobile ? '70vw' : '496px')};
  }
`;

export const Label = styled.label<{ disabled?: boolean }>`
  ${bodySemibold};
  gap: 4px;
  display: flex;
  color: ${(p) =>
    p.disabled
      ? p.theme.config.input.label.disabled
      : p.theme.config.input.label.default};
`;
