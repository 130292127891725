import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { providesList, invalidatesList } from 'utils/query-cache';

import { apiQuery } from '../../query';

import { Carrier } from './types';

const tagType = 'carriers';

export const carriersApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'carriersApi',
  endpoints: (build) => ({
    list: build.query<Carrier[], void>({
      providesTags: providesList(tagType),
      query: () => ({
        method: 'get',
        url: URL.CARRIERS,
      }),
    }),
    saveAll: build.mutation<void, Partial<Carrier>[]>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        method: 'put',
        url: URL.CARRIERS,
        data: {
          data: data.filter((item) => item.name),
        },
      }),
    }),
  }),
});

export const { useListQuery, useSaveAllMutation } = carriersApi;
export type { Carrier };
