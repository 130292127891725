import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { SearchRequest } from 'types';
import { addSearchParams } from 'utils/query';
import { filterEmptyFields } from 'utils/object';
import {
  providesList,
  cacheByIdArg,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import { Product, ProductFormValues, CreateProductFormValues } from './types';

const tagType = 'Product';

export const productApi = createApi({
  tagTypes: [tagType],
  baseQuery: apiQuery,
  reducerPath: 'productApi',
  endpoints: (build) => ({
    details: build.query<Product, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.PRODUCT_DETAILS.replace(':id', id),
      }),
    }),

    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.PRODUCT_DETAILS.replace(':id', id),
      }),
    }),

    list: build.query<Product[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: addSearchParams(URL.PRODUCT_LIST, params),
      }),
    }),

    create: build.mutation<void, CreateProductFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => {
        delete data.attributeIds;

        return {
          method: 'post',
          url: URL.PRODUCT_LIST,
          data: {
            ...data,
            attributeValueIds: Object.values(data.attributeValueIds).flat(),
          },
        };
      },
    }),

    edit: build.mutation<void, ProductFormValues>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, attributeIds, ...data }) => {
        return {
          method: 'patch',
          url: URL.PRODUCT_DETAILS.replace(':id', String(id)),
          data: filterEmptyFields({
            ...data,
            attributeValueIds: Object.entries(data.attributeValueIds)
              .filter(([attributeId]) => attributeIds?.includes(+attributeId))
              .map((value) => value[1])
              .flat(),
          }),
        };
      },
    }),
  }),
});

export const {
  useListQuery,
  useDetailsQuery,
  useEditMutation,
  useRemoveMutation,
  useCreateMutation,
} = productApi;
