import styled, { css } from 'styled-components';

export const Container = styled.div<{
  gap: string;
  padding: string;
  template: string;
  isScrolled: boolean;
  hasScrollbar: boolean;
}>`
  display: grid;
  font-weight: 600;
  text-align: left;
  ${({ gap, theme, padding, template, isScrolled, hasScrollbar }) => css`
    margin: 0 ${hasScrollbar ? 24 : 16}px 0 16px;
    grid-template-columns: ${template};
    gap: ${gap};
    color: ${theme.config.table.head.title};
    padding: ${padding};

    ${isScrolled &&
    css`
      box-shadow: ${theme.config.table.head.shadow};
    `}
  `}
`;
