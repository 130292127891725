import { InputHTMLAttributes } from 'react';
import { ReactGoogleAutocompleteInputProps } from 'react-google-autocomplete';

import { Config } from 'config';
import {
  InputContainer,
  InputContainerProps,
} from 'components/input-container';

import * as Styles from './styles';

export type AddressAutocompleteInputProps = InputContainerProps &
  ReactGoogleAutocompleteInputProps &
  InputHTMLAttributes<HTMLInputElement>;

export const AddressAutoCompleteInput = ({
  label,
  value,
  style,
  error,
  large,
  className,
  ...props
}: AddressAutocompleteInputProps) => {
  return (
    <InputContainer
      label={label}
      style={style}
      value={value}
      error={error}
      large={large}
      className={className}
    >
      <Styles.Input
        error={error}
        value={value}
        language="en"
        apiKey={Config.googleMapsApiKey}
        options={{
          types: ['geocode', 'establishment'],
          componentRestrictions: { country: 'US' },
        }}
        {...props}
      />
    </InputContainer>
  );
};
