import styled, { css } from 'styled-components';

import { captionBold } from 'styles/mixins/fonts';
import { Button as ButtonComponent } from 'components/button';

export const Container = styled.div`
  flex: 1;
  display: flex;
  ${({ theme }) => css`
    ${!theme.responsive.isMobile &&
    css`
      gap: 8px;
    `}
  `};
`;

export const Number = styled.span<{ isCurrent: boolean; isDisabled: boolean }>`
  ${captionBold};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  ${({ theme, isCurrent, isDisabled }) => css`
    ${isDisabled &&
    css`
      border: 2px solid ${theme.config.subMenu.title.color};
      color: ${theme.config.subMenu.title.color} !important;
    `}
    ${isCurrent &&
    css`
      border: 2px solid ${theme.config.text.blue};
      color: ${theme.config.text.blue} !important;
    `}
    ${!isDisabled &&
    !isCurrent &&
    css`
      color: ${theme.config.text.white} !important;
      background-color: ${theme.config.text.blue};
    `}
  `};
`;

export const Button = styled(ButtonComponent)<{ isCurrent: boolean }>`
  ${({ theme, disabled, isCurrent }) => css`
    flex-direction: column;

    ${isCurrent &&
    css`
      pointer-events: none;
    `}

    ${theme.responsive.isMobile &&
    css`
      span {
        ${captionBold};
      }
    `}

    ${disabled &&
    css`
      opacity: 1 !important;

      span {
        color: ${theme.config.subMenu.title.color} !important;
      }
    `}
  `};
`;

export const Divider = styled.div<{ isActive: boolean }>`
  display: flex;
  flex: 1;
  height: 1px;
  margin-top: 20px;

  ${({ theme, isActive }) => css`
    background-color: ${isActive
      ? theme.config.text.primary
      : theme.config.subMenu.title.color};
  `};
`;
