import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Flex } from 'components/flex';
import { SectionHeaderExpandable } from 'components/section-header';

import * as Styles from './styles';
import { SectionProps } from './types';

export const ExpandableSection = ({
  label,
  gap = 16,
  children,
  customHeader,
  defaultOpened = true,
}: SectionProps) => {
  const [isOpened, setIsOpened] = useState(defaultOpened);

  const onToggle = () => setIsOpened(!isOpened);

  return (
    <Flex gap={gap} flexDirection="column">
      {customHeader?.(isOpened, onToggle) || (
        <SectionHeaderExpandable
          title={label}
          isOpened={isOpened}
          onToggle={onToggle}
        />
      )}

      <CSSTransition
        in={isOpened}
        timeout={300}
        unmountOnExit
        classNames="expand"
      >
        <Styles.Container>{children}</Styles.Container>
      </CSSTransition>
    </Flex>
  );
};

export type { SectionProps };
