import { useState, useEffect, useCallback } from 'react';

export const useLocalStorageListener = (key: string) => {
  const getValue = useCallback(() => localStorage.getItem(key), [key]);

  const [currentValue, setCurrentValue] = useState(getValue());

  const setValue = useCallback(
    (value: string | null) => {
      if (!value) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, value);
      }
      setCurrentValue(value);
    },
    [setCurrentValue]
  );

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (
        event.storageArea === localStorage &&
        event.key === key &&
        currentValue !== event.newValue
      ) {
        setCurrentValue(event.newValue);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [currentValue, setCurrentValue]);

  return { setValue, currentValue };
};
