import { Link } from 'react-router-dom';
import styled, { css, RuleSet } from 'styled-components';

import { Popup } from 'components/popup';
import { flexCenter } from 'styles/mixins';
import { bodySemibold } from 'styles/mixins/fonts';

import { ButtonStyleProps } from './types';

const buttonStyle: RuleSet<any> = css<ButtonStyleProps>`
  ${flexCenter};
  white-space: nowrap;
  outline: none;

  & span {
    ${bodySemibold};
  }

  ${({
    theme,
    $weight,
    $padding,
    $fullWidth,
    $styleType,
    $colorType,
    $alignItems,
    $transparent,
    $justifyContent,
    $defaultPrimary,
    $nativeColorIcon,
    $nativeColorText,
    $positionRightIcon,
  }) => {
    const buttonConfig = theme.config.button[$styleType][$colorType];

    return css`
      display: inline-flex;
      align-items: ${$alignItems};
      justify-content: ${$justifyContent};
      gap: 8px;
      padding: ${$padding};
      ${$fullWidth && 'width: 100%;'};
      ${!$fullWidth && 'max-width: 100%;'};
      border-radius: ${theme.borderRadius};
      ${buttonConfig && `background-color: ${buttonConfig.default.bg};`};
      ${$transparent && 'background-color: transparent;'};
      ${$defaultPrimary && `background-color: ${theme.config.background};`};
      box-shadow: inset 0 0 0 1px ${buttonConfig?.default?.border || 'none'};
      transition: ${theme.transition.default};
      min-width: 0;

      & > span {
        font-weight: ${$weight};
        ${buttonConfig && `color: ${buttonConfig.default.text};`};
        ${$nativeColorText && `color: ${theme.config.text.primary};`};
      }

      & > svg {
        order: ${$positionRightIcon ? 1 : 0};
        ${!$nativeColorIcon &&
        buttonConfig &&
        css`
          & path,
          & circle {
            fill: ${buttonConfig.default.text} !important;
          }
        `};
      }

      &:hover {
        ${buttonConfig && `background-color: ${buttonConfig.hover.bg};`};
        ${$transparent && 'background-color: transparent;'};
        box-shadow: inset 0 0 0 1px ${buttonConfig?.hover?.border || 'none'};

        & > span {
          ${buttonConfig && `color: ${buttonConfig.hover.text};`};
          ${$nativeColorText && `color: ${theme.config.text.primary};`};
        }

        ${!$nativeColorIcon &&
        buttonConfig &&
        css`
          & > svg path,
          svg circle {
            fill: ${buttonConfig.hover.text} !important;
          }
        `}
      }

      &:active {
        ${buttonConfig && `background-color: ${buttonConfig.action.bg};`};
        ${$transparent && 'background-color: transparent;'};
        box-shadow: inset 0 0 0 1px ${buttonConfig?.action?.border || 'none'};

        & > span {
          ${buttonConfig && `color: ${buttonConfig.action.text};`};
          ${$nativeColorText && `color: ${theme.config.text.primary};`};
        }

        ${!$nativeColorIcon &&
        buttonConfig &&
        css`
          & > svg path,
          svg circle {
            fill: ${buttonConfig.action.text} !important;
          }
        `}
      }

      &:disabled {
        opacity: 0.24;
        cursor: initial;
        ${buttonConfig && `background-color: ${buttonConfig.disabled.bg};`};
        ${$transparent && 'background-color: transparent;'};

        & > span {
          ${buttonConfig && `color: ${buttonConfig.disabled.text};`};
          ${$nativeColorText && `color: ${theme.config.text.secondary};`};
        }

        ${!$nativeColorIcon &&
        buttonConfig &&
        css`
          & > svg path,
          svg circle {
            fill: ${buttonConfig.disabled.text} !important;
          }
        `};

        &:hover,
        &:active {
          ${buttonConfig && `background-color: ${buttonConfig.default.bg};`};
          ${$transparent && 'background-color: transparent;'};
          box-shadow: inset 0 0 0 1px ${buttonConfig?.default?.border || 'none'};
        }
      }
    `;
  }}
`;

export const StyledButton = styled.button`
  ${buttonStyle};
`;

export const StyledLink = styled(Link)`
  ${buttonStyle};
`;

export const StyledPopup = styled(Popup)`
  &-content {
    padding: 8px !important;
    max-width: ${({ theme }) => (theme.responsive.isMobile ? '70vw' : '496px')};
  }
`;

export const EllipsisInner = styled.span`
  display: block;
`;
