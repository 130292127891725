import { UserType } from 'types';
import { EventStatus } from 'store/api/event';

const baseTitles = [
  'Name',
  'Between dates',
  'State',
  'City',
  'Attendance',
  'Rating',
];

export const titlesByUserType: Record<UserType, string[]> = {
  client: [...baseTitles, 'Status', 'Last edited', 'Last edited by', ''],
  ops: [...baseTitles, 'Client', 'Status', 'Last edited', 'Last edited by'],
};

export const templateByUserType: Record<UserType, string> = {
  ops: 'repeat(2, 2fr) repeat(3, 1fr) 2fr 1fr 1fr 1fr 120px',
  client: 'repeat(2, 2fr) repeat(3, 1fr) 2fr 2fr 1fr 1fr 120px',
};

export const eventStatusText: Record<EventStatus, string> = {
  new: 'New',
  offered: 'Offered',
  accepted: 'Accepted',
};

export const cellsMobile = [
  { row: '1/2', col: '1/3' },
  { row: '2/3', col: '1/2' },
  { row: '2/3', col: '2/3' },
  { row: '3/4', col: '1/2' },
  { row: '3/4', col: '2/3' },
];
