import { Icon } from 'components/icon';
import { ButtonStyleTypes } from 'types';

import * as Styles from './styles';
import { AddButtonProps } from './types';

export const AddButton = ({
  asRow,
  withIcon = true,
  icon = <Icon.Plus />,
  ...props
}: AddButtonProps) => (
  <Styles.AddButton
    transparent
    padding="0"
    asRow={asRow}
    icon={withIcon ? icon : undefined}
    styleType={ButtonStyleTypes.Ghost}
    {...props}
  />
);
