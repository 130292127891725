import Sticky from 'react-sticky-el';
import styled, { css } from 'styled-components';

const border = css`
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.config.border};
`;

export const StyledSticky: typeof Sticky = styled(Sticky)`
  .sticky {
    z-index: 50;
    padding: 8px 0;
    ${({ theme }) => css`
      ${border};
      background-color: ${theme.config.background};
    `}
  }
`;

export const Container = styled.div<{
  withBorder?: boolean;
  paddingBottom?: number;
}>`
  display: flex;
  flex-direction: column;

  ${({ withBorder, paddingBottom }) => css`
    ${withBorder && border}

    ${paddingBottom &&
    css`
      padding-bottom: ${paddingBottom}px;
    `}
  `};
`;

export const Header = styled.div`
  margin-bottom: 16px;

  ${({ theme }) => css`
    > svg {
      width: 40px;
      height: 40px;
      path {
        fill: ${theme.config.text.primary};
      }
    }
  `}
`;

export const Name = styled.div<{ isSubtitle?: boolean }>`
  min-width: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: ${({ isSubtitle }) => (isSubtitle ? 'flex-start' : 'center')};

  h2 {
    margin-right: 16px;
  }
`;

export const Footer = styled.div`
  margin-top: 24px;
`;

export const RightSlot = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => (theme.responsive.isMobile ? '24px 0' : '0')};
`;
