import styled from 'styled-components';

import { Row, Col } from 'components/grid';

export const StyledCol = styled(Col)`
  margin-bottom: unset;
`;

export const StyledRow = styled(Row)`
  ${({ theme }) => theme.responsive.isMobile && 'gap: 8px;'};
`;

export const Title = styled.p<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.config.select.label.disabled
      : theme.config.select.label.default};
`;
