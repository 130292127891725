import { Badge } from 'components/badge';
import { PriceApprovalStatusTitle, PriceApprovalStatusColor } from 'types';

import { PriceApprovalBadgeStatusProps } from './types';

export const PriceApprovalBadgeStatus = ({
  status,
  ...props
}: PriceApprovalBadgeStatusProps) => (
  <Badge
    {...props}
    text={PriceApprovalStatusTitle[status]}
    styleType={PriceApprovalStatusColor[status]}
  />
);
