import { PopupPosition } from 'reactjs-popup/dist/types';
import { cloneElement, ForwardedRef, MouseEventHandler } from 'react';

import { withRef } from 'hoc/withRef';
import { getNavigationState } from 'hooks';
import { getTestId } from 'utils/test-utils';
import { EllipsisText } from 'components/ellipsis-text';
import { ButtonColors, ButtonStyleTypes } from 'types/enums';

import { ButtonProps, ButtonTypes } from './types';
import { StyledLink, StyledPopup, StyledButton, EllipsisInner } from './styles';

const Button = ({
  to,
  icon,
  text,
  style,
  testId,
  target,
  tooltip,
  onClick,
  innerRef,
  disabled,
  fullWidth,
  className,
  rightIcon,
  withBorder,
  weight = 600,
  defaultPrimary,
  nativeColorIcon,
  positionRightIcon,
  originalColorIcon,
  transparent = false,
  alignItems = 'center',
  isBlackTooltip = true,
  nativeColorText = false,
  stopPropagation = false,
  type = ButtonTypes.Button,
  justifyContent = 'center',
  colorType = ButtonColors.Blue,
  padding = text ? '8px 16px' : '8px',
  styleType = ButtonStyleTypes.Standard,
  ...props
}: ButtonProps): JSX.Element => {
  const commonProps = {
    style,
    className,
    $weight: weight,
    $padding: padding,
    $fullWidth: fullWidth,
    $styleType: styleType,
    $colorType: colorType,
    $alignItems: alignItems,
    $transparent: transparent,
    $justifyContent: justifyContent,
    $defaultPrimary: defaultPrimary,
    $nativeColorText: nativeColorText,
    $positionRightIcon: positionRightIcon,
    'data-testid': testId || (text && getTestId(text)),
    $nativeColorIcon: nativeColorIcon || originalColorIcon,
  };

  const position: PopupPosition[] = [
    'top center',
    'left center',
    'right center',
    'bottom center',
  ];

  const children = (
    <>
      {originalColorIcon && icon
        ? cloneElement(icon, { className: 'svg-non-filled' })
        : icon}

      {text && (
        <EllipsisText as="span">
          <EllipsisInner>{text}</EllipsisInner>
        </EllipsisText>
      )}

      {rightIcon}
    </>
  );

  const handleClick: MouseEventHandler = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
  };

  const link = to ? (
    <StyledLink
      to={to}
      target={target}
      onClick={handleClick}
      state={getNavigationState()}
      ref={innerRef as ForwardedRef<HTMLAnchorElement>}
      {...commonProps}
    >
      {children}
    </StyledLink>
  ) : undefined;

  if (link && !disabled && !tooltip) {
    return link;
  }

  const button = (
    <StyledButton
      {...props}
      type={type}
      disabled={disabled}
      aria-label={tooltip}
      ref={innerRef as ForwardedRef<HTMLButtonElement>}
      onClick={(e) => {
        handleClick(e);
        onClick?.(e);
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) =>
        e.preventDefault()
      }
      {...commonProps}
    >
      {children}
    </StyledButton>
  );

  if (disabled) {
    return button;
  }

  if (icon && tooltip && !text) {
    return (
      <StyledPopup
        position={position}
        aria-label={tooltip}
        on={['hover', 'focus']}
        isBlack={isBlackTooltip}
        trigger={link ?? button}
      >
        {tooltip}
      </StyledPopup>
    );
  }

  return button;
};

export const ButtonWithRef = withRef(Button);
export { Button, ButtonTypes };
export type { ButtonProps };
