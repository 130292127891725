import * as config from './config';

const white = Object.freeze({
  primary: {
    standard: config.white,
    hover: config.whiteHover,
    action: config.whiteAction,
  },
  tertiary: {
    standard: config.white,
    hover: config.whiteHover,
    action: config.whiteAction,
  },
  secondary: {
    standard: config.white,
    hover: config.whiteHover,
    action: config.whiteAction,
  },
});

const blue = Object.freeze({
  additional: config.additionalBlue,
  primary: {
    standard: config.blue,
    hover: config.blueHover,
    action: config.blueAction,
  },
  tertiary: {
    standard: config.tetrieryBlue,
    hover: config.tetrieryBlueHover,
    action: config.tetrieryBlueAction,
  },
  secondary: {
    standard: config.secondaryBlue,
    hover: config.secondaryBlueHover,
    action: config.secondaryBlueAction,
  },
});

const black = Object.freeze({
  primary: {
    standard: config.black,
    hover: config.blackHover,
    action: config.blackAction,
  },
  tertiary: {
    standard: config.black,
    hover: config.blackHover,
    action: config.blackAction,
  },
  secondary: {
    standard: config.black,
    hover: config.blackHover,
    action: config.blackAction,
  },
});

const grey = Object.freeze({
  additional: config.additionalGrey,
  primary: {
    standard: config.grey,
    hover: config.greyHover,
    action: config.greyAction,
  },
  tertiary: {
    standard: config.tetrieryGrey,
    hover: config.tetrieryGreyHover,
    action: config.tetrieryGreyAction,
  },
  secondary: {
    standard: config.secondaryGrey,
    hover: config.secondaryGreyHover,
    action: config.secondaryGreyAction,
  },
});

const green = Object.freeze({
  everglade: config.everglade,
  tePapaGreen: config.tePapaGreen,
  additional: config.additionalGreen,
  primary: {
    standard: config.green,
    hover: config.greenHover,
    action: config.greenAction,
  },
  tertiary: {
    standard: config.tetrieryGreen,
    hover: config.tetrieryGreenHover,
    action: config.tetrieryGreenAction,
  },
  secondary: {
    standard: config.secondaryGreen,
    hover: config.secondaryGreenHover,
    action: config.secondaryGreenAction,
  },
});

const red = Object.freeze({
  additional: config.additionalRed,
  primary: {
    standard: config.red,
    hover: config.redHover,
    action: config.redAction,
  },
  tertiary: {
    standard: config.tetrieryRed,
    hover: config.tetrieryRedHover,
    action: config.tetrieryRedAction,
  },
  secondary: {
    standard: config.secondaryRed,
    hover: config.secondaryRedHover,
    action: config.secondaryRedAction,
  },
});

const purple = Object.freeze({
  additional: config.additionalPurple,
  primary: {
    standard: config.purple,
    hover: config.purpleHover,
    action: config.purpleAction,
  },
  tertiary: {
    standard: config.tetrieryPurple,
    hover: config.tetrieryPurpleHover,
    action: config.tetrieryPurpleAction,
  },
  secondary: {
    standard: config.secondaryPurple,
    hover: config.secondaryPurpleHover,
    action: config.secondaryPurpleAction,
  },
});

const yellow = Object.freeze({
  additional: config.additionalYellow,
  primary: {
    standard: config.yellow,
    hover: config.yellowHover,
    action: config.yellowAction,
  },
  tertiary: {
    standard: config.tetrieryYellow,
    hover: config.tetrieryYellowHover,
    action: config.tetrieryYellowAction,
  },
  secondary: {
    standard: config.secondaryYellow,
    hover: config.secondaryYellowHover,
    action: config.secondaryYellowAction,
  },
});

const cerulean = Object.freeze({
  additional: config.additionalCerulean,
  primary: {
    standard: config.cerulean,
    hover: config.ceruleanHover,
    action: config.ceruleanAction,
  },
  tertiary: {
    standard: config.tetrieryCerulean,
    hover: config.tetrieryCeruleanHover,
    action: config.tetrieryCeruleanAction,
  },
  secondary: {
    standard: config.secondaryCerulean,
    hover: config.secondaryCeruleanHover,
    action: config.secondaryCeruleanAction,
  },
});

const orange = Object.freeze({
  additional: config.additionalOrange,
  primary: {
    standard: config.orange,
    hover: config.orangeHover,
    action: config.orangeAction,
  },
  tertiary: {
    standard: config.tetrieryOrange,
    hover: config.tetrieryOrangeHover,
    action: config.tetrieryOrangeAction,
  },
  secondary: {
    standard: config.secondaryOrange,
    hover: config.secondaryOrangeHover,
    action: config.secondaryOrangeAction,
  },
});

const pigmentIndigo = Object.freeze({
  primary: {
    standard: config.pigmentIndigo,
    hover: config.pigmentIndigoHover,
    action: config.pigmentIndigoAction,
  },
  tertiary: {
    standard: config.tertiaryPigmentIndigo,
    hover: config.tertiaryPigmentIndigoHover,
    action: config.tertiaryPigmentIndigoAction,
  },
  secondary: {
    standard: config.secondaryPigmentIndigo,
    hover: config.secondaryPigmentIndigoHover,
    action: config.secondaryPigmentIndigoAction,
  },
});

export const DARK_COLORS = Object.freeze({
  red,
  blue,
  grey,
  white,
  black,
  green,
  purple,
  yellow,
  orange,
  cerulean,
  pigmentIndigo,
});
