import styled, { css } from 'styled-components';

export const Container = styled.div<{
  isFullScreen: boolean;
  absolutePosition: boolean;
}>`
  width: 100%;
  height: 100%;

  ${({ theme, isFullScreen, absolutePosition }) => css`
    ${isFullScreen &&
    css`
      top: 0;
      left: 0;
      z-index: 2000;
      position: fixed;
      background-color: ${theme.config.popup.modal96};
    `}

    ${absolutePosition &&
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2000;
      position: absolute;
      background-color: ${theme.config.popup.modal96};
    `}
  `}

  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(3px);
`;

export const Cat = styled.div`
  width: 200px;
  height: 220px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  > div {
    width: 1024px;
    height: 1024px;
    transform: translate(-23px, -40px);
  }
`;
