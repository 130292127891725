import mime from 'mime';
import 'yet-another-react-lightbox/styles.css';
import { Slide } from 'yet-another-react-lightbox';
import { useRef, useMemo, useCallback } from 'react';
import 'yet-another-react-lightbox/plugins/captions.css';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Video from 'yet-another-react-lightbox/plugins/video';
import { useWindowSize, useDebounceValue } from 'usehooks-ts';
import Captions from 'yet-another-react-lightbox/plugins/captions';

import { Icon } from 'components/icon';
import { Button } from 'components/button';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { FileType, ButtonColors, ButtonStyleTypes } from 'types';

import * as Styles from './styles';
import { GalleryFile, GalleryProps } from './types';

const buttonStyle = {
  styleType: ButtonStyleTypes.Ghost,
  colorType: ButtonColors.TetrieryGrey,
};

export const Gallery = ({
  open,
  index,
  close,
  files,
  className,
  footerButtons,
  hideFooterButton,
}: GalleryProps) => {
  const visibleIndex = useRef<number>(0);
  const { width, height } = useWindowSize();
  const sizeString = `${height}x${width}`;
  const [debounceSize] = useDebounceValue(sizeString, 100);

  const { downloadFile } = useDownloadFile();

  const supportedFiles = useMemo(
    () => files.filter((file) => file.type !== FileType.File),
    [files]
  );

  const slides = useMemo(
    () =>
      supportedFiles.map((file): Slide | undefined => {
        const title = (
          <div>
            {file.title}
            <p>{file.name}</p>
          </div>
        );
        switch (file.type) {
          case FileType.Image:
            return {
              title,
              src: file.url,
            };

          case FileType.Video:
            return {
              title,
              type: 'video',
              width: width * 0.8,
              height: height * 0.8,
              poster: file.thumbnailUrl ?? undefined,
              sources: [
                {
                  src: file.url,
                  type: mime.getType(file.url) ?? 'video/mp4',
                },
              ],
            };
        }
      }) as Slide[],
    [supportedFiles, debounceSize]
  );

  const isMulti = slides.length > 1;
  const changeButton = isMulti ? undefined : () => <div />;

  const getFile = useCallback(
    (slide: Slide) => {
      let idx = slides.indexOf(slide);
      if (idx === -1) {
        idx = visibleIndex.current;
      }
      return supportedFiles[idx];
    },
    [slides, supportedFiles]
  );

  const onDownload = (slide: Slide) => {
    const file = getFile(slide);
    downloadFile(file.url, file.name);
  };

  return (
    <Styles.Gallery
      open={open}
      index={index}
      close={close}
      slides={slides}
      className={className}
      zoom={{ maxZoomPixelRatio: 10 }}
      plugins={[Zoom, Video, Captions]}
      animation={{ swipe: 0, fade: 300 }}
      controller={{ closeOnBackdropClick: true }}
      on={{
        view: ({ index: idx }) => {
          visibleIndex.current = idx;
        },
      }}
      render={{
        buttonNext: changeButton,
        buttonPrev: changeButton,
        iconClose: () => <Icon.Close />,
        iconZoomOut: () => <Icon.Minus />,
        iconLoading: () => <p>Loading...</p>,
        iconPrev: () => <Icon.ChevronLeft />,
        iconZoomIn: () => <Icon.PlusSmall />,
        iconNext: () => <Icon.ChevronRight />,
        slideFooter: !hideFooterButton
          ? ({ slide }) => (
              <Styles.Footer>
                <Button
                  {...buttonStyle}
                  icon={<Icon.Download />}
                  onClick={() => onDownload(slide)}
                />
                {footerButtons?.(getFile(slide), buttonStyle)}
              </Styles.Footer>
            )
          : undefined,
      }}
    />
  );
};

export type { GalleryFile };
