import React from 'react';

import * as Styles from './styles';
import { ImageProps } from './types';

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  style,
  width,
  height,
  onLoad,
  onClick,
  clickable,
  objectFit = 'fill',
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [source, setSource] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setSource(src);
    setIsLoading(src !== undefined);
  }, [src]);

  const loaderSize = Math.min(40, height || 40);

  return (
    <Styles.ImageBlock
      onClick={onClick}
      clickable={clickable || !!onClick}
      style={{
        width,
        height,
        ...style,
      }}
    >
      <Styles.ImageView
        alt={alt}
        src={source}
        isLoaded={isLoading}
        onError={() => setIsLoading(false)}
        style={{
          objectFit,
        }}
        onLoad={(e) => {
          onLoad?.(e);
          setIsLoading(false);
        }}
        {...props}
      />

      {isLoading && <Styles.Loader width={loaderSize} height={loaderSize} />}
    </Styles.ImageBlock>
  );
};

export { Image };
