import { useState, FormEvent, LabelHTMLAttributes } from 'react';

import * as Styles from './styles';
import { SwitchProps } from './types';

const Switch = ({
  id,
  name,
  label,
  isRed,
  disabled,
  onChange,
  labelStyle,
  checked = false,
  stopPropagation = false,
  ...props
}: SwitchProps) => {
  const [isHovered, setHovered] = useState(false);
  const idValue = id || name || label;

  const addHoverEffect = () => setHovered(true);
  const removeHoverEffect = () => setHovered(false);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    removeHoverEffect();
    onChange?.(e);
  };

  const switchLabelProps: boolean | LabelHTMLAttributes<HTMLLabelElement> =
    !disabled && {
      onMouseEnter: addHoverEffect,
      onMouseLeave: removeHoverEffect,
      ...(stopPropagation && {
        onClick: (e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        },
      }),
    };

  return (
    <Styles.SwitchLabel {...switchLabelProps} htmlFor={idValue}>
      <Styles.Switch
        name={name}
        id={idValue}
        isRed={isRed}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        isHovered={isHovered}
        onChange={handleChange}
        {...props}
      />
      <Styles.SwitchSlider isRed={isRed} />
      {label && (
        <Styles.SwitchText style={labelStyle}>{label}</Styles.SwitchText>
      )}
    </Styles.SwitchLabel>
  );
};

export { Switch };
export type { SwitchProps };
