import { Icon } from 'components/icon';
import { CashCardType } from 'types/enums';

export const CashTitles = {
  [CashCardType.Out]: 'Total out',
  [CashCardType.Cash]: 'Total cash',
  [CashCardType.Balance]: 'Balance',
};

export const CashIcons = {
  [CashCardType.Out]: <Icon.CoinDollar />,
  [CashCardType.Cash]: <Icon.CoinDollar />,
  [CashCardType.Balance]: <Icon.CoinDollar />,
};

export const titles = ['Expenses', 'Amount', 'Receipt'];
