import { Formik, FormikConfig } from 'formik';

import { useHeader } from 'context';
import { Company, ButtonColors } from 'types';
import { isMobile, useAlert, useNavigate } from 'hooks';
import { handleFormSubmitWithFiles } from 'utils/helpers';
import { CompanyFormValues } from 'store/api/company/types';
import {
  Row,
  Col,
  Button,
  FormFooter,
  InputField,
  ButtonTypes,
  CommonStyles,
  SectionHeader,
  FormPhotoFieldRow,
} from 'components';

import { validationSchema, getInitialValues } from './config';

export const Form = ({
  data,
  ...props
}: {
  data?: Company;
} & Omit<FormikConfig<CompanyFormValues>, 'initialValues'>) => {
  const mobile = isMobile();
  const navigate = useNavigate();

  const { handleError } = useAlert();

  const isEdit = Boolean(data);
  useHeader({
    showBackButton: true,
    pageName: `${isEdit ? 'Edit' : 'New'} company`,
  });

  return (
    <Formik<CompanyFormValues>
      {...props}
      validationSchema={validationSchema}
      initialValues={getInitialValues(data)}
      onSubmit={handleFormSubmitWithFiles({
        data,
        handleError,
        onSubmit: props.onSubmit,
        files: [
          {
            formFieldName: 'photo',
            requestFieldName: 'photoUrl',
          },
        ],
      })}
    >
      {({ isSubmitting }) => {
        return (
          <CommonStyles.Form>
            <CommonStyles.FormInner>
              <FormPhotoFieldRow />

              <SectionHeader withBorder={false} title="General info" />
              <Row>
                <Col col={3}>
                  <InputField name="name" label="Name" placeholder="Acme Inc" />
                </Col>
                <Col col={3}>
                  <InputField
                    number
                    decimal
                    name="processingFee"
                    label="Processing Fee"
                    placeholder="Processing Fee"
                  />
                </Col>
              </Row>
            </CommonStyles.FormInner>

            <FormFooter
              right={
                <CommonStyles.FormButtons>
                  <Button
                    text="Cancel"
                    fullWidth={mobile}
                    onClick={() => navigate(-1)}
                    colorType={ButtonColors.LightBlue}
                  />
                  <Button
                    fullWidth={mobile}
                    disabled={isSubmitting}
                    type={ButtonTypes.Submit}
                    text={isEdit ? 'Edit' : 'Create'}
                  />
                </CommonStyles.FormButtons>
              }
            />
          </CommonStyles.Form>
        );
      }}
    </Formik>
  );
};
