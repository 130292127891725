import { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from 'components/icon';
import { Button } from 'components/button';
import { isDesktop, useClickOutside } from 'hooks';
import { NotDesktop } from 'components/responsive';
import { ChoiceTheme } from 'components/choice-theme';
import { ChoiceUserStatus } from 'components/choice-user-status';
import {
  BadgeColor,
  BadgePadding,
  ButtonColors,
  ButtonStyleTypes,
} from 'types';

import * as Styles from './styles';
import { LinkProps, SideBarProps } from './types';

const visibleClass = 'side-bar-visible';

const SidebarLink = ({
  path,
  icon,
  title,
  count,
  onClick,
  disabled,
  lastItemAtBottom = false,
  ...rest
}: LinkProps & Pick<SideBarProps, 'lastItemAtBottom'>) => {
  const content = (
    <Styles.ContextBlock>
      {icon}
      {!!count && (
        <Styles.Count
          smallFont
          text={String(count)}
          padding={BadgePadding.Small}
          styleType={BadgeColor.RedAlert}
        />
      )}
      <Styles.TitleLink>{title}</Styles.TitleLink>
    </Styles.ContextBlock>
  );

  return (
    <Styles.SideBarLink
      onClick={onClick}
      disabled={disabled ?? false}
      lastItemAtBottom={lastItemAtBottom}
      {...rest}
    >
      {path ? <NavLink to={path}>{content}</NavLink> : content}
    </Styles.SideBarLink>
  );
};

const SideBar = ({
  links,
  options,
  isWidget,
  isOpened,
  className,
  setOpened,
  onSignOut,
  userInfoProps,
  lastItemAtBottom = false,
  closeOnDocumentClick = true,
}: SideBarProps) => {
  const refMenu = useRef(null);

  const desktop = isDesktop();
  const close = () => setOpened(false);

  const isVisible = isOpened || desktop;

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add(visibleClass);
    } else {
      document.body.classList.remove(visibleClass);
    }
  }, [isVisible]);

  useEffect(() => {
    return () => document.body.classList.remove(visibleClass);
  }, []);

  useClickOutside(refMenu, (e) => {
    if (
      isOpened &&
      closeOnDocumentClick &&
      (e.target as HTMLDivElement)?.id !== 'burger'
    ) {
      close();
    }
  });

  return (
    <div ref={refMenu} className={className}>
      <Styles.SideBarBlock isOpen={isOpened} isWidget={isWidget}>
        <Styles.LogoItem to="/">
          <Icon.Logo />
        </Styles.LogoItem>

        <NotDesktop>
          <Styles.UserInfoWrap {...userInfoProps}>
            <Styles.ButtonWrap>
              <Button
                fullWidth
                text="Sign out"
                colorType={ButtonColors.Red}
                styleType={ButtonStyleTypes.Ghost}
                onClick={() => {
                  close();
                  onSignOut();
                }}
              />
            </Styles.ButtonWrap>
          </Styles.UserInfoWrap>
        </NotDesktop>

        {links.map(
          ({ icon, path, title, count, onClick, disabled, ...rest }) => (
            <Styles.Popup
              on="hover"
              key={path ?? title}
              closeOnDocumentClick
              position="right center"
              trigger={
                <div>
                  <SidebarLink
                    icon={icon}
                    path={path}
                    title={title}
                    count={count}
                    onClick={onClick}
                    disabled={disabled}
                    lastItemAtBottom={lastItemAtBottom}
                  />
                </div>
              }
              {...rest}
            >
              <Styles.Tooltip>{title}</Styles.Tooltip>
            </Styles.Popup>
          )
        )}

        <NotDesktop>
          <Styles.ThemeBlock>
            <ChoiceUserStatus options={options} />

            <ChoiceTheme padding="16px 16px 0" />
          </Styles.ThemeBlock>
        </NotDesktop>
      </Styles.SideBarBlock>

      <Styles.OpenButton isOpen={isOpened} onClick={() => setOpened(!isOpened)}>
        <Icon.ChevronRight />
      </Styles.OpenButton>
    </div>
  );
};

export { SideBar };
export type { LinkProps };
