import { LIGHT_COLORS } from './light-theme/colors';
import { LIGHT_CONFIG } from './light-theme/config';

export interface FontConfig {
  fontSize: number;
  lineHeight: number;
}

export interface FontsConfig {
  body?: FontConfig;
  small?: FontConfig;
  message?: FontConfig;
  ultraSmall?: FontConfig;
}

export const DefaultConfig: FontsConfig = {
  body: { fontSize: 14, lineHeight: 24 },
  small: { fontSize: 14, lineHeight: 20 },
  message: { fontSize: 16, lineHeight: 22 },
  ultraSmall: { fontSize: 12, lineHeight: 16 },
};

export interface AppTheme {
  fonts?: FontsConfig;
  borderRadius: string;
  config: typeof LIGHT_CONFIG;
  colors: typeof LIGHT_COLORS;
  transition: {
    default: string;
  };
  scrollBar: typeof LIGHT_COLORS.grey.secondary;
  responsive: {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isTabletUp: boolean;
  };
}
