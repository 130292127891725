import styled, { css } from 'styled-components';

import { flexSpaceBetween } from 'styles';
import { ErrorColor, ErrorPadding } from 'types';
import { bodySemibold } from 'styles/mixins/fonts';

export const Text = styled.div`
  ${bodySemibold};
`;

export const Info = styled.div<{ alignCenter?: boolean }>`
  gap: 8px;
  width: 100%;
  display: flex;
  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }

  ${({ alignCenter }) => css`
    ${alignCenter &&
    css`
      align-items: center;
    `}
  `}
`;

export const Error = styled.div<{
  color: ErrorColor;
  padding: ErrorPadding;
}>`
  ${flexSpaceBetween}
  gap: 8px;
  ${({ theme, color, padding }) => css`
    padding: ${padding};
    background-color: ${theme.config.errorColor[color].background};
    border-radius: ${theme.borderRadius};

    ${Text} {
      color: ${theme.config.errorColor[color].text};
    }

    ${Info} {
      svg path {
        fill: ${theme.config.errorColor[color].icon};
      }
    }
  `}
`;
