import { DefaultTheme } from 'styled-components';

import { GetCellsProps } from './types';

export const getCells = (
  { cells, cellsTablet, cellsMobile }: GetCellsProps,
  theme: DefaultTheme
) => {
  const { responsive } = theme;

  let gridCells = cells;

  if (responsive.isMobile) {
    if (cellsMobile) {
      gridCells = cellsMobile;
    } else if (cellsTablet) {
      gridCells = cellsTablet;
    }
  } else if (responsive.isTablet) {
    if (cellsTablet) {
      gridCells = cellsTablet;
    }
  }

  return gridCells;
};
