import { mapQuery } from 'utils/query';
import { useAppSelector } from 'store';
import { formatDate } from 'utils/date';
import { formatPrice } from 'utils/helpers';
import { useFilter, useSearchQuery } from 'hooks';
import { selectAuthUser } from 'store/slices/auth';
import { Payout, useListQuery } from 'store/api/payout';
import { Role, PayoutType, BackOfficeRoles } from 'types';
import { ListCard, ListHeader, PayoutStatusBadge } from 'components';

import * as CommonStyles from '../../styles';

import { ReimbursementProps } from './types';
import { CreateReimbursement } from './create';

export const titles = ['Date', 'Employee', 'Description', 'Amount', 'Status'];

export const Reimbursement = ({ event }: ReimbursementProps) => {
  const authUser = useAppSelector(selectAuthUser);
  const { debounceQuery, ...search } = useSearchQuery();
  const { data, ...props } = useListQuery({
    eventId: String(event.id),
    types: JSON.stringify([PayoutType.Reimbursements]),
  });

  const filteredValues = useFilter<Payout>({
    paths: ['name'],
    query: debounceQuery,
    data: data?.reimbursements || [],
  });

  const canCreateReimbursement = ([BackOfficeRoles.MANAGER] as Role[]).includes(
    authUser?.role?.name!
  );

  return (
    <CommonStyles.StyledList
      {...mapQuery(props)}
      {...search}
      count={filteredValues?.length}
      header={<ListHeader titles={titles} template="repeat(5, 1fr)" />}
      searchBarRightSlot={
        canCreateReimbursement ? (
          <CreateReimbursement event={event} />
        ) : undefined
      }
    >
      {filteredValues?.map((value) => (
        <ListCard
          padding="16px"
          key={value.id}
          headers={titles}
          values={[
            formatDate(value.date),
            value.employee.user.name,
            value.notes,
            formatPrice(value.amount),
            <PayoutStatusBadge status={value.status} />,
          ]}
        />
      ))}
    </CommonStyles.StyledList>
  );
};
