import { formatPrice } from 'utils/helpers';
import { ListCard, ListHeader } from 'components';

import * as CommonStyles from '../styles';

import { ProductListProps } from './types';
import { titles, cellsMobile, mobileTemplate } from './config';

export const ProductList = ({ products }: ProductListProps) => (
  <CommonStyles.StyledList
    count={products.length}
    mainWrapperStyles={{ padding: 0 }}
    header={<ListHeader padding="8px" titles={titles} />}
  >
    {products.map((value) => (
      <ListCard
        padding="16px"
        headers={titles}
        key={value.productId}
        cellsMobile={cellsMobile}
        mobileTemplate={mobileTemplate}
        values={[
          value.name,
          formatPrice(value.price),
          value.totalSold,
          value.remaining,
          value.totalSold + value.remaining,
        ]}
      />
    ))}
  </CommonStyles.StyledList>
);
