import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';
import { Icon } from 'components/icon';
import { bodySemibold } from 'styles/mixins/fonts';
import { RadioButton } from 'components/radio-button';

const getTemplate = (count: number, removeFirst?: boolean) => {
  let template = '';
  if (!removeFirst) {
    template += '128px ';
  }
  template += `repeat(${count}, 1fr)`;
  return template;
};

export const GridContainer = styled.div<{
  count: number;
  removeFirst?: boolean;
}>`
  display: grid;
  width: 100%;
  ${({ count, removeFirst }) => css`
    grid-template-columns: ${getTemplate(count, removeFirst)};
  `}
`;

export const Header = styled.div<{ $isError?: boolean }>`
  display: flex;
  border-radius: 8px;
  ${({ theme, $isError }) => css`
    background-color: ${$isError
      ? theme.colors.red.secondary.standard
      : theme.config.border};
    padding: 8px ${theme.responsive.isDesktop ? '0' : '16px'};
  `}
`;

export const HeaderCol = styled.div`
  gap: 8px;
  display: flex;
  padding: 0 8px;
  ${bodySemibold};
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  ${({ theme }) => css`
    height: ${theme.responsive.isDesktop ? '56px' : 'unset'};

    ${theme.responsive.isDesktop &&
    css`
      box-shadow: inset 0px -1px 0px ${theme.config.border};
    `}
  `}
`;

export const RowTitle = styled.div`
  display: flex;
  align-items: center;
  ${bodySemibold};
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    box-shadow: inset 1px 0px 0px ${theme.config.border};

    ${!theme.responsive.isDesktop &&
    css`
      padding: 8px;
      text-align: center;
      flex-direction: column;
      justify-content: flex-start;
    `}
  `}
`;

export const Check = styled(Icon.CheckCircle)`
  ${({ theme }) => css`
    path,
    rect {
      fill: ${theme.config.text.green};
    }
  `}
`;

export const SelectedList = styled.div`
  margin-bottom: 16px;
`;

export const InputContainer = styled.div`
  border-radius: 8px;
  ${({ theme }) =>
    !theme.responsive.isDesktop &&
    css`
      border: 1px solid ${theme.config.border};
    `}
`;

export const InputHeader = styled.div<{
  $hasError: boolean;
  hasBorder?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  ${bodySemibold};
  ${({ theme, hasBorder, $hasError }) => css`
    background-color: ${$hasError
      ? theme.colors.red.secondary.standard
      : theme.config.border};
    padding: 8px ${theme.responsive.isDesktop ? '0' : '16px'};

    ${hasBorder &&
    css`
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    `}
  `}
`;

export const Radio = styled(RadioButton)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RadioLabel = styled.p`
  margin-top: 8px;
`;

export const Heading = styled(Flex)`
  flex-wrap: wrap;
  margin-bottom: 4px;
  justify-content: space-between;
`;
