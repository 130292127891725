import styled, { css } from 'styled-components';

import { Flex } from 'components/flex';
import { captionSemibold } from 'styles/mixins/fonts';
import { EllipsisText } from 'components/ellipsis-text';

import { ListCardCell, GetOddStyles, ContainerProps } from './types';

const getCellsCss = (cells: ListCardCell[]) => {
  let cssString = '';

  cells.forEach((cell, idx) => {
    const string = `
    > :nth-child(${idx + 1}) {
        grid-column: ${cell.col};
        grid-row: ${cell.row};
    }
    `;
    cssString = cssString + string;
  });

  return cssString;
};

const getOddStyles: GetOddStyles = ({
  odd,
  theme,
  onClick,
  disabled,
  selected,
  nativeIconColor,
}) => css`
  color: ${disabled
    ? theme.config.table[odd].disabled.text
    : theme.config.table[odd].default.text};
  background: ${theme.config.table[odd].default.bg};
  border: ${selected && theme.config.table[odd].selected.border};

  & svg {
    flex-shrink: 0;
    ${!nativeIconColor &&
    css`
      & > path {
        fill: ${disabled
          ? theme.config.table[odd].disabled.text
          : theme.config.table[odd].default.text};
      }
    `}
  }

  ${onClick &&
  css`
    &:hover {
      background: ${theme.config.table[odd].hover.bg};
    }

    &:active {
      background: ${theme.config.table[odd].action.bg};
    }
  `}
`;

export const Container = styled.div<Omit<ContainerProps, 'template'>>`
  position: relative;
  box-sizing: border-box;

  ${({
    odd,
    even,
    theme,
    isOdd,
    radius,
    onClick,
    disabled,
    selected,
    isPropagation,
    nativeIconColor,
  }) => css`
    color: ${disabled
      ? theme.config.table[even].disabled.text
      : theme.config.table[even].default.text};
    background: ${theme.config.table[even].default.bg};
    border: ${selected
      ? theme.config.table[even].selected.border
      : '1px solid transparent'};
    border-radius: ${radius};

    ${disabled && 'pointer-events: none'};
    ${isPropagation && 'pointer-events: auto'};

    & svg {
      flex-shrink: 0;
      ${!nativeIconColor &&
      css`
        & > path {
          fill: ${disabled
            ? theme.config.table[even].disabled.text
            : theme.config.table[even].default.text};
        }
      `}
    }
    ${isOdd === undefined &&
    css`
      &:nth-child(odd) {
        ${getOddStyles({
          odd,
          theme,
          onClick,
          selected,
          disabled,
          nativeIconColor,
        })};
      }
    `}

    ${isOdd &&
    getOddStyles({ odd, theme, onClick, selected, disabled, nativeIconColor })}
  `};

  ${({ even, theme, onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        background: ${theme.config.table[even].hover.bg};
      }

      &:active {
        background: ${theme.config.table[even].action.bg};
      }
    `};
`;

export const Content = styled.div<
  { template: string } & Partial<ContainerProps>
>`
  display: grid;
  ${({
    gap,
    cells,
    theme,
    rowGap,
    padding,
    template,
    verticalAlign,
    tabletTemplate,
    mobileTemplate,
  }) => css`
    grid-template-columns: ${template};
    column-gap: ${gap ?? 24}px;
    row-gap: ${rowGap ?? 16}px;
    align-items: ${verticalAlign};
    padding: ${padding || '24px'};
    grid-auto-rows: auto;

    ${theme.responsive.isTablet &&
    css`
      grid-template-columns: ${tabletTemplate || '1fr 1fr 1fr'};
    `}

    ${theme.responsive.isMobile &&
    css`
      grid-template-columns: ${mobileTemplate || '1fr 1fr'};
    `}

     ${cells?.length && getCellsCss(cells)};
  `};
`;

export const InnerFooter = styled.div<Partial<ContainerProps>>`
  position: relative;
  ${({ innerFooterPadding }) => css`
    padding: ${innerFooterPadding || '16px'};
  `};
`;

export const InnerDivider = styled.div`
  position: absolute;
  left: 24px;
  top: 0;
  right: 24px;
  height: 1px;
  ${({ theme }) => css`
    background-color: ${theme.config.table.innerDivider};
  `};
`;

export const MobileTitle = styled(EllipsisText)`
  ${captionSemibold};
  ${({ theme }) => css`
    color: ${theme.config.table.grey.disabled.text};
  `};
`;

export const MobileContainer = styled(Flex)`
  min-width: 0;
  flex-direction: column;
`;
