import ReactLinkify from 'react-linkify';

import { Link } from '../link';

import { LinkifyProps } from './types';

export const Linkify = ({ getText, children, ...props }: LinkifyProps) => {
  return (
    <ReactLinkify
      componentDecorator={(url, text, key) => (
        <Link
          url={url}
          key={key}
          ellipsis={false}
          text={getText?.(url, text)}
          {...props}
        />
      )}
    >
      {children}
    </ReactLinkify>
  );
};
