import styled, { css } from 'styled-components';

import { Font } from 'styles';

export const Column = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ gap }) => css`
    gap: ${gap}px;
  `}
`;

export const Title = styled.div<{ boldTitle?: boolean }>`
  ${(p) => (p.boldTitle ? Font.heading3 : Font.bodySemibold)};
  user-select: none;
`;
