import { Flex } from 'components/flex';
import { CloseButton } from 'components/specified-buttons';

import { DrawerHeaderProps } from './types';

export const DrawerHeader = ({
  title,
  buttons,
  onClose,
  rightSlot,
}: DrawerHeaderProps) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Flex gap={40} alignItems="center">
      <h2>{title}</h2>

      {buttons && <Flex gap={16}>{buttons}</Flex>}
    </Flex>

    <Flex gap={24}>
      {rightSlot}
      {onClose && <CloseButton onClose={onClose} />}
    </Flex>
  </Flex>
);
