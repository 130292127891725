import { createApi } from '@reduxjs/toolkit/query/react';

import { URL } from 'api/constants';
import { addParamsToUrl } from 'utils/query';
import { Company, SearchRequest } from 'types';
import { filterEmptyFields } from 'utils/object';
import {
  cacheByIdArg,
  providesList,
  invalidatesList,
  cacheByIdArgProperty,
} from 'utils/query-cache';

import { apiQuery } from '../../query';

import { CompanyFormValues, CompanyEditFormValues } from './types';

const tagType = 'Company';

export const companyApi = createApi({
  baseQuery: apiQuery,
  tagTypes: [tagType],
  reducerPath: 'companyApi',
  endpoints: (build) => ({
    get: build.query<Company, string>({
      providesTags: cacheByIdArg(tagType),
      query: (id) => ({
        method: 'get',
        url: URL.COMPANY_DETAILS.replace(':id', id),
      }),
    }),
    remove: build.mutation<void, string>({
      invalidatesTags: invalidatesList(tagType),
      query: (id) => ({
        method: 'delete',
        url: URL.COMPANY_DETAILS.replace(':id', id),
      }),
    }),
    list: build.query<Company[], SearchRequest | void>({
      providesTags: providesList(tagType),
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(URL.COMPANY_LIST, { search: params?.search }),
      }),
    }),
    create: build.mutation<void, CompanyFormValues>({
      invalidatesTags: invalidatesList(tagType),
      query: (data) => ({
        method: 'post',
        url: URL.COMPANY_LIST,
        data: filterEmptyFields({
          ...data,
        }),
      }),
    }),
    edit: build.mutation<void, CompanyEditFormValues>({
      invalidatesTags: cacheByIdArgProperty(tagType),
      query: ({ id, ...data }) => ({
        method: 'patch',
        url: URL.COMPANY_DETAILS.replace(':id', id),
        data: filterEmptyFields({
          ...data,
        }),
      }),
    }),
  }),
});

export const {
  useGetQuery,
  useListQuery,
  useEditMutation,
  useRemoveMutation,
  useCreateMutation,
} = companyApi;
