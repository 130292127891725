import styled from 'styled-components';

import { Badge } from 'components/badge';

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.grey.primary.action};
`;

export const StyledBadge = styled(Badge)`
  text-align: unset;
  justify-content: space-between;
`;

export const Divider = styled.p`
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background-color: ${({ theme }) => theme.config.common.border};
`;
