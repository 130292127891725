import { lazy } from 'react';

import { ApprovalList, ApprovalDetails } from './approval';
import { PriceApprovalList, PriceApprovalDetails } from './price-approval';

const Home = lazy(() => import('./dashboard'));
const Analytics = lazy(() => import('./analytics'));
const EditTransaction = lazy(() => import('./analytics/edit-transaction'));

export {
  Home,
  Analytics,
  ApprovalList,
  ApprovalDetails,
  EditTransaction,
  PriceApprovalList,
  PriceApprovalDetails,
};
