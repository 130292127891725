import styled, { css } from 'styled-components';

import { flexCenter } from 'styles/mixins/index';
import { bodyRegular, captionRegular } from 'styles/mixins/fonts';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  ${flexCenter};
  flex-direction: column;
  cursor: pointer;
  user-select: none;

  ${({ theme, width, height, isError, isLoading }) => css`
    width: ${width};
    height: ${height};
    color: ${isError
      ? theme.config.dropImage.error.text
      : theme.config.dropImage.standard.text};
    border-radius: ${theme.borderRadius};
    background: ${isError
      ? theme.config.dropImage.error.bg.default
      : theme.config.dropImage.standard.bg.default};
    pointer-events: ${isLoading && 'none'};
    transition: ${theme.transition.default};

    &:hover {
      background: ${isError
        ? theme.config.dropImage.error.bg.hover
        : theme.config.dropImage.standard.bg.hover};
    }
    & svg {
      margin-bottom: ${(isError && '24px') || (isLoading && 0) || '8px'};
      path {
        fill: ${(isError && theme.config.dropImage.error.text) ||
        theme.config.dropImage.standard.text};
      }
    }
  `};
`;

export const Info = styled.p`
  ${bodyRegular};
`;

export const Warning = styled.p`
  ${captionRegular};
`;

export const Wrap = styled.div`
  ${flexCenter};
  flex-direction: column;
  margin-top: 8px;
`;
