import { Icon } from 'components/icon';

import { Linkify } from '../linkify';

import * as Styles from './styles';
import { RadioGroupItem, RadioGroupProps, RadioButtonProps } from './types';

const RadioButton = ({
  id,
  icon,
  style,
  value,
  label,
  onChange,
  disabled,
  fontSize,
  className,
  component,
  transparent = false,
  padding = '8px 12px',
  ...props
}: RadioButtonProps): JSX.Element => {
  const inputId = id || label;

  return (
    <Styles.RadioButtonBlock className={className}>
      <Styles.StyledInput
        id={inputId}
        type="radio"
        value={value}
        label={label}
        disabled={disabled}
        onChange={onChange}
        transparent={transparent}
        {...props}
      />

      <Styles.RadioButtonLabel
        label={label}
        style={style}
        htmlFor={inputId}
        padding={padding}
        transparent={transparent}
      >
        <Styles.RadioMark label={label}>
          <Icon.RadioButtonEmpty id="radio-button-empty" />
          <Icon.RadioButtonHover id="radio-button-hover" />
          <Icon.RadioButton id="radio-button-select" />
        </Styles.RadioMark>
        {icon && <Styles.StyledIcon>{icon}</Styles.StyledIcon>}
        {component}
        {label && (
          <Styles.RadioButtonText fontSize={fontSize}>
            <Linkify>{label}</Linkify>
          </Styles.RadioButtonText>
        )}
      </Styles.RadioButtonLabel>
    </Styles.RadioButtonBlock>
  );
};

export { RadioButton };
export type { RadioGroupItem, RadioGroupProps, RadioButtonProps };
