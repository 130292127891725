import styled, { css } from 'styled-components';

import { bodySemibold } from 'styles/mixins/fonts';
import {
  TextColor,
  BadgeColor,
  BadgeCursor,
  BadgePadding,
  BadgeBorderRadius,
} from 'types/enums';

import { BadgeProps, BadgesProps } from './types';

export const Badge = styled.span<{
  bold: boolean;
  noHover?: boolean;
  disabled?: boolean;
  isActive?: boolean;
  smallFont?: boolean;
  cursor: BadgeCursor;
  noEllipsis?: boolean;
  styleType: BadgeColor;
  padding: BadgePadding;
  borderRadius: BadgeBorderRadius;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  user-select: none;

  ${({
    bold,
    theme,
    cursor,
    noHover,
    padding,
    disabled,
    isActive,
    styleType,
    smallFont,
    noEllipsis,
    borderRadius,
  }) => css`
    min-width: ${noEllipsis ? 'max-content' : `${smallFont ? 16 : 20}px`};
    transition: ${theme.transition.default};
    background-color: ${disabled
      ? 'transparent'
      : isActive
        ? theme.config.badge[styleType].active.bg
        : theme.config.badge[styleType].default.bg};
    color: ${disabled
      ? theme.config.input.label.disabled
      : isActive
        ? theme.config.badge[styleType].active.text
        : theme.config.badge[styleType].default.text};
    font-weight: ${bold ? 600 : 400};
    border-radius: ${borderRadius};
    ${smallFont && 'font-size: 12px'};
    ${smallFont && 'line-height: 16px'};
    padding: ${padding};
    cursor: ${cursor};
    ${noEllipsis && 'white-space: normal'};
    border: ${theme.config.badge[styleType].default.border};

    ${() =>
      !noHover &&
      css`
        &:hover {
          background-color: ${theme.config.badge[styleType].hover.bg};
          color: ${theme.config.badge[styleType].hover.text};
        }

        &:active {
          background-color: ${theme.config.badge[styleType].active.bg};
          color: ${theme.config.badge[styleType].active.text};
        }
      `};
  `}
`;

export const Icon = styled.span<
  Pick<BadgeProps, 'styleType' | 'nativeIconColor'>
>`
  display: flex;
  margin-right: 4px;

  ${({ theme, styleType, nativeIconColor }) => css`
    svg {
      path {
        fill: ${!nativeIconColor
          ? theme.config.text.primary
          : theme.config.badge[styleType!].default.text} !important;
      }
    }
  `}
`;

export const Count = styled.h3<{ color?: TextColor }>`
  font-size: 16px;
  font-weight: 700;
  ${({ theme, color }) => css`
    margin-left: ${theme.responsive.isMobile ? '4px' : '8px'};
    color: ${theme.config.text[color ?? TextColor.Primary]};
  `}
`;

export const Container = styled.div`
  gap: 8px;
  min-width: 0;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  ${bodySemibold};
`;

export const Badges = styled.div<BadgesProps>`
  display: inline-flex;

  ${({ gap, inline }) => css`
    flex-wrap: ${inline === true ? 'nowrap' : 'wrap'};
    gap: ${gap}px;
  `}
`;

export const Dot = styled.span<Required<Pick<BadgeProps, 'styleType'>>>`
  ${({ theme, styleType }) => {
    const config = theme.config.badge[styleType];

    if ('dot' in config) {
      return css`
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        margin-right: 8px;
        border-radius: 50%;
        background-color: ${config.dot};
      `;
    }
  }}
`;
