import { useNavigate } from 'hooks';
import { formatPhone } from 'utils/helpers';
import { Supplier } from 'store/api/supplier/types';
import { ListCard, EllipsisText } from 'components';

import { titles, cellsMobile } from './config';

export const Card = ({ data }: { data: Supplier }) => {
  const navigate = useNavigate();

  const values = [
    <EllipsisText className="semibold">{data.name}</EllipsisText>,
    data.address,
    data.contactName,
    data.contactEmail,
    formatPhone(data.contactMobile),
    formatPhone(data.contactLandline),
    formatPhone(data.contactFax),
  ];

  return (
    <ListCard
      values={values}
      headers={titles}
      cellsMobile={cellsMobile}
      onClick={() => navigate(String(data.id))}
    />
  );
};
