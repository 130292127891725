import { Messages, AppRoutes } from 'config';
import { useNavigate, handleMutation } from 'hooks';
import { useCreateMutation } from 'store/api/users';

import { Form } from './form';

export const Create = () => {
  const navigate = useNavigate();
  const [create, mutation] = useCreateMutation();

  handleMutation<{ id: number }>({
    ...mutation,
    successMessage: `User ${Messages.ADDED_SUCCESSFULLY}`,
    onSuccess: (data) => data && navigate(`${AppRoutes.Users.Home}/${data.id}`),
  });

  return <Form onSubmit={create} />;
};
