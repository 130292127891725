import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Messages } from 'config';
import { Details } from 'components';
import { mapQuery } from 'utils/query';
import { useAppSelector } from 'store';
import { selectAuthUser } from 'store/slices/auth';
import { useListQuery } from 'store/api/inventory';
import { useAlert, useNavigate, handleMutation } from 'hooks';
import { useEditMutation, useDetailsQuery } from 'store/api/stock-transfers';

import { Form } from './form';

const Edit = () => {
  const authUser = useAppSelector(selectAuthUser);
  const { id } = useParams();
  const navigate = useNavigate();
  const { showSuccessAlert } = useAlert();
  const [edit, mutation] = useEditMutation();

  const { data, ...props } = useDetailsQuery(id!);
  const { data: sourceStorageProducts } = useListQuery(
    String(data?.sourceStorage?.id)
  );

  const onSuccess = useCallback(() => {
    showSuccessAlert(`Transfer ${Messages.UPDATED_SUCCESSFULLY}`);
    navigate(-1);
  }, []);

  handleMutation({ ...mutation, onSuccess });

  return (
    <Details {...mapQuery(props)}>
      {data && (
        <Form
          onSubmit={edit}
          initialValues={{
            ...data,
            date: new Date(data.date),
            sourceCompanyId: authUser?.company?.id,
            companyTransferType: data.sourceStorage?.type,
            items: data.items.map((item) => ({
              cases: item.cases,
              price: item.price,
              units: item.units,
              count: String(item.count),
              productId: item.productId,
              totalPrice: item.price * item.count,
              maxCount: sourceStorageProducts?.find(
                (product) => product.productId === item.productId
              )?.count,
            })),
          }}
        />
      )}
    </Details>
  );
};

export default Edit;
