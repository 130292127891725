import * as Yup from 'yup';

import { Role, UserTypes } from 'types';
import { getUploadedFile } from 'utils/helpers';
import { UserData, UserFormValues } from 'store/api/users';

export const validationSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required('Name is required'),
  role: Yup.string().required('Role is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string().when('id', {
    is: (id: number | undefined) => !id,
    then: (schema) => schema.required('Password is required'),
  }),
  companyId: Yup.string().when(['role', 'id'], {
    then: (schema) => schema.required('Company is required'),
    is: (role: Role, id: number) => UserTypes.client.includes(role) && !id,
  }),
});

export const getInitialValues = (
  data: UserData | undefined
): UserFormValues => {
  const values: UserFormValues = {
    password: '',
    id: data?.id || '',
    name: data?.name || '',
    role: data?.role?.name,
    email: data?.email || '',
    photo: getUploadedFile(data?.photoUrl),
  };

  if (!data?.id) {
    values.companyId = undefined;
  }

  return values;
};
