import { useAlert } from 'hooks';
import { handleFormSubmitWithFiles } from 'utils/helpers';
import {
  Row,
  Col,
  PaymentsForm,
  DropZoneContent,
  FileDropzoneField,
} from 'components';

import { FormProps, FormValues } from './types';
import { acceptFileTypes, getInitialValues, validationSchema } from './config';

export const PaymentReimbursementForm = ({
  data,
  isEdit,
  onSubmit,
  isDialog,
  ...props
}: FormProps) => {
  const { handleError } = useAlert();

  return (
    <PaymentsForm<FormValues>
      {...props}
      isEdit={isEdit}
      isDialog={isDialog}
      title="reimbursement"
      validationSchema={validationSchema}
      initialValues={getInitialValues(data)}
      onSubmit={handleFormSubmitWithFiles({
        data,
        onSubmit,
        handleError,
        files: [
          {
            formFieldName: 'receipt',
            dataFieldName: 'receiptUrl',
            requestFieldName: 'receiptUrl',
          },
        ],
      })}
    >
      {({ values }) => (
        <Row>
          <Col col={isDialog ? undefined : 4}>
            <FileDropzoneField
              errorMessage
              name="receipt"
              label="Upload receipt"
              accept={acceptFileTypes}
            >
              <DropZoneContent file={values?.receipt} />
            </FileDropzoneField>
          </Col>
        </Row>
      )}
    </PaymentsForm>
  );
};
