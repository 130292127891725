import { useMemo } from 'react';
import { useField } from 'formik';

import { UseValidationByRules } from './types';

export const useValidationByRules: UseValidationByRules = (
  fieldName,
  rules
) => {
  const [field] = useField(fieldName);

  return useMemo(
    () =>
      Object.fromEntries(
        Object.entries(rules).map(([ruleName, validate]) => [
          ruleName,
          validate(field.value),
        ])
      ),
    [field.value]
  );
};
