import styled from 'styled-components';

import { Icon } from 'components/icon';

export const Search = styled(Icon.Search)`
  margin-left: 12px;
  path {
    fill: ${(props) => props.theme.config.text.primary};
  }
`;
