import { ComponentProps } from 'react';

import { Messages } from 'config';
import { useNavigate, handleMutation } from 'hooks';
import { useCreateMutation } from 'store/api/customers';

import { Form } from './form';

export interface CreateProps
  extends Omit<ComponentProps<typeof Form>, 'onSubmit'> {
  navigateUrl: ((data: { id: number }) => string) | string;
}

export const Create = ({
  navigateUrl,
  initialValues,
  employeeOptions,
}: CreateProps) => {
  const navigate = useNavigate();
  const [create, mutation] = useCreateMutation();

  handleMutation<{ id: number }>({
    ...mutation,
    successMessage: `Customer ${Messages.ADDED_SUCCESSFULLY}`,
    onSuccess: (data) =>
      data &&
      navigate(
        typeof navigateUrl === 'function' ? navigateUrl(data) : navigateUrl,
        { replace: true }
      ),
  });

  return (
    <Form
      onSubmit={create}
      initialValues={initialValues}
      employeeOptions={employeeOptions}
    />
  );
};
